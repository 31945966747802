var $ = window.$ || window.jQuery,
    WKT = require('./wkt.js'),

accentMap = {'à': 'a','á': 'a','â': 'a','ã': 'a','ä': 'a','å': 'a','æ': 'ae','ç': 'c','è': 'e','é': 'e','ê': 'e','ë': 'e','ì': 'i','í': 'i','î': 'i','ï': 'i','ò': 'o','ó': 'o','ô': 'o','õ': 'o','ö': 'o','œ': 'oe','ù': 'u','ú': 'u','û': 'u','ü': 'u','ý': 'y','ÿ': 'y'},

/**
 * normalisation de chaine (suppression des accents et passage en minuscules) - utilisé pour la recherche
 * @param  {string} s la chaine à normaliser
 * @return {string}   la chaine normalisée
 */
normalizeStr = function(s) {
  if (!s) { return ''; }
  s = s.trim().toLowerCase();
  var ret = '';
  for (var i=0; i<s.length; i++) {
    ret += accentMap[s.charAt(i)] || s.charAt(i);
  }
  return ret;
},

coordsToLatLng = function(coordinates) {
  if (coordinates.length == 2 && typeof coordinates[0] == 'number')
    return [coordinates[1], coordinates[0]];
  if (coordinates.length)
    return coordinates.map(coordsToLatLng);
  return coordinates;
},

searchEngine = {

  tplTruckProfiles: '',

  tplLayout:
    '<div id="search-bar" class="input-group" style="display: none">' +
      '<input id="search-input" data-result-list="#search-result-list" type="text" placeholder="{{search}} ..." class="form-control" autocomplete="off">' +
      '<div class="input-group-addon">' +
        '<button id="search-submit-btn" title="{{search}}" class="btn btn-link"><span class="glyphicon glyphicon-menu-right"></span></button>' +
        '<button id="locate-btn" title="{{locate_me}}" class="btn btn-link"><span class="glyphicon glyphicon-screenshot"></span></button>' +
      '</div>' +
      '<ul id="search-result-list" data-input="#search-input"></ul>' +
    '</div>' +
    '<div id="route-bar" style="display: none">' +
      '<div class="form-group form-inline">' +
        '<input id="route-start-input" data-result-list="#route-start-result-list" type="text" placeholder="{{route_start_lbl}} ..." class="form-control" autocomplete="off">' +
        '<button id="route-start-marker-btn" title="{{route_marker_tooltip}}" class="btn btn-link"><span class="glyphicon glyphicon-map-marker"></span></button>' +
        '<button id="route-start-locate-btn" title="{{locate_me}}" class="btn btn-link"><span class="glyphicon glyphicon-screenshot"></span></button>' +
      '</div>' +
      '<ul id="route-start-result-list" data-input="#route-start-input"></ul>' +
      '<div class="form-group form-inline">' +
        '<input id="route-end-input" data-result-list="#route-end-result-list" type="text" placeholder="{{route_end_lbl}} ..." class="form-control" autocomplete="off">' +
        '<button id="route-end-marker-btn" title="{{route_marker_tooltip}}" class="btn btn-link"><span class="glyphicon glyphicon-map-marker"></span></button>' +
        '<button id="route-end-locate-btn" title="{{locate_me}}" class="btn btn-link"><span class="glyphicon glyphicon-screenshot"></span></button>' +
      '</div>' +
      '<ul id="route-end-result-list" data-input="#route-end-input"></ul>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label">{{route_mode_lbl}}</label>' +
        '<select id="route-transport-select" class="form-control">' +
          '<option value="PassengerCar">{{route_mode_car}}</option>' +
          '<option value="Taxi">{{route_mode_taxi}}</option>' +
//        '<option value="PublicBus">{{route_mode_bus}}</option>' +
          '<option value="Emergency">{{route_mode_emergency}}</option>' +
          '<option value="Bicycle">{{route_mode_bike}}</option>' +
          '<option value="Pedestrian">{{route_mode_walk}}</option>' +
          '{{tplTruckProfiles}}' +
        '</select>' +
      '</div>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label">{{route_criteria_lbl}}</label>' +
        '<label class="radio-inline"><input type="radio" name="route-criteria" value="fastest" checked> {{route_criteria_fastest}}</label>' +
      '</div>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label"></label>' +
        '<label class="radio-inline"><input type="radio" name="route-criteria" value="shortest"> {{route_criteria_shortest}}</label>' +
      '</div>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label">{{route_avoid_lbl}}</label>' +
        '<label class="checkbox-inline"><input type="checkbox" id="route-no-motorway"> {{route_avoid_motorway}}</label>' +
      '</div>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label"></label>' +
        '<label class="checkbox-inline"><input type="checkbox" id="route-no-toll"> {{route_avoid_toll}}</label>' +
      '</div>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label"></label>' +
        '<label class="checkbox-inline"><input type="checkbox" id="route-no-ferry"> {{route_avoid_ferry}}</label>' +
      '</div>' +
      '<div class="form-group form-inline">' +
        '<label class="form-label"></label>' +
        '<button class="btn btn-primary" id="route-submit-btn">{{route_submit}}</button>' +
        '<button class="btn btn-link" id="route-remove-btn" style="display:none">{{route_remove}}</button>' +
      '</div>' +
      '<div class="form-group form-inline text-danger" id="route-error"></div>' +
    '</div>',

  _marker: null,
  _marker2: null,
  _route: null,
  _highlightedModule: null,

  addressSearchMinLen:  4, // Nombre de caractères minimum avant de faire une requête à nominatim
  entitySearchMinLen :  2, // Nombre de caractères minimum avant de faire rechercher une entité
  addressMaxResults  :  5, // Nombre maximum d'adresses dans la liste de résultats
  entityMaxResults   : 10, // Nombre maximum d'entités dans la liste de résultats

  /**
   * Affiche un marker sur la carte avec une infobulle
   * @param {string} lat latitude du marker à afficher
   * @param {string} lon longitude du marker à afficher
   * @param {string} name nom complet de l'adresse recherché (s'affiche dans le popup)
   * @param {object} options
   *                  * {string} markerName   nom du marqueur ("_marker" ou "_marker2")
   *                  * {bool} draggable      marqueur déplacable par l'utilisateur. false par défaut
   *                  * {function} onDragend  callback lorsque le marqueur est déplacé par l'utilisateur (si `draggable` est true)
   *                  * {bool} clear          supprimer tous les marqueurs existants. false par défaut
   *                  * {bool} fireEvent      génère un événement "markeradded". false par défaut
   * @return void
   *
   */
  displayMarker: function(lat, lon, name, options){
    var self = this,
        marker,
        latlng = L.latLng(lat, lon);

    if(this.parent.webView && lat && lon){
      this.clear(options.clear ? ["_marker", "_marker2"] : [options.markerName]);
      this[options.markerName] = marker = L.marker(latlng, options.draggable ? { draggable: true} : {});
      var div = $('<div></div>');
      div.on('click', 'button', function() {
        self.parent.webView.map.setView(latlng, 16, { animate: true });
      });
      div.html(name + '<p class="text-center"><button class="btn btn-link">' + __('search_goto') + '</button></p>');
      marker.bindPopup(div[0]);
      if (options.clear) {
        marker.on("popupclose", function(){
          self.parent.fire('markerremoved', { marker: self._marker });
          self.clear();
        });
      }
      marker.on('dragend', function() {
        div.html("lat : " + marker.getLatLng().lat.toFixed(5) + "<br/>lng : " + marker.getLatLng().lng.toFixed(5));
        if (self._route) {
          self._route.remove();
          self._route = null;
        }
        if (options.onDragend)
          options.onDragend(marker.getLatLng());
      });
      marker.addTo(this.parent.webView.map);
      marker.openPopup();
      marker.name = name;
      this.parent.webView.map.panTo(latlng);
      if (options.fireEvent)
        this.parent.fire('markeradded', { marker: marker });
    }
  },

  /**
   * Recherche de plusieurs adresses via nominatim et affichent le résultat dans une liste déroulante
   * @param {string} query l'adresse recherchée. Si le paramètre n'est pas spécifié, le contenu du champ de recherche #search-input sera utilisé
   * @return void
   *
   */
  fetchResult: function(input, $resultList, query){
    var self = this;
    query = query || input.value;
    if (query == ""){
      return;
    }

    clearTimeout(this.searchNominatim);

    this.searchNominatim = window.setTimeout(function() {
      var addresses = [],
          entities = [],
          adressesDeferred, entitiesDeferred;

      // recherche par adresse
      if (self.baseUrl && query.length >= self.addressSearchMinLen) {
        adressesDeferred = $.getJSON(self.baseUrl + "?format=json&limit=" + self.addressMaxResults + (self.countryCodes != "" ? ("&countrycodes=" + self.countryCodes) : "") + "&polygon_geojson=1&q=" + query)
        .done(function(results) { addresses = results; })
        .error(function() { addresses = "error"; });
      } else {
        adressesDeferred = $.Deferred().resolve();
      }

      // recherche par entités
      if (self.searchDbDeferred && query.length >= self.entitySearchMinLen) {
        // on attend que le fichier _search-db.json soit chargé pour effectuer la recherche
        entitiesDeferred = self.searchDbDeferred.done(function() {
          var query2 = normalizeStr(query).split(' '),
              match, q;
          searchLoop :
          for (var key in self.searchDb.entities) {
            for(var id in self.searchDb.entities[key]) {
              match = query2.length > 0;
              for (q = query2.length - 1; q >= 0; q--) {
                match = match && (normalizeStr(id).indexOf(query2[q]) === 0 || self.searchDb.entities[key][id].norm.indexOf(query2[q]) > -1);
              }
              if (match) {
                // on a trouvé une entité dont le nom ou l'id répond
                var targets = [];
                for(var f in self.searchDb.files) {
                  for(var m in self.searchDb.files[f]) {
                    if (self.searchDb.files[f][m].entitiesHash == key) {
                      targets.push({ filename: f, details: self.searchDb.files[f][m], resid: m });
                    }
                  }
                }

                if (targets.length) { // Si on a au moins une cible
                  currentDetails = self.parent.fileList[self.parent._currentFilename];

                  // on choisit la cible la plus adaptée en fonction de la carte actuellement affichée
                  // en 1er, on cherche une cible ayant le même numéro de visualisation, puis le même niveau, puis le même identifiant de carte
                  targets.sort(function(a, b) {
                    if (a.details.order     == currentDetails.order     && b.details.order     != currentDetails.order)     return -1;
                    if (a.details.order     != currentDetails.order     && b.details.order     == currentDetails.order)     return  1;
                    if (a.details.level     == currentDetails.level     && b.details.level     != currentDetails.level)     return -1;
                    if (a.details.level     != currentDetails.level     && b.details.level     == currentDetails.level)     return  1;
                    if (a.details.featureId == currentDetails.featureId && b.details.featureId != currentDetails.featureId) return -1;
                    if (a.details.featureId != currentDetails.featureId && b.details.featureId == currentDetails.featureId) return  1;
                    return 0;
                  });
                  entities.push({ key: key, id: id, name: self.searchDb.entities[key][id].s, target: targets[0].filename, context: targets[0].details.context, resid: targets[0].resid });

                  // on interromp la recherche si on a atteint le nombre maxi de résultats
                  if (entities.length >= self.entityMaxResults) {
                    break searchLoop;
                  }
                }
              }
            }
          }
        });
      } else {
        entitiesDeferred = $.Deferred().resolve();
      }

      // on attend de disposer des résultats des 2 recherches
      $.when(adressesDeferred, entitiesDeferred).done(function() {
        $resultList.empty();

        if (addresses == "error") {
          $resultList.append('<li class="search-result-element" >'+ __('service_unavailable')+'</li>');
        }

        if (!addresses.length && !entities.length) {
          $resultList.append('<li class="search-result-element" >'+ __('search_no_result')+'</li>');
        } else {
          //On recherche les caractères à mettre en surbrillance
          var arrayWord = query.split(' ');

          //On liste les mots du plus long au plus court (feinte pour bien surligner les bons mots) - Exemple : recherche de "place de deplace"
          arrayWord.sort(function(a, b) { return b.length - a.length; });

          if (addresses.length && entities.length) {
            $resultList.append('<li class="search-result-category">' + __('search_cat_addresses') + '</li>');
          }

          // on affiche les résultats de la recherche d'adresses
          addresses.forEach(function(result){
            var displayName = result.display_name;

            for (var i = 0; i < arrayWord.length; i++) {
              if (arrayWord[i] && arrayWord[i] != ' ') {
                var re = new RegExp(arrayWord[i],"gi");
                displayName = displayName.replace(re, '<b>' + arrayWord[i] + '</b>');
              }
            }

            //On ajoute une ligne dans la liste des adresses trouvés
            $resultList.append('<li class="search-result-element" data-lon="' + result.lon + '" data-lat="' + result.lat + '">' + displayName + '</li>');
          });

          if (addresses.length && entities.length) {
            $resultList.append('<li class="search-result-category">' + __('search_cat_entities') + '</li>');
          }

          // on affiche les résultats de la recherche d'entités
          entities.forEach(function(entity) {
            var displayName = entity.id + ' &ndash; ' + entity.name;
            if (entity.context) {
              displayName += ' (' + entity.context + ')';
            }

            for (var i = 0; i < arrayWord.length; i++) {
              if (arrayWord[i] && arrayWord[i] != ' ') {
                var re = new RegExp(arrayWord[i],"gi");
                displayName = displayName.replace(re, '<b>' + arrayWord[i] + '</b>');
              }
            }

            //On ajoute une ligne dans la liste des adresses trouvés
            $resultList.append('<li class="search-result-element" data-filename="' + entity.target + '" data-resid="' + entity.resid + '" data-id="' + entity.id + '">' + displayName + '</li>');
          });

          $resultList.show();
        }
      });

    }, 1000);
  },

  /**
   * Recherche d'itinéraire entre les marqueurs `_marker1` et `_marker2`
   * @return void
   */
  computeRoute: function() {
    var self = this;
    if (self._route) {
      self._route.remove();
      self._route = null;
    }
    if (this.parent.webView.map && this.parent.options.computeRouteUrl && this._marker && this._marker2) {
      $("#route-submit-btn").attr("disabled", "disabled").addClass("disabled");
      $("#route-error").html("");
      return $.ajax({
        url : this.parent.options.computeRouteUrl,
        dataType: 'json',
        data: {
          x1: this._marker.getLatLng().lng,
          y1: this._marker.getLatLng().lat,
          x2: this._marker2.getLatLng().lng,
          y2: this._marker2.getLatLng().lat,
          mode: $("#route-transport-select").val(),
          isFastest: $("#route-bar :radio[name=route-criteria]:checked:first").val() == "fastest",
          avoidToll: $("#route-no-toll").is(":checked"),
          avoidMotorway: $("#route-no-motorway").is(":checked"),
          avoidFerry: $("#route-no-ferry").is(":checked")
        }
      }).done(function(result) {
        var geom;
        $("#route-submit-btn").removeAttr("disabled").removeClass("disabled");
        $("#route-remove-btn").show();
        if (result.status == "success" && result.data && result.data.polyline != "") geom = WKT.parse(result.data.polyline);
        if (geom && geom.coordinates) {
          self._route = L.polyline(coordsToLatLng(geom.coordinates), { renderer: L.canvas() });
          var popupContent = $('<div></div>');
          var dist = result.data.length > 1000 ? Math.round(result.data.length / 1000) + " km" : result.data.length + " m",
              time = (result.data.time > 3600 ? Math.floor(result.data.time / 3600) + " h " : "") + Math.round((result.data.time % 3600) / 60) + " min",
              gmapLink = "https://www.google.com/maps/dir/?api=1&origin=" + self._marker.getLatLng().lat + "," + self._marker.getLatLng().lng +
                         "&destination=" + self._marker2.getLatLng().lat + "," + self._marker2.getLatLng().lng;
          switch ($("#route-transport-select").val()) {
            case "Pedestrian" : gmapLink += "&travelmode=walking"; break;
            case "Bicycle" : gmapLink += "&travelmode=bicycling"; break;
          }
          popupContent.on('click', 'button', function() {
            self.clear();
            $("#route-start-input, #route-end-input").val("");
          });
          popupContent.html(__("route_distance") + " : " + dist + "<br/>" + __("route_time") + " : " + time + '<p class="text-center"><a href="' + gmapLink + '" target="_blank" class="btn btn-link">Google maps <span class="glyphicon glyphicon-new-window"></span></a>&emsp;<button data-action="clear" class="btn btn-link">' + __('route_remove') + '</button></p>');
          self._route.bindPopup(popupContent[0]);
          self._route.addTo(self.parent.webView.map);
          self._route.openPopup();
        }
        if (result.status == "error") {
          $("#route-error").html(result.message);
        }
      });
    }
  },

  render: function(parent, domId) {
    var self = this;
    if (parent.options.nominatimUrl || parent.options.hasSearchDb) {

      if (parent.options.isochroneTruckProfiles && parent.options.isochroneTruckProfiles.length) {
        this.tplTruckProfiles = parent.options.isochroneTruckProfiles.map(function(p) { return '<option value="' + p + '">' + p + '</option>'; }).join('');
      }

      this.baseUrl = parent.options.nominatimUrl;
      this.countryCodes = parent.options.nominatimCountryCodes.join(",");
      this.searchDb = null;
      this.parent = parent;
      $('#' + domId).append(parent.renderTpl(this.tplLayout, this));

      $('#search-input, #route-start-input, #route-end-input').focusin(function(){
        $(this.dataset.resultList).show();
        if (! self.searchDb && ! self.searchDbDeferred && self.parent.fileList['_search-db.json']) {
          self.searchDbDeferred = $.ajax({ url: self.parent._buildUrl(self.parent.options.displayingInfosUrl, { filename: '_search-db.json' }), dataType: 'json' })
            .done(function(data) {
              self.searchDb = data || "load_error";
              if (data) {
                for (var key in self.searchDb.entities) {
                  for(var id in self.searchDb.entities[key]) {
                    self.searchDb.entities[key][id] = { s : self.searchDb.entities[key][id], norm : normalizeStr(self.searchDb.entities[key][id]) };
                  }
                }
              }
            });
          self.parent.on('maphasloaded', self._hightlightTarget.bind(self));
        }
      }).focusout(function(){
        var resultList = $(this.dataset.resultList);
        setTimeout(function() { resultList.hide(); }, 250);
      });

      $('#search-input, #route-start-input, #route-end-input').keyup(function(e){
        var $resultList = $(this.dataset.resultList),
            keyPressed = e.keyCode || e.which;
        if (keyPressed == 40) { // bas
          if ($resultList.find("li.active").length > 0) {
            var storeTarget1	= $resultList.find("li.active").next();
            $resultList.find("li.active").removeClass("active");
            storeTarget1.focus().addClass("active");
          } else {
            $resultList.find("li:first").focus().addClass("active");
          }
        } else if (keyPressed == 38) { // haut
          if ($resultList.find("li.active").length > 0) {
            var storeTarget	= $resultList.find("li.active").prev();
            $resultList.find("li.active").removeClass("active");
            storeTarget.focus().addClass("active");
          } else {
            $resultList.find("li:first").focus().addClass("active");
          }
        } else if (keyPressed == 27) { // echap
          $resultList.hide();
        } else if (keyPressed == 13) { // entrée
          if ($resultList.find("li.active").length > 0) {
            $resultList.find("li.active").trigger('click'); //On sélectionne celui qui est sélectionné
          } else if ($resultList.find("li").length > 0) {
            $resultList.find("li:first").trigger('click'); //On sélectionne le premier
          } else {
            self.fetchResult(this, $resultList); // on lance la recherche
          }
        } else { // cas général
          var value = $(this).val();
          $resultList.empty();
          if (value.length >= self.addressSearchMinLen || value.length >= self.entitySearchMinLen) {
            self.fetchResult(this, $resultList);
          }
        }
      });

      //Event bubbling pour les éléments que l'on fera apparaître
      $('#search-result-list').on("click", "li", function(){
        //On masque les choix si cela a un intérêt
        $('#search-result-list').empty().hide();
        if (this.dataset.lat && this.dataset.lon) {
          self.displayMarker(this.dataset.lat, this.dataset.lon, this.innerText, { markerName: "_marker", clear: true, fireEvent: true });
        } else if (this.dataset.filename && this.dataset.resid && this.dataset.id) {
          if (parent) {
            self._highlightDetails = { filename: this.dataset.filename, resid: this.dataset.resid, id: this.dataset.id };
            if (self.parent._currentFilename == this.dataset.filename) {
              // on est déjà sur la bonne carte
              self._hightlightTarget({ filename: this.dataset.filename });
            } else {
              parent.navigateTo(this.dataset.filename, null, "searchEngine");
            }
          }
        }

      });

      $('#route-start-result-list').on("click", "li", function(){
        $('#route-start-result-list').empty().hide();
        $('#route-end-input').focus();
        if (this.dataset.lat && this.dataset.lon) {
          self.displayMarker(this.dataset.lat, this.dataset.lon, this.innerText, {
            markerName: "_marker",
            draggable: true,
            onDragend: function(latlng) { $("#route-start-input").val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); }
          });
        } else if (this.dataset.filename && this.dataset.resid && this.dataset.id) {
          if (parent) {
            self._highlightDetails = {
              filename: this.dataset.filename,
              resid: this.dataset.resid,
              id: this.dataset.id,
              marker: "_marker"
            };
            if (self.parent._currentFilename == this.dataset.filename) {
              // on est déjà sur la bonne carte
              self._hightlightTarget({ filename: this.dataset.filename, onDragend: function(latlng) { $("#route-start-input").val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); } });
            } else {
              parent.navigateTo(this.dataset.filename, null, "searchEngine");
            }
          }
        }
      });

      $('#route-end-result-list').on("click", "li", function(){
        $('#route-end-result-list').empty().hide();
        if (this.dataset.lat && this.dataset.lon) {
          self.displayMarker(this.dataset.lat, this.dataset.lon, this.innerText, {
            markerName: "_marker2",
            draggable: true,
            onDragend: function(latlng) { $("#route-end-input").val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); }
          });
        } else if (this.dataset.filename && this.dataset.resid && this.dataset.id) {
          if (parent) {
            self._highlightDetails = {
              filename: this.dataset.filename,
              resid: this.dataset.resid,
              id: this.dataset.id,
              marker: "_marker2"
            };
            if (self.parent._currentFilename == this.dataset.filename) {
              // on est déjà sur la bonne carte
              self._hightlightTarget({ filename: this.dataset.filename, onDragend: function(latlng) { $("#route-end-input").val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); } });
            } else {
              parent.navigateTo(this.dataset.filename, null, "searchEngine");
            }
          }
        }
      });

      $('#search-result-list, #route-start-result-list, #route-end-result-list').on("mouseover", "li", function(){
        $("#search-result-list li.active, #route-start-result-list li.active, #route-end-result-list li.active").removeClass("active");
      });

      $("#route-submit-btn").click(function() { self.computeRoute(); });
      $("#route-remove-btn").click(function() { self.clear(); $("#route-start-input, #route-end-input").val(""); });

      $('#search-submit-btn').click(function() { self.fetchResult($("#search-input")[0], $("#search-result-list")); });

      $('#locate-btn').click(function(){
        self.parent.webView.map.once('locationfound', function(e){
          self.displayMarker(e.latlng.lat, e.latlng.lng, __("my_location") +" <br/> lat : " + e.latlng.lat.toFixed(5) + "<br/> lng : " + e.latlng.lng.toFixed(5), { markerName: "_marker", clear: true, fireEvent: true });
        });
        self.parent.webView.map.once('locationerror', function(e){ alert(e.message); });
        self.parent.webView.map.locate();
      });

      $('#route-start-locate-btn').click(function(){
        self.parent.webView.map.once('locationfound', function(e){
          self.displayMarker(e.latlng.lat, e.latlng.lng, __("my_location") +" <br/> lat : " + e.latlng.lat.toFixed(5) + "<br/> lng : " + e.latlng.lng.toFixed(5), { markerName: "_marker", draggable: true, onDragend: function(latlng) { $("#route-start-input").val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); } });
          $("#route-start-input").val(e.latlng.lat.toFixed(5) + ", " + e.latlng.lng.toFixed(5));
        });
        self.parent.webView.map.once('locationerror', function(e){ alert(e.message); });
        self.parent.webView.map.locate();
      });

      $('#route-end-locate-btn').click(function(){
        self.parent.webView.map.once('locationfound', function(e){
          self.displayMarker(e.latlng.lat, e.latlng.lng, __("my_location") +" <br/> lat : " + e.latlng.lat.toFixed(5) + "<br/> lng : " + e.latlng.lng.toFixed(5), { markerName: "_marker2", draggable: true, onDragend: function(latlng) { $("#route-end-input").val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); } });
          $("#route-end-input").val(e.latlng.lat.toFixed(5) + ", " + e.latlng.lng.toFixed(5));
        });
        self.parent.webView.map.once('locationerror', function(e){ alert(e.message); });
        self.parent.webView.map.locate();
      });

      this._onAddMarker = function(marker, $input, e) {
        self.displayMarker(
          e.latlng.lat,
          e.latlng.lng,
          "lat : " + e.latlng.lat.toFixed(5) + "<br/> lng : " + e.latlng.lng.toFixed(5),
          {
            markerName: marker,
            draggable: true,
            onDragend: function(latlng) { $input.val(latlng.lat.toFixed(5) + ", " + latlng.lng.toFixed(5)); }
          }
        );
        $input.val(e.latlng.lat.toFixed(5) + ", " + e.latlng.lng.toFixed(5));
        $("#route-start-marker-btn, #route-end-marker-btn").removeClass("active");
        self.parent.webView.map._container.style.cursor = '';
      };
      this._onAddMarker1 = this._onAddMarker.bind(this, '_marker', $("#route-start-input"));
      this._onAddMarker2 = this._onAddMarker.bind(this, '_marker2', $("#route-end-input"));
      $("#route-start-marker-btn").on("click", function() {
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          self.parent.webView.map.off('click', self._onAddMarker1, self);
          self.parent.webView.map._container.style.cursor = '';
        } else {
          $(this).addClass("active");
          $("#route-end-marker-btn").removeClass("active");
          self.parent.webView.map.off('click', self._onAddMarker2, self);
          self.parent.webView.map.once('click', self._onAddMarker1, self);
          self.parent.webView.map._container.style.cursor = 'url(\'' + window.multiSelectCursorPath + '/crosshair-isochrone-toggle.cur\'), crosshair';
        }
      });
      $("#route-end-marker-btn").on("click", function() {
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          self.parent.webView.map.off('click', self._onAddMarker2, self);
          self.parent.webView.map._container.style.cursor = '';
        } else {
          $(this).addClass("active");
          $("#route-start-marker-btn").removeClass("active");
          self.parent.webView.map.off('click', self._onAddMarker1, self);
          self.parent.webView.map.once('click', self._onAddMarker2, self);
          self.parent.webView.map._container.style.cursor = 'url(\'' + window.multiSelectCursorPath + '/crosshair-isochrone-toggle.cur\'), crosshair';
        }
      });

      self.parent.on('toggleShareBtn', function(opt){
        if (opt.enabled);
          $("#search-bar,#route-bar").hide();
      });

      self.parent.on('paneltoggled', function(state) {
        if (! state.panels.toolbar.open) {
          $("#search-bar, #route-bar").hide();
        }
      });

      self.parent.on('mapwillload', self.clear.bind(self, null));

    }
  },

  /**
   * Mets en surbrillance une entité recherchée
   * @param  {Object} details détails de l'entité à mettre en surbrillance
   * @return void
   */
  _hightlightTarget: function(details) {
    if (this._highlightDetails && this._highlightDetails.filename == details.filename) {
      this.parent.webView.moduleGroup.getModules().forEach(function(module) {
        if (module._cd_id == this._highlightDetails.resid) {
          if (this._highlightedModule) {
            this._highlightedModule = null;
          }
          module.selectByIds([this._highlightDetails.id]);
          var selection = module.getSelection();
          if (selection.length == 1) {
            this.displayMarker(
              selection[0].center.y,
              selection[0].center.x,
              selection[0].properties.Id + ' &ndash; ' + selection[0].properties.Name,
              {
                markerName: this._highlightDetails.marker || "_marker",
                clear: ! this._highlightDetails.marker,
                draggable: !! this._highlightDetails.marker,
                fireEvent: ! this._highlightDetails.marker,
                onDragend: details.onDragend || null
              });
          }
          this._highlightedModule = module;
        }
      }, this);
      this._highlightDetails = null;
    }
  },

  /**
    * Permet d'activer / désactiver la recherche d'adresse dans l'atlas
    */
  toggleSearch: function(){
    var self = this;
    $("#search-bar").toggle(0, function() {
      self.clear();
      $("#route-bar").hide();
      $("#route-start-input, #route-end-input").val("");
    });
    $("#search-input").focus();
  },

  /**
    * Permet d'activer / désactiver la recherche d'itinéraire dans l'atlas
    */
  toggleRoute: function(){
    var self = this;
    self.parent.webView.map.off('click', self._onAddMarker1, self);
    self.parent.webView.map.off('click', self._onAddMarker2, self);
    $("#route-bar").toggle(0, function() {
      if ($("#search-bar").is(":visible")) {
        self.clear();
        $("#route-start-input, #route-end-input").val("");
        $("#search-bar").hide()
      }
    });
    $("#route-start-input").focus();
  },

  /**
    * Permet de supprimer le marqueur d'adresse sur la carte ainsi que les iténéraires
    */
  clear: function(markers) {
    if(this._marker && (!markers || markers.indexOf("_marker") > -1)){
      this._marker.remove();
      this._marker = null;
    }
    if (this._marker2 && (!markers || markers.indexOf("_marker2") > -1)) {
      this._marker2.remove();
      this._marker2 = null;
    }
    if (this._route) {
      this._route.remove();
      this._route = null;
      $("#route-remove-btn").hide();
    }
    if (this._highlightedModule) {
      if (this._highlightedModule._layer) {
        this._highlightedModule.clearSelection({ refresh: true });
      }
      this._highlightedModule = null;
    }
  }

}

module.exports = searchEngine;
