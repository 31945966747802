(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.zoomVisibility = {

    parent: null,

    loadExtension: function(parent) {
      this.parent = parent;
      this.parent.on('updateview', this.onUpdateView, this);
    },

    onUpdateView: function() {
      var wvc = this.parent.wvc,
          zoom = wvc.map.getZoom();
      wvc._modules.forEach(function(mod) {
        if (mod.module.customParams && mod.module.customParams.zoomVisibility) {
          var zv = mod.module.customParams.zoomVisibility;
          if (typeof zv.min !== 'undefined' && typeof zv.max !== 'undefined') {
            mod.module.options.zoomVisibility = { min: zv.min, max: zv.max };
            mod.module.applyZoomLevel(zoom);
          }
        }
      }, this);

      wvc.tileLayers.forEach(function(layer) {
        if (layer.customParams && layer.customParams.zoomVisibility) {
          var zv = layer.customParams.zoomVisibility;
          if (typeof zv.min !== 'undefined' && typeof zv.max !== 'undefined') {
            layer.options.minZoom = zv.min;
            layer.options.maxZoom = zv.max;
          }
        }
      });
    }

  };
})();
