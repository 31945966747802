var A = window.articque = window.articque || {};
A.ext = A.ext || {};

L.articque.Util.addCss(
  'div.layer-list-simple { margin: 7.5px 0; }' +
  'div.layer-list-simple label { display: block; padding: 0.7em 7.5px; margin: 0; font-weight: normal; }' +
  'div.layer-list-simple label:hover { background: #f4f4f4; }'
);

A.layerControlSimple = function(options) { return new A.LayerControlSimple(options); };

A.LayerControlSimple = L.Class.extend({

  options: {
    container: null,
    map: null,
    layers: [],
    tileLayer: null
  },

  initialize: function(options) {
    L.Util.setOptions(this, options);

    this.setLayers(this.options.layers, this.options.tileLayer);
    this._container = this.options.container;
    this._events = [];
    this._groups = {};
    if (this._container) {
      this._layout();
    }
  },

  setLayers: function(layers, tileLayers) {
    this._layerList = layers;
    this._tileLayers = tileLayers;
    if (this._container) {
      this._layout();
    }
  },

  _onClick: function(node, evt) {
    if (node instanceof L.TileLayer) {
      node.setVisible(node.input.checked);
    } else {
      node.module.setVisible(node.input.checked);
    }
    L.DomEvent.stop(evt);
    setTimeout(this._refresh.bind(this), 0);
  },

  _layout: function() {
    var status = this._status;
    this._events.forEach(function(evt) { L.DomEvent.off(evt.domNode, 'click', evt.onclick); }, this);
    this._events = [];
    this._groups = {};
    this._container.innerHTML = '';

    function buildHtml(container, type, node) {
      var lbl;
      lbl = L.DomUtil.create('label', 'cd-checkbox', container);
      lbl.innerHTML = '<input type="checkbox"/><i class="icon-checkbox"></i> ' + node.name;
      node.input = lbl.firstChild;
      var evt = { domNode: node.input, onclick: this._onClick.bind(this, node) };
      L.DomEvent.on(evt.domNode, 'click', evt.onclick);
      if (status && (status[node.name] === false)) {
        node.input.checked = false;
        if (type == "TileLayer") {
          node.setVisible(false);
        } else {
          node.module.setVisible(false);
        }
      }
      this._events.push(evt);
    }

    this._layerList.map(buildHtml.bind(this, this._container, "RepresentationModule"));

    if (this._tileLayers) {
      this._tileLayers.map(buildHtml.bind(this, this._container, "TileLayer"));
    }
    this._refresh();
  },

  /**
   * Renvoie un instantané de l'état des différentes cases à cocher
   * @return {Object} un Objet du même format que le cookie layerlist
   */
  saveStatus: function() {
    var status = {};
    this._layerList.forEach(function(node) {
      status[node.name] = node.input.checked;
    }, this);
    return status;
  },

  /**
   * Restore un état depuis un objet généré par saveStatus
   * @param  {Object} saved
   * @return void
   */
  restoreStatus: function(saved) {
    this._status = saved;
  },

  _refresh: function() {
    function refreshNode(node) {
      node.input.checked = (node instanceof L.TileLayer) ? node.isVisible() : node.module.isVisible();
    }
    this._layerList.map(refreshNode);
    if (this._tileLayers) {
      this._tileLayers.map(refreshNode);
    }
  }

});

module.exports = {

  parent: null,
  container: null,
  isActive: false,

  loadExtension: function(parent) {
    var $containerParent = $('#offcanvas-layer-list');
    if (! $containerParent.length) {
      $containerParent = $('.offcanvas-menu > .offcanvas-content');
    }
    if ($containerParent.length) {
      this.parent = parent;

      this.container = document.createElement('div');
      this.container.className = 'layer-list-simple expanded-content';
      $containerParent.get(0).appendChild(this.container);

      this.layerControl = A.layerControlSimple({
        container: this.container,
        map: this.parent.wvc.map,
        layers: [],
        tileLayer: null
      });

      this.parent.on('updateview', this.onUpdateView, this);
      this.onUpdateView();
    }
  },

  onUpdateView: function() {
    if (
      this.parent.wvc._modules.filter(function(mod) { return mod.module.customParams && mod.module.customParams.layerList; }).length ||
      this.parent.wvc.tileLayers.filter(function(layer) { return layer.customParams && layer.customParams.layerList; }).length
    ) {
      // si au moins un module a un param complémentaire layerList
      this.isActive = false;
      this.layerControl.setLayers([], null); // on laisse layerListSimple vide
    } else {
      this.isActive = true;
      this.layerControl.setLayers(this.parent.wvc._modules, this.parent.wvc.tileLayers.slice().reverse());
    }
  }

};
