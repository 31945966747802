(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.legendFilterable = {
    
    loadExtension: function(parent) {
      // pour les modules qui ne sont pas encore créés
      parent.on('preaddrepresentation', this.onAddRepresentation, this);
      // pour les modules qui sont déja créés
      parent.wvc._modules.forEach(function(mod) {
        if (mod.module.customParams && mod.module.customParams.legendFilterable) {
          if (mod.module._legend && mod.module._legend.options) { 
            mod.module._legend.options.filterable = true;
            mod.module._legend.refresh();
          }
          ['size', 'shape', 'fill', 'stroke', 'orient'].forEach(function(legendType) {
            if (mod.module['_' + legendType + 'Legend'] && mod.module['_' + legendType + 'Legend'].options) {
              mod.module['_' + legendType + 'Legend'].options.filterable = true;
              mod.module['_' + legendType + 'Legend'].refresh();
            }
          });
        }
      });
    },

    onAddRepresentation: function(data) {
      var module = data.module;

      if (module.customParams && module.customParams.legendFilterable) {
        if (module.options.legend) { module.options.legend.filterable = true; }
        ['size', 'shape', 'fill', 'stroke', 'orient'].forEach(function(legendType) {
          if (module.options[legendType + 'Legend']) { module.options[legendType + 'Legend'].filterable = true; }
        });
      }
    }

  };
})();