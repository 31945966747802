var $ = window.$ || window.jQuery,
loader = {
  tplLayout: '<div class="webapp-loader readonly"><div></div><span></span></div>',

  render: function(parent, domId) {
    var $node = $(parent.renderTpl(this.tplLayout, this));
    $('#' + domId).append($node);

    this.show    = $node.show.bind($node);
    this.hide    = $node.hide.bind($node);
    this.fadeIn  = $node.fadeIn.bind($node);
    this.fadeOut = $node.fadeOut.bind($node);
  }

};

module.exports = loader;