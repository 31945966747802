(function (window, document, undefined) { // eslint-disable-line no-unused-vars
var CD = { version: '7.0' };

// polyfill Object.values
if (typeof window.Object.values == 'undefined') {
  window.Object.values = function values(obj) { var vals = []; for (var key in obj) { if (obj.hasOwnProperty(key)) { vals.push(obj[key]); } } return vals; };
}

function expose() {
  var oldCD = window.CD;

  CD.noConflict = function () {
    window.CD = oldCD;
    return this;
  };

  window.CD = CD;
}

// define Leaflet for Node module pattern loaders, including Browserify
if (typeof module === 'object' && typeof module.exports === 'object') {
  module.exports = CD;
// define Leaflet as a global L variable, saving the original L to restore later if needed
} else {
  expose();
}


/*
 * CD.Util contains various utility functions used throughout Leaflet code.
 */

CD.Util = {
  // extend an object with properties of one or more other objects
  extend: function (dest) {
    var sources = Array.prototype.slice.call(arguments, 1),
        i, j, len, src;

    for (j = 0, len = sources.length; j < len; j++) {
      src = sources[j];
      for (i in src) {
        dest[i] = src[i];
      }
    }
    return dest;
  },

  // create an object from a given prototype
  create: Object.create || (function () {
    function F() {}
    return function (proto) {
      F.prototype = proto;
      return new F();
    };
  })(),

  // bind a function to be called with a given context
  bind: function (fn, obj) {
    var slice = Array.prototype.slice;

    if (fn.bind) {
      return fn.bind.apply(fn, slice.call(arguments, 1));
    }

    var args = slice.call(arguments, 2);

    return function () {
      return fn.apply(obj, args.length ? args.concat(slice.call(arguments)) : arguments);
    };
  },

  // return unique ID of an object
  stamp: function (obj) {
    obj._cd_id = obj._cd_id || ++CD.Util.lastId;
    return obj._cd_id;
  },

  lastId: 0,

  // do nothing (used as a noop throughout the code)
  falseFn: function () { return false; },

  // trim whitespace from both sides of a string
  trim: function (str) {
    return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g, '');
  },

  // split a string into words
  splitWords: function (str) {
    return CD.Util.trim(str).split(/\s+/);
  },

  // set options to an object, inheriting parent's options as well
  setOptions: function (obj, options) {
    if (!obj.hasOwnProperty('options')) {
      obj.options = obj.options ? CD.Util.create(obj.options) : {};
    }
    for (var i in options) {
      obj.options[i] = options[i];
    }
    return obj.options;
  },

  isArray: Array.isArray || function (obj) {
    return (Object.prototype.toString.call(obj) === '[object Array]');
  },

  // propertyName => property-name
  camel2Hyphen: function(str) {
    return str.replace(/\W+/g, '-')
              .replace(/([a-z\d])([A-Z])/g, '$1-$2')
              .toLowerCase();
  },

  // property-name => propertyName
  hyphen2Camel: function(str) {
    return str.replace(/\W+(.)/g, function (x, chr) {
      return chr.toUpperCase();
    });
  }
};

// shortcuts for most used utility functions
CD.extend = CD.Util.extend;
CD.bind = CD.Util.bind;
CD.stamp = CD.Util.stamp;
CD.setOptions = CD.Util.setOptions;


/*
 * CD.Class powers the OOP facilities of the library.
 * Thanks to John Resig and Dean Edwards for inspiration!
 */

CD.Class = function () {};

CD.Class.extend = function (props) {

  // extended class with the new prototype
  var NewClass = function () {

    // call the constructor
    if (this.initialize) {
      this.initialize.apply(this, arguments);
    }

    // call all constructor hooks
    if (this._initHooks.length) {
      this.callInitHooks();
    }
  };

  var parentProto = NewClass.__super__ = this.prototype;

  var proto = CD.Util.create(parentProto);
  proto.constructor = NewClass;

  NewClass.prototype = proto;

  //inherit parent's statics
  for (var i in this) {
    if (this.hasOwnProperty(i) && i !== 'prototype') {
      NewClass[i] = this[i];
    }
  }

  // mix static properties into the class
  if (props.statics) {
    CD.extend(NewClass, props.statics);
    delete props.statics;
  }

  // mix includes into the prototype
  if (props.includes) {
    CD.Util.extend.apply(null, [proto].concat(props.includes));
    delete props.includes;
  }

  // merge options
  if (proto.options) {
    props.options = CD.Util.extend(CD.Util.create(proto.options), props.options);
  }

  // mix given properties into the prototype
  CD.extend(proto, props);

  proto._initHooks = [];

  // add method for calling all hooks
  proto.callInitHooks = function () {

    if (this._initHooksCalled) { return; }

    if (parentProto.callInitHooks) {
      parentProto.callInitHooks.call(this);
    }

    this._initHooksCalled = true;

    for (var i = 0, len = proto._initHooks.length; i < len; i++) {
      proto._initHooks[i].call(this);
    }
  };

  return NewClass;
};


// method for adding properties to prototype
CD.Class.include = function (props) {
  CD.extend(this.prototype, props);
};

// merge new default options to the Class
CD.Class.mergeOptions = function (options) {
  CD.extend(this.prototype.options, options);
};

// add a constructor hook
CD.Class.addInitHook = function (fn) { // (Function) || (String, args...)
  var args = Array.prototype.slice.call(arguments, 1);

  var init = typeof fn === 'function' ? fn : function () {
    this[fn].apply(this, args);
  };

  this.prototype._initHooks = this.prototype._initHooks || [];
  this.prototype._initHooks.push(init);
};


/*
 * CD.Evented is a base class that Leaflet classes inherit from to handle custom events.
 */

CD.Evented = CD.Class.extend({

  on: function (types, fn, context) {

    // types can be a map of types/handlers
    if (typeof types === 'object') {
      for (var type in types) {
        // we don't process space-separated events here for performance;
        // it's a hot path since Layer uses the on(obj) syntax
        this._on(type, types[type], fn);
      }

    } else {
      // types can be a string of space-separated words
      types = CD.Util.splitWords(types);

      for (var i = 0, len = types.length; i < len; i++) {
        this._on(types[i], fn, context);
      }
    }

    return this;
  },

  off: function (types, fn, context) {

    if (!types) {
      // clear all listeners if called without arguments
      delete this._events;

    } else if (typeof types === 'object') {
      for (var type in types) {
        this._off(type, types[type], fn);
      }

    } else {
      types = CD.Util.splitWords(types);

      for (var i = 0, len = types.length; i < len; i++) {
        this._off(types[i], fn, context);
      }
    }

    return this;
  },

  // attach listener (without syntactic sugar now)
  _on: function (type, fn, context) {

    var events = this._events = this._events || {},
        contextId = context && context !== this && CD.stamp(context);

    if (contextId) {
      // store listeners with custom context in a separate hash (if it has an id);
      // gives a major performance boost when firing and removing events (e.g. on map object)

      var indexKey = type + '_idx',
          indexLenKey = type + '_len',
          typeIndex = events[indexKey] = events[indexKey] || {},
          id = CD.stamp(fn) + '_' + contextId;

      if (!typeIndex[id]) {
        typeIndex[id] = {fn: fn, ctx: context};

        // keep track of the number of keys in the index to quickly check if it's empty
        events[indexLenKey] = (events[indexLenKey] || 0) + 1;
      }

    } else {
      // individual layers mostly use "this" for context and don't fire listeners too often
      // so simple array makes the memory footprint better while not degrading performance

      events[type] = events[type] || [];
      events[type].push({fn: fn});
    }
  },

  _off: function (type, fn, context) {
    var events = this._events,
        indexKey = type + '_idx',
        indexLenKey = type + '_len';

    if (!events) { return; }

    if (!fn) {
      // clear all listeners for a type if function isn't specified
      delete events[type];
      delete events[indexKey];
      delete events[indexLenKey];
      return;
    }

    var contextId = context && context !== this && CD.stamp(context),
        listeners, i, len, listener, id;

    if (contextId) {
      id = CD.stamp(fn) + '_' + contextId;
      listeners = events[indexKey];

      if (listeners && listeners[id]) {
        listener = listeners[id];
        delete listeners[id];
        events[indexLenKey]--;
      }

    } else {
      listeners = events[type];

      if (listeners) {
        for (i = 0, len = listeners.length; i < len; i++) {
          if (listeners[i].fn === fn) {
            listener = listeners[i];
            listeners.splice(i, 1);
            break;
          }
        }
      }
    }

    // set the removed listener to noop so that's not called if remove happens in fire
    if (listener) {
      listener.fn = CD.Util.falseFn;
    }
  },

  fire: function (type, data, propagate) {
    if (!this.listens(type, propagate)) { return this; }

    var event = CD.Util.extend({}, data, {type: type, target: this}),
        events = this._events;

    if (events) {
        var typeIndex = events[type + '_idx'],
            i, len, listeners, id;

      if (events[type]) {
        // make sure adding/removing listeners inside other listeners won't cause infinite loop
        listeners = events[type].slice();

        for (i = 0, len = listeners.length; i < len; i++) {
          listeners[i].fn.call(this, event);
        }
      }

      // fire event for the context-indexed listeners as well
      for (id in typeIndex) {
        typeIndex[id].fn.call(typeIndex[id].ctx, event);
      }
    }

    if (propagate) {
      // propagate the event to parents (set with addEventParent)
      this._propagateEvent(event);
    }

    return this;
  },

  listens: function (type, propagate) {
    var events = this._events;

    if (events && (events[type] || events[type + '_len'])) { return true; }

    if (propagate) {
      // also check parents for listeners if event propagates
      for (var id in this._eventParents) {
        if (this._eventParents[id].listens(type, propagate)) { return true; }
      }
    }
    return false;
  },

  once: function (types, fn, context) {

    if (typeof types === 'object') {
      for (var type in types) {
        this.once(type, types[type], fn);
      }
      return this;
    }

    var handler = CD.bind(function () {
      this
          .off(types, fn, context)
          .off(types, handler, context);
    }, this);

    // add a listener that's executed once and removed after that
    return this
        .on(types, fn, context)
        .on(types, handler, context);
  },

  // adds a parent to propagate events to (when you fire with true as a 3rd argument)
  addEventParent: function (obj) {
    this._eventParents = this._eventParents || {};
    this._eventParents[CD.stamp(obj)] = obj;
    return this;
  },

  removeEventParent: function (obj) {
    if (this._eventParents) {
      delete this._eventParents[CD.stamp(obj)];
    }
    return this;
  },

  _propagateEvent: function (e) {
    for (var id in this._eventParents) {
      this._eventParents[id].fire(e.type, CD.extend({layer: e.target}, e), true);
    }
  }
});

var proto = CD.Evented.prototype;

// aliases; we should ditch those eventually
proto.addEventListener = proto.on;
proto.removeEventListener = proto.clearAllEventListeners = proto.off;
proto.addOneTimeEventListener = proto.once;
proto.fireEvent = proto.fire;
proto.hasEventListeners = proto.listens;

CD.Mixin = {Events: proto};

// http://davidwalsh.name/pubsub-javascript
// CD.events = (function(){
//   var topics = {};

//   return {
//     subscribe: function(topic, listener) {
//       // Create the topic's object if not yet created
//       if(!topics[topic]) topics[topic] = { queue: [] };

//       // Add the listener to queue
//       var index = topics[topic].queue.push(listener) -1;

//       // Provide handle back for removal of topic
//       return {
//         remove: function() {
//           delete topics[topic].queue[index];
//         }
//       };
//     },
//     publish: function(topic, info) {
//       // If the topic doesn't exist, or there's no listeners in queue, just leave
//       if(!topics[topic] || !topics[topic].queue.length) return;

//       // Cycle through topics queue, fire!
//       var items = topics[topic].queue;
//       items.forEach(function(item) {
//           item(info || {});
//       });
//     }
//   };
// })();

}(window, document));