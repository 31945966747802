var CD = require("./cd.js"),
    stringFormatter = require('./string-formatter.js'),
    simpleChart = require('./simple-chart.js'),
    jailed = require('../../public/js/jailed/jailed.js');

// liste des extensions qui ne nécessitent pas de charger un fichier JS spécifique
var extWithoutExternalJS = ["searchable"];

var Extensions = CD.Class.extend(/** @lends Extensions# */{

  includes: [L.Mixin.Events],

  _deferreds: {},

  wvc: null,

  uiState: null,

  resourcesPath: '',

  assetsVersion: null,

  jailedPlugin: null,

  /**
   * Evaluation d'une expression JS dans un  environnement isolé (jailed.js) - Webworrker à l'intérieur d'une iframe
   * @param      {jailed.Plugin}  plugin  bindé automatiquement
   * @param      {string}         code    le code JS à évaluer
   * @return     {string}                 le résultat de l'éxécution
   */
  jailedEval: function(plugin, code) {
    var deferred = window.jQuery.Deferred();
    if (plugin) {
      plugin.whenConnected(function() {
        plugin.remote.jailedEval(code, function(result) { deferred.resolve(result); });
      });
    } else {
      deferred.resolve("** ERROR : jailed plugin is not initialized **");
    }
    return deferred.promise();
  },

  /**
   * Charge les extensions nécessaires pour les customParams d'un module donné
   * @public
   * @param  {Object} customParams
   * @param  {String} moduleName    le nom du module pour prévenir l'utilisateur en cas d'erreur
   * @return {WebViewCreator} this
   */
  loadFromParams: function(customParams, moduleName) {
    simpleChart.resourcesPath = this.resourcesPath;
    simpleChart.assetsVersion = this.assetsVersion;
    if (this.jailedPlugin == null) {
      var url = this.resourcesPath + "js/safe-eval.bundle.js";
      if (url.substr(0, 4) != "http") // on a ici besoin d'une URL absolue pour la création du WebWorker
        url = document.location.protocol + "//" + document.location.hostname + url;
      this.jailedPlugin = new jailed.Plugin(url);
      // définition d'un contexte commun à toutes les évaluations JS en fonction de la locale de l'utilisateur
      this.jailedEval(this.jailedPlugin, "L = { articque: { Util: { THOUSANDS_SEP : '" + L.articque.Util.THOUSANDS_SEP + "', DECIMAL_SEP: '" + L.articque.Util.DECIMAL_SEP + "' } } };");
    }
    var dependancies = { stringFormatter: stringFormatter, simpleChart: simpleChart, jailedEval: this.jailedEval.bind(null, this.jailedPlugin) }; // injection de dépendances
    for(var p in customParams) {
      if (this.resourcesPath && ! this._deferreds[p] && extWithoutExternalJS.indexOf(p) < 0) {
        if (window.articque && window.articque.ext && window.articque.ext[p]) { // extension pré-chargée
          if (! window.articque.ext[p].__loaded) {
            window.articque.ext[p].__loaded = true;
            window.articque.ext[p].loadExtension(this, dependancies);
          }
        } else {
          this._deferreds[p] = window.jQuery.ajax({ // chargement dynamique
            url: this.resourcesPath + "js/extensions/" + CD.Util.camel2Hyphen(p) + '.js?v=' + this.assetsVersion,
            dataType: "script",
            cache: !! this.assetsVersion
          }).done((function(param) {
            if (window.articque.ext[param]) {
              window.articque.ext[param].loadExtension(this, dependancies);
            }
          }).bind(this, p))
          .fail((function(param) {
            alert(__("Erreur de chargement du paramètre complémentaire {0} dans le module {1}", param, moduleName));
          }).bind(this, p));
        }
      } else if (this.resourcesPath == '' && ! this.warned) {
        console.warn('"resourcesPath" options is not specified.'); // eslint-disable-line no-console
        this.warned = true;
      }
    }
    return this;
  },

  /**
   * Retourne un deferred qui sera "done" lorsque toutes les extensions seront chargées
   * @public
   * @return {Deferred}
   */
  whenLoaded: function() {
    return window.jQuery.when.apply(
      null,
      Object.keys(this._deferreds) // conversion d'un objet en tableau
            .map(function(key) {
              return this._deferreds[key];
            }, this)
    );
  }

});

module.exports = new Extensions();