(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.selectable = {

    wvc: null,
    atlas: null,

    loadExtension: function(parent) {
      this.wvc = parent.wvc;
      this.atlas = parent.atlas;
      parent.on('updateview', this.onUpdateView, this);
      this.onUpdateView();
    },

    onUpdateView: function() {
      var enabled = this.wvc._modules.filter(function(mod) {
        return (!! mod.module.customParams && !! mod.module.customParams.selectable);
      }).length > 0;

      if (this.atlas) {
        this.atlas.filterIfSelectionEmpty = enabled;
      }
      if (enabled) {
        this.wvc._modules.forEach(function(mod) {
          mod.module.setSelectable(!! mod.module.customParams && !! mod.module.customParams.selectable);
        })
      }
    }

  };
})();