var $ = window.$ || window.jQuery,
    WKT = require('./wkt.js'),

AtlasIsochroneEngine = {

  tplTruckProfiles: '',

  tplMarkerPopup:
    '<form class="form-horizontal" id="isoc-popup-form-{{id}}" style="width: 290px; margin-top: 30px" onsubmit="return false;">' +
      '<fieldset id="isoc-fieldset-{{id}}" style="padding: 15px">' +
        '<div class="form-group">' +
          '<label for="isoc-mode-{{id}}" class="col-sm-4 control-label">{{route_mode_lbl}}</label>' +
          '<div class="col-sm-8">' +
            '<select class="form-control" id="isoc-mode-{{id}}">' +
              '<option value="PassengerCar">{{route_mode_car}}</option>' +
              '<option value="Taxi">{{route_mode_taxi}}</option>' +
//              '<option value="PublicBus">{{route_mode_bus}}</option>' +
              '<option value="Emergency">{{route_mode_emergency}}</option>' +
              '<option value="Bicycle">{{route_mode_bike}}</option>' +
              '<option value="Pedestrian">{{route_mode_walk}}</option>' +
              '{{tplTruckProfiles}}' +
            '</select>' +
          '</div>' +
        '</div>' +
        '<div class="form-group">' +
          '<label for="isoc-zone-value-{{id}}" class="col-sm-4 control-label">{{route_area_lbl}}</label>' +
          '<div class="col-sm-4">' +
            '<input class="form-control" type="number" min="1" max="999" id="isoc-zone-value-{{id}}"/>' +
          '</div>' +
          '<div class="col-sm-4">' +
            '<select class="form-control" id="isoc-zone-unit-{{id}}">' +
              '<option value="min">{{route_unit_min}}</option>' +
              '<option value="km">{{route_unit_km}}</option>' +
              '<option value="miles">{{route_unit_miles}}</option>' +
            '</select>' +
          '</div>' +
        '</div>' +
        '<div class="form-group">' +
          '<label for="isoc-avoid-{{id}}" class="col-sm-4 control-label">{{route_avoid_lbl}}</label>' +
          '<div class="col-sm-8">' +
            '<div class="checkbox"><label><input type="checkbox" id="isoc-avoid-motorway-{{id}}"/>{{route_avoid_motorway}}</label></div>' +
            '<div class="checkbox"><label><input type="checkbox" id="isoc-avoid-toll-{{id}}"/>{{route_avoid_toll}}</label></div>' +
            '<div class="checkbox"><label><input type="checkbox" id="isoc-avoid-ferry-{{id}}"/>{{route_avoid_ferry}}</label></div>' +
          '</div>' +
        '</div>' +
        '<div class="text-danger" id="isoc-error-msg-{{id}}"></div>' +
        '<div class="text-right">' +
          '<button type="button" id="isoc-delete-{{id}}" onclick="window.articque.isochroneBtnClick(\'delete\', {{id}})" class="btn btn-link">{{route_delete}}</button> &emsp; ' +
          '<button type="button" id="isoc-apply-{{id}}" onclick="window.articque.isochroneBtnClick(\'apply\', {{id}})" class="btn btn-primary">{{route_apply}}</button>' +
        '</div>' +
      '</fieldset>' +
    '</form>',

  pathStyle: {color: 'red', weight: 2, dashArray: "4", fillColor: "white", fillOpacity: 0.4, interactive: false },

  render: function(parent, domId) {
    if (parent.options.isochroneTruckProfiles && parent.options.isochroneTruckProfiles.length) {
      this.tplTruckProfiles = parent.options.isochroneTruckProfiles.map(function(p) { return '<option value="' + p + '">' + p + '</option>'; }).join('');
    }

    this.parent = parent;
    this.domId = domId;
    this.markers = [];
    this._lastEditedIdx = 0;
    window.articque.isochroneBtnClick = this._btnClick.bind(this); // utilisation d'une callback globale pour simplifier le branchement aux évènements dans les popups leaflet
  },

  /**
   * Activation/desactivation de la sélection par isochrones
   * @public
   * @param  {object} options        `{<bool> enabled}` Activation si `{ enabled: true }`, désactivation si `{ enabled: false }`
   */
  toggleIsochrones: function(options) {
    var map = this.parent.webView.map;
    if (options.enabled) {
      this._touchActionSave = map._container.style.touchAction;
      map._container.style.touchAction = 'none';
      L.DomEvent.on(map._container, L.articque.MultiSelect.START, this._onDown, this);
      this._mapDragging        = map.dragging.enabled();
      this._mapDoubleClickZoom = map.doubleClickZoom.enabled();
      this._mapBoxZoom         = map.boxZoom.enabled();
      this._mapTouchZoom       = map.touchZoom.enabled();
      map.doubleClickZoom.disable();
      map.boxZoom.disable();
    } else {
      this.clear();
      map._container.style.touchAction = this._touchActionSave;
      L.DomEvent.off(map._container, L.articque.MultiSelect.START, this._onDown, this);
      if (this._mapDragging)        map.dragging.enable();
      if (this._mapDoubleClickZoom) map.doubleClickZoom.enable();
      if (this._mapBoxZoom)         map.boxZoom.enable();
      if (this._mapTouchZoom)       map.touchZoom.enable();
    }
  },

  /**
   * gestion de l'appui sur le bouton de la souris. Inspiré de L.articque.MultiSelect
   * @private
   * @param  {L.Event} e
   * @return {void}
   */
  _onDown: function(e) {
    if (e._simulated || !!this._startPoint || !this.parent.options.selectionActive || (e.target.tagName != 'CANVAS') || (this.parent._selectionTool != 'isochrone') || ((e.which !== 1) && (e.button !== 1) && ! e.touches)) { return; }
    this._startPoint = this.parent.webView.map.mouseEventToContainerPoint((e.touches && e.touches.length === 1) ? e.touches[0] : e);
    L.DomUtil.disableTextSelection();
    L.DomUtil.disableImageDrag();
    L.DomEvent.on(document, L.articque.MultiSelect.END[e.type], this._onUp, this);
  },

  /**
   * gestion du relâchement du bouton de la souris. Inspiré de L.articque.MultiSelect
   * @private
   * @param  {L.Event} e
   * @return {void}
   */
  _onUp: function(e) {
    if (e._simulated || !this.parent.options.selectionActive) { return; }
    var endPoint = this.parent.webView.map.mouseEventToContainerPoint((e.touches && e.touches.length === 1) ? e.touches[0] : e),
        latlng = this.parent.webView.map.containerPointToLatLng(this._startPoint);
    if ((endPoint.x - this._startPoint.x) * (endPoint.x - this._startPoint.x) + (endPoint.y - this._startPoint.y) * (endPoint.y - this._startPoint.y) <= 2) {
      this.addIsochroneMarker(latlng.lat, latlng.lng);
    }
    this._startPoint = null;
    L.DomUtil.enableTextSelection();
    L.DomUtil.enableImageDrag();
    for (var i in L.articque.MultiSelect.END) {
      L.DomEvent.off(document, L.articque.MultiSelect.END[i], this._onUp, this);
    }
  },

  /**
   * Déclenché lorsqu'un marqueur a été déplacé (en drag-and-drop) par l'utilisateur. Recalcule l'isochrone à partir du nouveau centre
   * @private
   * @param  {L.Marker} marker
   * @return {void}
   */
  _onMarkerDragged: function(marker) {
    marker.isocQuery.x = marker.getLatLng().lng; // nouvelles coordonnées
    marker.isocQuery.y = marker.getLatLng().lat;
    this._updateIsochrone(marker);
  },

  /**
   * Initialisation du formulaire lorsqu'on ouvre une popup sur un marqueur
   * @private
   * @param  {L.Marker} marker
   * @return {void}
   */
  _onPopupOpen: function(marker) {
    this.parent.webView.moduleGroup.disableTooltips();
    var id = marker._leaflet_id;
    $("#isoc-fieldset-" + id).attr("disabled", marker.computing);
    $("#isoc-mode-" + id).val(marker.isocQuery.mode);
    $("#isoc-zone-value-" + id).val(marker.isocQuery.value);
    $("#isoc-zone-unit-" + id).val(marker.isocQuery.unit);
    $("#isoc-avoid-motorway-" + id).attr("checked", marker.isocQuery.avoidMotorway);
    $("#isoc-avoid-toll-" + id).attr("checked", marker.isocQuery.avoidToll);
    $("#isoc-avoid-ferry-" + id).attr("checked", marker.isocQuery.avoidFerry);
    $("#isoc-error-msg-" + id).text(marker.errorMsg ? marker.errorMsg : '');
  },

  /**
   * A la fermeture d'une popup, on supprime le marqueur s'il son formulaire n'a pas été validé
   * @private
   * @param  {L.Marker} marker
   * @return {void}
   */
  _onPopupClose: function(marker) {
    this.parent.webView.moduleGroup.enableTooltips();
    var m = this._getMarker(marker._leaflet_id);
    if (! marker.computing && marker.polygon == null && marker.errorMsg == null && m >= 0) {
      marker.off("dragend").off("popupopen").off("popupclose");
      marker.removeFrom(this.parent.webView.map);
      this.markers.splice(m, 1);
    }
  },

  /**
   * Ajoute un marqueur sur la carte et ouvre la popup contenant le formulaire de saisie de configuration de l'isochrone
   * @public
   * @param {float} lat   latitude  où ajouter le marqueur
   * @param {float} lng   longitude où ajouter le marqueur
   */
  addIsochroneMarker: function(lat, lng) {
    var marker = L.marker(L.latLng(lat, lng), { draggable: true, bounceOnAdd: true }).addTo(this.parent.webView.map),
        html = this.parent.renderTpl(this.tplMarkerPopup, { id: marker._leaflet_id, tplTruckProfiles: this.tplTruckProfiles });
    marker.bindPopup(html, {});
    // on recopie les données du dernier formulaire validé
    if (this.markers[this._lastEditedIdx] && this.markers[this._lastEditedIdx].isocQuery) {
      marker.isocQuery = JSON.parse(JSON.stringify(this.markers[this._lastEditedIdx].isocQuery));
      marker.isocQuery.x = lng;
      marker.isocQuery.y = lat;
    } else { // sinon valeurs par défaut
      marker.isocQuery = { mode: "PassengerCar", x: lng, y: lat, value: "10", unit: "min", avoidMotorway: false, avoidToll: false, avoidFerry: false }; // par défaut
    }
    marker.polygon = null;
    marker.errorMsg = null;
    $(marker._icon).addClass("isochrone-icon isochrone-icon-disabled");
    marker.on('dragend', this._onMarkerDragged.bind(this, marker)); // enregistrement des callbacks
    marker.on('popupopen', this._onPopupOpen.bind(this, marker));
    marker.on('popupclose', this._onPopupClose.bind(this, marker));
    this.markers.push(marker);
    marker.openPopup(); // ouvre la popup
  },

  /**
   * retrouve un marqueur à partir de son id
   * @private
   * @param  {int} id
   * @return {int} l'index du marqueur dans le tableau `this.markers`, -1 si non trouvé
   */
  _getMarker: function(id) {
    for(var m = this.markers.length - 1; m >= 0; m--) {
      if (this.markers[m]._leaflet_id == id) {
        return m;
      }
    }
    return -1;
  },

  /**
   * Callback pour les clicks sur les 2 boutons des formulaires (appliquer et supprimer)
   * @private
   * @param  {string} action l'action à effectuer (appliquer ou supprimer)
   * @param  {int} data      l'id du marqueur qur lequel l'action doit être effectuée
   * @return {void}
   */
  _btnClick: function(action, data) {
    var m = this._getMarker(data),
        marker, map;
    if (m >= 0) {
      marker = this.markers[m];
      map = this.parent.webView.map;
      switch (action) {
        case 'delete': // suppresion du marqueur et du polygone associé
          if (marker.polygon) {
            marker.polygon.removeFrom(map);
          }
          marker.removeFrom(map);
          marker.off("dragend").off("popupopen").off("popupclose");
          this.markers.splice(m, 1);
          this._updateSelection();
          break;
        case 'apply': // application de la nouvelle configuration
          var id = marker._leaflet_id;
          marker.isocQuery = { // stocke la configuration saisie dans le formulaire
            x: marker.getLatLng().lng,
            y: marker.getLatLng().lat,
            mode: $("#isoc-mode-" + id).val(),
            value: $("#isoc-zone-value-" + id).val(),
            unit: $("#isoc-zone-unit-" + id).val(),
            avoidMotorway: $("#isoc-avoid-motorway-" + id).is(":checked"),
            avoidToll: $("#isoc-avoid-toll-" + id).is(":checked"),
            avoidFerry: $("#isoc-avoid-ferry-" + id).is(":checked")
          };
          this._lastEditedIdx = m;
          marker.computing = true; // désactive le formulaire le temps du calcul
          marker.closePopup(); // ferme la popup
          this._updateIsochrone(marker);
          break;
      }
    }
  },

  /**
   * Créé une géométrie utilisable par leaflet à partir d'un WKT reçu du webservice
   * @private
   * @param  {string} wkt
   * @return {number[][][]} les coordonnées d'un Polygon ou d'un MultiPolygon
   */
  _toGeometry: function(wkt) {
    var i, j, k,
        geometry = WKT.parse(wkt);
    // besoin d'inverser les coordonnées (https://macwright.org/lonlat/)
    if (geometry.type == "Polygon") {
      for(i = 0; i < geometry.coordinates.length; i++) {
        for(j = 0; j < geometry.coordinates[i].length; j++) {
          geometry.coordinates[i][j] = [geometry.coordinates[i][j][1], geometry.coordinates[i][j][0]];
        }
      }
    } else if (geometry.type == "MultiPolygon") {
      for(i = 0; i < geometry.coordinates.length; i++) {
        for(j = 0; j < geometry.coordinates[i].length; j++) {
          for(k = 0; k < geometry.coordinates[i][j].length; k++) {
            geometry.coordinates[i][j][k] = [geometry.coordinates[i][j][k][1], geometry.coordinates[i][j][k][0]];
          }
        }
      }
    }
    return geometry.coordinates;
  },

  /**
   * Met à jour la sélection sur la carte après calcul d'un isochrone
   * @private
   * @return {void}
   */
  _updateSelection: function() {
    var geometries = [];
    this.markers.forEach(function(m) {
      if (m.polygon && ! m.computing) {
        geometries.push(m.polygon.toGeoJSON().geometry)
      }
    }, this);
    if (geometries.length) {
      this.parent.webView.map.multiSelect.selectByGeometries(geometries);
    } else {
      this.parent.webView.map.multiSelect.clearSelection();
    }
  },

  /**
   * Recalcule un isochrone pour un marqueur donné
   * @private
   * @param  {L.Marker} marker
   * @return {void}
   */
  _updateIsochrone: function(marker) {
    var self = this,
        map = this.parent.webView.map;
    if (marker.polygon) { // supprime l'ancien polygone représentant l'isochrone
      marker.polygon.removeFrom(map)
      marker.polygon = null;
    }
    marker.errorMsg = null;
    marker.dragging.disable();
    marker.computing = true; // désactive le formulaire le temps du recalcul
    $(marker._icon).addClass("isochrone-icon-computing").removeClass("isochrone-icon-error isochrone-icon-disabled"); // l'icone du marqueur est grise le temps du calcul
    this.computeIsochrone(marker.isocQuery).done(function(result) { // calcule le nouvel isochrone
      marker.computing = false;
      marker.dragging.enable();
      if (result.status == 'success') {
        marker.polygon = L.polygon(self._toGeometry(result.data), self.pathStyle).addTo(map); // créé le nouveau polygone
      } else {
        marker.errorMsg = result.message;
        $(marker._icon).addClass("isochrone-icon-error");
      }
      $("#isoc-fieldset-" + marker._leaflet_id).attr("disabled", false); // réactive le formulaire
      $("#isoc-error-msg-" + marker._leaflet_id).text(marker.errorMsg ? marker.errorMsg : '');
      $(marker._icon).removeClass("isochrone-icon-computing"); // repasse l'icone du marqueur en bleu
      self._updateSelection();
    });
  },

  /**
   * Supprime tous les marqueurs et les polygones associés
   * @public
   * @return {void}
   */
  clear: function() {
    map = this.parent.webView.map;
    this.markers.forEach(function(marker) {
      if (marker.polygon) {
        marker.polygon.removeFrom(map);
      }
      marker.off("dragend").off("popupopen").off("popupclose");
      marker.removeFrom(map);
    }, this);
    this.markers = [];
  },

  /**
   * calcule un isochrone (côté serveur)
   * @param  {object} query   `{<number> x, <number> y, <string> mode, <number> value, <string> unit, <bool> avoidMotorway, <bool> avoidToll, <bool> avoidFerry}`
   *                            x              longitude du centre de l'isochrone à calculer
   *                            y              latitude  du centre de l'isochrone à calculer
   *                            mode           mode de transport à utiliser pour le calcul de l'isochrone (valeurs possibles: "Pedestrian", "PassengerCar", "Taxi", "PublicBus", "DeliveryTruck", "Bicycle" ou "Emergency")
   *                            value          valeur de l'isochrone (dans l'unité précisée ci-dessous)
   *                            unit           unité de la valeur de l'isochrone (valeurs possibles: "min", "km", "miles")
   *                            avoidMotorway  si true, évite les autoroutes lors du calcul des isochrones
   *                            avoidToll      si true, évite les péages lors du calcul des isochrones
   *                            avoidFerry     si true, évitte les ferries lors du calcul des isochrones
   * @return {$.Deferred}  une promesse qui sera résolue avec la réponse du serveur
   */
  computeIsochrone: function(query) {
    return $.ajax({
      url : this.parent.options.isochroneUrl,
      dataType: 'json',
      data: query
    });
  }

};

module.exports = AtlasIsochroneEngine;