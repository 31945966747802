var CD = require("./cd.js"),
    WKT = require("./wkt.js"),
    CartoJson = require("./cartojson.js"),
    extensions = require("./extensions.js"),
    ChartList = require("./chart-list.js");

var WebViewCreator = CD.Class.extend(/** @lends WebViewCreator# */{

  includes: [L.Mixin.Events],

  MAX_ZOOM: 26,

  options: {
    cartoJson: null,
    fluidNavigation: false,
    domId: null,
    unit: null,
    containerDomId: null,
    sideLegendDomId: null,
    legendFixed: false, // si true, les légendes ne peuvent pas être déplacées
    bounds: null,
    zoomControl: false,
    attributionControl: false,
    attribution: null,
    onLegendMoved: null, // function(webDisplayingModuleId, moduleId, captionType, x, y)
    libraryGetUrl: null, // function(uri)
    resourcesPath: '',
    assetsVersion: null,
    tooltipClassName: null,
    userCanSelect: true,
    wmsProviders: {}
  },

  // ancien mode de fonctionnement
  tileLayer: null,
  tileLayerInfos: null,

  // nouveau mode de fonctionnement 2018
  tileLayers: [],

  scaleInvalidated: false,
  customParams: {},

  _modules: [],
  _selection: {},

  /**
   * @constructs WebViewCreator
   * @classdesc Classe de transformation d'un cartoJson en modules de représentation
   * @param  {Object} options
   * @return {WebViewCreator}
   */
  initialize: function(options) {
    CD.Util.setOptions(this, options || {}); // fusionne les options par défaut et les options passées en paramètre

    this.cartoJson = CartoJson({ getUrl: this.options.libraryGetUrl });

    this.map = new L.map(this.options.domId, {
      zoomControl: this.options.zoomControl,
      maxZoom: this.MAX_ZOOM,
      attributionControl: this.options.attributionControl,
      boxZoom: false,
      worldCopyJump: true,
      closePopupOnClick: false,
      //zoomSnap: false, // ne fonctionne pas avec L.articque.Screenshot qui ne supporte pas les niveaux de zoom non-entiers
      inertia: L.Browser.touch
    }); // maxZoom 26 car au-delà, il n'est plus possible de calculer la valeur à afficher dans RMScale (< 10cm)
    this.map.on('zoomend', this._onZoomend, this);
    this.map.on('resize', function(){
      if(window.reactComponents && window.reactComponents.imageSizeDropdown){
        var $vis = window.jQuery("#visualisation");
        window.reactComponents.imageSizeDropdown.setCurrentSize($vis.width(), $vis.height());
      }
    });

    extensions.resourcesPath = this.options.resourcesPath;
    extensions.assetsVersion = this.options.assetsVersion;
    extensions.wvc = this;


    this.moduleGroup = L.articque.moduleGroupLayer({ tooltipClassName: this.options.tooltipClassName });
    this.mapLegend = L.articque.legendLayer({ draggable: ! this.options.legendFixed });
    this.mapLegend.type = "map";
    this.sideLegend = L.articque.legendLayer({ vertical: true, draggable: ! this.options.legendFixed });
    this.sideLegend.type = "side";
    this.legend = this.mapLegend;
    // on créé un conteneur de légendes temporaire pour le layerList en attendant que ce dernier soit initialisé et récupère les légendes
    // on désactive les fonctions de sauvegarde des positions sur ce conteneur pour éviter des appels inutiles à setCaptionPositions()
    this._layerListLegendTmp = L.articque.legendLayer({ vertical: true, draggable: ! this.options.legendFixed });
    this._layerListLegendTmp.type = "layerlist";
    this._layerListLegendTmp.refreshVerticalLayout = this._layerListLegendTmp.computePositions = function() { return this; };
    this.chartList = new ChartList({ wvc: this, resourcesPath: this.options.resourcesPath, cartoJson: this.cartoJson, assetsVersion: this.options.assetsVersion });
    this._availableDataspaces = null;
    if (this.options.userCanSelect) {
      this.map.addHandler('multiSelect', L.articque.MultiSelect);
      this.map.multiSelect.setSelectionMode('auto');
      this.map.multiSelect.enable();
      this.map.on('multiselectend', this.onMapMultiSelection.bind(this));
    }

    if (this.options.unit) {
      this.cartoJson.setUnit(this.options.unit);
    }

    if (this.options.cartoJson) {
      this.setCartoJson(this.options.cartoJson, this.options.bounds, true);
      if (window.reactComponents && this.legend.type == "side" && this.legend._modules && this.legend._modules.length) {
        window.reactComponents.visualisationTabs.showLegend('legend', {override: false});
      }
    } else {
      this.beginUpdate();
    }

    if (this.options.attribution) {
      this.map.attributionControl.setPrefix(this.options.attribution); // exemple : '&copy; Articque | osm.org'
    }

    this.moduleGroup.on('filterupdated', this.onLegendFilterUpdated.bind(this));
    this.moduleGroup.addTo(this.map);
    this.mapLegend.addTo(this.map);
    var domNode = document.getElementById(this.options.sideLegendDomId);
    // si caché, on met une largeur en dur car elle ne pourra pas être calculée automatiquement par L.articque.LegendLayer
    this.sideLegend.addTo(domNode || this.map, domNode && (domNode.offsetParent === null) ? (window.reactComponents ? 200 : 300) : null);
  },

  /**
   * Créé une couche WMS par defaut - utilisé par les modules sélection de pôles et fond de carte personnalisé
   */
  getDefaultWmsLayerDescription: function() {
    try {
      for(var p in window._webappOptions.wmtsProviders) {
        var xml = new window.DOMParser().parseFromString(window._webappOptions.wmtsProviders[p], "text/xml"),
            useInArticqueModules = xml.documentElement.getElementsByTagName('Json')[0].getAttribute("useInArticqueModules");
        if (useInArticqueModules && (useInArticqueModules.toLowerCase() == "true")) {
          return {
            "Type": "Wms", "Visible": true, "ZoomTarget": false,
            "Map": { "Id": 1, "Name": "" },
            "Data": { "ColumnsIndex": [], "Id": 0, "Name": "" },
            "DrawingParams": { "IsWms": false, "WmtsJsonParameters": xml.documentElement.getElementsByTagName('Json')[0].textContent },
            "Id": 1, "Name": ""
          };
        }
      }
    } catch(e) { /* do nothing */ }
    return { // par défaut si non renseigné dans le BO
      "Type": "Wms",
      "Visible": true,
      "ZoomTarget": false,
      "Map": { "Id": 1, "Name": "" },
      "Data": { "ColumnsIndex": [], "Id": 0, "Name": "" },
      "DrawingParams": {
        "HasCaption": false,
        "CustomParams": "",
        "ServerUrl": "https://dalles.articque.com/bgis/wms?styles=greyscale",
        "MinZoom": 3, // car les dalles articque ont un rendu incorrect sur les 3 premiers niveaux de zoom
        "MaxZoom": 18,
        "Version": "1.1.1",
        "OutputFormat": "image/png",
        "Alpha": 0.0,
        "Layers": ["maxCoverage"],
        "IsWms": true,
        "WmtsJsonParameters": "{}"
      },
      "Id": 1,
      "Name": ""
    };
  },

  /**
   * @param {int[]} ds tableau d'ids des dataspaces
   */
  setAvailableDataspaces: function(ds) {
    this._availableDataspaces = ds;
  },

  /**
   * Supprime tous les modules et les resources
   * @public
   * @return {WebViewCreator} this
   */
  clear: function() {
    this.resources = { maps: {}, datas: {}, images: {}, zoomTargets: [], representations: [] };
    this._modules = [];
    this._selection = {};
    this.webDisplayingModuleId = null;
    this.moduleGroup.clearFilter();
    this.moduleGroup.clearModules();
    this.mapLegend.clearModules();
    this.sideLegend.clearModules();
    this.chartList.clear();
    return this;
  },

  /**
   * Réinitialisation de la vue au zoom par défaut
   * @public
   * @param {L.LatLngBounds} bounds  [optionnel] bounds autours desquels ajuster la vue. Si non spécifié, les bounds calculés automatiquement seront utilisés
   * @return {WebViewCreator} this
   */
  resetView: function(bounds) {
    bounds = bounds || this.options.bounds;
    if (bounds && bounds.isValid()) {
      this.map.fitBounds(bounds, { animate: false });
    }
    return this;
  },

  /**
   * redimensionne la carte
   * @public
   * @return {WebViewCreator} this
   */
  invalidateSize: function(adjustScale) {
    this.map.invalidateSize(false);
    var sideLegend = L.DomUtil.get('offcanvas-legend');
    if (sideLegend && sideLegend.offsetParent === null) { // test si l'élément est visible. source : http://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
      this.sideLegend.invalidateSize();
    }
    if (this.scale && this.options.bounds) {
      if (adjustScale) {
        this.scale.remove();
        this.resetView();
        this.scale = L.articque.adjustedScale({ map: this.map, bounds: this.options.bounds, minimumSurfaceWithTiles: this.options.fluidNavigation ? 0 : 0.6 });
      } else {
        this.scaleInvalidated = true;
      }
    }
    return this;
  },

  beginUpdate: function() { return this.setUpdatingState(true); },
  endUpdate:   function() { return this.setUpdatingState(false); },

  /**
   * Modifie l'état de la visualisation
   * @public
   * @param {boolean} enabled  si false, grise la zone de visualisation. Si true, retire le grisage
   */
  setUpdatingState: function(disabled) {
    // TODO pê faire qqch qui bloque toutes les intéractions durant que c'est disabled
    this.disabled = disabled;
    var el = L.DomUtil.get(this.options.containerDomId || this.options.domId);
    if (disabled) {
      L.DomUtil.addClass(el, 'loading');
    } else {
      L.DomUtil.removeClass(el, 'loading');
    }
    return this;
  },

  /**
   * Définit les éléments sélectionnées => carte
   * @public
   * @param {int}   resourceId   id de la ressource de données dans laquelle des éléments sont sélectionnés
   * @param {int[]} selection    liste des ids des éléments sélectionnés
   */
  setSelection: function(selections) {

    //On stock les modules qui ont été set par la sélection
    var ids = [];

    for(var id in selections){
      var resourceId = id;
      this._modules.forEach(function(mod) {
        if(ids.indexOf(mod.module._leaflet_id) < 0){
          if (mod.dataId == resourceId || mod.mapId == resourceId || mod.dataParentIds.indexOf(resourceId) > -1) {
            mod.module.selectByIds(selections[id]);
            ids.push(mod.module._leaflet_id);
          } else {
            mod.module.selectByIds([]);
          }
        }
      }, this);
    }

    return this;
  },

  /**
   * Méthode pour supprimer toutes les sélections actives sur la carte
   */
  _clearSelection: function(){
    //On parcours l'objet _selection pour le vider
    if(this._selection){
      for (id in this._selection) {
        this._selection[id] = [];
      }
    }
  },

  /**
   * Méthode qui permet d'ajouter ou de supprimer un id dans la sélection d'une couche
   * @param  {int} ressourceId identifiant (mapId ou dataId)
   * @param  {int} id identifiant à ajouter ou retirer de la sélection
   * @param  {boolean} ctrl permet de savoir si on est en mode CTRL ou non
   */
  _handleSelection: function(ressourceId, id, ctrl) {
    if(ressourceId && !this._selection[ressourceId])
      this._selection[ressourceId] = [];

    if (ctrl) {
      //on check si on a pas l'id
      if(ressourceId){
        var idx = this._selection[ressourceId].indexOf(id);
        if(idx > -1)
          this._selection[ressourceId].splice(idx,1);
        else
          this._selection[ressourceId].push(id);
      }
    } else {
      if(ressourceId && this._selection[ressourceId].indexOf(id) < 0)
        this._selection[ressourceId].push(id);
    }
  },


  /**
   * Un ensemble d'objets a été sélectionné sur la carte => dataspace
   * @param  {Object} data contient un tableau de features nommé "selection"
   * @return {void}
   */
  onMapMultiSelection: function(data) {
    //On fait un traitement sur la sélection que l'on vient de recevoir
    var ids = [];
    var dico = {};
    data.selection.forEach(function(moduleSelection){
      if (moduleSelection.selections && moduleSelection.selections.length > 0) {
        ids.push(moduleSelection.id);
        dico[moduleSelection.id] = moduleSelection.selections;
      }
    });

    //On vide la sélection en cours
    this._selection = {};

    for (var i = this._modules.length - 1; i >= 0; i--) {
      // on vide la sélection des modules réglés en "restreindre la sélection", si aucun élément n'est sélectionné
      if (typeof dico[this._modules[i].module._leaflet_id] == "undefined" && this._modules[i].module.customParams && this._modules[i].module.customParams.selectable) {
        ids.push(this._modules[i].module._leaflet_id);
        dico[this._modules[i].module._leaflet_id] = [];
      }
      if (ids.indexOf(this._modules[i].module._leaflet_id) > -1) {
        //On ajoute les 2 ID car CDO est devenu une boite noire ...
        var dataId = this._modules[i].dataId;
        var mapId = this._modules[i].mapId;

        var selection = dico[this._modules[i].module._leaflet_id].map(function(feature){ return feature.id; }),
            current = this._selection[dataId];
        if(dataId) { //Pour éviter de créer une sélection car l'id est égal à 0
          if (this._selection[dataId]) {
            this._selection[dataId] = this._selection[dataId].concat(selection.filter(function (item) { return current.indexOf(item) < 0; }));
          } else {
            this._selection[dataId] = selection.slice(0);
          }
        }

        current = this._selection[mapId];
        if(mapId) { //Pour éviter de créer une sélection car l'id est égal à 0
          if (this._selection[mapId]) {
            this._selection[mapId] = this._selection[mapId].concat(selection.filter(function (item) { return current.indexOf(item) < 0; }));
          } else {
            this._selection[mapId] = selection.slice(0);
          }
        }
      }
    }

    this.fire('mapselection', {datas : this._selection});
  },

  onLegendFilterUpdated: function(filter) {
    for (var i = this._modules.length - 1; i >= 0; i--) {
      if (this._modules[i].module._leaflet_id == filter.module._leaflet_id) {
        //On récupère tous les ids du module pour récupérer l'inverse des éléments filtrés
        var ids = [];
        for(var j = 0; j < this._modules[i].module._features.length; j++){
          if(filter.filteredIds.indexOf(this._modules[i].module._features[j].id) < 0)
            ids.push(this._modules[i].module._features[j].id)
        }
        
        this.fire('legendselectionfilter', {datas : ids, dataId: this._modules[i].dataId, mapId: this._modules[i].mapId });
      }
    }
  },

  selectLegendFromPositions: function(positions) {
    this._captionPositions = positions; // on conserve l'objet pour pouvoir gérer les éléments cachés lors du déplacement des légendes (latérale ou sur la carte)
    var counts = { 'map': 0, 'side': 0, 'auto': 0 },
        where = 'map';
    for(var mod in positions) {
      for(var leg in positions[mod]) {
        counts[positions[mod][leg].Container || 'auto']++;
        if (positions[mod][leg].Posy && positions[mod][leg].Posy.toString().indexOf('vert-stacked-top') === 0) {
          where = 'side';
        }
      }
    }
    if (! counts.map && ! counts.side && counts.auto) { // tout en auto
      this.selectLegend(where);
    } else if (! counts.auto && ! counts.side && counts.map) { // tout sur la carte
      this.selectLegend('map');
      where = "map";
    } else if (! counts.auto && ! counts.map /*&& counts.side*/) { // tout sur le côté
      this.selectLegend('side');
      where = "side";
    } else if (counts.auto && ! counts.map && counts.side && where == "side") { // tout sur le côté
      this.selectLegend('side');
    } else if (counts.auto && counts.map && !counts.side && where == "map") { // tout sur la carte
      this.selectLegend('map');
    } else {
      where = "mixed";
      this.legend = this.mapLegend; // en mixte, on ajoute les légendes par défaut sur la carte
      this.toggleSideLegend(true); // affiche les éléments liés à la légende latérale
    }
    if (window.reactComponents && window.reactComponents.legendPositionCombobox) {
      window.reactComponents.legendPositionCombobox.setValue(where);
    }
  },

  toggleSideLegend: function(on) {
    window.jQuery('.show-if-side-legend').each(function() {
      var $this = window.jQuery(this);
      if (on) {
        $this.show()
        if (! $this.hasClass('active') && (! window.articque.cdonline || ! window.articque.cdonline.atlasUI || ! window.articque.cdonline.atlasUI.panels)) { // pas pour la nouvelle version des templates atlas
          $this.find('a:first').trigger('click');
        }
      } else {
        $this.hide();
        if ($this.hasClass('active')) {
          if (window.articque.cdonline.atlasUI.drawer) {
            $this.siblings('li:first').find('a:first').trigger('click');
          } else {
            $this.find('a:first').trigger('click');
          }
        }
      }
    });
  },

  selectLegend: function(where) {
    var self = this,
        title = null,
        mods = [],
        m, l,
        rc = window.reactComponents;
    if (rc && rc.legendPositionCombobox) {
      rc.legendPositionCombobox.setValue(where);
    }
    if (where == 'mixed') {
      this.legend = this.mapLegend; // en mixte, on ajoute les légendes par défaut sur la carte
      rc.notifBar.update({
        clearState: true,
        type: 'info',
        notification: __('Clic droit sur les éléments de légende pour les déplacer entre la carte et la zone latérale'),
        animation: false,
        action: __("Fermer"),
        duration: -1
      });
      this.toggleSideLegend(true); // affiche les éléments liés à la légende latérale
    } else if (where == 'map' && (this.sideLegend._modules.length || this.legend != this.mapLegend)) {
      this.legend = this.mapLegend;
      this.toggleSideLegend(false); // cache les éléments liés à la légende latérale
      mods = this.sideLegend._modules;
      this.sideLegend._modules = [];
      mods.forEach(function(mod) {
        if (mod instanceof L.articque.RMLTitle) {
          if (title == null) {
            title = mod;
            mod.setPosition('left', 'top');
          } else {
            mod.setPosition('right', 'stacked-bottom');
          }
        } else if (mod instanceof L.articque.RMLOrientation || mod instanceof L.articque.RMLScale) {
          mod.setPosition('left', 'stacked-bottom');
        } else {
          mod.setPosition('right', 'stacked-top');
        }
        mod.invalidatePosition().invalidateSize();
        this.mapLegend.addModule(mod);
      }, this);
      mods.forEach(function(mod) {
        mod.refresh()._savePosition();
      }, this);
      // on traite les légendes qui sont cachées (exemple : comment non coché dans les options du module visualisation)
      for(m in this._captionPositions) {
        for(l in this._captionPositions[m]) {
          if (this._captionPositions[m][l].Posy.toString().indexOf('vert-stacked-top') === 0) {
            this._captionPositions[m][l].Posy = 'stacked-top';
            this._onLegendMoved(m, l, { logicalPosition: { x: this._captionPositions[m][l].Posx, y: this._captionPositions[m][l].Posy }, container: this.mapLegend});
          }
        }
      }
    } else if (where == 'side' && this.legend != this.sideLegend) {
      this.toggleSideLegend(true); // affiche les éléments liés à la légende latérale
      this.legend = this.sideLegend;
      this.mapLegend._modules.forEach(function(mod) {
        if (title == null && mod instanceof L.articque.RMLTitle) {
          title = mod;
          mods.splice(0, 0, title);
        } else {
          mods.push(mod);
        }
      }, this);
      this.mapLegend._modules = [];
      mods.sort(function(a, b) {
        var aPos = parseFloat(a._logicalPosition.y.toString().replace('vert-stacked-top#', '')),
            bPos = parseFloat(b._logicalPosition.y.toString().replace('vert-stacked-top#', ''));
        return (aPos < bPos) ? -1 : ((aPos > bPos) ? 1 : 0);
      })
      mods.forEach(function(mod, idx) {
        mod.setPosition('left', 'vert-stacked-top#' + idx);
        this.sideLegend.addModule(mod);
        mod.invalidateSize().invalidatePosition().refresh()._savePosition();
        mod.fire('legendmoved', { position : mod._position, logicalPosition: mod._logicalPosition, container: this.sideLegend });
      }, this);
      // on traite les légendes qui sont cachées (exemple : comment non coché dans les options du module visualisation)
      for(m in this._captionPositions) {
        for(l in this._captionPositions[m]) {
          if (this._captionPositions[m][l].Posy.toString().indexOf('vert-stacked-top') < 0) {
            this._captionPositions[m][l].Posx = 'left';
            this._captionPositions[m][l].Posy = 'vert-stacked-top#99';
            this._onLegendMoved(m, l, { logicalPosition: { x: this._captionPositions[m][l].Posx, y: this._captionPositions[m][l].Posy }, container: this.sideLegend});
          }
        }
      }
      // les légendes ne sont pas dessinées dans le bon ordre (les RMLTitle sont dessinées immédiatement, les autres attentend que la carte ait été dessinée)
      // on attend que toutes les légendes aient été dessinées au moins un fois pour appeler _modulesChanged qui va les remettre dans le bon ordre
      this.sideLegend._div.style.opacity = 0;
      var timer = setInterval(function() {
            var allReady = true;
            self.sideLegend._modules.forEach(function(mod) { if (! mod._ready && mod.isVisible()) { allReady = false; } })
            if (allReady) {
              clearInterval(timer);
              self.sideLegend._div.style.opacity = 1;
              self.sideLegend._modulesChanged();
            }
          }, 100);
    }
    if (rc) {
      rc.visualisationTabs.setLegendTabFilled(where == 'side');
    }
    return this;
  },

  /**
   * créé les modules décrits dans le cartoJson passé en paramètre
   * @public
   * @param {CartoJson} cartoJson les données décrivant la carte au format cartoJson
   * @return {WebViewCreator} this
   */
  setCartoJson: function(cartoJson, bounds, noRefresh) {
    try {
      cartoJson = cartoJson || {};
      var tileLayers = [];
      this.moduleGroup.beginUpdate();
      extensions.fire('preupdateview', { displayingInfos: cartoJson });
      this.clear();
      this.webDisplayingModuleId = cartoJson.Id;
      this.selectLegendFromPositions(cartoJson.CaptionPositions);
      this._zoomTargetPad = (cartoJson.ZoomTargetMargin || 1) / 100;

      if (cartoJson.Resources) {
        if (cartoJson.Resources.Maps && cartoJson.Resources.Maps.length) {
          cartoJson.Resources.Maps.forEach(this.addMapResource, this);
        }
        if (cartoJson.Resources.Datas && cartoJson.Resources.Datas.length) {
          cartoJson.Resources.Datas.forEach(this.addDataResource, this);
        }
        if (cartoJson.Resources.Images && cartoJson.Resources.Images.length) {
          cartoJson.Resources.Images.forEach(this.addImageResource, this);
        }
      }
      if (cartoJson.Representations && cartoJson.Representations.length) {
        cartoJson.Representations.forEach(this.addRepresentation.bind(this, cartoJson.CaptionPositions, tileLayers));
        if (!this.resources.zoomTargets || !this.resources.zoomTargets.length) {
          this.resources.zoomTargets = this.resources.representations;
        }
      }
      if (cartoJson.Comments && cartoJson.Comments.length) {
        cartoJson.Comments.forEach(this.addComment.bind(this, cartoJson.CaptionPositions), this);
      }

      var chartDeferred = this.chartList.update(cartoJson.Charts);

      // ajustement des positions dans le cas d'une légende latérale
      if (this.legend.options.vertical) {
        var self = this,
            timer = setInterval(function() {
              if (self.sideLegend._modules.every(function(mod) { return mod._ready; })) {
                clearInterval(timer);
                self.legend.computePositions();
              }
            }, 100);
      }

      var prevBounds = this.options.bounds;
      if (this.options.userCanSelect) {
        this.map.multiSelect.setModuleGroupLayer(this.moduleGroup);
      }
      this.options.bounds = bounds || null;
      this.options.autoBounds = this.options.bounds === null;

      if (this.options.autoBounds) {
        this.options.bounds = (this._computeBounds() || L.latLngBounds([[-45,-45],[45,45]])).pad(this._zoomTargetPad);
      }
      var boundsHaveChanged = ! this.options.bounds.equals(prevBounds);

      // ancien mode de fonctionnement
      if (cartoJson.WmsLayers) {
        if (! this.tileLayer || this.tileLayerInfos != cartoJson.WmsLayersProviderInfos) {
          if (this.tileLayer) { this.tileLayer.removeFrom(this.map); }
          this.tileLayer = this._getTileLayer(cartoJson.WmsLayersProviderInfos);
          this.tileLayerInfos = cartoJson.WmsLayersProviderInfos;
          if (this.tileLayer) this.tileLayer.addTo(this.map);
        }
      } else {
        if (this.tileLayer) {
          this.tileLayer.removeFrom(this.map);
          this.tileLayer = null;
        }
      }

      // nouveau mode de fonctionnement 2018
      var resetTiles = true;
      if (tileLayers.length == this.tileLayers.length) {
        resetTiles = false;
        tileLayers.forEach(function(tl, i) {
          if (! this.tileLayers[i] || !tl || JSON.stringify(this.tileLayers[i].options) != JSON.stringify(tl.options) || JSON.stringify(this.tileLayers[i].customParams) != JSON.stringify(tl.customParams)) {
            resetTiles = true;
          }
        }, this);
      }

      if (this.scale && (boundsHaveChanged || resetTiles)) {
        var scale = this.scale;
        this.scale = null; // on commence par mettre this.scale à null car l'appel à remove() provoque un rafraichissement qui finit par exécuter _onZoomEnd() et on ne veut pas que _onZoomEnd recalcule this.scale
        scale.remove();
      }

      if (resetTiles) {
        this.tileLayers.forEach(function(tl) { tl.removeFrom(this.map); }, this);
        this.tileLayers = tileLayers;
        this.tileLayers.forEach(function(tl) { tl.addTo(this.map); }, this);
      }

      if (boundsHaveChanged || resetTiles) {
        this.resetView();
        this.scale = L.articque.adjustedScale({ map: this.map, bounds: this.options.bounds, minimumSurfaceWithTiles: this.options.fluidNavigation ? 0 : 0.6 });
        this.resetView();
      }

      if (typeof cartoJson.CustomParams == "string" && cartoJson.CustomParams != "") {
        try {
          this.customParams = JSON.parse(cartoJson.CustomParams);
        } catch(e) {
          this.customParams = {};
          alert(__("Syntaxe incorrecte pour les paramètres complémentaire du module ") + cartoJson.Name);
        }
        extensions.loadFromParams(this.customParams, cartoJson.Name);
      } else {
        this.customParams = {};
      }

      window.jQuery.when(extensions.whenLoaded(), chartDeferred).done(function() {
        // avec le setTimeout, on s'assure que que l'évènement est déclenché après le code courant et pas immédiatement (e.g. si aucune extension n'est nécessaire)
        setTimeout(extensions.fire.bind(extensions, 'willupdateview', { displayingInfos: cartoJson }), 0);
      });

      this.moduleGroup.endUpdate();
      if (! noRefresh) {
        if (boundsHaveChanged) {
          this.resetView();
        }
        this.moduleGroup.refresh();
      }

      if (cartoJson.ShowScale) {
        this.addScale(cartoJson.CaptionPositions);
      }

      this.scaleInvalidated = false;
      this.setUpdatingState(false);

      window.jQuery.when(extensions.whenLoaded(), chartDeferred).done(function() {
        // avec le setTimeout, on s'assure que que l'évènement est déclenché après le code courant et pas immédiatement (e.g. si aucune extension n'est nécessaire)
        setTimeout(extensions.fire.bind(extensions, 'updateview', { displayingInfos: cartoJson }), 0);
      });
    } catch(e) {
      if (window.Raven) window.Raven.captureException(e); else console.error(e); // eslint-disable-line no-console
      this.moduleGroup.endUpdate();
    }
    return this;
  },

  /**
   * Ajuste le zoom de la vue en fonction des filtres appliqués
   * @public
   * @param {Object} options  peut contenir une propriété `reset` qui, si elle est `true` prend en compte toutes les couches. Sinon, seules les couches sélectionnables sont prises en compte
   * @return {WebViewCreator} this
   */
  adjustZoom: function(options) {
    var bounds, scale = this.scale;
    if (! options || ! options.reset) {
      bounds = this._computeBounds({ onlySelectable: true });
      if (bounds) { // bounds peut être null si tous les éléments sont filtrés
        bounds = bounds.pad(0.05); // sur sur les éléments filtrés + 5% de marge
      }
    } else {
      bounds = this._computeBounds().pad(this._zoomTargetPad); // zoom initial
    }
    if (bounds) { // si les bounds n'ont pas pu être calculés, on ne fait rien
      if (scale) {
        this.scale = null; // on commence par mettre this.scale à null car l'appel à remove() provoque un rafraichissement qui finit par exécuter _onZoomEnd() et on ne veut pas que _onZoomEnd recalcule this.scale
        scale.remove();
      }
      this.scale = L.articque.adjustedScale({ map: this.map, bounds: bounds, minimumSurfaceWithTiles: 0 });
    }
    return this;
  },

  /**
   * Ajoute un fond de carte (au sens module C&D)
   * @public
   * @param {object} mapJson Description du fond de carte
   * @return {WebViewCreator} this
   */
  addMapResource: function(mapJson) {
    var features = [],
      item;

    if (mapJson.GeomList) {
      if (mapJson.features && mapJson.features.length >= mapJson.GeomList.length) {
        features = mapJson.features;
      } else {
        if (mapJson.features && mapJson.features.length) {
          features = mapJson.features;
        }
        for (var i = features.length, len = mapJson.GeomList.length; i < len; i++) {
          item = mapJson.GeomList[i];
          features.push({
            "id": item.id,
            "type":"Feature",
            "properties":{
              "Id": item.id,
              "Name": item.name
            },
            "geometry": WKT.parse(item.geom)
          });
        }
      }
    }

    this.resources.maps[mapJson.Id] = features;
    mapJson.features = features;
    return this;
  },

  /**
   * Ajoute une source de données (au sens module C&D)
   * @public
   * @param {object} datJson Description de la source de données
   * @return {WebViewCreator} this
   */
  addDataResource: function(dataJson) {
    this.resources.datas[dataJson.Id] = dataJson;
    return this;
  },

  /**
   * Ajoute une source de données (au sens module C&D)
   * @public
   * @param {object} datJson Description de la source de données
   * @return {WebViewCreator} this
   */
  addImageResource: function(imageJson) {
    this.resources.images[imageJson.Id] = imageJson;
    return this;
  },

  /**
   * Créé un TileLayer
   * @private
   * @return {L.articque.TileLayer}
   */
  _getTileLayer: function(provider) {
    // maxZoom supporté par les fonds tuilés : 19. Interpolé sur les niveaux de zoom de 20, à 26
    var layer = null, url = '', attribution = '', subdomains = null, maxZoom = 19,
        providers = this.options.wmsProviders || {},
        first = (typeof providers == 'object') ? Object.keys(providers)[0] : null,
        json;
    try {
      json = JSON.parse(provider);
      url = json.url || '';
      attribution = json.attribution || '';
      subdomains = json.subdomains || null;
      maxZoom = json.maxZoom || 19;
    } catch(e) { // si le fournisseur spécifié est introuvable ou ne peut être parsé, on utilise le premier fournisseur
      if (! first) {
        console.warn('wmsProviders is empty. If map contains a wms layer, the wmsProviders options must be defined when instanciating cdonline API.'); // eslint-disable-line no-console
        return null;
      }
      url         = providers[first].url || '';
      attribution = providers[first].attribution || '';
      subdomains  = providers[first].subdomains || null;
      maxZoom     = providers[first].maxZoom || 19;
    }
    if (! provider) {
      // rétro-compatibilité => on utilise le premier forunisseur de la liste si aucun fournisseur n'est spécifié
      provider = first;
    }
    if (providers[provider]) {
      url         = providers[provider].url || '';
      attribution = providers[provider].attribution || '';
      subdomains  = providers[provider].subdomains || null;
      maxZoom     = providers[provider].maxZoom || 19;
    }
    if (url) {
      // maxZoom supporté par les fonds tuilés : 19. Interpolé sur les niveaux de zoom de 20, à 26
      var options = { attribution: attribution, maxZoom: this.MAX_ZOOM, maxNativeZoom: maxZoom };
      if (subdomains) {
        options.subdomains = subdomains;
      }
      layer = L.articque.tileLayer(url, options);
    }
    return layer;
  },

  /**
   * Callback quand une légende est déplacée. Sauvegarde la position via un appel au webservice
   * @private
   * @param  {int} moduleId      Id du module dont la légende a été déplacée
   * @param  {string} legendType Type de légende déplacée (certains modules ont plusieurs légendes)
   * @param  {object} data       la nouvelle position { position: {x, y}, logicalPosition: {x, y} }
   */
  _onLegendMoved: function(moduleId, legendType, data) {
    if (typeof this.options.onLegendMoved == 'function') {
      var self = this,
          container = data.container ? data.container.type || "auto" : "auto",
          ignore = false;
      this._movedCaptions = this._movedCaptions || [];
      if (this._captionPositions && this._captionPositions[moduleId] && this._captionPositions[moduleId][legendType]) {
        ignore = this._captionPositions[moduleId][legendType].Posx == data.logicalPosition.x
                  && this._captionPositions[moduleId][legendType].Posy == data.logicalPosition.y
                  && this._captionPositions[moduleId][legendType].Container == container;
        this._captionPositions[moduleId][legendType].Posx = data.logicalPosition.x;
        this._captionPositions[moduleId][legendType].Posy = data.logicalPosition.y;
        this._captionPositions[moduleId][legendType].Container = container;
      }
      if (! ignore) {
        var i = 0;
        while (i < this._movedCaptions.length) {
          if (this._movedCaptions[i].ModuleId == moduleId && this._movedCaptions[i].CaptionType == legendType) {
            this._movedCaptions.splice(i, 1);
          } else {
            i++;
          }
        }

        this._movedCaptions.push({ ModuleId: moduleId, CaptionType: legendType, Posx: data.logicalPosition.x, Posy: data.logicalPosition.y, Container: container });
        // cette méthode est souvent appellée plusieurs fois de suite
        // => on empile les informations et on effectue un appel au WS dès qu'on est en idle
        if (this._movedCaptionTimer) {
          clearTimeout(this._movedCaptionTimer);
        }
        this._movedCaptionTimer = setTimeout(function() {
          self.options.onLegendMoved(self.webDisplayingModuleId, self._movedCaptions);
          self._movedCaptions = [];
        }, 0);
      }
    }
  },

  _onLegendContext: function(moduleId, legendType, rml) {
    if (
      typeof this.options.onLegendMoved == 'function' && this._captionPositions &&
      window.reactComponents && window.reactComponents.legendPositionCombobox && window.reactComponents.legendPositionCombobox.getValue() == 'mixed'
    ) {
      this._captionPositions[moduleId] = this._captionPositions[moduleId] || {};
      this._captionPositions[moduleId][legendType] = this._captionPositions[moduleId][legendType] || {};
      if (rml._layer._leaflet_id == this.mapLegend._leaflet_id) {
        // map => side
        this.mapLegend.removeModule(rml);
        this._captionPositions[moduleId][legendType].Posx = 'left';
        this._captionPositions[moduleId][legendType].Posy = 'vert-stacked-top#' + (this.sideLegend._modules.length + 1);
        this._captionPositions[moduleId][legendType].Container = 'side';
        rml.setPosition(this._captionPositions[moduleId][legendType].Posx, this._captionPositions[moduleId][legendType].Posy);
        this.sideLegend.addModule(rml);
        rml.invalidatePosition().invalidateSize().refresh()._savePosition();
        newContainer = this.sideLegend;
        // nécessaire uniquement dans ce cas (car RepresentationModuleLegend._savePosition() déclenche "legendmoved" si légende non verticale)
        this._onLegendMoved(moduleId, legendType, { logicalPosition: rml._logicalPosition, container: newContainer });
      } else if (rml._layer._leaflet_id == this.sideLegend._leaflet_id) {
        // side => map
        this.sideLegend.removeModule(rml);
        this._captionPositions[moduleId][legendType].Posx = (rml instanceof L.articque.RMLTitle || rml instanceof L.articque.RMLOrientation || rml instanceof L.articque.RMLScale) ? 'left' : 'right';
        this._captionPositions[moduleId][legendType].Posy = (rml instanceof L.articque.RMLOrientation || rml instanceof L.articque.RMLScale) ? 'stacked-bottom' : 'stacked-top';
        this._captionPositions[moduleId][legendType].Container = 'map';
        rml.setPosition(this._captionPositions[moduleId][legendType].Posx, this._captionPositions[moduleId][legendType].Posy);
        rml.invalidatePosition().invalidateSize();
        this.mapLegend.addModule(rml);
        rml.refresh()._savePosition();
        newContainer = this.mapLegend;
      }
      if (window.reactComponents) {
        window.reactComponents.visualisationTabs.forceUpdate(); // pour afficher ou cacher le btn "Utilliser cet espace pour les légendes"
      }
    }
  },

  _onLegendDblclick: function(moduleId/*, legendType, rml*/) {
    if (window.reactComponents && window.reactComponents.diagramTabs) {
      window.reactComponents.diagramTabs.legendDblClick(moduleId);
    }
  },

  /**
   * Callback lorsque qu'un zoom a lieu sur la carte
   * @param  {L.Event} evt
   * @return {void}
   */
  _onZoomend: function() {
    if (this.scale && this.scaleInvalidated) {
      // réinitialisation de AdjustedScale tout en essayant de conserver une zone visible comparable
      this.scaleInvalidated = false;
      var center = this.map.getCenter(),
          zoom = this.map.getZoom(),
          minZoom = this.map.options.minZoom,
          maxZoom = this.map.options.maxZoom;
      this.scale.remove();
      this.map.options.minZoom = 1;
      this.map.options.maxZoom = this.MAX_ZOOM;
      this.map.fitBounds(this.options.bounds, { animate: false });
      this.scale = L.articque.adjustedScale({ map: this.map, bounds: this.options.bounds, minimumSurfaceWithTiles: this.options.fluidNavigation ? 0 : 0.6 });
      this.map.options.minZoom = minZoom;
      this.map.options.maxZoom = maxZoom;
      this.map.setView(center, zoom, { animate: false });
    }
  },

  /**
   * Ajoute un module de repésentation
   * @public
   * @param {object} positions         positions des légendes classées par module et par type de légende
   * @param {L.TileLayer[]} tileLayers liste des tileLayers à créer sur la carte (cas particulier pour les modules WMS)
   * @param {object} repJson           Description du module de représentation
   * @return {WebViewCreator} this
   */
  addRepresentation: function(positions, tileLayers, repJson) {
    if (repJson.DrawingParams.CustomParams) {
      if (typeof repJson.DrawingParams.CustomParams == "string" && repJson.DrawingParams.CustomParams != "") {
        try {
          repJson.DrawingParams.CustomParams = JSON.parse(repJson.DrawingParams.CustomParams);
        } catch(e) {
          repJson.DrawingParams.CustomParams = {};
          alert(__("Syntaxe incorrecte pour les paramètres complémentaire du module ") + repJson.Name);
        }
      }
    }

    if (repJson.Type == 'Wms') { // cas particulier pour les modules WMS
      var layer = this.cartoJson.toTileLayer(repJson);
      if (layer) {
        tileLayers.push(layer);
        layer._cd_id = repJson.Id; // on recopie l'id du module C&D dans le module du leaflet plugin (utile pour la secto manuelle par exemple)
        if (repJson.DrawingParams.CustomParams) {
          layer.customParams = repJson.DrawingParams.CustomParams;
          extensions.loadFromParams(layer.customParams, repJson.Name);
        }
      }
    } else { // cas général
      var mod = this.cartoJson.toModule(repJson, this.resources);
      if (mod) {
        mod._cd_id = repJson.Id; // on recopie l'id du module C&D dans le module du leaflet plugin (utile pour la secto manuelle par exemple)
        if (repJson.DrawingParams.CustomParams) {
          mod.customParams = repJson.DrawingParams.CustomParams;
        }
        this._modules.push({ module: mod, dataId: repJson.Data.Id, dataParentIds: repJson.Data.ParentIDs || [], mapId: repJson.Map.Id, name: repJson.Name });
        extensions.fire('preaddrepresentation', { module: mod, modulesList: this.modules, moduleGroup: this.moduleGroup, legendGroup: this.legend });
        mod.addTo(this.moduleGroup);
        var legends = { "auto": this.legend, "map": this.mapLegend, "side": this.sideLegend };
        if (mod.customParams && mod.customParams.layerList) { // on ne fournit _layerListLegendTmp que si le customParam layerList est activé sur le module
          legends["layerlist"] = this._layerListLegendTmp;
        }
        this.cartoJson.addModuleLegendsTo(mod, repJson, legends, positions, repJson.Id, this._onLegendMoved.bind(this), this._onLegendDblclick.bind(this), this._onLegendContext.bind(this));
        // extensions.fire('representationadded', { module: mod, modulesList: this.modules, moduleGroup: this.moduleGroup, legendGroup: this.legend });
        if (repJson.ZoomTarget) {
          this.resources.zoomTargets.push(mod);
        }
        this.resources.representations.push(mod);
        if (repJson.DrawingParams.CustomParams) {
          extensions.loadFromParams(mod.customParams, repJson.Name);
        }
      }
    }
    return this;
  },

  /**
   * Ajoute une zone de texte
   * @public
   * @param {object} positions positions des légendes classées par module et par type de légende
   * @param {object} comJson Description de la zone de texte
   * @return {WebViewCreator} this
   */
  addComment: function(positions, comJson) {
    var layer = this.legend;
    if (positions && positions[this.webDisplayingModuleId] && positions[this.webDisplayingModuleId]['comment_' + comJson.Id])  {
      switch (positions[this.webDisplayingModuleId]['comment_' + comJson.Id].Container) {
        case "map":  layer = this.mapLegend;  break;
        case "side": layer = this.sideLegend; break;
      }
    }
    var comment = this.cartoJson.toComment(comJson, positions, this.webDisplayingModuleId, this.map, layer, this._onLegendMoved.bind(this), this._onLegendDblclick.bind(this), this._onLegendContext.bind(this));
    if (comment) {
      if (positions && positions[this.webDisplayingModuleId] && positions[this.webDisplayingModuleId]['comment_' + comJson.Id])  {
        comment.setPosition(positions[this.webDisplayingModuleId]['comment_' + comJson.Id].Posx, positions[this.webDisplayingModuleId]['comment_' + comJson.Id].Posy);
      } else if (! layer.options.vertical && comJson.Id) {
        // pas encore de position enregistrée dans la visu => on enregistre dans le CDX
        // dans le cas layer.options.vertical, la position sera enregistrée par le _savePosition ci-dessous
        // sauf qu'on ne traite pas les élément sans Id (cas du WsUtil.emptyCartoJson)
        this._onLegendMoved(this.webDisplayingModuleId, 'comment_' + comJson.Id, { logicalPosition: comment._logicalPosition, container: layer });
      }
      layer.addModule(comment);
      if (layer.options.vertical) {
        comment.invalidatePosition().invalidateSize().refresh()._savePosition();
      }
    }
    return this;
  },

  /**
   * Ajoute une échelle
   * @public
   * @param {object} positions positions des légendes classées par module et par type de légende
   * @return {WebViewCreator} this
   */
  addScale: function(positions) {
    var layer = this.legend;
    if (positions && positions[this.webDisplayingModuleId] && positions[this.webDisplayingModuleId]['scale'])  {
      switch (positions[this.webDisplayingModuleId]['scale'].Container) {
        case "map":  layer = this.mapLegend;  break;
        case "side": layer = this.sideLegend; break;
      }
    }
    var scale = this.cartoJson.toScale(this.map, positions, this.webDisplayingModuleId, layer, this._onLegendMoved.bind(this), this._onLegendDblclick.bind(this), this._onLegendContext.bind(this));
    if (scale) {
      if (positions && positions[this.webDisplayingModuleId] && positions[this.webDisplayingModuleId]['scale'])  {
        scale.setPosition(positions[this.webDisplayingModuleId]['scale'].Posx, positions[this.webDisplayingModuleId]['scale'].Posy);
      } else if (layer.options.vertical) {
        // pas encore de position enregistrée dans la visu => on enregistre dans le CDX
        // dans le cas layer.options.vertical, la position sera enregistrée par le _savePosition ci-dessous
        this._onLegendMoved(this.webDisplayingModuleId, 'scale', { logicalPosition: scale._logicalPosition, container: layer });
      }
      layer.addModule(scale);
      if (layer.options.vertical) {
        scale.invalidatePosition().invalidateSize().refresh()._savePosition();
      }
    }
    return this;
  },

  /**
   * Permet de récupérer la liste des couches (de type L.articque.ModuleGroup, L.articque.LegendLayer et L.articque.LegendGroup) à intégrer dans une capture L.articque.screenshot
   * @param  {object} options les options contenant 2 booléens `map` et `legend`. Si le premier est `true`, les couches de type `L.articque.ModuleGroupLayer` sont incluses, Si le deuxième est `true`, les couches de type `L.articque.LegendLayer` et `L.articque.legendGroup` sont incluses
   * @return {array}  la liste des couches
   */
  getScreenshotLayers: function(options) {
    var layers = [];
    if (options.map) {
      layers.push(this.moduleGroup);
    }
    if (options.legends) {
      layers.push(this.mapLegend);
      layers.push(this.sideLegend);
    }
    this.fire('getscreenshotlayers', { options: options, layers: layers });
    return layers;
  },

  /**
   * Calcule le rectangle englobant des caractéristiques geoJSON utilisées par les modules
   * Puis met à jour la valeur de options.bounds
   * @private
   * @param {object} options   si `options` contient la propriété `onlySelectable` (valeur `true`), alors les bounds seront calculés uniquement pour les modules sur lesquels une sélection est possible
   * @return {void}
   */
  _computeBounds: function(options) {
    var bbox = null, cur;
    if (this.resources.zoomTargets) {
      this.resources.zoomTargets.forEach(function(mod) {
        if (mod._flows && mod._flows.length && (! options || ! options.onlySelectable || (mod.isSelectable() && mod.isVisible()))) {
          mod._flows.forEach(function(f) {
            if (! L.articque.Util.hasFilter(f) && f.from && f.from.center && f.to && f.to.center) {
              cur = L.latLng(f.from.center.y, f.from.center.x);
              if (bbox === null) {
                bbox = L.latLngBounds(cur, cur);
              } else {
                bbox.extend(cur);
              }
              bbox.extend(L.latLng(f.to.center.y, f.to.center.x));
            }
          });
        }
        // Cas particulier pour les couches de type 'Raster', on calcule les bounds par rapport à la carte parent
        else if (mod._rasters && mod.options.mapBounds && (! options || ! options.onlySelectable || (mod.isSelectable() && mod.isVisible()))) {
          var min = L.latLng(mod.options.mapBounds.min.y, mod.options.mapBounds.min.x);
          var max = L.latLng(mod.options.mapBounds.max.y, mod.options.mapBounds.max.x);
          if (bbox === null) {
            bbox = L.latLngBounds(min, max);
          } else {
            bbox.extend(L.latLngBounds(min, max));
          }
        }        
        else if (mod._features && mod._features.length && (! options || ! options.onlySelectable || (mod.isSelectable() && mod.isVisible()))) {
          mod._features.forEach(function(f) {
            if (! L.articque.Util.hasFilter(f)) {
              if (f.bbox) {
                cur = f.bbox;
              } else if (f.geometry.type == 'Point') {
                cur = L.latLng(f.geometry.coordinates.y, f.geometry.coordinates.x);
              } else {
                cur = null; // TODO autres types de géométries
              }
              if (cur) {
                if (bbox === null) {
                  bbox = (cur instanceof L.LatLngBounds) ? L.latLngBounds(cur.getNorthEast(), cur.getSouthWest()) : L.latLngBounds(cur, cur);
                } else {
                  bbox.extend(cur);
                }
              }
            }
          });
        }
      });
    }
    if (bbox !== null) return bbox;
    if (this.options.bounds && typeof this.options.bounds.toBBoxString == "string") return this.options.bounds;
    return null;
  },

  /**
   * Zoom sur les éléments sélectionnés dans la carte
   * @public
   * @return {WebViewCreator} this
   */
  zoomToSelection: function() {
    var bbox = null, cur;
    this._modules.forEach(function(mod) {
      if (! mod.module) return;
      mod.module.getSelection().forEach(function(f) {
        if (! L.articque.Util.hasFilter(f)) {
          if (f.bbox) {
            cur = f.bbox;
          } else if (f.geometry.type == 'Point') {
            cur = L.latLng(f.geometry.coordinates.y, f.geometry.coordinates.x);
          } else {
            cur = null; // TODO autres types de géométries
          }
          if (cur) {
            if (bbox === null) {
              bbox = (cur instanceof L.LatLngBounds) ? L.latLngBounds(cur.getNorthEast(), cur.getSouthWest()) : L.latLngBounds(cur, cur);
            } else {
              bbox.extend(cur);
            }
          }
        }
      });
    });
    if (bbox !== null) {
      this.map.fitBounds(bbox.pad(0.2), { animate: true, maxZoom: 18 }); // on ne va pas plus loin que le zoom 18 (zoom max de OSM)
    }
    return this;
  }

});

module.exports = WebViewCreator;