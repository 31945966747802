var CD = require("./cd.js");

var types = {
  "UnknowModule": "unknown",

  "Data" : "in-dat",
  "Map" : "in-map",
  "ManualMap" : "in-manual",
  "BDD" : "in-bdd",
  "SF" : 'in-sf',
  "Cognos" : 'in-cognos',
  "ADW": "in-adw",
  "Schema": "in-schema",

  "Union" : "geo-uni",
  "Length" : "geo-len",
  "Surface" : "geo-sur",
  "Intersection" : "geo-int",
  "InclusionExclusion" : "geo-inc",
  "MinimumDistance" : "geo-min",
  "PoleSelection" : "geo-sel",
  "FlyDistance" : "geo-dbird",
  "Maillage" : "geo-grid",
  "RoutingBN" : "geo-droad",
  "Belonging" : "geo-belg",

  "Filter" : "mix-filt",
  "Aggregation" : "mix-agr",
  "Polarisation" : "mix-pol",
  "Interpolation" : "mix-agbef",
  "GroupSurface" : "mix-gsf",
  "Geocod" : "mix-geoc",
  "SectoV3" : "mix-weig",
  "TradeArea" : "mix-trade",
  "GeoLocalization" : "mix-geoloc",
  "Implantation" : "mix-agaft",
  "PointsCreation" : "mix-pts",
  "Cartogram" : "mix-cartog",
  "Lissage" : "mix-lis",
  "Elevation3D" : "mix-3d",
  "DTM" : "mix-dtm",
  "Envelope" : "mix-env",
  "IsochroneBN" : "mix-isoc",
  "ManualSecto" : "mix-secto",
  "Centroid" : "mix-centroid",

  "Quantification" : "stat-qnt",
  "Calculation" : "stat-cal",
  "Regression" : "stat-reg",
  "Bertin" : "stat-bert",
  "MatrixOp" : "stat-mat",
  "ACP" : "stat-acp",
  "AFC" : "stat-afc",
  "CAH" : "stat-cah",
  "Winner" : "stat-win",
  "BoxWhiskers" : "stat-bw",
  "TriangularDiagram" : "stat-tri",
  "GroupData" : "stat-gdat",
  "JoinData" : "stat-join",

  "Strokes" : "rep-drs",
  "Fill" : "rep-fil",
  "Symbols" : "rep-qual",
  "Values" : "rep-val",
  "Pies" : "rep-pie",
  "Portions" : "rep-port",
  "Histograms" : "rep-hist",
  "Raster" : "rep-bac",
  "Flows" : "rep-flo",
  "WMS" : "rep-wms",

  "ChartPie" : "chart-pie",
  "ChartHisto" : "chart-bars",
  "ChartPlot" : "chart-plot",

  "Displaying" : "vis-doc",
  "SuperDisplaying" : "vis-comp"

  // "CdWeb" : "",
  // "SymbolsQual" : "",
  // "QualSymbols" : "",
  // "SeaUrchins" : "",
  // "Sectorization" : "",
  // "SectorizationV2" : "",
  // "Grid" : "",
  // "BackgroundImage" : "",
  // "CirclePOIs" : "",
  // "Charts" : "",
  // "Clouds" : "",
  // "Color" : "",
  // "External" : "",
  // "GroupData" : "",
  // "Isolines" : "",
  // "PotentialAnalysis" : "",
  // "SettingUpSimulation" : "",
  // "SIG" : "",
  // "Vectors" : "",
};

var types2 = {};
for (var t in types) {
    types2[types[t]] = t;
}

var DiagramModel = CD.Class.extend(/** @lends DiagramModel# */{

  includes: [CD.Mixin.Events],

  options: {
    diagramDesc: null,
    containers: null,
    errors: {}
  },

  /**
   * @constructs DiagramModel
   * @classdesc Classe de transformation d'un cartoJson en modules de représentation
   * @param  {Object} options
   * @return {DiagramModel}
   */
  initialize: function(options) {
    CD.Util.setOptions(this, options || {}); // fusionne les options par défaut et les options passées en paramètre
    this._warning = false;
    if (this.options.diagramDesc) {
      this.setDiagramDesc(this.options.diagramDesc);
    }
    if (this.options.containers) {
      this.setContainers(this.options.containers);
    }
  },

  /**
   * Spécifie l'objet de description de l'organigramme
   * @public
   * @param {object} diagramDesc Objet JSON provenant du webservice
   */
  setDiagramDesc: function(diagramDesc) {
    this._diagramDesc = diagramDesc;
    this._diagramDesc.forEach(function(mod) { 
      mod.type = types[mod.ModuleType] || 'unknown';
      mod.posx = Math.round(mod.Posx);
      mod.posy = Math.round(mod.Posy);
    });
  },

  /**
   * Spécifie la liste des conteneurs de l'organigramme
   * @public
   * @param {Object[]} containers
   */
  setContainers: function(containers) {
    this._containers = containers;
  },

  /**
   * Sélectionne les modules de l'organigramme en fonction de leur type
   * @public
   * @param  {string} type type de module à sélectionner
   * @return {array}       un tableau de module | un tableau vide si aucun module n'a été trouvé
   */
  selectModulesByType: function(type) {
    if (this._diagramDesc) {
      return this._diagramDesc.filter(function(mod) { return mod.ModuleType == type; });
    }
    return [];
  },

    /**
     * Retourne la liste des modules du diagramme
     * @public
     * @return {[type]} [description]
     */
  getModules: function() {
    return this._diagramDesc;
  },

  /**
   * Retourne la liste des conteneurs de modules
   * @public
   * @return {Object[]} une liste d'object
   */
  getContainers: function() {
    return this._containers;
  },

  /**
   * Retrouve un module à partir de son Id
   * @public
   * @param  {int} id
   * @return {object}
   */
  getModuleById: function(id) {
    for (var i = this._diagramDesc.length - 1; i >= 0; i--) {
      if (this._diagramDesc[i].ModuleId == id) {
        return this._diagramDesc[i];
      }
    }
    return null;
  },

  /**
   * Retrouve un container à partir de son Id
   * @public
   * @param  {int} id
   * @return {object}
   */
  getContainerById: function(id) {
    for (var i = this._containers.length - 1; i >= 0; i--) {
      if (this._containers[i].Id == id) {
        return this._containers[i];
      }
    }
    return null;
  },

  /**
   * Convertit un nom de type de la version normalisée vers son ancienne version
   * @public
   * @param  {String} type   type normalisé *-*
   * @return {String}        le nom du type utilisé par CDCore
   */
  convertType: function(type) {
    return types2[type] || 'UnknowModule';
  },

  /**
   * Convertit un nom de type de l'ancienne version vers la version normalisée
   * @public
   * @param  {String} type   le nom du type utilisé par CDCore
   * @return {String}        type normalisé *-*
   */
  normalizeType: function(type) {
    return types[type] || 'unknown';
  },

  /**
   * Ajoute un lien entre 2 modules
   * @public
   * @param {int} parent  id du parent
   * @param {int} child   id du fils
   */
  addLink: function(parent, child) {
    for (var i = this._diagramDesc.length - 1; i >= 0; i--) {
      if (this._diagramDesc[i].ModuleId == parent) {
        this._diagramDesc[i].ChildrenIds.push(child);
      }
      if (this._diagramDesc[i].ModuleId == child) {
        this._diagramDesc[i].ParentsIds.push(parent);
      }
    }
  },

  /**
   * Ajoute un module
   * @public
   * @param {object} module
   */
  addModule: function(module) {
    this._diagramDesc.push(module);
  },

  /**
   * Supprime des modules
   * @public
   * @param  {Array} modules
   */
  removeModules: function(modules) {
    var i, j;
    for (i = this._diagramDesc.length - 1; i >= 0; i--) {
      var id = this._diagramDesc[i].ModuleId;
      if (modules.indexOf(id) > -1) {
        this._diagramDesc.splice(i, 1);
        for (j = this._diagramDesc.length - 1; j >= 0; j--) {
          var mod = this._diagramDesc[j],
              idxP = mod.ParentsIds.indexOf(id),
              idxC = mod.ChildrenIds.indexOf(id);
          if (idxP > -1) { mod.ParentsIds.splice(idxP, 1); }
          if (idxC > -1) { mod.ChildrenIds.splice(idxC, 1); }
        }
      }
    }
    for (i = this._containers.length - 1; i >= 0; i--)
      for (j = this._containers[i].ModuleIds.length; j >= 0; j--)
        if (modules.indexOf(this._containers[i].ModuleIds[j]) > -1) {
          this._containers[i].ModuleIds.splice(j, 1);
        }
  },

  /**
   * Supprime des liens entre modules
   * @public
   * @param  {Array} links
   */
  unlinkModules: function(links) {
    links.forEach(function(link) {
      var modP = this.getModuleById(link.ParentModuleId),
          idxC = modP.ChildrenIds.indexOf(link.ChildModuleId),
          modC = this.getModuleById(link.ChildModuleId),
          idxP = modC.ParentsIds.indexOf(link.ParentModuleId);
      if (idxC > -1) {
        modP.ChildrenIds.splice(idxC, 1);
      }
      if (idxP > -1) {
        modC.ParentsIds.splice(idxP, 1);
      }
    }, this);
  },

  /**
   * initialise les erreurs
   * @param {[type]} errors [description]
   * @fires 'statuschanged'
   */
  setErrors: function(errors) {
    this.errors = errors;
    var errorCount = 0;
    Object.keys(errors).forEach(function(key){
      if (! errors[key].isHint)
        errorCount++;
    });
    var warning = errorCount > 0;
    if (warning != this._warning) {
      this._warning = warning;
      this.fire('statuschanged', { warning: warning });
    }
  },

  /**
   * Bascule le module en mode mémoire
   * @public
   * @param {int} moduleId   identifiant du module
   * @param {bool} lock   IsLocked module param
   */
  setLock: function(moduleId, lock) {
    var mod = this.getModuleById(moduleId);
    mod.IsLocked = lock;
  }

});

module.exports = DiagramModel;