var exportList = {
  all: [
    /* ************** Fichier ************** */
    {id: "Data_txt" , name : "data-txt", fullname: __('Txt'), type: "data", tooltip: __('Exportez un dataspace C&D sous la forme d\'un tableau de données dans un fichier au format Texte (séparateur : Tabulation), compatible avec de nombreux traitements de textes, tableurs ou bases de données')},
    {id: "Data_csv" , name : "data-csv", fullname: __('Csv'), type: "data", tooltip: __('Exportez un dataspace C&D sous la forme d\'un tableau de données dans un fichier au format Csv (séparateur \';\'), compatible avec de nombreux traitements de textes, tableurs ou bases de données')},
    {id: "Data_xls" , name : "data-xls", fullname: __('Xls'), type: "data", tooltip: __('Exportez un dataspace C&D sous la forme d\'un tableau de données dans un fichier au format Microsoft Excel 97-2003')},
    {id: "Data_xlsx" , name : "data-xlsx", fullname: __('Xlsx'), type: "data", tooltip: __('Exportez un dataspace C&D sous la forme d\'un tableau de données dans un fichier au format classeur Microsoft Excel')},

    {id: "Data_dbf" , name : "data-dbf", fullname: __(''), type: "map", hidden: true},
    {id: "Data_json" , name : "data-json", fullname: __(''), type: "map", hidden: true},

    /* ************** SQL ************** */
    {id: "Data_sql" , name : "data-sql", fullname: __('SQL'), type: "sql", tooltip: __('Exportez vos fonds de cartes ou vos dataspaces C&D directement vers une base de données externe via une requete SQL')},

    /* ************** Fond de carte ************** */
    {id: "WorkingMap_vxf" , name : "map-vxf", fullname: __('Vxf'), type: "map", tooltip: __('Exportez un fond de carte sous la forme d\'un fichier de données spatiales vectorielles au format VXF, compatible avec les logiciels Articque Cartes & Données')},
    {id: "WorkingMap_num" , name : "map-num", fullname: __('Num'), type: "map", tooltip: __('Exportez un fond de carte sous la forme d\'un fichier de données spatiales vectorielles au format NUM, compatible avec les logiciels Cartes & Données')},
    {id: "WorkingMap_shp" , name : "map-shp", fullname: __('Shapefile'), type: "map", disabled:true, hidden: true},
    {id: "WorkingMap_kml" , name : "map-kml", fullname: __('Kml'), type: "map", tooltip: __('Exportez un fond de carte sous la forme d\'un fichier de données spatiales vectorielles au format KML, compatible avec l\'extension Articque MAP ou des solutions SIG')},
    {id: "WorkingMap_wkt" , name : "map-wkt", fullname: __('WKT'), type: "map", tooltip: __('Exportez un fond de carte sous la forme d\'un fichier de données spatiales vectorielles au format WKT, compatible avec des solutions SIG ou des bases de données spatiales')},
    {id: "WorkingMap_mif" , name : "map-mif", fullname: __('MIF'), type: "map", disabled:true, hidden: true},
    {id: "WorkingMap_tab" , name : "map-tab", fullname: __('TAB'), type: "map", disabled:true, hidden: true},
    {id: "WorkingMap_geoJson" , name : "map-geojson", fullname: __('GeoJson'), type: "map", tooltip: __('Exportez un fond de carte sous la forme d\'un fichier de données spatiales vectorielles au format GeoJSON, compatible avec l\'extension Articque MAP, des solutions SIG ou de webmapping')},

    {id: "WorkingMap_geo" , name : "map-geo", fullname: __(''), type: "map", hidden: true},
    {id: "WorkingMap_ung" , name : "map-ung", fullname: __(''), type: "map", hidden: true},
    {id: "WorkingMap_dxf" , name : "map-dxf", fullname: __(''), type: "map", hidden: true},
    {id: "WorkingMap_svg" , name : "map-svg", fullname: __(''), type: "map", hidden: true},

    /* ************** Image ************** */
    {id: "Bitmap_png" , name : "bitmap-png", fullname: __('Image'), type: "image", tooltip: __('Exportez votre carte et sa mise en page sous la forme d\'une image statique (formats png, gif, jpeg, tiff, bmp, emf, wmf) pour l\'insérer dans vos documents textes et présentations')},
    {id: "Bitmap_jpg" , name : "bitmap-jpg", fullname: __(''), type: "image", hidden: true},
    {id: "Bitmap_gif" , name : "bitmap-gif", fullname: __(''), type: "image", hidden: true},
    {id: "Bitmap_pngBM" , name : "bitmap-png-bm", fullname: __(''), type: "image", hidden: true},
    {id: "GeoTiff" , name : "bitmap-geotiff", fullname: __(''), type: "image", hidden: true},
    {id: "Vectorial_emf" , name : "vect-emf", fullname: __(''), type: "image", hidden: true},

    {id: "Vectorial_svg" , name : "vect-svg", fullname: __(''), type: "image", hidden: true},
    {id: "Bitmap_html" , name : "bitmap-html", fullname: __(''), type: "image", hidden: true},

    /* ************** PDF ************** */
    {id: "Vectorial_pdf" , name : "vect-pdf", fullname: __('PDF'), type: "pdf", tooltip: __('Exportez votre carte et sa mise en page sous la forme d\'un fichier PDF vectoriel pour l\'imprimer ou l\'éditer dans une solution de création graphique')},

    {id: "Vectorial_eps" , name : "vect-eps", fullname: __(''), type: "pdf", hidden: true},
    {id: "Vectorial_swf" , name : "vect-swf", fullname: __(''), type: "pdf", hidden: true},


    /* ************** Autres ************** */
    {id: "Vectorial_html5" , name : "vect-html5", fullname: __(''), type: "other", hidden: true},
    {id: "CDWeb" , name : "cdweb", fullname: __(''), type: "other", hidden: true},
    {id: "Multimap" , name : "multimap", fullname: __('Multicarte'), type: "multimap", tooltip: __('Réalisez des opérations de traitements par lots pour automatiser la production de vos exports multiples')},
    {id: "Word" , name : "word", fullname: __('Word'), type: "word", tooltip: __('Exportez vos cartes, vos graphiques et vos tableaux de données dans un modèle de document Microsoft Word de votre choix, à des emplacements prédéfinis')},
    {id: "PowerPoint" , name : "powerpoint", fullname: __(''), type: "other", hidden: true},
    {id: "AutoReport" , name : "auto-report", fullname: __(''), type: "other", hidden: true},
    {id: "External" , name : "external", fullname: __(''), type: "other", hidden: true}
  ],

  byType: function(type) {
    return this.all.filter(function(e) {
      return e.type == type;
    });
  },

  byName: function(name) {
    for (var i = this.all.length - 1; i >= 0; i--) {
      if (this.all[i].name == name) {
        return this.all[i];
      }
    }
    return null;
  },

  byId: function(id) {
    for (var i = this.all.length - 1; i >= 0; i--) {
      if (this.all[i].id == id) {
        return this.all[i];
      }
    }
    return null;
  }
};

module.exports = exportList;