var CD = require("./cd.js"),
    ExportList = require('./export-list.js');


var ExportsModel = CD.Class.extend(/** @lends ExportsModel# */{

  includes: [CD.Mixin.Events],

  options: {
    exportsDesc: null
  },

  /** Constantes état de l'export **/
  TOFIX: "TOFIX",
  READY: "READY",
  DONE: "DONE",
  ERROR: "ERROR",
  PROCESSING: "PROCESSING",

  /**
   * @constructs ExportsModel
   * @classdesc Classe de transformation d'un cartoJson en une liste d'exports
   * @param  {Object} options
   * @return {ExportsModel}
   */
  initialize: function(options) {
    CD.Util.setOptions(this, options || {}); // fusionne les options par défaut et les options passées en paramètre
    this._warning = false;
    this.setExportsDesc(this.options.exportsDesc);
  },

  /**
   * Met à jour le status global des exports en prévenant les éventuels listeners
   * @public
   * @fires 'statuschanged'
   */
  updateStatus: function() {
    var warning = false;
    this._exportsDesc.forEach(function(exp) { 
      if (exp.state == this.TOFIX || exp.state == this.ERROR) {
        warning = true;
      }
    }, this);
    if (warning != this._warning) {
      this._warning = warning;
      this.fire('statuschanged', { warning: warning });
    }
  },

  /**
   * Spécifie l'objet de description de l'organigramme
   * @public
   * @param {object} exports Objet JSON provenant du webservice
   */
  setExportsDesc: function(exportsDesc) {
    var self = this;
    this._exportsDesc = exportsDesc;
    this._exportsDesc.forEach(function(exp) { 
      exp.type = ExportList.byId(exp.ExportType).name;
      exp.documentsId = [];
      exp.description = "";
      if (exp.ExportType == 'Data_sql' || exp.ExportType == "Multimap") {
        exp.path = ""; 
        exp.filename = "";
        exp.state = self.READY; 
      } else {
        try{
          var outputPath = JSON.parse(exp.OutputPath);
          exp.path = outputPath.ParentFolderKey; 
          exp.filename = outputPath.Filename;
        }catch(e){exp.path="";exp.filename="";}
        if(exp.filename.length > 0 && exp.path > 0){
          exp.state = self.READY;
        }
        else{
          exp.state = self.TOFIX;
        }
      }
    });
    // setTimeout pour que l'événement "statuschanged" soit déclenché après le initialize et laisser le temps de s'y abonner
    setTimeout(this.updateStatus.bind(this), 0);
  },

  addExport: function(id, type){
    var exp = {
      ExportId : id,
      ExportName : "",
      ExportType : type,
      type : ExportList.byId(type).name,
      OutputPath : "",
      state: this.TOFIX,
      description: "",
      documentsId: []
    }
    this._exportsDesc.push(exp);
    this.updateStatus();
    return this;
  },

  removeExport: function(id){
    for (var i = 0; i < this._exportsDesc.length; i++) {
      if(this._exportsDesc[i].ExportId == id) {
        this._exportsDesc.splice(i,1);
        this.updateStatus();
        return this;
      }
    }
    return this;
  },

  getExports: function() {
    return this._exportsDesc;
  },

  getExportForWizard: function() {
    var results = [];
    for (var i = 0; i < this._exportsDesc.length; i++) {
      if(this._exportsDesc[i].state == this.READY && !(this._exportsDesc[i].ExportType == 'Data_sql' || this._exportsDesc[i].ExportType == "Multimap" )){
        results.push({id: this._exportsDesc[i].ExportId, name: this._exportsDesc[i].ExportName, type: this._exportsDesc[i].ExportType});
      }
    }
    return results;
  },

  getExportById: function(id){
    for (var i = 0; i < this._exportsDesc.length; i++) {
      if(this._exportsDesc[i].ExportId == id) {
        return this._exportsDesc[i];
      }
    }
    return null;
  }

});

module.exports = ExportsModel;