(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.deltaInPx = {

    loadExtension: function(parent/*, dependancies*/) {
      this.parent = parent;
      parent.on('preaddrepresentation', this.onAddRepresentation, this);
      parent.wvc._modules.forEach(this.onAddRepresentation, this);
      parent.wvc.moduleGroup.refresh();
    },

    onAddRepresentation: function(data) {
      if (data.module.customParams && data.module.customParams.deltaInPx) {
        var dip = data.module.customParams.deltaInPx;
        if (dip && typeof dip.x == "number" && typeof dip.y == "number" &&
            data.module instanceof L.articque.RMFill ||
            data.module instanceof L.articque.RMLabels ||
            data.module instanceof L.articque.RMSymbols ||
            data.module instanceof L.articque.RMCharts
          ) {
          data.module.options.delta.x = dip.x;
          data.module.options.delta.y = - dip.y; // pour être cohérent avec le décalage en unités de la carte
        } else{
          console.warn("Le paramètre complémentaire deltaInPx est limité aux data.modules remplissage, contours, symboles, valeurs, secteurs, portions et histogrammes");
        }
      }
    }
  };
})();