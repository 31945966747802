var $ = window.$ || window.jQuery,
breadcrumb = {

  render: function(parent/*, domId*/) {
    var self = this;
    this._$node = $(parent.appBar.getBreadcrumbNode());
    this._doctype = parent.options.doctype;

    if (this._doctype == "publication") {
      this._$node.text(parent.options.title);
    } else { // type == "atlas"
      // évènements sur les éléments du fil d'Arianne (propre à l'atlas)
      this._$node.on('click', 'a', function() {
        var $this = $(this);
        if (! $this.hasClass('dropdown-toggle') && ! $this.hasClass('accordion-group') ) {
          if (parent) {
            parent.navigateTo($this.data('target'), null, "breadcrumb");
          }
        }
      })
      .on('DOMMouseScroll mousewheel', '.breadcrumb-btn, .indic-name', function(e) {
        self._$node.get(0).scrollLeft += (e.originalEvent.detail < 0|| e.originalEvent.wheelDelta > 0) ? -30 : 30;
        e.preventDefault();
      });
    }
  },

  update: function(fileList, currentFilename, indicGroups) {

    /**
     * Génère la liste des enfants de la carte en cours d'affichage
     * @param  {string} filename  nom du fichier
     * @return {string[]}         chaque élément de cette liste est un code HTML <li>
     */
    function getChildren(filename) {
      var children = [],
          order = fileList[filename].order;
      for(var i in fileList) {
        if (fileList[i].type == 'cartojson') {
          if (fileList[i].drilldown && fileList[i].drilldown.ddSource && fileList[i].drilldown.ddSource == filename && fileList[i].order == order) {
            children.push('<li data-feature-name="' + fileList[i].featureName + '"><a href="javascript:void(0)" data-target="' + i + '">' + fileList[i].featureName + '</a></li>');
          }
        }
      }
      children.sort();
      return children;
    }

    /**
     * récupère la liste des frère d'un élément
     * @param  {string} filename    nom de l'élément dont on cherche les frères
     * @param  {bool}   last        est-ce le dernier élément du fil d'Arianne. Si True, une liste d'indicateurs frères sera également générée
     * @param  {string[]} children  html des enfants par niveau
     * @return {geo: string[], indic: string[]} 2 listes de frères en 2 catégories (frères géographiques et frères indicateurs). chaque élément de ces listes est un code HTML <li>
     */
    function getSiblings(filename, last, children) {
      var siblings = { geo: [], indic: [] },
          level = fileList[filename].level,
          order = fileList[filename].order,
          featureId = fileList[filename].featureId,
          ddSource = fileList[filename].drilldown ? fileList[filename].drilldown.ddSource : null;
      for(var i in fileList) {
        if (fileList[i].type == 'cartojson') {
          if (fileList[i].drilldown && fileList[i].drilldown.ddSource && fileList[i].drilldown.ddSource == ddSource && fileList[i].order == order) {
            siblings.geo.push('<li data-feature-name="' + fileList[i].featureName + '"><a href="javascript:void(0)"' + (i == filename ? ' class="current"' : '') + ' data-target="' + i + '">' + fileList[i].featureName + '</a>' + (!!children && fileList[i].featureId == featureId ? children : "") + '</li>');
          }
          if (last && fileList[i].level == level && fileList[i].featureId == featureId) {
            siblings.indic.push('<li data-indic-name="' + fileList[i].indicName + '" data-order="' + fileList[i].order + '"><a href="javascript:void(0)"' + (i == filename ? ' class="current"' : '') + ' data-target="' + i + '">' + fileList[i].indicName + '</a></li>');
          }
        }
      }
      siblings.geo.sort();
      siblings.indic.sort();
      return siblings;
    }

    if (fileList[currentFilename] && fileList[currentFilename].type == "cartojson") {
      var bcItems = [currentFilename],
          f = currentFilename,
          htmlByDepth = [],
          indicHtml = '',
          html = [],
          cartojsonCount = 0,
          li, data, text, siblings, children;
      while (fileList[f].drilldown && fileList[f].drilldown.ddSource && fileList[fileList[f].drilldown.ddSource]) {
        f = fileList[f].drilldown.ddSource;
        bcItems.unshift(f);
      }

      for(var i0 in fileList) {
        if (fileList[i0].type == 'cartojson') {
          cartojsonCount++;
        }
      }

      children = getChildren(currentFilename);
      if (children.length) {
        htmlByDepth[bcItems.length] = '<li><div class="breadcrumb-children"><ul class="list-unstyled">' +
          children.join('') +
          '</ul></div></li>';
      }

      if (cartojsonCount == 1) {
        html.push('<ol class="breadcrumb"><li>');
        html.push('<span class="indic-name">&emsp;<span class="breadcrumb-printable breadcrumb-indic">' + fileList[currentFilename].indicName + '</span></span>');
        html.push('</ol></li>');
      } else {
        text = (fileList[currentFilename].level == 0) ? '<span class="glyphicon glyphicon-home"></span>' : fileList[currentFilename].featureName;
        html.push('<ol class="breadcrumb"><li>');
        html.push('<div class="dropdown">');
        html.push('<a href="javascript:void(0)" class="breadcrumb-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">');
        html.push('<span class="breadcrumb-printable breadcrumb-geo">' + text + '</span>');
        html.push(' <span class="caret"></span></a><ul class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenu1">');
        for(var l = bcItems.length, i = l - 1; i >= 0; i--) {
          data = fileList[bcItems[i]];
          text = (data.level == 0) ? '<span class="glyphicon glyphicon-home"></span>' : data.featureName;
          siblings = getSiblings(bcItems[i], i == l - 1, htmlByDepth[i + 1]);
          li = ['<li>'];
          if (siblings.geo.length > 0) {
            li.push(
              (i > 1 ? '<div class="breadcrumb-children"><ul class="list-unstyled">' : '<div><ul class="list-unstyled">') +
              siblings.geo.join('') +
              '</ul></div>'
            );
          } else {
            li.push('<a href="javascript:void(0)" data-target="' + bcItems[i] + '" ><span class="breadcrumb-printable breadcrumb-geo">' + text + '</span></a>' + (htmlByDepth[i + 1] || ''));
          }
          li.push('</li>');
          htmlByDepth[i] = li.join('');

          if (i == l - 1) { // seulement pour le dernier niveau
            li = ['<li>'];
            if (siblings.indic.length > 1) {

              //On check s'il y a un fichier de menu avant de faire le menu standard
              li.push('<div class="dropdown dropdown-accordion"><a href="javascript:void(0)" class="breadcrumb-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-accordion="#accordion">');
              li.push('<span class="breadcrumb-printable breadcrumb-indic">' + data.indicName + '</span>');
              li.push(' <span class="caret"></span></a><ul class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenu1">');


              if(indicGroups){
                var menus = indicGroups;

                //On parcours tous les siblings pour quel order il manque
                var listSiblingOrder = [];
                var listAlreadyDisplay = [];
                for(var y = 0; y < siblings.indic.length; y++){
                  var idOrder = $(siblings.indic[y])[0].getAttribute('data-order');
                  var target = $(siblings.indic[y]).find('a')[0].getAttribute('data-target');
                  listSiblingOrder[idOrder] = target;
                }

                li.push('<div class="panel-group panel-group-accordion" id="accordion">');

                for(var x = 0; x < menus.length; x++) {
                  var element = menus[x];

                  if(element.type == 'group'){
                    //On check si c'est pas la dernière ligne
                    if(x < menus.length -1){
                      //On check s'il a au moins un enfant visible
                      var flag = false;
                      for(var z = 1; z < 3; z++){
                        var child = menus[x+z];
                        if(child){
                          if(child.type == 'group')
                            break; //On ne cherche pas plus loin

                          if(child.params.order in listSiblingOrder){
                            flag = true;
                            break;
                          }
                        } else { //Si pas d'enfant on passe à l'élément suivant
                          break;
                        }
                      }
                    }

                    //S'il respecte les conditions on ajoute le groupe
                    if(flag){
                      li.push('<div class="panel panel-default panel-accordion">');
                      li.push('<div class="panel-heading  accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#panelMenu'+x+'">');
                      li.push('<a href="#panelMenu'+x+'" class="accordion-group" >'+ element.params.name +'</a>');

                      li.push('</div>'); //Div HEADING
                      li.push('<div class="panel-collapse collapse" id="panelMenu'+x+'" style="height: 0px;">'); //height 0px pour l'initialisation
                      li.push('<ul class="list-group">');

                      //On ajoute tous ces enfants (visu enfant)
                      for(var w = 1; w < menus.length; w++){
                        var child2 = menus[x+w];
                        if(!child2)
                          break;

                        if(child2.type == 'group' || child2.params.parent == 0)
                          break;
                        else {
                          if(child2.params.order in listSiblingOrder) {
                            li.push('<li><a href="javascript:void(0)" data-target="' + listSiblingOrder[child2.params.order] + '">'+ child2.params.modName +'</a></li>');
                            listAlreadyDisplay.push(child2.params.order);
                          }
                        }
                      }
                      li.push('</ul>');
                      li.push('</div>');
                      li.push('</div>'); //panel default
                    }

                  } else { //Case Visu
                    if(element.params.order in listSiblingOrder && listAlreadyDisplay.indexOf(element.params.order) < 0) //On vérifie qu'on a pas déjà traité l'enfant
                      li.push('<li><a href="javascript:void(0)" data-target="' + listSiblingOrder[element.params.order] + '">'+ element.params.modName +'</a></li>');
                  }
                }
              } else {
                li.push(siblings.indic.join('')); //Case normal
              }

              li.push('</ul></div>');
            } else {
              li.push('<span class="indic-name">&emsp;<span class="breadcrumb-printable breadcrumb-indic">' + data.indicName + '</span></span>');
            }
            indicHtml = li.join('');
          }
        }
        html.push(htmlByDepth[0]);
        html.push('</ul></div></li>');
        html.push(indicHtml);
        html.push('</ol>');
      }
      this._$node.html(html.join('')).addClass('full-height');

      // Prevent dropdown to be closed when we click on an accordion link
      $('.dropdown-accordion').on('click', '.panel-heading[data-toggle="collapse"]', function (event) {
        event.preventDefault();
        event.stopPropagation();

        $($(this).data('parent')).find('.panel-collapse.in').collapse('hide');
        $($(this).data('target')).collapse('show');
      });

    }
  },

  print: function(/*fileList, currentFilename, indicGroups*/) {
    if (this._doctype == "publication") {
      return { css: '', html: this._$node.text() }
    } else { // type == "atlas"
      var printableBreadcrumb = this._$node.find(".breadcrumb-printable")
                                    .map(function() { return this.outerHTML; })
                                    .toArray();
      return {
        html: (printableBreadcrumb.length ? printableBreadcrumb.join(' ') : ''),
        css: 'h1 .breadcrumb-geo + .breadcrumb-geo:before { display: inline; content: " / "; }' +
             'h1 .breadcrumb-geo:first-child + .breadcrumb-printable:before { display: inline; content: ""; }' + // pas devant le premier (décalage de 1 car le vrai premier est est le lien vers l'accueil)
             'h1 .breadcrumb-geo + .breadcrumb-indic:before { display: inline; content: " - "; }'
      };
    }
  }
};

module.exports = breadcrumb;