var stringFormatter = {

  defaultNaNStr: '--',

  // L.articque.Util.numberUnformat - La méthode est reproduite ici pour pouvoir être utilisée dans l'environnement isolé par jailed.js
  numberUnformat: function(str) {
    if (typeof str == 'number') {
      return str;
    }
    str = str.split(L.articque.Util.THOUSANDS_SEP).join('').replace(L.articque.Util.DECIMAL_SEP, '.').replace(' x 10^', 'E').replace(/\s/g, '');
    if (/(-?(?:(?:\d+|\d*\.\d+)(?:[E|e][+|-]?\d+)?))/.test(str)) {
      return parseFloat(str);
    } else {
      return Number.NaN;
    }
  },

  nanStr: function(str) {
    return (typeof str == 'string') ? str : stringFormatter.defaultNaNStr;
  },

  toPercent: function(data, decimals, decimalSeparator, nan) {
    var options = (typeof decimalSeparator == 'object') ? decimalSeparator : { decimalSeparator: decimalSeparator, nan: nan },
        number = (typeof data == 'object') ? data.number : data;

    if (typeof number == 'string') {
      number = this.numberUnformat(number);
    }

    return (typeof number != "number" || isNaN(number)) ? stringFormatter.nanStr(options.nan) : stringFormatter.round(number * 100, decimals, options);
  },

  toFixed: function(data, decimals, decimalSeparator, thousandsSeparator, nan) {
    var options = (typeof decimalSeparator == 'object') ? decimalSeparator : { decimalSeparator: decimalSeparator, thousandsSeparator: thousandsSeparator, nan: nan },
        number = (typeof data == 'object') ? data.number : data;

    if (typeof number == 'string') {
      number = this.numberUnformat(number);
    }

    if (typeof decimals == 'string') {
      decimals = parseInt(decimals.trim(), 10);
    } else if (typeof decimals == 'undefined') {
      decimals = 3;
    }

    if (typeof number != "number" || isNaN(number)) {
      return stringFormatter.nanStr(options.nan);
    }

    var str = number.toFixed(decimals);
    if (options.thousandsSeparator) {
      var parts = str.split('.');
      str = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator) + ((parts.length == 2) ? ('.' + parts[1]) : '');
    }
    if (options.decimalSeparator) {
      str = str.replace('.', options.decimalSeparator);
    }
    return (options.prefix || "") + str + (options.suffix || "");
  },

  round: function(data, decimals, decimalSeparator, thousandsSeparator, nan) {
    var options = (typeof decimalSeparator == 'object') ? decimalSeparator : { decimalSeparator: decimalSeparator, thousandsSeparator: thousandsSeparator, nan: nan },
        number = (typeof data == 'object') ? data.number : data,
        str,
        prefix = options.prefix || "",
        suffix = options.suffix || "";
    if (typeof number == 'string') {
      number = this.numberUnformat(number);
    }
    if (typeof number != "number" || isNaN(number)) {
      return stringFormatter.nanStr(options.nan);
    }
    delete options.prefix; // on ne veut pas de prefix / suffix pour les ajustements ci-dessous
    delete options.suffix;
    str = stringFormatter.toFixed(number, decimals, options);
    if (str.indexOf(options.decimalSeparator || '.') > -1) {
      while (str.substr(-1) == '0') {
        str = str.substring(0, str.length - 1);
      }
      if (str.substr(-1) == (options.decimalSeparator || '.')) {
        str = str.substring(0, str.length - 1);
      }
    }
    return prefix + str + suffix;
  },

  escapeHtml: function(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;")
      .split(" ").join("&nbsp;")
      .replace(/`/g, "&#x60;");
  },

  unescapeHtml: function(safe) {
    return safe
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#x27;/g, "'")
      .replace(/&nbsp;/g, " ")
      .replace(/&#x60;/g, "`");
  },

  _div: null,
  sanitizeHTML: function(str) {
    if (this._div == null)
      this._div = document.createElement('div');
    this._div.textContent = str;
    return this._div.innerHTML;
  },

  toChartPercent: function(data, decimals, decimalSeparator, nan) {
    var options = (typeof decimalSeparator == 'object') ? decimalSeparator : { decimalSeparator: decimalSeparator, nan: nan },
        number = data.number,
        sum = 1;

    if (data.values && typeof data.values.reduce == 'function') {
      sum = data.values.reduce(function(acc, val) {
        // on considère les données invalides comme nulles.
        // si l'option n'est pas cochée dans les params du module,
        // alors le graphique n'est pas affiché et on ne peut pas activer la tooltip
        return (typeof val != 'number' || isNaN(val)) ? acc : acc + val
      }, 0);
    }

    if (typeof number == 'string') {
      number = this.numberUnformat(number);
    }

    return stringFormatter.toPercent(number / sum, decimals, options);
  },

  /**
   * Convertit une date au format ISO `YYYY-MM-DD` au format local du navigateur
   * @param  {string} yyyy_mm_dd la date au format ISO à convertir
   * @return {string}            la date au format local
   */
  dateIsoToStr: function(yyyy_mm_dd) {
    try {
      var parts = yyyy_mm_dd.split('-');
      if (parts.length == 3) {
        var date = new Date(parseFloat(parts[0]), parseFloat(parts[1]) - 1, parseFloat(parts[2]));
        return date.toLocaleDateString(); // on laisse le navigateur formater la date selon la locale de l'utilisateur
      }
    } catch (e) {/* rien */}
    return yyyy_mm_dd; // en cas d'échec, on renvoit la date non modifiée
  },

  /**
   * convertit une date au format "yyyy-mm-dd hh:ii:ss" avec l'heure UTC
   * @param  {DateTime} dateTime  la date à convertir
   * @return {string}             une chaine au format "yyyy-mm-dd hh:ii:ss"
   */
  dateTimeUTCToStr: function(dateTime) {
    try {
      return ("000" + dateTime.getUTCFullYear()).slice(-4) + '-' +
             ("0"   + (dateTime.getUTCMonth() + 1)).slice(-2) + '-' +
             ("0"   + dateTime.getUTCDate()).slice(-2) + ' ' +
             ("0"   + dateTime.getUTCHours()).slice(-2) + ':' +
             ("0"   + dateTime.getUTCMinutes()).slice(-2) + ':' +
             ("0"   + dateTime.getUTCSeconds()).slice(-2);
    } catch (e) {/* rien */}
    return yyyy_mm_dd; // en cas d'échec, on renvoit la date non modifiée
  }

};

module.exports = stringFormatter;