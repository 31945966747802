var $ = window.$ || window.jQuery,
shareEngine = {

  render: function(parent, domId) {
    var self = this;
    this.open = false;
    this.viewStateHash = null;
    this.parent = parent;
    this.bookmarks = parent.options.bookmarks || [];
    $('#' + domId).append(parent.renderTpl(this._getTplLayout(), this));

    parent.on('toggleSearchBtn toggleRouteBtn', function(){
      if (self.open) {
        self.toggleShare();
      }
    });

    // génération d'un lien de partage
    $("#share-link-btn, #share-link-info").on("click", function(){
      $("#share-link-info").hide();
      $("#share-link-input").show().find("input").val('...').addClass('disabled');
      var viewState = JSON.stringify(self.parent.getViewState());
      $.post(parent.options.generateShareUrl, { data: viewState }, function(result) {
        $("#share-link-input input").val(result["displayUrl"]).removeClass('disabled').selectAndPutCursorAtEnd();
        self.viewStateHash = self._hashCode(viewState);
      });

    });

    // création d'un nouveau favori
    $("#add-bookmark-btn").click(function() {
      $("#bookmark_list").append('<div class="text-muted"><span class="glyphicon glyphicon-hourglass"></span></div>')
      $.post(parent.options.generateBookmarkUrl, { data: JSON.stringify(self.parent.getViewState()) }, function(result) {
        self.bookmarks.push(result);
        $("#bookmark_list").append(self._bookmarkLiTpl(self.bookmarks.length - 1)).find(".text-muted").remove();
        $("#bookmark_list .edit-bookmark-btn:last").trigger('click');
      });
    });

    // renommage d'un favori existant
    $("#bookmark_list").on('click', '.edit-bookmark-btn', function() {
      var $li = $(this).parents('li:first'),
          $apply = $li.find('.apply-btn'),
          $cancel = $li.find('.cancel-btn'),
          $input,
          bookmarkId = parseFloat($li.data('bookmark-id'));
      $li.find('.btn-group, a').hide();
      $input = $li.find('.bookmark-name-input').show().find('input').val(self.bookmarks[bookmarkId].name).selectAndPutCursorAtEnd()
         .on('keyup', function (e) { if (e.keyCode == 13) { $apply.trigger('click'); } });
      $apply.off('click').on('click', function() {
        var newName = $input.val();
        if (self.bookmarks[bookmarkId].name != newName) {
          self.bookmarks[bookmarkId].name = newName;
          $apply.addClass('disabled');
          $cancel.addClass('disabled');
          $li.find('a').text(self.bookmarks[bookmarkId].name);
          $.post(self.bookmarks[bookmarkId].renameUrl, { name: $input.val() }, function() {
            $apply.removeClass('disabled');
            $cancel.removeClass('disabled');
          });
        }
        $cancel.trigger('click');
      });
      $cancel.off('click').on('click', function() {
        $li.find('.btn-group, a').show();
        $li.find('.bookmark-name-input').hide();
      });
    });

    // copie du lien d'un favori dans le presse-papier
    $("#bookmark_list").on('click', '.copy-bookmark-btn', function() {
      var bookmarkId = parseFloat($(this).parents('li:first').data('bookmark-id'));
      if ($("#share-link-info").is(':visible')) {
        var $info = $("#share-link-info").hide(),
            $inputContainer = $("#share-link-input").show();
        $inputContainer.find("input").val(self.bookmarks[bookmarkId].displayUrl).select();
        document.execCommand("copy");
        $info.show();
        $inputContainer.hide();
      } else {
        var $input = $("#share-link-input input"),
            tmp = $input.val();
        $input.val(self.bookmarks[bookmarkId].displayUrl).select();
        document.execCommand("copy");
        $input.val(tmp);
      }
    });

    // suppression d'un favori
    $("#bookmark_list").on('click', '.delete-bookmark-btn', function() {
      var $btn = $(this),
          $li = $btn.parents('li:first'),
          bookmarkId = parseFloat($li.data('bookmark-id'));
      if ($btn.hasClass('btn-danger')) {
        $btn.popover("hide");
        setTimeout(function() { $li.remove(); }, 0); // setTimeout pour que la suppression ait lieu après le _onDocumentClick
        $.post(self.bookmarks[bookmarkId].deleteUrl, {}, function() {});
      } else {
        $btn.addClass('btn-danger')
            .popover({ container: 'body', content: __("share_confirm"), placement: "top", trigger: "focus" })
            .popover('show')
            .on('hide.bs.popover', function () {
              $btn.removeClass("btn-danger");
            });
      }
    });

    // ouverture d'un favori
    $("#bookmark_list").on('click', 'a', function(evt) {
      evt.preventDefault();
      var bookmarkId = parseFloat($(this).parents('li:first').data('bookmark-id'));
      self.parent.setViewState(JSON.parse(self.bookmarks[bookmarkId].state));
      return false;
    });

    // copie d'un lien de partage dans le presse-papier
    $("#copy-link-btn").on("click", function(){
      $("#share-link-input input").selectAndPutCursorAtEnd();
      document.execCommand("copy");
    })
  },

  /**
   * génération de l'interface du panneau de partage / des favoris
   * @private
   * @return {string} code html
   */
  _getTplLayout: function(){
    var opts = this.parent.options,
        tpl = '<div id="share-bar" class="input-group" style="display: none; max-height: ' + ($(window).height() - 100) + 'px; overflow: auto"><h3>' + __("share_view") + '&emsp;<span style="white-space:nowrap;">';
    if (opts.generateShareUrl) {
      tpl += '<a id="share-link-btn" class="btn btn-link" title="'+__("share_link")+'"><span class="glyphicon glyphicon-share" style="font-size: 30px; color:#555;"></span></a></span>';
    }
    if (opts.includeSocialLinks) {
      tpl += '<a class="btn btn-link" href="https://twitter.com/intent/tweet?url=' + opts.host + opts.pathinfo + '" target="_blank" title="' + __("share_twitter") + '"><span style="background-color: #1da1f2; vertical-align: middle; display: inline-block; padding: 2px; width: 32px; height: 32px; line-height: 38px"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 430.117 430.117" style="enable-background:new 0 0 430.117 430.117;" xml:space="preserve"><g><path id="Twitter__x28_alt_x29_" d="M381.384,198.639c24.157-1.993,40.543-12.975,46.849-27.876   c-8.714,5.353-35.764,11.189-50.703,5.631c-0.732-3.51-1.55-6.844-2.353-9.854c-11.383-41.798-50.357-75.472-91.194-71.404   c3.304-1.334,6.655-2.576,9.996-3.691c4.495-1.61,30.868-5.901,26.715-15.21c-3.5-8.188-35.722,6.188-41.789,8.067   c8.009-3.012,21.254-8.193,22.673-17.396c-12.27,1.683-24.315,7.484-33.622,15.919c3.36-3.617,5.909-8.025,6.45-12.769   C241.68,90.963,222.563,133.113,207.092,174c-12.148-11.773-22.915-21.044-32.574-26.192   c-27.097-14.531-59.496-29.692-110.355-48.572c-1.561,16.827,8.322,39.201,36.8,54.08c-6.17-0.826-17.453,1.017-26.477,3.178   c3.675,19.277,15.677,35.159,48.169,42.839c-14.849,0.98-22.523,4.359-29.478,11.642c6.763,13.407,23.266,29.186,52.953,25.947   c-33.006,14.226-13.458,40.571,13.399,36.642C113.713,320.887,41.479,317.409,0,277.828   c108.299,147.572,343.716,87.274,378.799-54.866c26.285,0.224,41.737-9.105,51.318-19.39   C414.973,206.142,393.023,203.486,381.384,198.639z" fill="#FFFFFF"/></g></svg></span></a>' +
        '<a class="btn btn-link" href="https://www.facebook.com/sharer.php?u=' + opts.host + opts.pathinfo + '" target="_blank" title="' + __("share_facebook") + '"><span style="background-color: #3b5998; vertical-align: middle; display: inline-block; padding: 2px; width: 32px; height: 32px; line-height: 38px"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 430.113 430.114" style="enable-background:new 0 0 430.113 430.114; " xml:space="preserve"><g><path id="Facebook" d="M158.081,83.3c0,10.839,0,59.218,0,59.218h-43.385v72.412h43.385v215.183h89.122V214.936h59.805   c0,0,5.601-34.721,8.316-72.685c-7.784,0-67.784,0-67.784,0s0-42.127,0-49.511c0-7.4,9.717-17.354,19.321-17.354   c9.586,0,29.818,0,48.557,0c0-9.859,0-43.924,0-75.385c-25.016,0-53.476,0-66.021,0C155.878-0.004,158.081,72.48,158.081,83.3z" fill="#FFFFFF"/></g></svg></span></a>' +
        '<a class="btn btn-link" href="https://www.linkedin.com/shareArticle?url=' + opts.host + opts.pathinfo + '" target="_blank" title="' + __("share_linkedin") + '"><span style="background-color: #0077b5; vertical-align: middle; display: inline-block; padding: 2px; width: 32px; height: 32px; line-height: 38px"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 430.117 430.117" style="enable-background:new 0 0 430.117 430.117;" xml:space="preserve"><g><path id="LinkedIn" d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707   c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21   v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824   C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463   c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z    M5.477,420.56h92.184v-277.32H5.477V420.56z" fill="#FFFFFF"/></g></svg></span></a>';
    }
    tpl += '</span></h3>';
    if (opts.generateShareUrl) {
      tpl += '<div style="padding: 4px 4px 4px 4px;line-height: 32px;font-size: 16px;">' +
          '<span id="share-link-info" class="btn btn-link">' + __("share_create_link") + '</span>' +
          '<span id="share-link-input" class="input-group input-group-lg" style="display:none"><input type="text" class="form-control" readonly><span class="input-group-btn"><button id="copy-link-btn" type="button" class="btn btn-default"><span class="glyphicon glyphicon-copy"></span></button></span>' +
        '</div>';
    }
    if (opts.generateBookmarkUrl) {
      var bms = [];
      if (this.bookmarks && this.bookmarks.length) {
        for(var i in this.bookmarks) {
          bms.push(this._bookmarkLiTpl(i));
        }
      }
      tpl += '<hr/><h3 class="clearfix"><button id="add-bookmark-btn" title="' + __("share_add_tooltip") + '" class="btn btn-default btn-lg pull-right"><span class="glyphicon glyphicon-plus"></span></button>'
      + __("my_bookmarks") + '</h3><ul class="list-unstyled" id="bookmark_list">' + bms.join('') + '</ul>';
    }
    tpl += '</div>';
    return tpl;
  },

  /**
   * génération du code html pour un favori
   * @private
   * @param  {int} index   index du favori dans this.bookmarks
   * @return {string}      code html
   */
  _bookmarkLiTpl: function(index) {
    return '<li class="clearfix" style="margin-bottom: 5px" data-bookmark-id="' + index + '">'
            + '<div class="btn-group pull-right">'
            + '<button type="button" class="btn btn-default edit-bookmark-btn" title="' + __("share_rename") + '"><span class="glyphicon glyphicon-pencil"></span></button>'
            + '<button type="button" class="btn btn-default copy-bookmark-btn" title="' + __("share_copy") + '"><span class="glyphicon glyphicon-copy"></span></button>'
            + '<button type="button" class="btn btn-default delete-bookmark-btn" title="' + __("share_delete") + '"><span class="glyphicon glyphicon-trash"></span></button>'
            + '</div><a href="' + this.bookmarks[index].displayUrl + '">' + this.bookmarks[index].name + '</a>'
            + '<span class="bookmark-name-input input-group" style="display:none"><input type="text" class="form-control"><span class="input-group-btn"><button type="button" class="apply-btn btn btn-default"><span class="glyphicon glyphicon-ok" title="' + __("share_rename") + '"></span></button><button type="button" class="cancel-btn btn btn-default" title="' + __("share_cancel") + '"><span class="glyphicon glyphicon-remove"></span></button></span>'
            + '</li>';
  },

  /**
   * Callback lors d'un click sur le page web complète - permet de fermer le panneau si on click en dehors
   * @private
   * @param  {Event} e
   * @return void
   */
  _onDocumentClick: function(e) {
    var $container = $("#share-bar"),
        $btn = $("#toggle-share-btn");
    // if the target of the click isn't the container nor a descendant of the container
    if (this.open && !$container.is(e.target) && $container.has(e.target).length === 0  && !$btn.is(e.target) && $btn.has(e.target).length === 0 && !$(e.target).hasClass('popover-content')) {
      this.parent.toggleShare();
    }
  },

  /**
   * Calcule un hash pour une chaine donnée
   * @param  {String} s
   * @return {number}
   */
  _hashCode: function(s) {
    return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
  },

  /**
   * Affiche ou cache le panneau des favoris (et de partage de lien)
   * @public
   * @return void
   */
  toggleShare: function(){
    this.open = ! this.open;
    $("#share-bar").toggle(this.open);
    if (this.open) {
      $(document).on('click.hideShareBar', this._onDocumentClick.bind(this));
      if (this.viewStateHash && this.viewStateHash != this._hashCode(JSON.stringify(this.parent.getViewState()))) {
        // si l'état a changé depuis la dernière génération d'un lien, on respasse en mode "créer un lien de partage"
        $("#share-link-input").hide();
        $("#share-link-info").show();
      }
    } else {
      $(document).off('.hideShareBar');
    }
  }

};

$.fn.selectAndPutCursorAtEnd = function() {
  return this.each(function() {
    $(this).focus();
    if (this.setSelectionRange) { // (Doesn't work in IE)
      this.setSelectionRange(0, 1000);
    } else { // (Doesn't work in Google Chrome)
      $(this).val($(this).val());
    }
  });
};

module.exports = shareEngine;