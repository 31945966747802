var $ = window.$ || window.jQuery,
    stringFormatter = require('./string-formatter.js');

var simpleChart = {
  _loaded: false,
  resourcesPath: '',
  assetsVersion: null,

  /**
   * Chargement dynamique de la bibliothèque Chartist
   * @return {jQuery.Deferred} une promesse qui sera satisfaite lorsque la librairie sera chargée
   */
  init: function() {
    if (! this._loaded) {
      if (window.Chartist) {
        this._loaded = $.Deferred().resolve();
      } else {
        var js = this.resourcesPath + "js/chartist.min.js?v=" + this.assetsVersion,
            css = this.resourcesPath + "css/chartist.min.css?v=" + this.assetsVersion;
        this._loaded = $.when(
          $.ajax({ type: "GET", url: js, dataType: "script", cache: !! this.assetsVersion }),
          $.ajax({ type: "GET", url: css, cache: !! this.assetsVersion, success: function() { $('<link>', { rel:'stylesheet', type:'text/css', 'href': css }).prependTo('head'); } })
        );
      }
    }
    return this._loaded;
  },

  /**
   * Création d'un graphique
   * @param  {DomNode} imgNode le noeud DOM de l'image à remplacer par un graphique
   * @param  {Object} data     les données provenant du dataspace. {columns, values} `columns` contient les titres des colonnes et `values` les valeurs correspondantes dans le même ordre
   * @param  {Bool} tooltip    [optionnel] si true, intègre des tooltip sur le graphique. true par défaut
   * @return {jQuery.Deferred} une promesse qui sera satisfaite lorsque le graphique sera créé
   */
  create: function(imgNode, data, tooltip) {
    var self = this,
        deferred = $.Deferred();
    this.init().done(function() {
      var params, chart = null;
      try {
        params = JSON.parse(imgNode.getAttribute('data-chart'));
      } catch (e) {
        console.log("Erreur : can't parse chart parameters", imgNode.getAttribute('data-chart')); // eslint-disable-line no-console
        deferred.resolve(null);
        return;
      }
      if (typeof self._createFunctions[params.type] == 'function') {
        params.data.forEach(function(datum) {
          var idx = data.columns.indexOf(datum.column);
          datum.value = idx < 0 ? 0 : L.articque.Util.numberUnformat(data.values[idx]);
        });
        params.hasTooltip = !! tooltip;
        chart = self._createFunctions[params.type](self._createNode(imgNode), params);
      }
      deferred.resolve(chart);
    });
    return deferred.promise();
  },

  /**
   * Détache tous les évènements d'un graphique - pour nettoyage avant suppression
   * @param  {Chartist.Base} chart le graphique qui va être supprimé
   * @return {void}
   */
  detach: function(chart) {
    if (chart && typeof chart.detach == "function") {
      chart.detach();
    }
  },

  /**
   * remplace le noeud DOM image par un nouveau noeud DIV dans lequel le graphique va être créé
   * @param  {DomNode} imgNode le noeud DOM à remplacer
   * @return {DomNode}         le nouveau noeud DOM vide
   */
  _createNode: function(imgNode) {
    var div = document.createElement('div');
    $(imgNode).replaceWith(div);
    return div;
  },

  // fonction de création de graphique en fonction du type de graphique
  _createFunctions: {
    /**
     * Création d'un graphique de type secteurs
     * @param  {DomNode} div    le conteneur pour le graphique
     * @param  {object} params  les paramètres du graphique
     * @return {Chartist.Pie} le graphique
     */
    pie: function(div, params) {
      var series = [],
          colors = [],
          legends = [];
      params.data.forEach(function(datum) {
        if (isFinite(datum.value)) {
          series.unshift({ meta: datum.legend, value: datum.value });
          colors.unshift(datum.color);
          legends.push('<span style="display: inline-block; margin-right: 8px; white-space: nowrap;"><svg style="vertical-align: text-bottom;" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><circle cx="8" cy="8" r="7.5" style="fill:' + datum.color + ';"/></svg>&nbsp;' + stringFormatter.sanitizeHTML(datum.legend) + '</span> ');
        }
      });
      var sum = series.reduce(function(a, b) { return isFinite(b.value) ? a + b.value : a; }, 0),
          isValid = series.reduce(function(a, b) { return isFinite(b.value) && a; }, true),
          options = {
            chartPadding: 8,
            startAngle: 90,
            showLabel: true,
            labelPosition: 'inside',
            width: params.width,
            height: params.height,
            labelInterpolationFnc: function(value) {
              if(params.labels == "percent" || params.percent === true){
                return stringFormatter.round(value / sum * 100, 2, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + '%';
              } else if (params.labels == "raw" || params.percent === false){
                return stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '');
              } 
              return null;
            },
            plugins: params.hasTooltip ? [Chartist.plugins.tooltip({
              appendToBody: true,
              tooltipFnc: function(meta, value) {
                return (meta ? meta + '<br/>' : '') +
                  '<span class="chartist-tooltip-value">' +
                  stringFormatter.round(value, 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '') + 
                  '</span>';
              }
            })] : []
          };

      div.innerHTML = (params.title ? '<h3 style="text-align:left;">' + stringFormatter.sanitizeHTML(params.title) + '</h3>' : '') + '<p style="text-align: left; font-size: 12px; line-height: 20px; white-space:normal; width: ' + parseFloat(params.width) + 'px;">' + legends.join('') + '</p>';
      var chart = Chartist.Pie(div, { series: isValid ? series : [] }, options);
      chart.on('draw', function(context) {
        if (context.type === 'slice') {
          context.element.attr({ style: 'fill: ' + colors[context.index % colors.length] + '; stroke: #fff; stroke-width: 1px;' });
        }
      });

      return chart;
    },

    /**
     * Création d'un graphique de type secteurs
     * @param  {DomNode} div    le conteneur pour le graphique
     * @param  {object} params  les paramètres du graphique
     * @return {Chartist.Pie} le graphique
     */
    bar: function(div, params) {
      var series = [],
          colors = [],
          legends = [];
      params.data.forEach(function(datum) {
        if (isFinite(datum.value)) {
          series.push({ meta: datum.legend, value: datum.value });
          colors.push(datum.color);
          legends.push('<span style="display: inline-block; margin-right: 8px; white-space: nowrap;"><svg style="vertical-align: text-bottom;" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><rect x="0" y="0" width="16" height="16" style="fill:' + datum.color + ';" /></svg>&nbsp;' + stringFormatter.sanitizeHTML(datum.legend) + '</span> ');
        }
      });

      // rendu temporaire dans un canvas pour calculer la taille des labels de l'axe Y
      var ctx = document.createElement('canvas').getContext("2d");
      var style = window.getComputedStyle(div,null);
      ctx.font = style.getPropertyValue("font-size") + " " + style.getPropertyValue("font-family")
      var min = series.reduce(function(a, b) { return Math.min(b.value, a); }, 0); // valeur minumum représentée 
      var max = series.reduce(function(a, b) { return Math.max(b.value, a); }, 0); // valeur maximum représentée
      var high, low;
      if (params.customAxis && typeof params.customAxis.min == "number" && typeof params.customAxis.max == "number") {
        min = low = params.customAxis.min;
        max = high = params.customAxis.max;
      }
      var minText = stringFormatter.round(min,  Math.abs(min) > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '');
      var maxText = stringFormatter.round(max,  Math.abs(max) > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '');
      labelWidth = Math.max(ctx.measureText(minText).width, ctx.measureText(maxText).width) + 5; // marge de 5px

      var isValid = series.reduce(function(a, b) { return isFinite(b.value) && a; }, true),
          options = {
            axisX: { offset: 0, showLabel: false, showGrid: true },
            axisY: { 
              offset: labelWidth, 
              showLabel: true, 
              showGrid: true,
              labelInterpolationFnc: function(value) {
                return stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '')
              }
            },
            width: params.width,
            height: params.height,
            distributeSeries: true,
            high: high,
            low: low,
            labelInterpolationFnc: function(value) {
              if(params.labels == "percent"){
                return stringFormatter.round(value / sum * 100, 2, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + '%';
              } else if (params.labels == "raw"){
                return stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '');
              }
              return null;
            },
            // labelInterpolationFnc: function(value) {
            //   return stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
            // },
            plugins: params.hasTooltip ? [Chartist.plugins.tooltip({
              appendToBody: true,
              tooltipFnc: function(meta, value) {
                return (meta ? meta + '<br/>' : '') +
                  '<span class="chartist-tooltip-value">' +
                  stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '') +
                  '</span>';
              }
            })] : []
          };

      div.innerHTML = (params.title ? '<h3 style="text-align:left;">' + stringFormatter.sanitizeHTML(params.title) + '</h3>' : '') + '<p style="text-align: left; font-size: 12px; line-height: 20px; white-space:normal; width: ' + parseFloat(params.width) + 'px;">' + legends.join('') + '</p>';
      var chart = Chartist.Bar(div, { series: isValid ? series : [] }, options);
      chart.on('draw', function(context) {
        if (context.type === 'bar') {
          context.element.attr({ style: 'stroke: ' + colors[context.seriesIndex % colors.length] + ';' });
        }
      });

      return chart;
    },

    /**
     * Création d'un graphique de type secteurs
     * @param  {DomNode} div    le conteneur pour le graphique
     * @param  {object} params  les paramètres du graphique
     * @return {Chartist.Pie} le graphique
     */
    line: function(div, params) {
      var series = [],
          colors = [],
          axisLabels = [];
      params.data.forEach(function(datum) {
        if (isFinite(datum.value)) {
          series.push({ meta: datum.legend, value: datum.value });
          colors.push(datum.color);
          axisLabels.push(datum.legend);
        }
      });

      // rendu temporaire dans un canvas pour calculer la taille des labels de l'axe Y
      var ctx = document.createElement('canvas').getContext("2d");
      var style = window.getComputedStyle(div,null);
      ctx.font = style.getPropertyValue("font-size") + " " + style.getPropertyValue("font-family")
      var min = series.reduce(function(a, b) { return Math.min(b.value, a); }, 0); // valeur minumum représentée 
      var max = series.reduce(function(a, b) { return Math.max(b.value, a); }, 0); // valeur maximum représentée
      var high, low;
      if (params.customAxis && typeof params.customAxis.min == "number" && typeof params.customAxis.max == "number") {
        min = low = params.customAxis.min;
        max = high = params.customAxis.max;
      }
      var minText = stringFormatter.round(min,  Math.abs(min) > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '');
      var maxText = stringFormatter.round(max,  Math.abs(max) > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '');
      labelWidth = Math.max(ctx.measureText(minText).width, ctx.measureText(maxText).width) + 5; // marge de 5px

      var isValid = series.reduce(function(a, b) { return isFinite(b.value) && a; }, true),
          options = {
            axisX: { offset: 15, showLabel: true, stretch: true, showGrid: true },
            axisY: { 
              offset: labelWidth, 
              showLabel: true, 
              showGrid: true,
              labelInterpolationFnc: function(value) {
                return stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '')
              }
            },
            fullWidth: true,
            showPoint: true,
            chartPadding: { top: 15, right: 40, bottom: 18, left: 10 },
            width: params.width,
            height: params.height,
            high: high,
            low: low,
            // labelInterpolationFnc: function(value) {
            //   return stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
            // },
            plugins: params.hasTooltip ? [Chartist.plugins.tooltip({
              appendToBody: true,
              tooltipFnc: function(meta, value) {
                return (meta ? meta + '<br/>' : '') +
                  '<span class="chartist-tooltip-value">' +
                  stringFormatter.round(value, value > 100 ? 2 : 5, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + (params.suffix ? params.suffix.replace(/ /g,'\xa0') : '') +
                  '</span>';
              }
            })] : []
          };

      div.innerHTML = (params.title ? '<h3 style="text-align:left;">' + stringFormatter.sanitizeHTML(params.title) + '</h3>' : '');
      var chart = Chartist.Line(div, { series: isValid ? [series] : [], labels: axisLabels }, options);
      chart.on('draw', function(context) {
        if (context.type === 'line') {
          context.element.attr({ style: 'stroke: ' + colors[0] + ';' });
        }
        if (context.type === 'point') {
          context.element.attr({ style: 'stroke: ' + colors[0] + '; fill: ' + colors[0] + ';' });
        }
      });

      return chart;
    }
  }
};

module.exports = simpleChart;