(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.sizeInPx = {
    
    loadExtension: function(parent, dependancies) {
      this.parent = parent;
      parent.on('preaddrepresentation', this.onAddRepresentation, this);
      parent.wvc._modules.forEach(this.onAddRepresentation, this);
      parent.wvc.moduleGroup.refresh();
    },

    onAddRepresentation: function(data) {
      if (data.module.customParams && data.module.customParams.sizeInPx) {
        var sip = data.module.customParams.sizeInPx;
        if (data.module instanceof L.articque.RMSymbols) {
          if (data.module.options.sizeValue.valueName) {
            data.module.options.sizeValue.sizeRef = sip;
          } else {
            data.module.options.sizeValue.fixedSize = sip;
          }
        } else if (data.module instanceof L.articque.RMLabels) {
          data.module.options.font = data.module.options.font.replace(/([0-9\.]+px)/, sip + 'px');
        } else if (data.module instanceof L.articque.RMFill) {
          if (data.module.options.widthValue) { // module contours
            if (data.module.options.widthValue.valueName) {
              data.module.options.widthValue.sizeRef = sip;
            } else {
              data.module.options.widthValue.fixedSize = sip;
            }
          } else { // module remplissage
            data.module.options.groups.forEach(function(group) { group.style.strokeWidth = sip; });
          }
        } else if (data.module instanceof L.articque.RMFlows) {
          if (data.module.options.sizeValue.fixedSize) {
            data.module.options.sizeValue.fixedSize = sip;
          } else {
            data.module.options.sizeValue.sizeRef = sip;
          }
        } else if (data.module instanceof L.articque.RMCharts) {
          if (data.module.options.renderer instanceof L.articque.CRPie) {
            if (data.module.options.renderer.options.chartSize.fixedSizeExt) {
              data.module.options.renderer.options.chartSize.fixedSizeExt = sip;
            } else {
              data.module.options.renderer.options.chartSize.sizeRef = sip;
            }
          } else if (data.module.options.renderer instanceof L.articque.CRPortions) {
            data.module.options.renderer.options.chartSize.sizeRef = sip;
          } else if (data.module.options.renderer instanceof L.articque.CRBars) {
            data.module.options.renderer.options.barSize.heightRef = sip;
          }
          if (data.module._layer) { // si module déjà ajouté à un moduleGroupLayer alors rafraichissement nécessaire
            data.module._layer._needRefresh(1);
          }
        } else{
          console.warn("Le paramètre complémentaire sizeInPx est limité aux data.modules symboles, valeurs, secteurs, portions et histogrammes");
        }
      }
    }
  };
})();