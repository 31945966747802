(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.areaLimits = {

    parent: null,

    loadExtension: function(parent) {
      this.parent = parent;
      this._preUpdated = false;
      this.parent.on('updateview', this.onUpdateView, this);
      this.parent.on('preupdateview', this.onPreUpdateView, this);
    },

    onPreUpdateView: function(data) {
      // On veut assigner le niveau de zoom max avant tout autre chose.
      // En particulier pour que les clusters de symboles soient calculés en connaissant les niveaux de zoom réellement utilisés
      this._preUpdated = true;
      var wvc = this.parent.wvc;
      if (data.displayingInfos.CustomParams) {
        try {
          var cp = JSON.parse(data.displayingInfos.CustomParams);
          if (cp && cp.areaLimits) {
            var al = cp.areaLimits;
            if (al.type == "zoom" && typeof al.min !== 'undefined' && typeof al.max !== 'undefined') {
              var min = al.min || 1,
                  max = al.max || wvc.MAX_ZOOM;
              // on s'assure que la carte est à un niveau de zoom compatible
              // car si ce n'est pas le cas, leaflet modifie le zoom avec une animation qui se termine après le chargement de la carte
              // le calcul du zoom target est alors ignoré/perdu/écrasé par l'animation
              if (wvc.map.getZoom() < min)
                wvc.map.setZoom(min, {animate: false});
              if (wvc.map.getZoom() > max)
                wvc.map.setZoom(max, {animate: false});
              wvc.map.setMinZoom(min);
              wvc.map.setMaxZoom(max);
              wvc.map.setMaxBounds(null);
            }
            return;
          }
        } catch(e) { /* do nothing */ }
      }
      wvc.map.setMinZoom(1);
      wvc.map.setMaxZoom(this.parent.wvc.MAX_ZOOM);
      wvc.map.setMaxBounds(null);
    },

    onUpdateView: function(data) {
      if (! this._preUpdated) {
        this.onPreUpdateView(data);
      }
      var wvc = this.parent.wvc;
      if (wvc.customParams && wvc.customParams.areaLimits) {
        var al = wvc.customParams.areaLimits;
        if (al.type == "bounds" && typeof al.west !== 'undefined' && al.east !== 'undefined' && al.north !== 'undefined' && al.south !== 'undefined') {
          var llb, bbox = [[al.north||180, al.east||90],[al.south||-180, al.west||-90]];
          if (al.auto) {
            llb = this.parent.wvc.options.bounds;
            bbox = [[llb.getNorth(), llb.getEast()],[llb.getSouth(), llb.getWest()]];
          } else {
            llb = L.latLngBounds(L.latLng(al.north||180, al.east||90), L.latLng(al.south||-180, al.west||-90));
          }
          wvc.map.setMinZoom(wvc.map.getBoundsZoom(llb));
          wvc.map.setMaxBounds(bbox);
        }
      }
    }
  };
})();