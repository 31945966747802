var $ = window.$ || window.jQuery,
    stringFormatter = require('./string-formatter.js'),
filtersUI = {
  
  searchTimeout: null,
  /**
   * Initialise les ressources et les événements des filtres
   * @param  {object} parent         Objet atlas-data-tables
   * @param  {string} $domContainer  Nom du conteneur du tableau (pour les events)
   * @return void
   */
  init: function(parent, $domContainer){
    this._parent = parent;
    var self = this;
    
     //On ouvre la fenêtre des filtres de la colonne
    $($domContainer).on("click", ".atlas-filter", function(e){
      e.preventDefault();
      e.stopPropagation();
          var id = $(this).parent().data('datatable'),
          col = $(this).parent().data('column'),
          $dom = $(this),
          cache = self._parent._parent._cache[self._parent._parent._currentFilename];
      
      //en fonction du type on affiche la fenêtre de filtre quali ou quanti 
      var type = self._parent._getColumnDataType(cache.datatables[id].ColumnTypes[col]);
      var decimals = cache.datatables[id].decimals ? cache.datatables[id].decimals[col] : "auto";
      self.destroyFilterWindow();
      if(type == 'quanti')
        self.generateFilterWindowQuanti(cache.datatables[id], col, decimals, $dom);
       else if(type == 'quali')
        self.generateFilterWindowQuali(cache.datatables[id], col, $dom);
       else 
        self.generateFilterWindowDate(cache.datatables[id], col, $dom);
      
      self.addBackDrop();
    });
    
    //On supprime le filtre actif
    $($domContainer).on("click", ".btn-filter-delete", function(e){
      e.preventDefault();
      e.stopPropagation();
          var col = $(this).parent().data('column');

      self._parent._deleteFilters(self._parent.currentTab, col);
      self._parent._updateTableAndMap(self._parent.currentTab);
    });
  },
  
  /**
   * Génère le dom et les événements de la fenêtre de filtre Qualitatif
   * @param  {object} data  Tableau de données associé
   * @param  {int}    col   Index de la colonne à filtrer
   * @param  {string} $dom  Dom parent pour le positionnement
   * @return void
   */
  generateFilterWindowQuali: function(data, col, $dom){
    var self = this;
    
    var domWindow = 
    '<div id="atlas-filter">'+
      '<div id="atlas-filter-quali-top" class="form-inline">'+
        '<div class="btn-group" role="group">'+
          '<button id="btn-filter-all" type="button" class="btn btn-default pull-left">'+__('data_filter_all')+'</button>'+
          '<button id="btn-filter-none" type="button" class="btn btn-default pull-left">'+__('data_filter_none')+'</button>'+
        '</div>'+
        '<div class="form-group has-feedback pull-right" style="margin-left:10px;">'+
          '<input id="input-filter-search" type="text" class="form-control" >'+
          '<span class="searchbar-icon glyphicon glyphicon-search form-control-feedback" style="color:#aaa"></span>'+
        '</div>'+
      '</div>'+
      '<div id="atlas-filter-quali-input" class="layer-list-simple expanded-content"></div>'+
      '<div id="atlas-filter-quali-botttom">'+
        '<button id="btn-filter-cancel" type="button" class="btn btn-link pull-left">'+__('data_filter_cancel')+'</button>'+
        '<button id="btn-filter-ok" type="button" class="btn btn-primary pull-right">'+__('data_filter_ok')+'</button>'+
      '</div>'+
    '</div>';
    
    $('body').append(domWindow);
    var position = $dom.offset();
    //On place correctement la fenêtre des filtres
    this.setFilterWindowPosition(position);
    
    
    //On parcours les datas pour afficher toutes les valeurs de la liste
    var datas = this._parent.allRows[data.Id];
    var arrayValueToCheck = [];
    var filtersId = this._parent.filters[data.Id];
    
    for(var idx1 = 1; idx1 < datas.length; idx1++){ //idx 1 car on ignore le header
      //On check si la ligne est filtré ou non
      if(filtersId && filtersId.indexOf(datas[idx1][0]) > -1 && arrayValueToCheck.indexOf(datas[idx1][col]) <0){ //on compare la première colonne qui est l'id
        arrayValueToCheck.push(datas[idx1][col]);
      }
    }
    
    var arrayValue = [];
    for(var idx = 1; idx < datas.length; idx++){
      var value = datas[idx][col];
      if(arrayValue.indexOf(value) < 0){
        var checked = (arrayValueToCheck.indexOf(value) >-1 || !filtersId) ?  'checked': '';
        var html = '<label class="cd-checkbox filter-checkbox"><input id="'+idx+'" type="checkbox" class="filter-checkbox-input" value="'+value+'" '+ checked+'><i class="icon-checkbox"></i>'+value+'</label>';
        $('#atlas-filter-quali-input').append(html);
        arrayValue.push(value);
      }
    }
    
    //On gère les events pour chaque bouton
    $('#btn-filter-all').click(function(){
      //On sélectionne toutes les cases à cocher
      $("#atlas-filter-quali-input label").not(".hidden").find(".filter-checkbox-input").prop('checked', true);
    });
    
    $('#btn-filter-none').click(function(){
      //On déselectionne toutes les cases à cocher
      $("#atlas-filter-quali-input label").not(".hidden").find(".filter-checkbox-input").prop('checked', false);
    });

    $('#input-filter-search').on("change keyup", function(){
      var target = $(this);
      if(self.searchTimeout != null){
        clearTimeout(self.searchTimeout);
      }

      self.searchTimeout = setTimeout(
        function(){
          var pattern = target.val().toLowerCase();
          if(pattern == ""){
            $("#atlas-filter-quali-input label").removeClass("hidden");
          } 
          else{
            $("#atlas-filter-quali-input .filter-checkbox-input").each(function(){
              var $this = $(this);
              $this.parent().toggleClass("hidden", $this.val().toLowerCase().indexOf(pattern) == -1);
            });
          }
        }
        , 1000);
    });
    
    $('#btn-filter-cancel').click(function(){
      //On ferme la fenêtre et on détruit tout ce bouzin
      self.destroyFilterWindow();
    });
    
    $('#btn-filter-ok').click(function(){
      //On récupère la liste des éléments
      var elementChecked = [];
      $('.filter-checkbox-input').each(function(){
        if($(this).is(":checked") === true)
          elementChecked.push($(this).val());
      });
      //On set le filtre 
      self._parent._addFilters(self._parent.currentTab, col, elementChecked, "datatable");
      self._parent._updateTableAndMap(self._parent.currentTab);
      $('.panel-filter').show();
      
      //On ferme la fenêtre et on détruit tout ce bouzin
      self.destroyFilterWindow();
    });
  },  
  
  /**
   * Génère le dom et les événements de la fenêtre de filtre Quantitatif
   * @param  {object} data  Tableau de données associé
   * @param  {int}    col   Index de la colonne à filtrer
   * @param  {string} $dom  Dom parent pour le positionnement
   * @return void
   */
  generateFilterWindowQuanti: function(data, col, decimals, $dom){
    var self = this;
  
    //On parcours les datas pour avoir le min et max du slider
    var datas = this._parent.allRows[data.Id];
    var values = datas.filter(function(f){ return !isNaN(L.articque.Util.numberUnformat(f[col]))}).map(function(f2){ return L.articque.Util.numberUnformat(f2[col])});
    var min = Math.min.apply(Math, values);
    var max = Math.max.apply(Math, values);
    if (typeof decimals != "number")
      decimals = Math.abs(max - min) > 1 ? 2 : 6;
    var currentMin = min;
    var currentMax = max;
    
    var domWindow = 
    '<div id="atlas-filter">'+
      '<div id="atlas-filter-quanti-input" style="padding-top: 10px;padding-left:15px;padding-right:15px;">'+
        '<input id="slider-quanti" ref="input" class="slider-quanti form-control" type="text" data-min="'+min+'" data-max="'+max+'" />'+
        '<input id="slider-min" class="form-control filter-input-slider filter-input-left" type="text" />&nbsp;'+
        '<input id="slider-max" class="form-control filter-input-slider filter-input-right" type="text" />'+
      '</div>'+
      '<div id="atlas-filter-botttom">'+
        '<button id="btn-filter-cancel" type="button" class="btn btn-link pull-left">'+__('data_filter_cancel')+'</button>'+
        '<button id="btn-filter-ok" type="button" class="btn btn-primary pull-right">'+__('data_filter_ok')+'</button>'+
      '</div>'+
    '</div>';
    
    $('body').append(domWindow);
    var position = $dom.offset();
    //On place correctement la fenêtre des filtres
    this.setFilterWindowPosition(position);
    
    if(this._parent.activeFilters[this._parent.currentTab] && this._parent.activeFilters[this._parent.currentTab][col]){
      currentMin = L.articque.Util.numberUnformat(Math.min.apply(Math, this._parent.activeFilters[this._parent.currentTab][col]));
      currentMax = L.articque.Util.numberUnformat(Math.max.apply(Math, this._parent.activeFilters[this._parent.currentTab][col]));
    }

    var valueMinSlider = (min == max) ? 0 : (currentMin - min) / (max - min) * 210;
    var valueMaxSlider = (min == max) ? 210 : (currentMax - min) / (max - min) * 210;
    
    this.sliderFilter = $('#slider-quanti')
      .slider({ 
        min: 0,
        max: 210,
        value: [valueMinSlider, valueMaxSlider],
        step: 1,
        tooltip: 'hide',
        range: true,
        enabled: (min != max)
      })
      .on('slide', this.changeSlider.bind(this, decimals))
      .on('slideStop', this.sliderStop.bind(this, decimals));

    $('#slider-min').val(stringFormatter.round(currentMin, decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP));
    $('#slider-max').val(stringFormatter.round(currentMax, decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP));
    
    //On gère les events pour chaque bouton
    $('#btn-filter-cancel').click(function(){
      //On ferme la fenêtre et on détruit tout ce bouzin
      self.destroyFilterWindow();
    });
    
    $('#btn-filter-ok').click(function(){
      // //On récupère la liste des éléments
      var min = L.articque.Util.numberUnformat($('#slider-min').val());
      var max = L.articque.Util.numberUnformat($('#slider-max').val());
      var valFinal = [min, max];
      
      //On set le filtre 
      self._parent._addFilters(self._parent.currentTab, col, valFinal, "datatable");
      self._parent._updateTableAndMap(self._parent.currentTab);
      $('.panel-filter').show();
      
      //On ferme la fenêtre et on détruit tout ce bouzin
      self.destroyFilterWindow();
    });
    
    $('#slider-min').focusout(function(){
      var min = L.articque.Util.numberUnformat($('#slider-quanti').data('min'));
      var max = L.articque.Util.numberUnformat($('#slider-quanti').data('max'));
      var valueSlider = self.sliderFilter.slider('getValue');
      currentMin = Math.max(min, parseFloat($(this).val()));
      this.value = currentMin;

      valueSlider[0] = (min == max) ? 0 : (currentMin - min) / (max - min) * 210;
      self.sliderFilter.slider('setValue', valueSlider);
    });    
    
    $('#slider-max').focusout(function(){
      var min = L.articque.Util.numberUnformat($('#slider-quanti').data('min'));
      var max = L.articque.Util.numberUnformat($('#slider-quanti').data('max'));
      var valueSlider = self.sliderFilter.slider('getValue');
      currentMax = Math.min(max, parseFloat($(this).val()));
      this.value = currentMax;
      
      valueSlider[1] = (min == max) ? 210 : (currentMax - min) / (max - min) * 210;
      self.sliderFilter.slider('setValue', valueSlider);
    });
  },
  
  /**
   * Event lorsque le slider a bougé qui définit les champs texte associés
   * @return void
   */
  changeSlider: function(decimals, evt){
    //On set les inputs du filtre
    var min = L.articque.Util.numberUnformat($(evt.target).data('min'));
    var max = L.articque.Util.numberUnformat($(evt.target).data('max'));
    if (evt.dragged === 0) {
      $('#slider-min').val(stringFormatter.round(min + evt.value[0] * (max - min) / 210, decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP));
    } else if (evt.dragged === 1) {
      $('#slider-max').val(stringFormatter.round(min + evt.value[1] * (max - min) / 210, decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP));
    }
  },  
  sliderStop: function(decimals, evt){
    //On set les inputs du filtre
    var min = L.articque.Util.numberUnformat($(evt.target).data('min'));
    var max = L.articque.Util.numberUnformat($(evt.target).data('max'));
    if (evt.dragged === 0) {
      $('#slider-min').val(stringFormatter.round(min + evt.value[0] * (max - min) / 210, decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP));
    } else if (evt.dragged === 1) {
      $('#slider-max').val(stringFormatter.round(min + evt.value[1] * (max - min) / 210, decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP));
    }
  },  
  
  /**
   * Génère le dom et les événements de la fenêtre de filtre Date
   * @param  {object} data  Tableau de données associé
   * @param  {int}    col   Index de la colonne à filtrer
   * @param  {string} $dom  Dom parent pour le positionnement
   * @return void
   */
  generateFilterWindowDate: function(data, col, $dom){
    var self = this;
  
    //On parcours les datas pour avoir le min et max des dates
    var datas = this._parent.allRows[data.Id];
    var values = datas.filter(function(f, index){return index !== 0 && stringFormatter.dateIsoToStr(f[col]) != 'Invalid Date' && f[col] != '0001-01-01'}).map(function(f2){ return f2[col]}).sort(sortFunction);
    var sortFunction = function(a, b) {
        return self._parent._compareSort(a[col], b[col], 'asc', 'Date');
     }
    var min = values[0];
    var max = values[values.length-1];
    var currentMin = min;
    var currentMax = max;
    var domWindow = 
    '<div id="atlas-filter">'+
      '<div class="input-daterange input-group" id="datepicker" style="width:250px; padding:15px;">'+
          '<input id="date-min" type="text" class="form-control" name="start" />'+
          '<span class="input-group-addon">'+__('data_filter_to')+'</span>'+
          '<input id="date-max" type="text" class="form-control" name="end" />'+
      '</div>'+
      '<div id="atlas-filter-botttom">'+
        '<button id="btn-filter-cancel" type="button" class="btn btn-link pull-left">'+__('data_filter_cancel')+'</button>'+
        '<button id="btn-filter-ok" type="button" class="btn btn-primary pull-right">'+__('data_filter_ok')+'</button>'+
      '</div>'+
    '</div>';
    
    $('body').append(domWindow);
    var position = $dom.offset();
    //On place correctement la fenêtre des filtres
    this.setFilterWindowPosition(position);
    
    if(this._parent.activeFilters[this._parent.currentTab] && this._parent.activeFilters[this._parent.currentTab][col]){
      var listIds = this._parent.activeFilters[this._parent.currentTab][col];
      currentMin = listIds[0];
      currentMax = listIds[listIds.length-1];
    }

    var $dateMin = $('#date-min'),
        $dateMax = $('#date-max');
        
    $dateMin.datepicker({
      language:__('data_language_datepicker'),
      format: 'yyyy-mm-dd'
    });    
    
    $dateMax.datepicker({
      language:__('data_language_datepicker'),
      format: 'yyyy-mm-dd'
    });

    //On définit les valeurs de base du datepicker
    $dateMin.datepicker('update',currentMin);
    $dateMax.datepicker('update',currentMax);
    
    //On fait nous même le formatage des nombres à afficher
    $dateMin.datepicker().on('changeDate', function(){
      $dateMin.val(stringFormatter.dateIsoToStr($dateMin.val()));
    }).on('hide', function(){
      $dateMin.val(stringFormatter.dateIsoToStr($dateMin.val()));
    });
    
    $dateMax.datepicker().on('changeDate', function(){
      $dateMax.val(stringFormatter.dateIsoToStr($dateMax.val()));
    }).on('hide', function(){
      $dateMax.val(stringFormatter.dateIsoToStr($dateMax.val()));
    });

    //On met les bonnes valeurs au chargement
    $dateMin.val(stringFormatter.dateIsoToStr(currentMin));
    $dateMax.val(stringFormatter.dateIsoToStr(currentMax));

    //On gère les events pour chaque bouton
    $('#btn-filter-cancel').click(function(){
      //On ferme la fenêtre et on détruit tout ce bouzin
      self.destroyFilterWindow();
    });
    
    $('#btn-filter-ok').click(function(){
      // //On récupère la liste des éléments
      var min = new Date($dateMin.datepicker('getUTCDate')).toISOString().substr(0, 10);
      var max = new Date($dateMax.datepicker('getUTCDate')).toISOString().substr(0, 10);
      var valFinal = values.filter(function(f){ return f >= min && f <= max});
      //On set le filtre 
      self._parent._addFilters(self._parent.currentTab, col, valFinal, "datatable");
      self._parent._updateTableAndMap(self._parent.currentTab);
      $('.panel-filter').show();
      
      //On ferme la fenêtre et on détruit tout ce bouzin
      self.destroyFilterWindow();
    });
  },

  /**
   * Méthode pour positionner correctement la fenêtre des filtres en fonction de la position du bouton filtre sur l'écran
   * @param  {object} position  Position du bouton filtre parent
   * @return void
   */
  setFilterWindowPosition: function(position){
    //On détermine la hauteur
    var heightScreen = $(window).height();
    var widthScreen = $(window).width();
    
    var positionObj = {};
    if(position.top < (heightScreen/2))
      positionObj['top'] = position.top;
    else 
      positionObj['bottom'] = heightScreen - position.top - 25; //25 dimension du bouton filtre
    
    if(position.left < (widthScreen/2))
      positionObj['left'] = position.left + 25;
    else
      positionObj['right'] = widthScreen - position.left;
    

    positionObj['position'] = 'absolute';
    $('#atlas-filter').css(positionObj);
  },
  
  /**
   * Ajoute un fond noir derrière la fenêtre de filtre
   * @return void
   */
  addBackDrop: function(){
    var self = this;
    var dom = '<div id="backdrop"></div>';
    $('body').fadeIn("slow", function(){
        $('body').append(dom);
    });

    $('#backdrop').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      self.destroyFilterWindow();

    });
  },
  
  /**
   * Supprime le fond noir derrière la fenêtre de filtre
   * @return void
   */
  removeBackDrop: function(){
    //On supprime l'élément
    $('#backdrop').off('click');
    $('#backdrop').remove();
  },
  
  /**
   * Détruit la fenêtre de filtre en cours
   * @return void
   */
  destroyFilterWindow: function(){
    $('#btn-filter-all').off('click');
    $('#btn-filter-none').off('click');
    $('#btn-filter-cancel').off('click');
    $('#btn-filter-ok').off('click');
    $('#atlas-filter').remove();
    this.removeBackDrop();
  }
};

module.exports = filtersUI;
