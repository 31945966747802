(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.infoPopup = {

    parent: null,    
    tplInfoPopup:
      '<div id="modalInfoPopup" class="modal fade" role="dialog" style="z-index:1045">'+
        '<div class="modal-dialog">'+
          '<div class="modal-content">'+
            '<div class="modal-header">'+
              '<button type="button" class="close" data-dismiss="modal">&times;</button>'+
              '<h4 id="infoPopupTitle" class="modal-title"> </h4>'+
            '</div>'+
            '<div id="infoPopupText" class="modal-body">'+
            '</div>'+
            '<div class="modal-footer">'+
              '<label class="checkbox-inline" style="float:left;"><input id="stopShowModal" type="checkbox" value="noMoreInfo">'+__('ext_info_disable_1h')+'</label>'+
              '<button type="button" class="btn btn-default" style="float:right;" data-dismiss="modal">'+__('ext_info_close')+'</button>'+
            '</div>'+
          '</div>'+

        '</div>'+
      '</div>',
          
    loadExtension: function(parent) {
      var self = this;
      this.parent = parent;
      this.parent.on('updateview', this.onUpdateView, this);
      
      //On ajoute au body la popup
      if(document.getElementById("modalInfoPopup") == null){
        $('body').append(this.tplInfoPopup);
        $("#modalInfoPopup").on("hidden.bs.modal", function () {
          self.onCloseModal();
        });      
      }
    },
    
    onCloseModal: function(){
      //on check si on doit créer le cookie pour éviter de ré afficher la modal 
      var checked = $('#stopShowModal').is(":checked");
      
      if(checked){
        var id = this.parent.wvc.webDisplayingModuleId;

        //On crée un cookie pour éviter d'afficher la modal si l'utilisateur ne veut plus la voir
        var name = "infoPopup"+id;
        var d = new Date();
        d = new Date(d.getTime() + 1000 * 3600); //1 heure
        // d = new Date(d.getTime() + 1000 * 60); //1 minute
        document.cookie = name + '=ok'+'; expires=' + d.toGMTString() + ';';
      }      
    },   
    
    checkCookieComment: function(id){      
      return (document.cookie.indexOf('infoPopup'+id+'=ok') > -1)
        return true;
      
      return false;
    },

    onUpdateView: function() {     
      var wvc = this.parent.wvc;
      
      if (wvc.customParams && wvc.customParams.infoPopup && !this.parent.uiState) {       
        //On check le cookie pour voir 
        var id = this.parent.wvc.webDisplayingModuleId;
        var infoPopup = wvc.customParams.infoPopup;

        $('#infoPopupTitle').html(infoPopup.title);        
        $('#infoPopupText').html(infoPopup.text);   
        
        if(!(document.cookie.indexOf('infoPopup'+id+'=ok') > -1) &&  wvc.customParams.infoPopup.showStart){                  
          $('#modalInfoPopup').modal('show'); //On affiche le popup         
        }
      }
                       
      //On gère la visibilité du bouton INFO dans les atlas
      if(!this.parent.uiState)
        this.parent.atlas.handleInfoButton();         
      
    }
  };
})();
