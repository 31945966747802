var CD = require("./cd.js"),
    $ = $ || window.jQuery;

var ChartList = CD.Class.extend(/** @lends ChartList# */{

  includes: [L.Mixin.Events],

  options: {
    wvc: null,
    resourcesPath: "",
    assetsVersion: null,
    cartoJson: null
  },

  container: null,
  containerWidth: 0,

  chartistDeferred: $.Deferred().resolve(),

  charts: [],

  initialize: function(options) {
    CD.Util.setOptions(this, options || {}); // fusionne les options par défaut et les options passées en paramètre
  },

  _createDom: function() {
    if (this.container) return this.chartistDeferred;

    var $containerParent;
    if (window.reactComponents) {
      window.reactComponents.visualisationTabs.addLegendTab('charts', __('Graphiques'));
      window.reactComponents.visualisationTabs.toggleLegend('charts');
      $containerParent = $('#offcanvas-charts');
      this.containerWidth = $containerParent.width();
    } else {
      $containerParent = $('#offcanvas-chart-list');
    }
    if ($containerParent.length) {
      this.container = document.createElement('div');
      this.container.className = 'chart-list expanded-content';
      $containerParent.get(0).appendChild(this.container);
    }

    if (window.Chartist) {
      return $.Deferred().resolve();
    } else {
      // chargement de chartist
      var js = this.options.resourcesPath + "js/chartist.min.js?v=" + this.options.assetsVersion,
          css = this.options.resourcesPath + "css/chartist.min.css?v=" + this.options.assetsVersion;
      this.chartistDeferred = $.when(
        $.ajax({ type: "GET", url: js, dataType: "script", cache: !! this.options.assetsVersion }),
        $.ajax({ type: "GET", url: css, cache: !! this.options.assetsVersion, success: function() { $('<link>', { rel:'stylesheet', type:'text/css', 'href': css }).prependTo('head'); } })
      );
      return this.chartistDeferred;
    }
  },

  clear: function() {
    if (this.container) {
      var self = this;
      this.chartistDeferred.done(function() {
        self.charts.forEach(function(chart) {
          chart.detach();
        });
        self.container.innerHTML = "";
        self.charts = [];
      });
    }
  },

  createFullscreenContainer: function() {
    if (! this._fullscreenContainer) {
      this._fullscreenContainer = document.createElement('div');
      this._fullscreenContainer.style.cssText = "display: none; position: fixed; top: 0; bottom: 0; left: 0; right: 0; background: #fff; z-index: 1100;";
      this._fullscreenContainer.className = "ct-chart ct-fullscreen";
      document.body.appendChild(this._fullscreenContainer);
      this._fullscreenContainer.innerHTML =
        '<div style="position: absolute; top: 12px; right: 24px;">' +
          '<button type="button" class="close" style="font-size: 32px"><span aria-hidden="true">&times;</span></button>' +
        '</div>' +
        '<div style="position: absolute; top: 72px; right: 12px">' +
          '<div class="btn-group-vertical btn-group-lg" role="group">' +
            '<button type="button" class="btn btn-default" title="' + __("tooltip_zoom_in") + '"><span class="glyphicon glyphicon-plus"></span></button>' +
            '<button type="button" class="btn btn-default" title="' + __("tooltip_zoom_out") + '"><span class="glyphicon glyphicon-minus"></span></button>' +
          '</div>' +
        '</div><div></div>';
    }
    return this._fullscreenContainer;
  },

  addFullscreenBtn: function(chart) {
    var fullscreenBtn = document.createElement('button'),
        self = this;
    fullscreenBtn.className = "btn btn-default btn-maximize";
    fullscreenBtn.title = __("fullscreen");
    fullscreenBtn.innerHTML = '<span class="glyphicon glyphicon-resize-full"></span>';
    fullscreenBtn.onclick = function() {
      var container = self.createFullscreenContainer();

      container.style.display = "block";
      chart2 = self.options.cartoJson.toChart(chart.repJson, container.childNodes[2]);

      // Zoom +
      container.childNodes[1].childNodes[0].childNodes[0].onclick = function(mult) {
        var width = (isFinite(mult) ? mult : 1.5) * chart2.options.width,
            height = (isFinite(mult) ? mult : 1.5) * chart2.options.height,
            maxWidth = container.clientWidth,
            maxHeight = container.clientHeight;
        [].forEach.call(container.childNodes[2].childNodes[0].childNodes, function(node) {
          if (node.tagName == "H3") {
            maxHeight -= node.offsetHeight;
          } else if (node.tagName == "TABLE") {
            maxHeight -= node.offsetHeight + 16; // les 8 px de marge avant et après le tableau de légende
          }
        });
        if (width > maxWidth) {
          height = maxWidth * height / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = maxHeight * width / height;
          height = maxHeight;
        }
        chart2.update(null, {
          width: width,
          height: height,
          labelOffset: (Math.min(width, height) / 2 - chart2.options.chartPadding) * 0.75
        }, true);
      };
      container.childNodes[1].childNodes[0].childNodes[0].onclick(5); // on commence avec un graphique 5 fois plus gros que l'original

      // Zoom -
      container.childNodes[1].childNodes[0].childNodes[1].onclick = function() {
        chart2.update(null, {
          width: chart2.options.width / 1.5,
          height: chart2.options.height / 1.5,
          labelOffset: (Math.min(chart2.options.width, chart2.options.height) / 3 - chart2.options.chartPadding) * 0.75
        }, true);
      };

      // réduire le graphique
      container.childNodes[0].childNodes[0].onclick = function() {
        chart2.detach();
        container.childNodes[2].innerHTML = "";
        container.style.display = "none";
      };

    };
    chart.div.appendChild(fullscreenBtn)
  },

  update: function(chartsJson) {
    var self = this,
        deferred = $.Deferred().resolve();
    if (chartsJson && chartsJson.length) {
      deferred = this._createDom();
      deferred.done(function() {
        $('.show-if-charts').each(function() {
          $(this).show();
          if (window.articque.cdonline.atlasUI.drawer) { $(this).find('a:first').trigger('click'); }
        });
        chartsJson.forEach(function(chartJson) {
          var customParams = {};
          if (chartJson.DrawingParams && chartJson.DrawingParams.CustomParams) {
            if (typeof chartJson.DrawingParams.CustomParams == "string" && chartJson.DrawingParams.CustomParams != "") {
              try {
                customParams = JSON.parse(chartJson.DrawingParams.CustomParams);
                chartJson.DrawingParams.CustomParams = customParams;
              } catch(e) {
                customParams = {};
                alert(__("Syntaxe incorrecte pour les paramètres complémentaire du module ") + chartJson.DrawingParams.moduleName);
              }
            } else { // CustomParams a déjà été parsé (string => object)
              customParams = chartJson.DrawingParams.CustomParams;
            }
          }
          var chart = self.options.cartoJson.toChart(chartJson, self.container);
          if (chart) {
            chart.customParams = customParams;
            self.charts.push(chart);
            self.addFullscreenBtn(chart);
          }
        });
        if (window.reactComponents && self.options.wvc && self.containerWidth != $('#offcanvas-charts').width()) {
          self.containerWidth = $('#offcanvas-charts').width();
          setTimeout(self.options.wvc.invalidateSize.bind(self.options.wvc), 0); // on laisse le rendu se terminer avant de redimmensionner la carte
        }
      });
    } else {
      $('.show-if-charts').each(function() {
        var $this = $(this).hide();
        if ($this.hasClass('active')) {
          if (window.articque.cdonline.atlasUI.drawer) {
            $this.siblings('li:first').find('a:first').trigger('click');
          } else {
            $this.find('a:first').trigger('click');
          }
        }
      });
    }
    return deferred;
  }

});

module.exports = ChartList;
