var stringFormatter = require('./string-formatter.js');

var types = {},
    charts = {};

var uniqueId = 1000;

function canvasFont(family, size, bold, italic) {
  var font = [],
      families = ['Arial', 'Arial Black', 'Tahoma', 'Trebuchet MS', 'Verdana', 'Georgia', 'Times', 'Times New Roman', 'Palatino', 'Palatino Linotype', 'Courier', 'Courier New'];
  if (italic) {
    font.push('italic');
  }
  if (bold) {
    font.push('bold');
  }
  font.push(size + 'px');
  if (families.indexOf(family) > -1) {
    font.push(family);
  } else {
    font.push(families[0]);
  }
  return font.join(' ');
}

function spacing2px(str) {
  return (str == 'Small') ? 4 : ((str == 'Large') ? 20 : 10);
}

var unit = 'kilometerUnit';

function setUnit(u) {
  unit = u;
}

function toKilometers(val) {
  switch (unit) {
    case 'millimeterUnit': return val / 1000000;
    case 'centimeterUnit': return val / 100000;
    case 'meterUnit': return val / 1000;
    case 'kilometerUnit': return val;
    case 'mileUnit': return val / 0.621371;
    case 'yardUnit': return val / 1093.61;
    case 'feetUnit': return val / 3280.84;
    case 'inchUnit': return val / 39370.1;
  }
}

function getBrightness(rgba) {
  var array = rgba.replace('rgba(', '').split(',');
  return Math.round(((parseInt(array[0], 10) * 299) +
                     (parseInt(array[1], 10) * 587) +
                     (parseInt(array[2], 10) * 114)) / 1000)
}

/**
 * Initialisation d'un module contours
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMFill} un module de représentation
 */
types.Stroke = function(cartoJson, resources) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id]) {
    return null;
  }
  var features = resources.maps[cartoJson.Map.Id],
      params = cartoJson.DrawingParams,
      dashTypes = {
        Line: [],
        Dash: [10,10],
        DashDot: [10,5,1,5],
        DashDotDot: [10,5,1,5,1,5],
        Dot: [1,5]
      },
      groups = [],
      col,
      i;

  // couleurs des contours dépendant d'une qualité
  if (params.DataColor && params.DataColor.Id && resources.datas[params.DataColor.Id]) {
    var dataColor = resources.datas[params.DataColor.Id];
    groupsAssoc = {};
    col = params.DataColor.ColumnIndex;

      for (i in params.Colors) {
        groupsAssoc['q' + params.Colors[i].Quality] = {
          legend: params.CaptionColorValues ? params.CaptionColorValues[i] : '',
          ids: [],
          style: {
            fillColor: params.HasFill ? params.FillColor : '',
            strokeColor: params.Colors[i].HasStroke ? params.Colors[i].StrokeColor : '',
            doubleStrokeColor: params.Colors[i].HasStroke ? params.Colors[i].DoubleStrokeColor : '',
            // strokeWidth: params.Colors[i].HasStroke ? params.StrokeThickness || 1 : 0.1, // 0.1 pour ne pas que L.articque.rmFill ne le surcharge par 1 si simplifyPolygons est activé
            // strokeWidthInternal: params.HasStroke ? params.InternalLineThickness || 1 : 0,
            strokeDashType: params.Colors[i].HasStroke ? dashTypes[params.Colors[i].DashType] : null,
            strokeDoubleLine: params.Colors[i].HasStroke ? params.Colors[i].IsDoubleStrokeLine : false
          }
        };
      }
      if (dataColor.Values) {
        for (i = dataColor.Values.length - 1; i >= 0; i--) {
          if (groupsAssoc['q' + dataColor.Values[i][col]]) {
            groupsAssoc['q' + dataColor.Values[i][col]].ids.push(dataColor.Values[i][0]);
          }
        }
      }
      for (i in groupsAssoc) {
        groups.push(groupsAssoc[i]);
      }
      if (params.CaptionClassInvert) {
        groups.reverse();
      }
  } else { // couleur de contours simple
    groups = [{
      legend: params.CaptionColorText || '',
      ids: 'all',
      style: {
        fillColor: params.HasFill ? params.FillColor : '',
        strokeColor: params.HasStroke ? params.StrokeColor : '',
        negativeStrokeColor: params.HasStroke ? params.NegativeStrokeColor : '',
        doubleStrokeColor: params.HasStroke ? params.DoubleStrokeColor : '',
        // strokeWidth: params.HasStroke ? params.StrokeThickness || 1 : 0.1, // 0.1 pour ne pas que L.articque.rmFill ne le surcharge par 1 si simplifyPolygons est activé
        // strokeWidthInternal: params.HasStroke ? params.InternalLineThickness || 1 : 0,
        strokeDashType: params.HasStroke ? dashTypes[params.DashType] : null,
        strokeDoubleLine: params.HasStroke ? params.IsDoubleStrokeLine : false
      }
    }];
  }

  var strokeModuleOptions = {
    features: features,
    delta: L.point(params.DeltaX, -params.DeltaY),
    strokeAlignment: params.StrokeAlignment || 'center',
    //selectionLayer: 'module',
    selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
    tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
    groups: groups,
    simplifyPolygons: 1e5 // simplification des polygones uniquement si plus de 100 000 points
  };

  // épaisseurs des contours
  if (params.DataThickness && params.DataThickness.Id && resources.datas[params.DataThickness.Id] && resources.datas[params.DataThickness.Id].Values) {
    col = params.DataThickness.ColumnIndex;
    if (params.Thicknesses && params.Thicknesses.length) { // épaisseur codée sur une valeur qualitative
      var dataThickness = resources.datas[params.DataThickness.Id]
      strokeModuleOptions.widthGroups = [];
      groupsAssoc = {};
      col = params.DataThickness.ColumnIndex;

      for (i in params.Thicknesses) {
        groupsAssoc['q' + params.Thicknesses[i].Quality] = {
          legend: params.CaptionThicknessValues ? params.CaptionThicknessValues[i] : '',
          ids: [],
          widths: {
            width: params.Thicknesses[i].StrokeThickness,
            internalWidth: params.Thicknesses[i].InternalLineThickness
          }
        };
      }
      if (dataThickness.Values) {
        for (i = dataThickness.Values.length - 1; i >= 0; i--) {
          if (groupsAssoc['q' + dataThickness.Values[i][col]]) {
            groupsAssoc['q' + dataThickness.Values[i][col]].ids.push(dataThickness.Values[i][0]);
          }
        }
      }
      for (i in groupsAssoc) {
        strokeModuleOptions.widthGroups.push(groupsAssoc[i]);
      }
    } else { // épaisseur codée sur une valeur continue
      var valueName = '__v' + uniqueId++;
      // recopie les valeurs dans les features
      for (i = strokeModuleOptions.features.length - 1; i >= 0; i--) {
        for (j = resources.datas[params.DataThickness.Id].Values.length - 1; j >= 0; j--) {
          if (strokeModuleOptions.features[i].id == resources.datas[params.DataThickness.Id].Values[j][0] && resources.datas[params.DataThickness.Id].Values[j][col] != null) {
            strokeModuleOptions.features[i].properties = strokeModuleOptions.features[i].properties || {};
            strokeModuleOptions.features[i].properties[valueName] = resources.datas[params.DataThickness.Id].Values[j][col];
            break;
          }
        }
      }
      strokeModuleOptions.widthValue = { valueName: valueName, valueRef: params.SizeValue, sizeRef: params.Size };
      strokeModuleOptions.internalWidthValue = { valueName: valueName, valueRef: params.InternalSizeValue, sizeRef: params.InternalSize };
    }
  } else { // épaisseur constante
    strokeModuleOptions.widthValue = { valueName: null, fixedSize: params.StrokeThickness };
    strokeModuleOptions.internalWidthValue = { valueName: null, fixedSize: params.InternalLineThickness };
  }

  if (params.HasCaptionColor) {
    strokeModuleOptions.fillLegend = {
      title: params.CaptionColorTitle,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      columns: params.CaptionColorColumnsNumber || 1,
      offset: spacing2px(params.CaptionSpacing)
    };
  }
  if (params.HasCaptionThickness) {
    strokeModuleOptions.widthLegend = {
      title: params.CaptionThicknessTitle,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      fixedValues: params.CaptionThicknessPositiveValues ? params.CaptionThicknessPositiveValues.concat(params.CaptionThicknessNegativeValues) : [],
      columns: params.CaptionThicknessColumnsNumber || 1
    };
  }
  return L.articque.rmFill(strokeModuleOptions);
};

/**
 * Initialisation d'un module remplissage
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMFill} un module de représentation
 */
types.Fill = function(cartoJson, resources) {

  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id]) {
    return null;
  }

  var params = cartoJson.DrawingParams,
    data = (cartoJson.Data && cartoJson.Data.Id && resources.datas[cartoJson.Data.Id]) ?
              resources.datas[cartoJson.Data.Id] :
              null,
    col = cartoJson.Data.ColumnsIndex[cartoJson.Data.ColumnsIndex.length - 1],
    groupsAssoc = {},
    groups = [],
    i;

  if (params.Qualities)  {
    if (data && data.Values) {
      for (i in params.Qualities) {
        groupsAssoc['q' + params.Qualities[i][1]] = {
          legend: params.CaptionValues ? params.CaptionValues[i] : '',
          ids: [],
          style: {
            fillColor: params.Qualities[i][0],
            strokeColor: params.HasStroke ? params.StrokeColor : '',
            strokeWidth: params.HasStroke ? params.StrokeThickness || 1 : 0.1 // 0.1 pour ne pas que L.articque.rmFill ne le surcharge par 1 si simplifyPolygons est activé
          }
        };
      }
      for (i = data.Values.length - 1; i >= 0; i--) {
        if (groupsAssoc['q' + data.Values[i][col]]) {
          groupsAssoc['q' + data.Values[i][col]].ids.push(data.Values[i][0]);
        }
      }
      for (i in groupsAssoc) {
        groups.push(groupsAssoc[i]);
      }
    }
  } else {
    // no data
    groups.push({
        legend: params.CaptionNoDataFillColorText,
        ids: "all",
        style: {
          fillColor: params.NoDataFillColor,
          strokeColor: params.HasStroke ? params.StrokeColor : '',
          strokeWidth: params.HasStroke ? params.StrokeThickness || 1 : 0.1 // 0.1 pour ne pas que L.articque.rmFill ne le surcharge par 1 si simplifyPolygons est activé
        }
    })
  }
  if (params.CaptionClassInvert) {
    groups.reverse();
  }
  var fillModuleOptions = {
    features: resources.maps[cartoJson.Map.Id],
    tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
    groups: groups,
    //selectionLayer: 'module',
    selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
    simplifyPolygon: 1e5 // simplification des polygones uniquement si plus de 100 000 points
  };
  if (params.HasCaption) {
    fillModuleOptions.fillLegend = {
      title: params.CaptionTitle,
      shape: params.CaptionSymbolType,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      columns: params.CaptionColumnsNumber || 1
    };
  }
  return L.articque.rmFill(fillModuleOptions);
};

function buildRenderer(shape, resources, options) {
  switch (shape.Type) {
    case 'vector' : if (typeof L.articque.RMSymbols.prototype['_render' + shape.Shape] == 'function')   { return shape.Shape;      } break;
    case 'font'   : if (typeof L.articque.RMSymbols.prototype._chars[shape.FontSymbol] !== 'undefined') { return shape.FontSymbol; } break;
    case 'image'  :
      var resource = resources.images[shape.ImagePath],
          img;
      if (resource) {
        switch  (resource.Format) {
          case 'svg': return resource.Content;
          default: // png / jpg / ou gif
            img = new Image();
            img.src = 'data:image/' + resource.Format + ';base64,' + resource.Content;
            return img;
        }
      }
      // si l'image n'a pas été trouvée dans le cartojson sous forme de ressource en base64
      if (options.getUrl) {
        img = new Image();
        img.crossOrigin = "use-credentials";
        img.src = options.getUrl(shape.ImagePath);
        return img;
      } else {
        console.warn('"libraryGetUrl" options is not specified. Cannot use image symbols (' + shape.ImagePath + ')'); // eslint-disable-line no-console
      }
      break;
}
  return 'Circle';
}

/**
 * Initialisation d'un module symboles
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMSymbols} un module de représentation
 */
types.Symbol = function(cartoJson, resources, options) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id] ||
      ! cartoJson.Data || ! cartoJson.Data.Id || ! resources.datas[cartoJson.Data.Id]) {
    return null;
  }

  var params = cartoJson.DrawingParams,
      data,
      col,
      i,
      j,
      valueName,
      symbolsModuleOptions = {
        features: resources.maps[cartoJson.Map.Id],
        tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
        delta: L.point(params.DeltaX, -params.DeltaY),
        anchor: params.Anchor,
        selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
        visibilityThreshold: params.VisibilityThreshold || 0, // 0 par défaut pour le cas où params.DataSize == null
        visibilityThresholdValueMode: params.VisibilityValueMode || false,
        fillTransparencyThresholdValue: params.FillTransparencyThresholdValue || 0,
        fillTransparencyFactor: params.FillTransparencyFactor || 0
      };

  // tailles des symboles
  if (params.DataSize && params.DataSize.Id && resources.datas[params.DataSize.Id] && resources.datas[params.DataSize.Id].Values) {
    col = params.DataSize.ColumnIndex;
    var maxValue = resources.datas[params.DataSize.Id].Values.reduce(function(previousVal, currentItem) {
          return Math.max(previousVal, currentItem[col]);
        }, Number.NEGATIVE_INFINITY),
        minValue = resources.datas[params.DataSize.Id].Values.reduce(function(previousVal, currentItem) {
          return Math.min(previousVal, currentItem[col]);
        }, Number.POSITIVE_INFINITY);
    valueName = '__v' + uniqueId++;
    // recopie les valeurs dans les features
    for (i = symbolsModuleOptions.features.length - 1; i >= 0; i--) {
      symbolsModuleOptions.features[i].properties = symbolsModuleOptions.features[i].properties || {};
      // si les données sont dans le même ordre que les géométries
      if (resources.datas[params.DataSize.Id].Values[i] && symbolsModuleOptions.features[i].id == resources.datas[params.DataSize.Id].Values[i][0]) {
        symbolsModuleOptions.features[i].properties[valueName] = resources.datas[params.DataSize.Id].Values[i][col];
      } else {
        // si l'ordre est différent, on recherche les données correspondantes à la géométrie
        for (j = resources.datas[params.DataSize.Id].Values.length - 1; j >= 0; j--) {
          if (symbolsModuleOptions.features[i].id == resources.datas[params.DataSize.Id].Values[j][0]) {
            symbolsModuleOptions.features[i].properties[valueName] = resources.datas[params.DataSize.Id].Values[j][col];
            break;
          }
        }
      }
    }
    symbolsModuleOptions.sizeValue = { valueName: valueName, autoSize: false, valueRef: params.SizeValue, sizeRef: params.Size, maxValue: maxValue, minValue: minValue };
  } else {
    symbolsModuleOptions.sizeValue = { valueName: null, autoSize: false, fixedSize: params.Size, maxValue: 1, minValue: 0 };
  }

  if (params.HasCaptionSize) {
    symbolsModuleOptions.sizeLegend = {
      title: params.CaptionSizeTitle,
      symbolCount: params.CaptionPositiveItemCount,
      negativeSymbolCount: params.CaptionNegativeItemCount,
      fixedValues: params.CaptionPositiveValues.concat(params.CaptionNegativeValues),
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      columns: params.CaptionSizeColumnsNumber || 1
    };
  }

  // formes des symboles
  if (params.DataShape && params.DataShape.Id && resources.datas[params.DataShape.Id] && resources.datas[params.DataShape.Id].Values) {
    data = resources.datas[params.DataShape.Id];
    col = params.DataShape.ColumnIndex;
    var shapeGroupsAssoc = {},
        shapeGroups = [];

    for (i in params.Shapes) {
      shapeGroupsAssoc['s' + params.Shapes[i].Quality] = {
        legend: params.CaptionShapeValues ? params.CaptionShapeValues[i] : '',
        ids: [],
        renderer: buildRenderer(params.Shapes[i], resources, options)
      };
    }
    for (i = data.Values.length - 1; i >= 0; i--) {
      if (shapeGroupsAssoc['s' + data.Values[i][col]]) {
        shapeGroupsAssoc['s' + data.Values[i][col]].ids.push(data.Values[i][0]);
      }
    }
    for (i in shapeGroupsAssoc) {
      shapeGroups.push(shapeGroupsAssoc[i]);
    }
    symbolsModuleOptions.shapeGroups = shapeGroups;
  } else {
    symbolsModuleOptions.shapeGroups = [{ legend: params.CaptionShapeText, ids: 'all', renderer: buildRenderer(params.Shape, resources, options) }];
  }

  if (params.HasCaptionShape) {
    symbolsModuleOptions.shapeLegend = {
      title: params.CaptionShapeTitle,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      columns: params.CaptionShapeColumnsNumber || 1
    };
  }

  // remplissages des symboles
  if (params.DataColor && params.DataColor.Id && resources.datas[params.DataColor.Id] && resources.datas[params.DataColor.Id].Values) {
    data = resources.datas[params.DataColor.Id];
    col = params.DataColor.ColumnIndex;
    var fillGroupsAssoc = {},
      fillGroups = [];

    for (i in params.Colors) {
      fillGroupsAssoc['f' + params.Colors[i].Quality] = {
        legend: params.CaptionFillingValues ? params.CaptionFillingValues[i] : '',
        ids: [],
        color: params.Colors[i].Color,
        negativeColor: params.Colors[i].Color
      };
    }
    for (i = data.Values.length - 1; i >= 0; i--) {
      if (fillGroupsAssoc['f' + data.Values[i][col]]) {
        fillGroupsAssoc['f' + data.Values[i][col]].ids.push(data.Values[i][0]);
      }
    }
    for (i in fillGroupsAssoc) {
      fillGroups.push(fillGroupsAssoc[i]);
    }
    symbolsModuleOptions.fillGroups = fillGroups;
  } else {
    symbolsModuleOptions.fillGroups = [{
      ids: 'all',
      color: params.HasFillingColor ? params.FillingColor : null,
      negativeColor: params.HasFillingColor ? (params.NegativeFillingColor || null) : null
    }];
  }

  if (params.HasCaptionFilling) {
    symbolsModuleOptions.fillLegend = {
      title: params.CaptionFillingTitle,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      columns: params.CaptionFillingColumnsNumber || 1
    };
  }

  // contours des symboles
  if (params.DataStroke && params.DataStroke.Id && resources.datas[params.DataStroke.Id] && resources.datas[params.DataStroke.Id].Values) {
    data = resources.datas[params.DataStroke.Id];
    col = params.DataStroke.ColumnIndex;
    var strokeGroupsAssoc = {},
        strokeGroups = [];

    for (i in params.Strokes) {
      strokeGroupsAssoc['s' + params.Strokes[i].Quality] = {
        legend: params.CaptionStrokeValues ? params.CaptionStrokeValues[i] : '',
        ids: [],
        color: params.Strokes[i].HasStroke ? params.Strokes[i].Color : null,
        width: params.Strokes[i].Thickness
      };
    }
    for (i = data.Values.length - 1; i >= 0; i--) {
      if (strokeGroupsAssoc['s' + data.Values[i][col]]) {
        strokeGroupsAssoc['s' + data.Values[i][col]].ids.push(data.Values[i][0]);
      }
    }
    for (i in strokeGroupsAssoc) {
      strokeGroups.push(strokeGroupsAssoc[i]);
    }
    symbolsModuleOptions.strokeGroups = strokeGroups;
  } else {
    symbolsModuleOptions.strokeGroups = [{
      ids: 'all',
      color: params.HasStrokeColor ? params.StrokeColor : null,
      width: params.HasStrokeColor ? params.StrokeThickness : 0
    }];
  }

  if (params.HasCaptionStroke) {
    symbolsModuleOptions.strokeLegend = {
      title: params.CaptionStrokeTitle,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      columns: params.CaptionStrokeColumnsNumber || 1
    };
  }

  // orientations des symboles
  if (params.DataOrientation && params.DataOrientation.Id && resources.datas[params.DataOrientation.Id] && resources.datas[params.DataOrientation.Id].Values) {
    data = resources.datas[params.DataOrientation.Id];
    col = params.DataOrientation.ColumnIndex;
    var orientGroupsAssoc = {},
        orientGroups = [];

    for (i in params.Orientations) {
      orientGroupsAssoc['o' + params.Orientations[i].Quality] = {
        legend: params.CaptionOrientationValues ? params.CaptionOrientationValues[i] : '',
        ids: [],
        angle: params.Orientations[i].Orientation
      };
    }
    for (i = data.Values.length - 1; i >= 0; i--) {
      if (orientGroupsAssoc['o' + data.Values[i][col]]) {
        orientGroupsAssoc['o' + data.Values[i][col]].ids.push(data.Values[i][0]);
      }
    }
    for (i in orientGroupsAssoc) {
      orientGroups.push(orientGroupsAssoc[i]);
    }
    symbolsModuleOptions.orientGroups = orientGroups;
  } else if (params.Orientation) {
    symbolsModuleOptions.orientGroups = [{ids: 'all', angle: params.Orientation}];
  }

  if (params.HasCaptionOrientation) {
    symbolsModuleOptions.orientLegend = {
      title: params.CaptionOrientationTitle,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing),
      columns: params.CaptionOrientationColumnsNumber || 1
    };
  }

  // clustering
  symbolsModuleOptions.clusters = {
    enabled: params.ClusterEnabled,
    maxRadius: Math.max(80, params.ClusterSize * 2),
    categoryName: null,
    clickEnabled: true,
    hullEnabled: !! params.ClusterHasConvexHull,
    hullFillColor: "rgba(128,128,128,0.5)",
    hullStrokeColor: "rgb(128,128,128)",
    hullStrokeWidth: 2
  };
  if (params.ClusterEnabled) {
    var agregTypes = { noData: null, sumAgreg: "sum", averageAgreg: "avg", countAgreg: "count" },
        quantTypes = { equalNb: "quantile", equalSize: "equalSize", averages: "average", user: "manual" },
        labelTypes = { noValue: null, countLbl: "count", sizeLbl: "size", colorLbl: "fill"},
        cdgqId = params.ClusterDataGroupQuality ? params.ClusterDataGroupQuality.Id : null,
        cdsId = params.ClusterDataSize ? params.ClusterDataSize.Id : null,
        cdcId = params.ClusterDataColor ? params.ClusterDataColor.Id : null;

    // regroupement selon une qualité commune
    if (params.ClusterGroupQualityEnabled && cdgqId && resources.datas[cdgqId] && resources.datas[cdgqId].Values) {
      valueName = '__v' + uniqueId++;
      symbolsModuleOptions.clusters.categoryName = valueName;
      // recopie les valeurs dans les features
      for (i = symbolsModuleOptions.features.length - 1; i >= 0; i--) {
        symbolsModuleOptions.features[i].properties = symbolsModuleOptions.features[i].properties || {};
        // si les données sont dans le même ordre que les géométries
        if (resources.datas[cdgqId].Values[i] && symbolsModuleOptions.features[i].id == resources.datas[cdgqId].Values[i][0]) {
          symbolsModuleOptions.features[i].properties[valueName] = resources.datas[cdgqId].Values[i][params.ClusterDataGroupQuality.ColumnIndex];
        } else {
          // si l'ordre est différent, on recherche les données correspondantes à la géométrie
          for (j = resources.datas[cdgqId].Values.length - 1; j >= 0; j--) {
            if (symbolsModuleOptions.features[i].id == resources.datas[cdgqId].Values[j][0]) {
              symbolsModuleOptions.features[i].properties[valueName] = resources.datas[cdgqId].Values[j][params.ClusterDataGroupQuality.ColumnIndex];
              break;
            }
          }
        }
      }
    }

    // taille des clusters
    if ((params.ClusterSizeAgregType == "averageAgreg" || params.ClusterSizeAgregType == "sumAgreg") &&
        cdsId && resources.datas[cdsId] && resources.datas[cdsId].Values) {
      valueName = '__v' + uniqueId++;
      // recopie les valeurs dans les features
      for (i = symbolsModuleOptions.features.length - 1; i >= 0; i--) {
        symbolsModuleOptions.features[i].properties = symbolsModuleOptions.features[i].properties || {};
        // si les données sont dans le même ordre que les géométries
        if (resources.datas[cdsId].Values[i] && symbolsModuleOptions.features[i].id == resources.datas[cdsId].Values[i][0]) {
          symbolsModuleOptions.features[i].properties[valueName] = resources.datas[cdsId].Values[i][params.ClusterDataSize.ColumnIndex];
        } else {
          // si l'ordre est différent, on recherche les données correspondantes à la géométrie
          for (j = resources.datas[cdsId].Values.length - 1; j >= 0; j--) {
            if (symbolsModuleOptions.features[i].id == resources.datas[cdsId].Values[j][0]) {
              symbolsModuleOptions.features[i].properties[valueName] = resources.datas[cdsId].Values[j][params.ClusterDataSize.ColumnIndex];
              break;
            }
          }
        }
      }
    } else {
      valueName = null;
    }

    symbolsModuleOptions.clusterSize = {
      valueType: agregTypes[params.ClusterSizeAgregType],
      valueName: valueName,
      autoSize: params.ClusterAutoSize,
      maxSize: params.ClusterSize
    };

    // couleurs des clusters
    if ((params.ClusterColorAgregType == "averageAgreg" || params.ClusterColorAgregType == "sumAgreg") &&
        cdcId && resources.datas[cdcId] && resources.datas[cdcId].Values) {
      valueName = '__v' + uniqueId++;
      // recopie les valeurs dans les features
      for (i = symbolsModuleOptions.features.length - 1; i >= 0; i--) {
        symbolsModuleOptions.features[i].properties = symbolsModuleOptions.features[i].properties || {};
        // si les données sont dans le même ordre que les géométries
        if (resources.datas[cdcId].Values[i] && symbolsModuleOptions.features[i].id == resources.datas[cdcId].Values[i][0]) {
          symbolsModuleOptions.features[i].properties[valueName] = resources.datas[cdcId].Values[i][params.ClusterDataColor.ColumnIndex];
        } else {
          // si l'ordre est différent, on recherche les données correspondantes à la géométrie
          for (j = resources.datas[cdcId].Values.length - 1; j >= 0; j--) {
            if (symbolsModuleOptions.features[i].id == resources.datas[cdcId].Values[j][0]) {
              symbolsModuleOptions.features[i].properties[valueName] = resources.datas[cdcId].Values[j][params.ClusterDataColor.ColumnIndex];
              break;
            }
          }
        }
      }
    } else {
      valueName = null;
    }
    var colors = [], steps = [];
    if (params.ClusterColorAgregType == "noData") { // couleur unique
      colors.push(params.ClusterColor);
    } else {
      for(i = params.ClusterClasses.length - 1; i >= 0;  i--) {
        colors.push(params.ClusterClasses[i].Color);
        if (i < params.ClusterClasses.length - 1) {
          steps.push(params.ClusterClasses[i].MaxValue);
        }
      }
    }
    symbolsModuleOptions.clusterFill = {
      valueType: agregTypes[params.ClusterColorAgregType],
      valueName: valueName,
      quantificationMethod: params.ClusterQuantType ? quantTypes[params.ClusterQuantType] : "manual",
      colors: colors,
      steps: steps
    };

    // libellés des clusters
    symbolsModuleOptions.clusterText = {
      valueType: labelTypes[params.ClusterLabelType] || null,
      decimals: params.ClusterHasPrecisionAuto ? "auto" : params.ClusterPrecisionAfter,
      prefix: params.ClusterPrefixText,
      suffix: params.ClusterSuffixText,
      font: canvasFont(params.ClusterTextFont.Family, params.ClusterTextSize, params.ClusterTextFont.Bold, params.ClusterTextFont.Italic),
      color: params.ClusterTextColor,
      shadowColor: getBrightness(params.ClusterTextColor) > 127 ? "#444" : "#ccc"
    };
  }

  return L.articque.rmSymbols(symbolsModuleOptions);
};

/**
 * Initialisation d'un module Histogrammes
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMCharts} un module de représentation
 */
types.Histogram = function(cartoJson, resources) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id] ||
      ! cartoJson.Data || ! cartoJson.Data.Id || ! resources.datas[cartoJson.Data.Id]) {
    return null;
  }
  var params = cartoJson.DrawingParams,
      data = resources.datas[cartoJson.Data.Id],
      i,
      j,
      vals,
      maxValue = Number.NEGATIVE_INFINITY,
      minValue = Number.POSITIVE_INFINITY,
      features = resources.maps[cartoJson.Map.Id];

  // tailles des barres
  if (features.length && data && data.Values && data.Values.length) {
    var cols = cartoJson.Data.ColumnsIndex,
        valueName = '__v' + uniqueId++,
        k, unavail;
    // recopie les valeurs dans les features
    for (i = features.length - 1; i >= 0; i--) {
      for (j = data.Values.length - 1; j >= 0; j--) {
        if (features[i].id == data.Values[j][0]) {
          features[i].properties = features[i].properties || {};
          vals = [];
          if (params.UnavailIsZero) {
            for (k = 0, l = cols.length; k < l; k++) {
              vals.push(data.Values[j][cols[k]] || 0); // remplace null par 0
            }
          } else {
            unavail = false;
            for (k = 0, l = cols.length; k < l; k++) {
              vals.push(data.Values[j][cols[k]]);
              unavail = unavail || (data.Values[j][cols[k]] === null);
            }
            if (unavail) {
              vals = [];
            }
          }
          maxValue = Math.max(maxValue, Math.max.apply(null, vals));
          minValue = Math.min(minValue, Math.min.apply(null, vals));
          features[i].properties[valueName] = vals;
          break;
        }
      }
    }
    histogramModuleOptions = {
      features: features,
      delta: L.point(params.DeltaX, -params.DeltaY),
      tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
      selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
      valuesName: valueName,
      fillColors: params.Colors,
      fillLabels: params.CaptionQualValues,
      renderer: L.articque.crBars({
        barSize: {
          width: Math.round(params.Width),
          autoHeight: false,
          valueRef: params.SizeValue,
          heightRef: params.Size,
          maxValue: maxValue
        },
        direction: params.IsHorizontal ? 'horizontal' : 'vertical',
        strokeColor: params.StrokeColor,
        strokeWidth: params.HasStroke ? 1 : 0,
        scale: params.HasScale,
        axisOriginZero: params.AxisOriginZero,
        sortBy: params.SortBy
      })
    };

    if (params.HasCaption) {
      histogramModuleOptions.fillLegend = {
        title: params.CaptionQualTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        shape: 'square',
        reverseOrder: !! params.CaptionQualReverseOrder,
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing),
        columns: params.CaptionColumnsNumber || 1
      };

      histogramModuleOptions.sizeLegend = {
        title: params.CaptionProportionalTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        maxSize: maxValue,
        minSizeNegative: minValue,
        fixedValues: params.CaptionPropValues,
        shapeBackgroundColor: params.CaptionPropEmptySymbol ? null : '#D3D3D3',
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing)
      };
    }
    return L.articque.rmCharts(histogramModuleOptions);
  }
  return null;
};

/**
 * Initialisation d'un module Portions
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMCharts} un module de représentation
 */
types.Portion = function(cartoJson, resources) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id] ||
      ! cartoJson.Data || ! cartoJson.Data.Id || ! resources.datas[cartoJson.Data.Id]) {
    return null;
  }

  var params = cartoJson.DrawingParams,
      data = resources.datas[cartoJson.Data.Id],
      i,
      j,
      vals,
      maxValue = Number.NEGATIVE_INFINITY,
      features = resources.maps[cartoJson.Map.Id];

  // tailles des portions
  if (features.length && data && data.Values && data.Values.length) {
    var cols = cartoJson.Data.ColumnsIndex,
        valueName = '__v' + uniqueId++,
        k, unavail;
    // recopie les valeurs dans les features
    for (i = features.length - 1; i >= 0; i--) {
      for (j = data.Values.length - 1; j >= 0; j--) {
        if (features[i].id == data.Values[j][0]) {
          features[i].properties = features[i].properties || {};
          vals = [];
          if (params.UnavailIsZero) {
            for (k = 0, l = cols.length; k < l; k++) {
              vals.push(data.Values[j][cols[k]] || 0); // remplace null par 0
            }
          } else {
            unavail = false;
            for (k = 0, l = cols.length; k < l; k++) {
              vals.push(data.Values[j][cols[k]]);
              unavail = unavail || (data.Values[j][cols[k]] === null);
            }
            if (unavail) {
              vals = [];
            }
          }
          maxValue = Math.max(maxValue, Math.max.apply(null, vals));
          features[i].properties[valueName] = vals;
          break;
        }
      }
    }
    portionsModuleOptions = {
      features: features,
      delta: L.point(params.DeltaX, -params.DeltaY),
      tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
      selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
      valuesName: valueName,
      fillColors: params.Colors,
      fillLabels: params.CaptionQualValues,
      renderer: L.articque.crPortions({
        chartSize: {
          autoSize: false,
          valueRef: params.SizeValue,
          sizeRef: params.Size,
          maxValue: maxValue
        },
        startAngle: params.MinAngle * Math.PI / 180,
        endAngle: params.MaxAngle * Math.PI / 180,
        strokeColor: params.StrokeColor,
        strokeWidth: params.HasStroke ? 1 : 0,
        sortBy: params.SortBy
      })
    };

    if (params.HasCaption) {
      portionsModuleOptions.fillLegend = {
        title: params.CaptionQualTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        shape: (params.CaptionQualSymbolType == 'ThreeQuarters') ? 'threeQuarters' : 'full',
        reverseOrder: !! params.CaptionQualReverseOrder,
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing),
        columns: params.CaptionColumnsNumber || 1
      };

      portionsModuleOptions.sizeLegend = {
        title: params.CaptionProportionalTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        maxSize: maxValue,
        shapeBackgroundColor: params.CaptionPropEmptySymbol ? null : '#D3D3D3',
        reverseOrder: !! params.CaptionPropReverseOrder,
        fixedValues: params.CaptionPropValues,
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing),
        columns: params.CaptionColumnsNumber || 1
      };
    }
    return L.articque.rmCharts(portionsModuleOptions);
  }
  return null;
};

/**
 * Initialisation d'un module Secteurs
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMCharts} un module de représentation
 */
types.Pie = function(cartoJson, resources) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id] ||
      ! cartoJson.Data || ! cartoJson.Data.Id || ! resources.datas[cartoJson.Data.Id]) {
    return null;
  }

  var params = cartoJson.DrawingParams,
      data = resources.datas[cartoJson.Data.Id],
      i,
      j,
      vals,
      maxValue = Number.NEGATIVE_INFINITY,
      features = resources.maps[cartoJson.Map.Id];

  // tailles des secteurs
  if (features.length && data && data.Values && data.Values.length) {
    var cols = cartoJson.Data.ColumnsIndex,
        valueName = '__v' + uniqueId++,
        k, unavail;
    // recopie les valeurs dans les features
    for (i = features.length - 1; i >= 0; i--) {
      for (j = data.Values.length - 1; j >= 0; j--) {
        if (features[i].id == data.Values[j][0]) {
          features[i].properties = features[i].properties || {};
          vals = [];
          if (params.UnavailIsZero) {
            for (k = 0, l = cols.length; k < l; k++) {
              vals.push(data.Values[j][cols[k]] || 0); // remplace null par 0
            }
          } else {
            unavail = false;
            for (k = 0, l = cols.length; k < l; k++) {
              vals.push(data.Values[j][cols[k]]);
              unavail = unavail || (data.Values[j][cols[k]] === null);
            }
            if (unavail) {
              vals = [];
            }
          }
          maxValue = Math.max(maxValue, Math.max.apply(null, vals));
          features[i].properties[valueName] = vals;
          break;
        }
      }
    }
    var chartSize = params.HasProportionalCircle ?
      { autoSize: false, valueRef: params.SizeValue, sizeRef: params.Size, maxValue: maxValue, fixedValues: params.CaptionPropValues } : // secteurs proportionnels
      { autoSize: false, fixedSizeExt: params.Size, maxValue: maxValue }; // secteurs de taille fixe

    var pieRendererOptions = {
        chartSize: chartSize,
        strokeColor: params.StrokeColor,
        strokeWidth: params.HasStroke ? 1 : 0,
        startAngle: params.MinAngle * Math.PI / 180,
        endAngle: params.MaxAngle * Math.PI / 180,
        visibilityThreshold: params.VisibilityThreshold || 0, // 0 par défaut pour le cas où params.HasProportionalCircle == false
        visibilityThresholdValueMode: params.VisibilityValueMode || false,
        sortBy: params.SortBy
      };

    if (params.DrawLabel) {
      pieRendererOptions.labels = {
        enabled: true,
        size: params.DrawLabelAutoSize ? 'auto' : params.DrawLabelSize,
        asPercent: params.DrawLabelAsPercent,
        suffix: params.DrawLabelSuffix,
        position: params.DrawLabelInside ? 'inside' : 'outside',
        posFallback: (params.DrawLabelSmallValuesParam == 'DisplayOutside') ? 'outside' : ((params.DrawLabelSmallValuesParam == 'Hide') ? 'hide' : 'inside'),
        round: params.DrawLabelRoundValue ? params.DrawLabelRoundNbDecimal : false,
        background: params.DrawLabelHasBackground ? params.DrawLabelBackgroundColor : false,
        color: params.DrawLabelColor,
        fontFamily: params.DrawLabelFont.Family + (params.DrawLabelFont.Italic ? ' italic' : '') + (params.DrawLabelFont.Bold ? ' bold' : '')
      };
    }

    var pieModuleOptions = {
      features: features,
      delta: L.point(params.DeltaX, -params.DeltaY),
      tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
      selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
      valuesName: valueName,
      fillColors: params.Colors,
      fillLabels: params.CaptionQualValues,
      renderer: L.articque.crPie(pieRendererOptions)
    };

    if (params.HasCaption) {
      pieModuleOptions.fillLegend = {
        title: params.CaptionQualTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        shape: (params.CaptionQualSymbolType == 'ThreeQuarters') ? 'threeQuarters' : 'full',
        reverseOrder: !! params.CaptionQualReverseOrder,
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing),
        columns: params.CaptionColumnsNumber || 1
      };

      if (params.HasProportionalCircle) {
        pieModuleOptions.sizeLegend = {
          title: params.CaptionProportionalTitle,
          borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
          backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
          maxSize: maxValue,
          shapeBackgroundColor: params.CaptionPropEmptySymbol ? null : '#D3D3D3',
          reverseOrder: !! params.CaptionPropReverseOrder,
          fixedValues: params.CaptionPropValues,
          titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
          font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
          fontColor: params.CaptionFontColor,
          textPosition: params.CaptionTextPosition.toLowerCase(),
          offset: spacing2px(params.CaptionSpacing),
          columns: params.CaptionColumnsNumber || 1
        };
      }
    }
    return L.articque.rmCharts(pieModuleOptions);
  }
  return null;
};

/**
 * Initialisation d'un module Flux
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMFlows} un module de représentation
 */
types.Flow = function(cartoJson, resources) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id] ||
      ! cartoJson.Data || ! cartoJson.Data.Id || ! resources.datas[cartoJson.Data.Id]) {
    return null;
  }

  var params = cartoJson.DrawingParams,
      data = resources.datas[cartoJson.Data.Id],
      i,
      j,
      features = resources.maps[cartoJson.Map.Id],
      flows = [],
      groups = [],
      sizeValue = { autoSize: false, valueRef: params.SizeValue, sizeRef: params.Size, maxValue: Number.NEGATIVE_INFINITY, minValue: Number.POSITIVE_INFINITY };

  if (features.length && data && data.Values && data.Values.length) {
    var col = params.DataProp ? params.DataProp.ColumnIndex : null,
        val;
    // recopie la valeur dans les features
    for (j = data.Values.length - 1; j >= 0; j--) {
      // tailles proportionnelles
      val = (col === null) ? 1 : ((typeof data.Values[j][col] == 'string') ? L.articque.Util.numberUnformat(data.Values[j][col]) : data.Values[j][col]);
      // val = (col === null) ? 1 : data.Values[j][col]; // à rétablir lorsque le WS renverra systématiquement des nombres
      flows.push({
        from: data.Values[j][0],
        to: data.Values[j][1],
        val: val
      });
      sizeValue.maxValue = Math.max(sizeValue.maxValue, val);
      sizeValue.minValue = Math.min(sizeValue.minValue, val);
    }

    // taille fixe
    if (col === null) {
      sizeValue.fixedSize = sizeValue.sizeRef;
    }

    // couleur des flèches
    if (params.DataQual && params.DataQual.Id && resources.datas[params.DataQual.Id]) {
      data = resources.datas[params.DataQual.Id];
      col = params.DataQual.ColumnIndex;
      var groupsAssoc = {};

      for (i = 0; i < params.Colors.length; i++) {
        groupsAssoc['g' + params.Qualities[i].quality] = {
          legend: params.CaptionQualValues ? params.CaptionQualValues[i] : '',
          ids: [],
          color: params.Colors[i],
          strokeColor: params.HasStroke ? params.StrokeColor.Color : null,
          strokeWidth: params.StrokeThickness || 1
        };
      }
      for (i = data.Values.length - 1; i >= 0; i--) {
        if (groupsAssoc['g' + data.Values[i][col]]) {
          groupsAssoc['g' + data.Values[i][col]].ids.push({ from: data.Values[i][0], to: data.Values[i][1] });
        }
      }
      for (i in groupsAssoc) {
        groups.push(groupsAssoc[i]);
      }
    } else {
      groups = [{
        legend: '',
        ids: 'all',
        color: params.PositiveColor,
        negativeColor: params.NegativeColor,
        strokeColor: params.HasStroke ? params.StrokeColor.Color : null,
        strokeWidth: params.StrokeThickness || 1
      }];
    }

  }

  var flowModuleOptions = {
    features: features,
    flows: flows,
    tooltip: function(flow) { return flow.from.properties.Id + ' - ' + flow.from.properties.Name + ' &gt; ' + flow.to.properties.Id + ' - ' + flow.to.properties.Name; },
    selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
    fillGroups: groups,
    sizeValue: sizeValue,
    visibilityThreshold: params.VisibilityThreshold,
    visibilityThresholdValueMode: params.VisibilityValueMode || false,
    arrowShape: {
      shift: params.IsCurveDist ? "distance" : "radius",
      shiftValue: toKilometers(params.ArrowCurve),
      margin: toKilometers(params.ArrowMargin),
      tipRatio: params.ArrowEnd,
      tipThicknessFactor: params.ArrowHeadThick,
      reversed: params.ArrowReverse,
      showInternalArrows: params.ShowInternalArrows,
      internalArrowCurveDist: params.InternalArrowCurveDist,
      defaultInternalArrowCurveDist: params.DefaultInternalArrowCurveDist
    }
  };

  if (params.HasCaption) {
    if (params.DataProp) {
      flowModuleOptions.sizeLegend = {
        title: params.CaptionPropTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        maxSize: sizeValue.maxValue,
        shapeBackgroundColor: params.CaptionPropEmptySymbol ? null : params.PositiveColor || '#D3D3D3',
        shapeBackgroundNegativeColor: params.CaptionPropEmptySymbol ? null : params.NegativeColor || '#D3D3D3',
        reverseOrder: !! params.CaptionPropReverseOrder,
        fixedValues: (!params.DataQual && params.CaptionAutoValues && sizeValue.minValue < 0) ? params.CaptionPropValues.concat(params.CaptionPropValues.map(function(val){ return '-' + val; }).reverse()) : params.CaptionPropValues,
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing),
        columns: params.CaptionColumnsNumber || 1
      };
    }
    if (params.DataQual) {
      flowModuleOptions.fillLegend = {
        title: params.CaptionQualTitle,
        borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
        backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
        reverseOrder: !! params.CaptionQualReverseOrder,
        titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
        fontColor: params.CaptionFontColor,
        textPosition: params.CaptionTextPosition.toLowerCase(),
        offset: spacing2px(params.CaptionSpacing),
        columns: params.CaptionColumnsNumber || 1
      };
    }
  }

  return L.articque.rmFlows(flowModuleOptions);
};

/**
 * Initialisation d'un module Secteurs
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMCharts} un module de représentation
 */
types.Value = function(cartoJson, resources) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! resources.maps[cartoJson.Map.Id] || ! cartoJson.Data) {
    return null;
  }

  var params = cartoJson.DrawingParams,
      i,
      j,
      labelModuleOptions = null,
      valueName = '__v' + uniqueId++,
      features = resources.maps[cartoJson.Map.Id];

  function format(n) {
    if (typeof n == 'number') {
      var precision = (params.HasAutoPrecision || (typeof params.PrecisionAfter != 'number')) ? 'auto' : params.PrecisionAfter;
      return L.articque.Util.numberFormat(n, precision, undefined, undefined, true);
    }
    return n;
  }

  // données
  if (features.length && cartoJson.Data.Id && params.DataType == 'data' && resources.datas[cartoJson.Data.Id] && resources.datas[cartoJson.Data.Id].Values && resources.datas[cartoJson.Data.Id].Values.length) {
    var col = cartoJson.Data.ColumnsIndex[0],
        data = resources.datas[cartoJson.Data.Id];
    // recopie les valeurs dans les features
    for (i = features.length - 1; i >= 0; i--) {
      for (j = data.Values.length - 1; j >= 0; j--) {
        if (features[i].id == data.Values[j][0]) {
          features[i].properties = features[i].properties || {};
          if (format(data.Values[j][col]) === null) {
            features[i].properties[valueName] = null;
          } else {
            features[i].properties[valueName] = params.Prefix + ' ' + format(data.Values[j][col]) + ' ' + params.Suffix;
          }
          break;
        }
      }
    }
  } else if (params.DataType == 'id' || params.DataType == 'name') {
    var propName = params.DataType.charAt(0).toUpperCase() + params.DataType.slice(1);
    // recopie les valeurs dans les features
    for (i = features.length - 1; i >= 0; i--) {
      features[i].properties[valueName] = params.Prefix + features[i].properties[propName] + params.Suffix;
    }
  }

  labelModuleOptions = {
    features: features,
    delta: L.point(params.DeltaX, -params.DeltaY),
    anchor: params.Anchor,
    avoidCollisions: params.avoidCollisions,
    automaticAnchorOnCollision: params.automaticAnchorOnCollision,
    tooltip: function(feature) { return feature.properties.Id + ' - ' + feature.properties.Name; },
    selectedStyle : { strokeColor : "#FF0000", dashColor: "#FFFF00", strokeWidth: 3, strokeDashType: [] },
    font: canvasFont(params.TextFont.Family, params.TextSize, params.TextFont.Bold, params.TextFont.Italic),
    valueName: valueName,
    groupStyles : {
      "all" : {
        fillColor : params.HasBackground ? params.BackgroundColor : false,
        strokeColor : false,
        textColor : params.TextColor,
        textStrokeColor: params.hasStrokeColor ? params.strokeColor : false,
        textStrokeWidth: Math.round(params.strokeThickness)
      }
    }
  };

  if (params.HasCaption) {
    labelModuleOptions.legend = {
      title: null, // pas de titre pour les légendes de valeurs
      text: params.CaptionTitle,
      description: params.CaptionText,
      borderColor: params.HasCaptionBorder ? params.CaptionBorderColor : '',
      backgroundColor: params.HasCaptionBackground ? params.CaptionBackgroundColor : '',
      titleFont: canvasFont(params.CaptionFont.Family, params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      font: canvasFont(params.CaptionFont.Family, 0.8 * params.CaptionTextSize, params.CaptionFont.Bold, params.CaptionFont.Italic),
      fontColor: params.CaptionFontColor,
      textPosition: params.CaptionTextPosition.toLowerCase(),
      offset: spacing2px(params.CaptionSpacing)
    };
  }

  return L.articque.rmLabels(labelModuleOptions);
};

/**
 * Initialisation d'un module Arrière plan
 * @param  {Object} cartoJson description du module dans le cartoJSON
 * @param  {Object} resources ressources disponibles dans le cartoJSON
 * @return {L.artique.RMCharts} un module de représentation
 */
types.Raster = function(cartoJson/*, resources*/) {
  if (! cartoJson.Map || ! cartoJson.Map.Id || ! cartoJson.DrawingParams || ! cartoJson.DrawingParams.Rasters || ! cartoJson.DrawingParams.Rasters.length) {
    return null;
  }

  var rasters = [],
      param, image;

  for (var i = cartoJson.DrawingParams.Rasters.length - 1; i >= 0; i--) {
    param = cartoJson.DrawingParams.Rasters[i];
    image = new Image();
    image.src = 'data:image/png;base64,' + param.Image64;
    if (param.IsWarped) {
      rasters.push({
        image: image,
        controlPoints: [
          L.latLng(param.YTopLeft, param.XTopLeft),
          L.latLng(param.YBottomRight, param.XBottomRight),
          L.latLng(param.YBottomLeft, param.XBottomLeft),
        ]
      });
    } else {
      rasters.push({
        image: image,
        bounds: L.latLngBounds(
          L.latLng(param.YTopLeft, param.XTopLeft),
          L.latLng(param.YBottomRight, param.XBottomRight)
        )
      });
    }
  }

  return L.articque.rmRaster({ rasters: rasters, mapBounds: cartoJson.DrawingParams.MapBounds || null });
};

var toComment = function(comJson, positions, moduleId, map, layer, movedCallback, dblclickCallback, contextCallback) {
  var font = canvasFont(comJson.TextFont.Family, comJson.TextSize, comJson.TextFont.Bold, comJson.TextFont.Italic),
      pos = {x: 'left', y: layer.options.vertical ? 'vert-stacked-top#' + layer._modules.length : 'top'},
      orientTypes = { "CompassRose": "compass-rose", "SimpleArrow": "arrow", "SimpleCircular": "simple-circular", "Articque": "articque" },
      scaleTypes = {"Line": "line", "BlackAndWhite": "checked", "DoubleBlackAndWhite": "double-checked"},
      rml, pos2;

  switch(comJson.Type) {
    case 'Orientation' :
      pos.x = layer.options.vertical ? 'left' : 'right';
      pos.y = layer.options.vertical ? 'vert-stacked-top#' + layer._modules.length : 'bottom';
      rml = L.articque.rmlOrientation({
        type: orientTypes[comJson.OrientationType],
        borderColor: comJson.HasStroke ? comJson.StrokeColor : '',
        backgroundColor: comJson.HasFill ? comJson.FillColor : '',
        font: font,
        fontColor: comJson.TextColor,
        size: 4 * comJson.TextSize
      });
      break;
    case 'Scale' :
      pos.x = layer.options.vertical ? 'left' : 'stacked-left';
      pos.y = layer.options.vertical ? 'vert-stacked-top#' + layer._modules.length : 'bottom';
      rml = L.articque.rmlCheckedScale({
        map: map,
        style: scaleTypes[comJson.ScaleForm],
        inverted: comJson.ScaleInvertColors,
        textPosition: comJson.ScaleTextPosition.toLowerCase(),
        interPoints: comJson.ScaleNbInterPoints,
        borderColor: comJson.HasStroke ? comJson.StrokeColor : '',
        backgroundColor: comJson.HasFill ? comJson.FillColor : '',
        font: font,
        fontColor: comJson.ScaleColor
      });
      break;
    case 'Text' :
      if (! layer.options.vertical) {
        pos.y = 'center';
      }
    case 'Title' : // eslint-disable-line no-fallthrough
      rml = L.articque.rmlTitle({
        title: comJson.Text,
        titleFont: font,
        titleAlign: comJson.TextAlignment.toLowerCase(),
        borderColor: comJson.HasStroke ? comJson.StrokeColor : '',
        backgroundColor: comJson.HasFill ? comJson.FillColor : '',
        fontColor: comJson.TextColor
      });
      break;
    case 'Copyright' :
      // pos.x = 'right';
      // pos.y = 'stacked-bottom';
      // break;
      return; // on supprime les copyrights du rendu web
  }

  pos2 = (positions && positions[moduleId] && positions[moduleId]['comment_' + comJson.Id]) ? positions[moduleId]['comment_' + comJson.Id] : { Posx: pos.x, Posy: pos.y };
  rml.setPosition(pos2.Posx, pos2.Posy);
  rml.on('legendmoved', movedCallback.bind(null, moduleId, 'comment_' + comJson.Id));
  rml.on('dblclick', dblclickCallback.bind(null, moduleId, 'comment_' + comJson.Id, rml));
  rml.on('context', contextCallback.bind(null, moduleId, 'comment_' + comJson.Id, rml));
  return rml;
};

var toScale = function(map, positions, moduleId, layer, movedCallback, dblclickCallback, contextCallback) {
  var pos = (positions && positions[moduleId] && positions[moduleId]['scale']) ?
              positions[moduleId]['scale'] :
              { Posx: layer.options.vertical ? 'left' : 'stacked-left', Posy: layer.options.vertical ? 'vert-stacked-top#' + layer._modules.length : 'bottom' },
      rmlScale = L.articque.rmlScale({ map: map }).setPosition(pos.Posx, pos.Posy);
  rmlScale.on('legendmoved', movedCallback.bind(null, moduleId, 'scale'));
  rmlScale.on('dblclick', dblclickCallback.bind(null, moduleId, 'scale', rmlScale));
  rmlScale.on('context', contextCallback.bind(null, moduleId, 'scale', rmlScale));
  return rmlScale;
};

var getDataColumns = function(repJson, resources) {
  if (repJson.Data && repJson.Data.Id && resources.datas[repJson.Data.Id] && resources.datas[repJson.Data.Id].Header) {
    return resources.datas[repJson.Data.Id].Header.slice(0);
  }

  return [];
};

var toModule = function(repJson, resources, options) {
  if (types[repJson.Type] && repJson.Visible && repJson.DrawingParams) {
    var mod = types[repJson.Type](repJson, resources, options);
    if (mod) {
      mod.name = repJson.Name;
      mod.dataColumns = getDataColumns(repJson, resources);
    }
    return mod;
  }
  return null;
};

var toTileLayer = function(repJson) {
  var layer = null;
  try {
    if (repJson.DrawingParams.IsWms) {
      layer = L.articque.tileLayer.wms(repJson.DrawingParams.ServerUrl, {
        format: repJson.DrawingParams.OutputFormat,
        version: repJson.DrawingParams.Version,
        url: repJson.DrawingParams.ServerUrl,
        minNativeZoom: repJson.DrawingParams.MinZoom || 0,
        maxNativeZoom: repJson.DrawingParams.MaxZoom || 18,
        // styles: repJson.DrawingParams.Styles.join(','),
        // transparent: false,
        // crs: null,
        // uppercase: false,
        layers: repJson.DrawingParams.Layers.join(','),
        opacity: 1 - repJson.DrawingParams.Alpha
      });
    } else {
      var json = JSON.parse(repJson.DrawingParams.WmtsJsonParameters);
      json.opacity = json.opacity || 1 - repJson.DrawingParams.Alpha;
      if (json.url)
        layer = L.articque.tileLayer(json.url, json);
    }
  } catch(e) { layer = null; }
  if (layer) {
    layer.name = repJson.Name;
  }
  return layer;
};

var addModuleLegendsTo = function(mod, repJson, layers, positions, moduleId, movedCallback, dblclickCallback, contextCallback) {
  if (repJson.Visible) {
    var legends = [];
    switch(repJson.Type) {
      case 'Symbol':
        if (repJson.DrawingParams.HasCaptionSize)        { legends.push({ name: "Size",        addMethod: "addSizeLegendTo",   propName: "_sizeLegend"   }); }
        if (repJson.DrawingParams.HasCaptionShape)       { legends.push({ name: "Shape",       addMethod: "addShapeLegendTo",  propName: "_shapeLegend"  }); }
        if (repJson.DrawingParams.HasCaptionFilling)     { legends.push({ name: "Filling",     addMethod: "addFillLegendTo",   propName: "_fillLegend"   }); }
        if (repJson.DrawingParams.HasCaptionStroke)      { legends.push({ name: "Stroke",      addMethod: "addStrokeLegendTo", propName: "_strokeLegend" }); }
        if (repJson.DrawingParams.HasCaptionOrientation) { legends.push({ name: "Orientation", addMethod: "addOrientLegendTo", propName: "_orientLegend" }); }
        break;
      case 'Flow':
      case 'Histogram':
      case 'Pie':
      case 'Portion':
        if (repJson.DrawingParams.HasCaption) {
          if (mod.options.fillLegend) { legends.push({ name: "Filling", addMethod: "addFillLegendTo", propName: "_fillLegend" }); }
          if (mod.options.sizeLegend) { legends.push({ name: "Size",    addMethod: "addSizeLegendTo", propName: "_sizeLegend" }); }
        }
        break;
      case 'Fill':
        if (repJson.DrawingParams.HasCaption) { legends.push({ name: "Color", addMethod: "addLegendTo", propName: "_fillLegend" }); }
        break;
      case 'Stroke':
        if (repJson.DrawingParams.HasCaptionColor)     { legends.push({ name: "Color",      addMethod: "addFillLegendTo",  propName: "_fillLegend"  }); }
        if (repJson.DrawingParams.HasCaptionThickness) { legends.push({ name: "Thickness",  addMethod: "addWidthLegendTo", propName: "_widthLegend" }); }
        break;
      case 'Raster':
        break;
      default:
        if (repJson.DrawingParams.HasCaption) { legends.push({ name: "Default", addMethod: "addLegendTo", propName: "_legend" }); }
    }
    legends.forEach(function(legend) {
      var layer = (positions && positions[moduleId] && positions[moduleId][legend.name] && positions[moduleId][legend.name].Container && layers[positions[moduleId][legend.name].Container]) ? layers[positions[moduleId][legend.name].Container] : layers["auto"],
          autosave = false,
          x, y;
      if (positions && positions[moduleId] && positions[moduleId][legend.name] && layers[positions[moduleId][legend.name].Container]) {
        x = positions[moduleId][legend.name].Posx;
        y = positions[moduleId][legend.name].Posy;
      } else { // position par défaut
        if (layer.options.vertical) {
          x = 'left';
          y = 'vert-stacked-top#99';
        } else {
          x = 'right';
          y = 'stacked-top';
        }
        autosave = true;
      }
      mod[legend.addMethod](layer, x, y);
      mod[legend.propName].on('legendmoved', movedCallback.bind(null, moduleId, legend.name));
      mod[legend.propName].on('dblclick', dblclickCallback.bind(null, moduleId, legend.name, mod[legend.propName]));
      mod[legend.propName].on('context', contextCallback.bind(null, moduleId, legend.name, mod[legend.propName]));
      mod[legend.propName].autosave = autosave;
    }, this);
  }
};

var getDataSpaceName = function(dataSpaceId, cartoJson) {
  for (var i = cartoJson.Representations.length - 1; i >= 0; i--) {
     if (cartoJson.Representations[i].Id == dataSpaceId) {
       return cartoJson.Representations[i].Map.Name;
     }
   }
   return __('Tableau de données');
};

/**
 * Initialisation d'un graphique de type secteur (utilisaton de Chartist)
 * @param {object} cartoJSON  description cartoJson du graphique
 * @param {domNode} domNode   conteneur
 * @return {Chartist.Pie}
 */
charts.Pie = function(cartoJSON, domNode) {
  if (! window.Chartist) { return null; }

  var dp = cartoJSON.DrawingParams,
      colors = dp.Colors.slice(0),
      isValid = cartoJSON.Data[0].reduce(function(a, b) { return isFinite(b) && a; }, true),
      series = cartoJSON.Data[0].map(function(val, idx) { return { meta: dp.CaptionValues[idx], value: (isFinite(val) ? val : 0) }; }),
      sum = cartoJSON.Data[0].reduce(function(a, b) { return isFinite(a) ? a + b : b; }, 0),
      drawLabelSuffix = dp.DrawLabelSuffix ? dp.DrawLabelSuffix.replace(/ /g, '\xa0') : "",
      options = {
        chartPadding: 8,
        startAngle: 90,
        showLabel: dp.DrawLabel,
        labelPosition: 'outside',
        donut: dp.WithHole,
        donutWidth: (100 - (dp.WithHole ? dp.HoleRatio : 100)) + '%',
        donutSolid: true,
        width: 275,
        height: 200,
        plugins: [Chartist.plugins.tooltip({
          appendToBody: true,
          tooltipFnc: function(meta, value) {
            return (meta ? meta + '<br/>' : '') +
              '<span class="chartist-tooltip-value">' +
              stringFormatter.round(value, dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) +
              drawLabelSuffix +
              '</span>';
          }
        })]
      };
  // chartist dessine dans le sens horaire donc on inverse les données pour faire comme desktop
  series.reverse();
  colors.reverse();

  if (dp.DrawLabel) {
    if (dp.DrawLabelInside) {
      options.labelPosition = 'center';
      options.labelOffset = (Math.min(options.width, options.height) / 2 - options.chartPadding) * 0.75;
    }
    options.labelInterpolationFnc = function(value) {
      var val = dp.DrawLabelAsPercent ? value / sum * 100 : value;
      val = stringFormatter.round(val, dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
      return val + (dp.DrawLabelAsPercent ? '%' : drawLabelSuffix);
    };
  }

  // Titre
  var title = "",
      legend = [];
  if (cartoJSON.Title) {
    title = '<h3 style="text-align:center;">' + cartoJSON.Title + '</h3>';
  }

  // Légende
  if (dp.HasCaption) {
    var col = 1,
        line = 1,
        lcount = Math.floor(dp.CaptionValues.length / dp.CaptionColumnsNumber),
        remain = dp.CaptionValues.length - lcount * dp.CaptionColumnsNumber,
        captionValues = dp.CaptionValues,
        colors2 = dp.Colors,
        tdStyle = dp.CaptionTextPosition == 'Under' ? ' style="text-align: center"' : '',
        shape;
    legend.push('<table class="chart-legend"><tbody><tr><td' + tdStyle + '>');
    if (dp.CaptionReverseOrder) {
      captionValues = captionValues.slice(0);
      captionValues.reverse();
      colors2 = colors2.slice(0);
      colors2.reverse();
    }
    for(var i = 0; i < captionValues.length; i++) {
      if (dp.CaptionSymbolType == 'ThreeQuarters') {
        shape = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="m 7.9,15.5 c -9.9,0 -9.9,-15 0,-15 0,0 7.6,0 7.6,7.5 H 7.9 Z" style="fill:' + colors2[i % colors2.length] + ';stroke:' + dp.StrokeColor + ';stroke-width:' + (dp.HasStroke ? 1 : 0) + ';" /></svg>';
      } else {
        shape = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><circle cx="8" cy="8" r="7.5" style="fill:' + colors2[i % colors2.length] + ';stroke:' + dp.StrokeColor + ';stroke-width:' + (dp.HasStroke ? 1 : 0) + ';" /></svg>';
      }
      legend.push('<span class="legend-item' + (dp.CaptionTextPosition == 'Right' ? ' pos-right' : '') + '">' + shape + (dp.CaptionTextPosition == 'Under' ? '<br/>' : '&nbsp;') + captionValues[i] + '</span>');
      line++;
      if (line > lcount + (col <= remain ? 1 : 0)) {
        line = 1;
        col++;
        legend.push('</td><td' + tdStyle + '>');
      }
    }
    legend.push('</td></tr></tbody></table>');
  }

  domNode.innerHTML = title + legend.join('');
  var chart = new Chartist.Pie(domNode, { series: isValid ? series : [] }, options);
  chart.titleHTML = title;
  chart.legendHTML = legend.join('');
  chart.on('draw', function(context) {
    var style;
    if (context.type === 'slice') {
      style = 'fill: ' + colors[context.index % dp.Colors.length] + ';';
      if (dp.HasStroke) {
        style += ' stroke: ' + dp.StrokeColor + '; stroke-width: ' + (dp.StrokeWidth || 1) + 'px;';
      }
      context.element.attr({ style: style });
    }
    if (context.type === 'label') {
      context.element.attr({ 'style': 'pointer-events: none; fill:' + dp.DrawLabelColor + ';' });
      if (dp.DrawLabelHasBackground) {
        try { // getBBox lève une erreur NS_ERROR_FAILURE sous firefox si le conteneur est en display:none
          var bbox = context.element._node.getBBox();
          context.group.append(new Chartist.Svg('rect', { x: bbox.x - 4, y: bbox.y - 4, width: bbox.width + 8, height: bbox.height + 8, fill: dp.DrawLabelBackgroundColor, style: "pointer-events: none;" }), true);
        } catch(e) { } // eslint-disable-line no-empty
      }
    }
  });

  return chart;
};

/**
 * Initialisation d'un graphique de type histogramme (utilisaton de Chartist)
 * @param {object} cartoJSON  description cartoJson du graphique
 * @param {domNode} domNode   conteneur
 * @return {Chartist.Bar}
 */
charts.Bar = function(cartoJSON, domNode) {
  if (! window.Chartist) { return null; }

  function transpose(array) {
    return array.reduce(function (prev, next) {
      return next.map(function (item, i) {
        return (prev[i] || []).concat(next[i]);
      });
    }, []);
  }

  function computeTicks(min, max, ticksInterval, originZero, isStacked, isManual) {
    // calcul des valeurs à placer
    var ticks = [];
    var range = (max - min);
    var tickCount;
    switch (ticksInterval)
    {
      case "Small":
        tickCount = 7;
        break;
      case "Large":
        tickCount = 3;
        break;
      case "Medium":
      default:
        tickCount = 5;
        break;
    }

    var unroundedTickSize = range / (tickCount - 1);
    var roundedTickRange = unroundedTickSize;
    var zeroY = 0;

    if (isManual) {
      zeroY = min;
    } else {
      var valx = Math.ceil((Math.log(unroundedTickSize) * Math.LOG10E) - 1);
      var pow10x = Math.pow(10, valx);
      roundedTickRange = Math.round(unroundedTickSize / pow10x) * pow10x;
      // on prend le max au-dessus qui est un multiple de roundedTickRange
      var roundedTickRangePow = roundedTickRange * pow10x;
      var maxTick = max;
      if (max * pow10x % roundedTickRangePow > 0)
        max = (max * pow10x + (roundedTickRangePow - max * pow10x % roundedTickRangePow)) / pow10x;
      else if (max * pow10x % roundedTickRangePow < 0)
        max = (max * pow10x - max * pow10x % roundedTickRangePow) / pow10x;

      // on prend le min au-dessous qui est un multiple de roundedTickRange
      var minTick = min;
      if (min  * pow10x % roundedTickRangePow > 0)
        min = (min * pow10x - min * pow10x % roundedTickRangePow) / pow10x;
      else if (min * pow10x % roundedTickRangePow < 0)
        min = (min * pow10x - (roundedTickRangePow + min * pow10x % roundedTickRangePow)) / pow10x;

      if (min * max >= 0 && min >= 0) // que des positifs
      {
        // si la barre fait moins d'un quart d'un intervalle, on va à l'intervalle suivant
        if ((minTick - min) < roundedTickRange / 4 && !isStacked && !originZero && min>0){
          min -= roundedTickRange;
        }
        zeroY = min;
      }
      else if (min * max >= 0 && min <= 0) // que des négatifs
      {
        // si la barre fait moins d'un quart d'un intervalle, on va à l'intervalle suivant
        if ((max - maxTick) < roundedTickRange / 4 && !isStacked && !originZero && max<0){
          max += roundedTickRange;
        }
        zeroY = max;
      }
    }
    var nbTickPos = Math.round((max - zeroY)/ roundedTickRange);
    var nbTickNeg = Math.round((min - zeroY)/ roundedTickRange);
    var nbDecPow10 = Math.pow(10,countDecimals(pow10x));
    for (cpt = nbTickNeg; cpt <= nbTickPos; cpt++)
    {
      ticks.push(zeroY + Math.round(nbDecPow10*cpt*roundedTickRange)/nbDecPow10);
    }
    return ticks;
  }

  function countDecimals(value) {
    if (isFinite(value) && Math.floor(value) !== value)
      return value.toString().split(".")[1].length || 0;
    return 0;
  }

  var minValue = 0, maxValue = 0;
  if(cartoJSON.DrawingParams.IsStacked){
    minValue = cartoJSON.Data.reduce(function(c, d) { return Math.min(d.reduce(function(a, b) { return Math.min(a, 0) + Math.min(b,0); }, 0) , c); }, 0);
    maxValue = cartoJSON.Data.reduce(function(c, d) { return Math.max(d.reduce(function(a, b) { return Math.max(a, 0) + Math.max(b,0); }, 0) , c); }, 0);
  } else {
    var values = [].concat.apply([], cartoJSON.Data);
    minValue = Math.min.apply(null,values);
    maxValue = Math.max.apply(null,values);
  }

  var dp = cartoJSON.DrawingParams,
      colors = dp.Colors.slice(0),
      axisLabels = dp.DrawAxisLabel ? dp.AxisLabels || [] : [],
      drawLabelSuffix = dp.DrawLabelSuffix ? dp.DrawLabelSuffix.replace(/ /g, '\xa0') : "",
      labelWidth = 5,
      hasGroup = cartoJSON.Data.length > 1 || dp.IsStacked,
      isValid = cartoJSON.Data.reduce(function(c, d) { return d.reduce(function(a, b) { return isFinite(b) && a; }, true) && c; }, true),
      series = hasGroup ?
        transpose(
          cartoJSON.Data.map(
            function(serie, idx) {
              return serie.map(
                function(val, idx2) {
                  return {
                    meta: (axisLabels[idx] ? (axisLabels[idx] + ' - ') : '') + dp.CaptionValues[idx2],
                    value: (isFinite(val) ? val : 0)
                  };
                }
              )
            }
          )
        ) :
        [cartoJSON.Data[0].map(
          function(val, idx) {
            return { meta: dp.CaptionValues[idx], value: (isFinite(val) ? val : 0) };
          }
        )],

      strokeWidth = dp.StrokeWidth || 1,
      options = {
        stackBars: dp.IsStacked,
        width: 275,
        height: 200,
        axisX: {
          offset: dp.DrawAxisLabel ? 30 : 0,
          showLabel: dp.DrawAxisLabel,
          showGrid: dp.HasScale
        },
        axisY: {
          offset: labelWidth,
          showLabel: dp.HasScale,
          showGrid: dp.HasScale
        },
        plugins: [Chartist.plugins.tooltip({
          appendToBody: true,
          tooltipFnc: function(meta, value) {
            return (meta ? meta + '<br/>' : '') +
              '<span class="chartist-tooltip-value">' +
              stringFormatter.round(value,  dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) +
              drawLabelSuffix +
              '</span>';
          }
        })]
      };

  if(dp.HasScale){
    // on impose l'origine à zéro dans les cas suivants
    // - origine à zéro ou histo empilé, avec des valeurs toutes positives ou négatives
    // - origine automatique mais que des valeurs identiques
    if (dp.AxisMinMax) dp.AxisOriginZero = dp.AxisMinMax == "Zero"; // retrocompatibilité
    if((dp.AxisOriginZero || dp.IsStacked) && minValue * maxValue > 0 ||
       !dp.AxisOriginZero && minValue === maxValue){
      if (maxValue > 0)
        minValue = 0;
      else
        maxValue = 0;
    }
    if (dp.AxisMinMax == "Manual") {
      minValue = dp.AxisMin;
      maxValue = dp.AxisMax;
    }
    ticks = computeTicks(minValue, maxValue, dp.ScaleIntervalSize, dp.AxisOriginZero, dp.IsStacked, dp.AxisMinMax == "Manual");
    if(ticks.length > 1){

      // rendu temporaire dans un canvas pour calculer la taille des labels de l'axe Y
      var ctx = document.createElement('canvas').getContext("2d");
      var style = window.getComputedStyle(domNode,null);
      ctx.font = style.getPropertyValue("font-size") + " " + style.getPropertyValue("font-family")
      var minTickText = stringFormatter.round(ticks[0], dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix;
      var maxTickText = stringFormatter.round(ticks[ticks.length - 1], dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix;
      labelWidth = Math.max(ctx.measureText(minTickText).width, ctx.measureText(maxTickText).width) + 5; // marge de 5px

      options.axisY = {
        type: Chartist.FixedScaleAxis,
        offset: labelWidth,
        showLabel: dp.HasScale,
        showGrid: dp.HasScale,
        ticks : ticks
      };
      options.low = ticks[0];
      options.high = ticks[ticks.length - 1];
    }
  }

  // ajustement des largeurs des barres
  var barCount = series.length ? ((hasGroup && ! dp.IsStacked) ? series[0].length * (series.length + 1) - 1 : series[0].length) : 1; // si aucune donnée, on initialise barCount à 1 (et pas à zéro pour éviter les divisions par 0)
  var barWidth = Math.round((275 - (dp.DrawAxisLabel ? labelWidth : 5)) / barCount);
  options.seriesBarDistance = barWidth;



  // Titre
  var title = "",
      legend = [];
  if (cartoJSON.Title) {
    title = '<h3 style="text-align:center;">' + cartoJSON.Title + '</h3>';
  }

  // Légende
  if (dp.HasCaption) {
    var col = 1,
        line = 1,
        lcount = Math.floor(dp.CaptionValues.length / dp.CaptionColumnsNumber),
        remain = dp.CaptionValues.length - lcount * dp.CaptionColumnsNumber,
        captionValues = dp.CaptionValues,
        colors2 = dp.Colors,
        tdStyle = dp.CaptionTextPosition == 'Under' ? ' style="text-align: center"' : '',
        shape;
    legend.push('<table class="chart-legend"><tbody><tr><td' + tdStyle + '>');
    if (dp.CaptionReverseOrder) {
      captionValues = captionValues.slice(0);
      captionValues.reverse();
      colors2 = colors2.slice(0);
      colors2.reverse();
    }
    for(var i = 0; i < captionValues.length; i++) {
      if (dp.HasStroke) {
        shape = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><rect x="0.5" y="0.5" width="15" height="15" style="fill:' + colors2[i % colors2.length] + ';stroke:' + dp.StrokeColor + ';stroke-width:1;" /></svg>';
      }else {
        shape = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><rect x="0" y="0" width="16" height="16" style="fill:' + colors2[i % colors2.length] + ';" /></svg>';
      }
      legend.push('<span class="legend-item' + (dp.CaptionTextPosition == 'Right' ? ' pos-right' : '') + '">' + shape + (dp.CaptionTextPosition == 'Under' ? '<br/>' : '&nbsp;') + captionValues[i] + '</span>');
      line++;
      if (line > lcount + (col <= remain ? 1 : 0)) {
        line = 1;
        col++;
        legend.push('</td><td' + tdStyle + '>');
      }
    }
    legend.push('</td></tr></tbody></table>');
  }

  if (dp.DrawRefValue) {
    options.plugins.push(Chartist.plugins.ctGoalLine({ value: dp.RefValueVal || 0, text: dp.RefValueText, color: dp.RefValueColor, thickness: dp.RefValueThickness || 1 }));
  }

  domNode.innerHTML = title + legend.join('');
  var chart = new Chartist.Bar(domNode, { series: isValid ? series : [], labels: axisLabels }, options),
      node, x1, y1, x2, y2;
  chart.titleHTML = title;
  chart.legendHTML = legend.join('');
  chart.on('draw', function(context) {
    switch (context.type) {
    case 'bar' :
      // il faut attendre que chartRect soit disponible pour calculer correctement barWidth et l'option seriesBarDistance
      // donc on déclenche un nouveau rendu si la valeur de barWidth calculée change (lors du 1er rendu ou lors d'un redimensionnement)
      if (Math.round(context.chartRect.width() / barCount) != barWidth) {
        barWidth = Math.round(context.chartRect.width() / barCount);
        chart.invalidated = true;
        setTimeout(function() { chart.invalidated = false; chart.update(null, { seriesBarDistance: barWidth }, true); }, 0);
      }
      if (chart.invalidated) {
        // si un nouveau rendu va avoir lieu immédiatement, on cache les éléments pour éviter un clignotement
        context.element.attr({ opacity: 0 });
        return;
      }

      node = context.element._node;
      x1 = parseFloat(node.getAttribute("x1"));
      y1 = parseFloat(node.getAttribute("y1"));
      y2 = parseFloat(node.getAttribute("y2"));
      if (dp.HasStroke) {
        // on supprime la ligne créée par chartist et on la remplace par un rectangle afin d'avoir des bordures
        context.group.append(new Chartist.Svg('rect', {
          x: Math.floor(x1 - barWidth / 2) + 0.5, // on décale de 0.5px pour avoir un trait net
          y: Math.min(y1, y2),
          width: barWidth,
          height: Math.abs(y2 - y1),
          fill: colors[(hasGroup ? context.seriesIndex : context.index) % dp.Colors.length],
          stroke: dp.StrokeColor,
          "stroke-width": strokeWidth,
          style: "pointer-events: none;"
        }), true);
        context.element.attr({ opacity: 0, style: 'stroke-width: ' + barWidth + 'px;' }); // on garde l'élément en opacité 0 pour que les tooltips fonctionnent normalement
      } else {
        context.element.attr({ style: 'stroke: ' + colors[(hasGroup ? context.seriesIndex : context.index) % dp.Colors.length] + '; stroke-width: ' + barWidth + 'px;' });
      }
      if (dp.DrawLabel && (! dp.IsStacked || Math.abs(y2 - y1) > 10)) {
        if (context.value.y !== '0') {
          label = new Chartist.Svg('text');
          label.text(stringFormatter.round(context.value.y, dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix);
          label.addClass("ct-label");
          label.attr({
            x: x1,
            y: dp.IsStacked ? Math.round((y2 + y1) / 2) + 5 : Math.floor(Math.min(y1, y2)) - 5,
            'text-anchor': 'middle',
            style: "pointer-events: none; fill: " + dp.DrawLabelColor + "; text-shadow: none;"
          });
          context.group.append(label);
          try { // getBBox lève une erreur NS_ERROR_FAILURE sous firefox si le conteneur est en display:none
            var bbox = label._node.getBBox();
            // si le libellé dépasse du conteneur (on est obligé de le créé pour le savoir)
            if (bbox.width > barWidth) {
              label.remove();
            }
          } catch(e) { } // eslint-disable-line no-empty
        }
      }
      break;
    case "grid" :
      node = context.element._node;
      x1 = parseFloat(node.getAttribute("x1"));
      x2 = parseFloat(node.getAttribute("x2"));
      y1 = parseFloat(node.getAttribute("y1"));
      y2 = parseFloat(node.getAttribute("y2"));
      context.element.attr({
        style: 'stroke: ' + dp.AxisColor,
        x1: Math.floor(x1) + 0.5, // on arrondit les coordonnées pour éviter les flous
        y1: Math.floor(y1) + 0.5,
        x2: Math.floor(x2) + 0.5,
        y2: Math.floor(y2) + 0.5
      });
      break;
    case "label" :
      if (context.element._node.children && context.element._node.children[0] && context.element._node.children[0].tagName == "SPAN") {
        if(!context.element._node.children[0].classList.contains('ct-horizontal')){
          context.element._node.children[0].innerHTML = stringFormatter.round(context.element._node.children[0].innerHTML,  dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix
        }
        context.element._node.children[0].style.color = dp.AxisLabelColor;
      } else if (context.element._node.tagName == "text") { // support IE 
        if(context.element._node.getAttribute('class').indexOf('ct-horizontal') == -1 ){
          context.element._node.textContent = stringFormatter.round(context.element._node.textContent,  dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix
        }
        context.element._node.style.fill = dp.AxisLabelColor;
      }
      break;
    }
  });

  return chart;
};

/**
 * Initialisation d'un graphique de type Courbe (utilisaton de Chartist)
 * @param {object} cartoJSON  description cartoJson du graphique
 * @param {domNode} domNode   conteneur
 * @return {Chartist.Bar}
 */
charts.Plot = function(cartoJSON, domNode) {
  if (! window.Chartist) { return null; }

  function transpose(array) {
    return array.reduce(function (prev, next) {
      return next.map(function (item, i) {
        return (prev[i] || []).concat(next[i]);
      });
    }, []);
  }

  function computeTicks(min, max, ticksInterval, isManual) {
    // calcul des valeurs à placer
    var ticks = [];
    var range = (max - min);
    var tickCount;
    switch (ticksInterval)
    {
      case "Small":
        tickCount = 7;
        break;
      case "Large":
        tickCount = 3;
        break;
      case "Medium":
      default:
        tickCount = 5;
        break;
    }

    var unroundedTickSize = range / (tickCount - 1);
    var roundedTickRange = unroundedTickSize;
    var zeroY = 0;

    if (isManual) {
      zeroY = min;
    } else {
      var valx = Math.ceil((Math.log(unroundedTickSize) * Math.LOG10E) - 1);
      var pow10x = Math.pow(10, valx);
      roundedTickRange = Math.round(unroundedTickSize / pow10x) * pow10x;
      // on prend le max au-dessus qui est un multiple de roundedTickRange
      var roundedTickRangePow = roundedTickRange * pow10x;
      if (max * pow10x % roundedTickRangePow > 0)
        max = (max * pow10x + (roundedTickRangePow - max * pow10x % roundedTickRangePow)) / pow10x;
      else if (max * pow10x % roundedTickRangePow < 0)
        max = (max * pow10x - max * pow10x % roundedTickRangePow) / pow10x;

      // on prend le min au-dessous qui est un multiple de roundedTickRange
      if (min  * pow10x % roundedTickRangePow > 0)
        min = (min * pow10x - min * pow10x % roundedTickRangePow) / pow10x;
      else if (min * pow10x % roundedTickRangePow < 0)
        min = (min * pow10x - (roundedTickRangePow + min * pow10x % roundedTickRangePow)) / pow10x;
    }

    var nbTickPos = Math.round((max - zeroY)/ roundedTickRange);
    var nbTickNeg = Math.round((min - zeroY)/ roundedTickRange);
    var nbDecPow10 = Math.pow(10,countDecimals(pow10x));
    for (cpt = nbTickNeg; cpt <= nbTickPos; cpt++)
    {
      ticks.push(zeroY + Math.round(nbDecPow10*cpt*roundedTickRange)/nbDecPow10);
    }
    return ticks;
  }

  function countDecimals(value) {
    if (isFinite(value) && Math.floor(value) !== value)
      return value.toString().split(".")[1].length || 0;
    return 0;
  }

  var values = [].concat.apply([], cartoJSON.Data);
  minValue = Math.min.apply(null,values);
  maxValue = Math.max.apply(null,values);

  var dp = cartoJSON.DrawingParams,
      axisLabels = dp.DrawAxisLabel ? dp.AxisLabels || [] : [],
      drawLabelSuffix = dp.DrawLabelSuffix ? dp.DrawLabelSuffix.replace(/ /g, '\xa0') : "",
      isValid = cartoJSON.Data.reduce(function(c, d) { return d.reduce(function(a, b) { return isFinite(b) && a; }, true) && c; }, true),
      hasGroup = cartoJSON.Data.length > 1,
      colors = dp.Colors.slice(0),
      series = hasGroup ?
        transpose(
          cartoJSON.Data.map(
            function(serie, idx) {
              return serie.map(
                function(val, idx2) {
                  return {
                    meta: (axisLabels[idx] ? (axisLabels[idx] + ' - ') : '') + dp.CaptionValues[idx2],
                    value: (isFinite(val) ? val : 0)
                  };
                }
              )
            }
          )
        ) :
        [cartoJSON.Data[0].map(
          function(val, idx) {
            return { meta: axisLabels[idx], value: (isFinite(val) ? val : 0) };
          }
        )],
      strokeWidth = dp.PlotsWidth || 1,
      options = {
        lineSmooth: false,
        fullWidth: true,
        showPoint: dp.ShowPlotPoints,
        width: 275,
        height: 200,
        chartPadding: { top: 15, right: dp.DrawAxisLabel ? 40 : 15, bottom: 5, left: 10 },
        axisX: {
          offset: dp.DrawAxisLabel ? 30 : 0,
          showLabel: dp.DrawAxisLabel,
          stretch: true,
          showGrid: dp.HasScale
        },
        axisY: {
          offset: 5,
          showLabel: dp.HasScale,
          showGrid: dp.HasScale
        },
        low: 0,
        plugins: [Chartist.plugins.tooltip({
          appendToBody: true,
          tooltipFnc: function(meta, value) {
            return (meta ? meta + '<br/>' : '') +
              '<span class="chartist-tooltip-value">' +
              stringFormatter.round(value,  dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) +
              drawLabelSuffix +
              '</span>';
          }
        })]
      };

  if(dp.HasScale){
    // on impose l'origine à zéro dans les cas suivants
    // - origine à zéro avec des valeurs toutes positives ou négatives
    // - origine automatique mais que des valeurs identiques
    if (dp.AxisMinMax) dp.AxisOriginZero = dp.AxisMinMax == "Zero"; // retrocompatibilité
    if(dp.AxisOriginZero && minValue * maxValue > 0 ||
       !dp.AxisOriginZero && minValue === maxValue){
      if (maxValue > 0)
        minValue = 0;
      else
        maxValue = 0;
    }
    if (dp.AxisMinMax == "Manual") {
      minValue = dp.AxisMin;
      maxValue = dp.AxisMax;
    }
    ticks = computeTicks(minValue, maxValue, dp.ScaleIntervalSize, dp.AxisMinMax == "Manual");
    if(ticks.length > 1){

      // rendu temporaire dans un canvas pour calculer la taille des labels de l'axe Y
      var ctx = document.createElement('canvas').getContext("2d");
      var style = window.getComputedStyle(domNode,null);
      ctx.font = style.getPropertyValue("font-size") + style.getPropertyValue("font-family")
      var minTickText = stringFormatter.round(ticks[0], dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix;
      var maxTickText = stringFormatter.round(ticks[ticks.length - 1], dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix;
      labelWidth = Math.max(ctx.measureText(minTickText).width, ctx.measureText(maxTickText).width) + 5; // marge de 5px

      options.axisY = {
        type: Chartist.FixedScaleAxis,
        offset: labelWidth,
        showLabel: dp.HasScale,
        showGrid: dp.HasScale,
        ticks: ticks,
        low: ticks[0],
        high: ticks[ticks.length - 1]
      };
    }
  }

  // Titre
  var title = "",
      legend = [];
  if (cartoJSON.Title) {
    title = '<h3 style="text-align:center;">' + cartoJSON.Title + '</h3>';
  }

  // Gestion des cas sans dimension => tous les points de la courbe sont de la même couleur
  if (! hasGroup && colors.length) {
    for(var k = colors.length - 1; k > 0; k--) {
      colors[k] = colors[0];
    }
  }

  // Légende
  if (dp.HasCaption && hasGroup) {
    var col = 1,
        line = 1,
        lcount = Math.floor(dp.CaptionValues.length / dp.CaptionColumnsNumber),
        remain = dp.CaptionValues.length - lcount * dp.CaptionColumnsNumber,
        captionValues = dp.CaptionValues,
        colors2 = dp.Colors,
        tdStyle = dp.CaptionTextPosition == 'Under' ? ' style="text-align: center"' : '',
        shape;
    legend.push('<table class="chart-legend"><tbody><tr><td' + tdStyle + '>');
    if (dp.CaptionReverseOrder) {
      captionValues = captionValues.slice(0);
      captionValues.reverse();
      colors2 = colors2.slice(0);
      colors2.reverse();
    }
    for(var i = 0; i < captionValues.length; i++) {
      shape = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M0,9.5L16,9.5" style="stroke:' + colors2[i % colors2.length] + '; stroke-width:' + strokeWidth + 'px;" /></svg>';
      legend.push('<span class="legend-item' + (dp.CaptionTextPosition == 'Right' ? ' pos-right' : '') + '">' + shape + (dp.CaptionTextPosition == 'Under' ? '<br/>' : '&nbsp;') + captionValues[i] + '</span>');
      line++;
      if (line > lcount + (col <= remain ? 1 : 0)) {
        line = 1;
        col++;
        legend.push('</td><td' + tdStyle + '>');
      }
    }
    legend.push('</td></tr></tbody></table>');
  }

  if (dp.DrawRefValue) {
    options.plugins.push(Chartist.plugins.ctGoalLine({ value: dp.RefValueVal || 0, text: dp.RefValueText, color: dp.RefValueColor, thickness: dp.RefValueThickness || 1 }));
  }
  
  domNode.innerHTML = title + legend.join('');
  var chart = new Chartist.Line(domNode, { series: isValid ? series : [], labels: axisLabels }, options);
  chart.titleHTML = title;
  chart.legendHTML = legend.join('');
  chart.on('draw', function(context) {
    switch (context.type) {
    case 'point' :
      if (dp.ChartPlotPointsStyle == "circle") {
        context.element.attr({ style: 'stroke: ' + colors[(hasGroup ? context.seriesIndex : context.index) % dp.Colors.length] + ';' });
      } else {
        var shape = null;
        switch (dp.ChartPlotPointsStyle) {
          case "square" :
            shape = ['M', context.x - 6, context.y - 6, 'L', context.x - 6, context.y + 6, 'L', context.x + 6, context.y + 6, 'L', context.x + 6, context.y - 6, 'z'];
            break;
          case "triangleUp" :
            shape = ['M', context.x, context.y - 8, 'L', context.x - 8, context.y + 4, 'L', context.x + 8, context.y + 4, 'z'];
            break;
          case "triangleDown" :
            shape = ['M', context.x, context.y + 8, 'L', context.x - 8, context.y - 4, 'L', context.x + 8, context.y - 4, 'z'];
            break;
          case "diamond" :
            shape = ['M', context.x, context.y - 8, 'L', context.x - 8, context.y, 'L', context.x, context.y + 8, 'L', context.x + 8, context.y, 'z'];
            break;
        }
        if (shape) {
          context.element.replace(
            new Chartist.Svg(
              'path',
              {
                "ct:value": context.value.y, // pour les tooltips
                "ct:meta": context.meta, // pour les tooltips
                d: shape.join(' '), // forme
                style: 'fill: ' + colors[(hasGroup ? context.seriesIndex : context.index) % dp.Colors.length] + '; stroke-width: 0;' // style avec remplissage et sans contours
              },
              'ct-point' // classe nécessaire pour les tooltips
            )
          );
        }
      }
      if (dp.DrawLabel) {
        if (context.value.y !== '0') {
          label = new Chartist.Svg('text');
          label.text(stringFormatter.round(context.value.y, dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix);
          label.addClass("ct-label");
          label.attr({
            x: context.x,
            y: context.y - 10,
            'text-anchor': 'middle',
            style: "pointer-events: none; fill: " + dp.DrawLabelColor + "; text-shadow: none;"
          });
          context.group.append(label);
        }
      }
      break;
    case "line" :
      context.element.attr({ style: 'stroke: ' + colors[(hasGroup ? context.seriesIndex : context.index) % dp.Colors.length] + '; stroke-width: ' + strokeWidth + 'px;' });
      break;
    case "grid" :
      node = context.element._node;
      x1 = parseFloat(node.getAttribute("x1"));
      x2 = parseFloat(node.getAttribute("x2"));
      y1 = parseFloat(node.getAttribute("y1"));
      y2 = parseFloat(node.getAttribute("y2"));
      context.element.attr({
        style: 'stroke: ' + dp.AxisColor,
        x1: Math.floor(x1) + 0.5, // on arrondit les coordonnées pour éviter les flous
        y1: Math.floor(y1) + 0.5,
        x2: Math.floor(x2) + 0.5,
        y2: Math.floor(y2) + 0.5
      });
      break;
    case "label" :
      
      if (context.element._node.children && context.element._node.children[0] && context.element._node.children[0].tagName == "SPAN") {
        if(!context.element._node.children[0].classList.contains('ct-horizontal')){
          context.element._node.children[0].innerHTML = stringFormatter.round(context.element._node.children[0].innerHTML,  dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix
        }
        context.element._node.children[0].style.color = dp.AxisLabelColor;
      } else if (context.element._node.tagName == "text") { // support IE
        if(context.element._node.getAttribute('class').indexOf('ct-horizontal') == -1 ){
          context.element._node.textContent = stringFormatter.round(context.element._node.textContent,  dp.DrawLabelRoundValue ? dp.DrawLabelRoundNbDecimal : 6, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP) + drawLabelSuffix
        }
        context.element._node.style.fill = dp.AxisLabelColor;
      }
      break;
    }
  });

  return chart;
};

/**
 * Création d'un graphique
 * @param  {object} repJson
 * @param  {DomNode} container
 * @return {Chartist.*}
 */
var toChart = function(repJson, container) {
  if (charts[repJson.Type] && repJson.DrawingParams && repJson.Data && repJson.Data.length) {
    var chartDiv = document.createElement('div');
    chartDiv.className = 'ct-chart';
    chartDiv.id = "__chart" + uniqueId++;
    container.appendChild(chartDiv);
    var chart = charts[repJson.Type](repJson, chartDiv);
    if (chart) {
      chart.name = repJson.DrawingParams.moduleName;
      chart.div = chartDiv;
      chart.repJson = repJson; // copie de la description du graphique pour pouvoir le re-créer plus tard en plein écran
    } else {
      container.removeChild(chartDiv);
    }

    // gestion de la visibilité des graphiques via LayerList
    chart._hidden = false;
    chart.setVisible = function(visible) {
      this._hidden = ! visible;
      this.div.style.display = visible ? "block" : "none";
    };
    chart.isVisible  = function(options) { return (options && options.zoom) || ! this._hidden; }; // pas de notion de zoom par niveau de zoom pour les graphiques
    chart.show = function() { this.setVisible(true); };
    chart.hide = function() { this.setVisible(false); };

    return chart;
  }
  return null;
};


module.exports = function(options) {
  return {
    setUnit: setUnit,
    toComment: toComment,
    toScale: toScale,
    toModule: function(repJson, resources) { return toModule(repJson, resources, options); },
    toChart: toChart,
    toTileLayer: toTileLayer,
    addModuleLegendsTo: addModuleLegendsTo,
    getDataSpaceName: getDataSpaceName
  };
};