var $ = window.$ || window.jQuery,
appBar = {
  tplLayout:  '<div class="app-bar navbar navbar-inverse navbar-fixed-top">' +
                '<div class="container-fluid">' +
                  '{{tplLogo}}'          + // logo CD7
                  '{{tplHelp}}'          + // icone documentation
                  '{{tplBreadcrumb}}'    + // fil d'ariane
                  '{{tplProgress}}'      + // barre de chargement
                '</div>' +
              '</div>',
    tplLogo:          '<a href="/" class="link" title="' + __("tooltip_logo") + '"><span class="navbar-brand hidden-xs bg-logo-103x32"></span><span class="navbar-brand visible-xs-block bg-logo-32"></span></a>',
    tplBreadcrumb:    '<div class="navbar-text breadcrumb-container" id="breadcrumb-container"></div>',
    tplProgress:      '<div id="load-progress" class="readonly-load-progress"></div>',
    tplHelp:          '',

  render: function(parent, domId) {
    if ( parent.options.webView.helpPath) {
      this.tplHelp = '<a target="_blank" href="' + parent.options.webView.helpPath +'"><span class="glyphicon glyphicon-question-sign pull-right readonly-help" aria-hidden="true"></span></a>';
    }
    $('#' + domId).append(parent.renderTpl(this.tplLayout, this));
  },

  getBreadcrumbNode:   function() { return document.getElementById('breadcrumb-container'); },
  getLoadProgressNode: function() { return document.getElementById('load-progress');        }
};

module.exports = appBar;