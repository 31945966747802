(function() {

  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  var ANIM_INTERVALS = [0.25, 0.5, 1, 1.5, 2, 3, 4, 5, 10];
  var DEFAULT_ANIM_INTERVAL = 1.5;

  L.articque.Util.addCss(
    'div.layer-list { margin: 7.5px 0; padding: 0 7.5px;}' +
    'div.layer-group { border-bottom: 1px solid #eee; padding-bottom: 7.5px; }' +
    'div.layer-group .toggle > a { font-weight: normal; float: right; visibility: hidden; opacity: 0; transition: opacity 0.2s linear; color: #999; }' +
    'div.layer-group .toggle > b { display: block; font-weight: normal; float: right; width: auto; height: 20px; overflow: hidden; opacity: 1; transition: width 0s, opacity 0.2s linear; color: #ccc; }' +
    'div.layer-group .toggle > a:hover { color: #2a6496; }' +
    'div.layer-group .toggle > a + a{ margin-right: 10px; }' +
    'div.layer-group .toggle.open > a { opacity: 1;  visibility: visible; }' +
    'div.layer-group .toggle.open > b { opacity: 0;  width: 0; }' +
    'div.layer-group .layer-group-title { cursor: pointer; position: relative; }' +
    'div.layer-group .layer-group-title.toggle { padding-left: 15px; }' +
    'div.layer-group .layer-group-title.toggle:after { content: ""; position: absolute; top: calc(3px + 0.75em); left: 0; width: 0; height: 0; border-style: solid; border-width: 0.4em 0 0.4em 0.5em; border-color: transparent transparent transparent #BBB; transition: all 0.2s linear; }' +
    'div.layer-group .layer-group-title.toggle.open:after { transform: rotate(90deg); }' +
    'div.layer-group:last-child { border-bottom: none; }' +
    'div.layer-group label { display: block; padding: 0.7em 0; margin: 0; }' +
    'div.layer-group label.disabled { color: #999; }' +
    'div.layer-group label select, div.layer-group label select option { font-weight: normal; }' +
    'div.layer-group label.layer { padding: 4px 7.5px 4px 15px; margin: 0; max-width: 300px; font-weight: normal; }' +
    'div.layer-group label.layer:hover { background: #f4f4f4; }' +
    'div.layer-group .legends { margin-left: 32px; }' +
    'div.layer-group .layer-group-title.animated .anim-details:after { content: ""; position: absolute; display: block; width: 0; left: 0; bottom: 0; height: 1px; background: #ddd; }' +
    'div.layer-group select { margin: 4px 0 4px 15px; width: calc(100% - 15px); border: 1px solid #ddd; padding: 4px; }' +
    ANIM_INTERVALS.map(function(interval) {
      return 'div.layer-group .layer-group-title.animated.interval' + interval.toString().replace('.', '_') + ' .anim-details:after { animation: progress ' + interval + 's infinite; }';
    }).join('') +
    'div.layer-group .anim-details { position: relative; display: block; font-weight: normal; padding-top: 8px; height: 48px; line-height: 32px; color: #999 }' +
    'div.layer-group .anim-details .btn { color: #aaa; border-color: #eee; }' +
    'div.layer-group .anim-details .btn:hover, div.layer-group .anim-details .btn:active, div.layer-group .anim-details .btn:focus { background: #f4f4f4; }' +
    'div.layer-group .anim-details .glyphicon-primary { color: #21bbef }' +
    'div.layer-group .slider-tooltip { padding: 5px 15px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden }' +
    'div.layer-group .layer-container.empty-selection .slider-handle { border: 1px solid #ccc; background-color: #eee; }' +
    '@keyframes progress { 0% { width: 0%; } 100% { width: 100%; }}'
  );

  var cleanLabel = function(lbl) {
    return lbl.replace(/^\(#\d+\)\s*/, '').replace(/\\\(#\d+\)\s*/g, '\\');
  };

  var isChecked = function(domNode) {
    if (domNode.tagName == 'INPUT')  return domNode.checked;
    if (domNode.tagName == 'OPTION') return !!domNode.selected;
    if (domNode.tagName == 'TICK')   return domNode.slider._selectedValue == domNode.sliderValue;
    return false;
  }

  var check = function(node, value, groups) {
    if (node.input.tagName == 'INPUT')  node.input.checked  = !! value;
    if (node.input.tagName == 'OPTION') node.input.selected = !! value;
    if (node.input.tagName == 'TICK') {
      if (value) {
        node.input.slider._selectedValue = node.input.sliderValue;
        $(node.input.sliderInput).slider('setValue', node.input.sliderValue);
        node.input.slider.parentNode.parentNode.classList.remove("empty-selection");
      } else if (node.input.slider._selectedValue == node.input.sliderValue) {
        node.input.slider._selectedValue = null;
        node.input.slider.parentNode.parentNode.classList.add("empty-selection");
      }
    }
    // si on coche un radio/select alors, on s'assure que tous les éléments exclusifs sont bien décochés
    if (node.layers && node.layers.length && node.layers[0].radioGroup && groups && value) {
      groups[node.layers[0].radioGroup].forEach(function(node2) {
        if (isChecked(node2.input) && node2.completeName != node.completeName) {
          if (node2.input.tagName == 'INPUT')  node2.input.checked  = false;
          if (node2.input.tagName == 'OPTION') node2.input.selected = false;
          if (node2.input.tagName == 'TICK') {
            node2.input.slider._selectedValue = null;
            node2.input.slider.parentNode.parentNode.classList.add("empty-selection");
          }
        }
      });
    }
  }

  A.layerControl = function(options) { return new A.LayerControl(options); };

  A.LayerControl = L.Class.extend({

    options: {
      container: null,
      parent: null,
      wvc: null,
      layers: [],
    },

    initialize: function(options) {
      L.Util.setOptions(this, options);

      this._animations = [];
      this.setLayers(this.options.layers);
      this._container = this.options.container;
      this._events = [];
      this._groups = {};
      this._groupStates = {};
      this._counts = [];
      this._layerlistBehavior = "open";
      this._animationLoop = "loop";
      if (this._container) {
        this._layout();
      }
      if (this.options.wvc) {
        this.options.wvc.on('getscreenshotlayers', this.prepareScreenshot.bind(this));
      }
    },

    setLayers: function(layers) {
      this._clearAnimations();
      this._layerTree = this.buildTree(layers);
      if (this._container) {
        this._layout();
      }
    },

    addEvent: function(domNode, eventName, callback) {
      var evt = { domNode: domNode, eventName: eventName, callback: callback };
      L.DomEvent.on(domNode, eventName, evt.callback);
      this._events.push(evt);
    },

    clearEvents: function() {
      this._events.forEach(function(evt) {
        L.DomEvent.off(evt.domNode, evt.eventName, evt.callback);
      }, this);
      this._events = [];
    },

    prepareScreenshot: function(data) {
      if (data.options.legends) {
        var legendGroup = L.articque.legendGroup({ vertical: true, width: 300 }),
            buildGroup = function(node) {
              var name = node.name.replace(/^\(#\d+\)\s*/, ''), // "(#2) nom" => "nom" (permet de gérer l'ordre des éléments)
                  rmlTitle;
              if (node.children) {
                if (node.children.filter(function(child){ return child.layers[0].module.isVisible(); }).length) {
                  rmlTitle = L.articque.rmlTitle({ title: name, borderColor: 'transparent' });
                  legendGroup.add(rmlTitle);
                  rmlTitle.setPosition('left', 'vert-stacked-top');
                  node.children.map(buildGroup);
                }
              } else {
                if (node.input && isChecked(node.input) && node.legendLayer && node.legendLayer._modules.length) {
                  rmlTitle = L.articque.rmlTitle({ title: name, borderColor: 'transparent', titleFont: '12px Arial' });
                  legendGroup.add(rmlTitle);
                  rmlTitle.setPosition('left', 'vert-stacked-top');
                  node.legendLayer._modules.map(legendGroup.add.bind(legendGroup));
                }
              }
            };

        this._layerTree.map(buildGroup);
        data.layers.push(legendGroup);
      }
    },

    buildTree: function(layers) {
      var tree = [];

      function insert(path, nodes) {
        var node, i, p;
        if (path.length == 1) {
          for (i = nodes.length - 1; i >= 0; i--) {
            if (nodes[i].name == path[0]) {
              return nodes[i];
            }
          }
          node = { name: path[0], layers: [] };
          nodes.push(node);
          return node;
        } else {
          for (i = 0; i < nodes.length; i++) {
            if (nodes[i].name == path[0]) {
              p = path.shift();
              if (nodes[i].children) {
                return insert(path, nodes[i].children);
              } else { // on passe par un noeud qui est déjà une feuille de l'arbre => pas possible
                path[0] = p + '\\' + path[0];
                return insert(path, nodes);
              }
            }
          }
          node = { name: path[0], children: [] };
          nodes.push(node);
          path.shift();
          return insert(path, node.children);
        }
      }

      function sort(nodes) {
        function sortFunc(a, b) {
          if (a.layers && a.layers[0] && a.layers[0].radioGroup && b.layers && b.layers[0] && b.layers[0].radioGroup) { // entre 2 radios
            return (a.layers[0].radioGroup + a.name < b.layers[0].radioGroup + b.name) ? -1 : 1;
          } else if (a.layers && a.layers[0] && a.layers[0].radioGroup) { // entre un radio et un checkbox
            return -1;
          } else if (b.layers && b.layers[0] && b.layers[0].radioGroup) { // entre un checkbox et un radio
            return 1;
          }
          return (a.name < b.name) ? -1 : 1;
        }
        nodes.sort(sortFunc);
        nodes.forEach(function(node) { if (node.children) sort(node.children); });
      }

      for (var i = 0; i < layers.length; i++) {
        var node = insert(layers[i].name.split('\\'), tree);
        node.completeName = layers[i].name;
        node.checkedByDefault = layers[i].checkedByDefault;
        node.isAnimated = !!layers[i].radioGroup && !!layers[i].isAnimated;
        node.isDropDown = !!layers[i].radioGroup && !!layers[i].isDropDown;
        node.isSlider   = !!layers[i].radioGroup && !!layers[i].isSlider;
        node.layers.push(layers[i]);
      }

      sort(tree);
      return tree;
    },

    _onClick: function(node, evt) {
      var i;
      if (node.layers && node.layers.length) {
        if (node.layers[0].radioGroup) {
          if (this._groupStates[node.layers[0].radioGroup] == node.completeName) { // si on clique sur un radio déjà coché
            check(node, false);
            delete this._groupStates[node.layers[0].radioGroup];
          } else {
            check(node, true, this._groups)
            this._groupStates[node.layers[0].radioGroup] = node.completeName;
          }
        }
        // couche
        if (node.layers[0].radioGroup && this._groups[node.layers[0].radioGroup]) {
          var defaultValue = '__none__',
              checkedNode = null;
          this._groups[node.layers[0].radioGroup].forEach(function(node) { if (node.checkedByDefault) defaultValue = cleanLabel(node.completeName); });
          this._groups[node.layers[0].radioGroup].forEach(function(node) {
            if (this._isVisible(node.layers[0]) != isChecked(node.input)) {
              if (isChecked(node.input)) {
                checkedNode = node;
                this._updateCookie(node.layers[0].radioGroup, defaultValue, cleanLabel(node.completeName));
              }
              for (i = node.layers.length - 1; i >= 0; i--) {
                this._setVisible(node.layers[i], isChecked(node.input), node.parent);
              }
            }
          }, this);
          if (! checkedNode) { // cas où on décoche tous les boutons radio
            this._updateCookie(node.layers[0].radioGroup, defaultValue, '__none__');
          }
        } else {
          this._updateCookie(node.completeName, node.checkedByDefault, isChecked(node.input));
          for (i = node.layers.length - 1; i >= 0; i--) {
            this._setVisible(node.layers[i], isChecked(node.input), node.parent);
          }
        }
      }
      if (evt) {
        this._disableAnimations();
        L.DomEvent.stop(evt);
      }
      setTimeout(this._refresh.bind(this), 0);
    },

    _onSelect: function(select, evt) {
      if (select.value == "__empty__") {
        // pour se rapprocher du fonctionnement des boutons radio, on simule un clic sur l'option précédemment sélectionnée
        this._onClick(select._nodes[this._groupStates[select.radioGroup]], evt);
      } else {
        this._onClick(select._nodes[select.value], evt);
      }
    },

    _onSlide: function(slider, data) {
      if (slider._selectedValue != data.value) {
        slider.tooltipDiv.innerHTML = slider._nodes[data.value].tooltip;
        slider._selectedValue = data.value;
        this._onClick(slider._nodes[data.value], data);
      }
    },

    _onAllClick: function(node, visible, evt) {
      if (node.children) {
        for (var i = 0; i < node.children.length; i++) {
          for (var j = node.children[i].layers.length - 1; j >= 0; j--) {
            this._setVisible(node.children[i].layers[j], visible);
          }
        }
        if (node.sliders) {
          for(s in node.sliders) {
            node.sliders[s].parentNode.parentNode.classList.add("empty-selection");
            node.sliders[s]._selectedValue = null;
          }
        }
      }
      L.DomEvent.stop(evt);
      this._groupStates = {};
      setTimeout(this._refresh.bind(this), 0);
    },

    _onLblClick: function(lbl, group, evt) {
      $(group).slideToggle();
      $(lbl).toggleClass('open');
      L.DomEvent.stop(evt);
    },

    _layout: function() {
      this.clearEvents();
      this._groups = {};
      this._container.innerHTML = '';
      this._counts = [];

      var buildHtml = function(container, dropdowns, sliders, node) {
        var lbl, div, isRadio,
            name = node.name.replace(/^\(#\d+\)\s*/, ''); // "(#2) nom" => "nom" (permet de gérer l'ordre des éléments)
        if (node.children) {
          var groupDiv = L.DomUtil.create('div', 'layer-group', container),
              onlyRadio = true,
              animChildren = [],
              animHtml = "";

          dropdowns = {};
          sliders = {};
          node.sliders = sliders;
          node.groupDiv = groupDiv;
          var childrenChecked = node.children.filter(function(child){return child.layers[0].module.isVisible()}).length;
          if(this._layerlistBehavior == "closed" || (this._layerlistBehavior == "auto" && childrenChecked == 0)){
            lbl = L.DomUtil.create('label', 'layer-group-title toggle', groupDiv);
            node.contentDiv = L.DomUtil.create('div', 'layer-group-content', groupDiv);
            node.contentDiv.style.display = 'none';
          } else {
            lbl = L.DomUtil.create('label', 'layer-group-title toggle open', groupDiv);
            node.contentDiv = L.DomUtil.create('div', 'layer-group-content', groupDiv);
          }
          node.lbl = lbl;
          lbl.dataset.layerListPath = node.name;
          node.maxChildrenChecked = Number.POSITIVE_INFINITY;
          for (var i = 0; i < node.children.length; i++) {
            onlyRadio = onlyRadio && node.children[i].layers[0].radioGroup;
            if (node.children[i].isAnimated) {
              animChildren.push(node.children[i]);
            }
            buildHtml.call(this, node.contentDiv, dropdowns, sliders, node.children[i]);
            node.children[i].parent = node;
            if (node.children[i].layers[0].maxSiblingsChecked) {
              node.maxChildrenChecked = node.children[i].layers[0].maxSiblingsChecked;
            }
          }
          if (animChildren.length > 1) { // création d'une animation temporelle
            animHtml  = '<span class="anim-details">' +
                          '<span class="btn-group pull-right">' +
                            '<a href="#" class="btn btn-default btn-lg btn-speedup" title="' + __("layer_anim_speed_up") + '"><span class="glyphicon glyphicon-minus"></span></a>' +
                            '<a href="#" class="btn btn-default btn-lg btn-speeddown" title="' + __("layer_anim_speed_down") + '"><span class="glyphicon glyphicon-plus"></span></a>' +
                            '<a href="#" class="btn btn-default btn-lg btn-playpause" title="' + __("layer_anim_play") + '"><span class="glyphicon glyphicon-play glyphicon-primary"></span></a>' +
                          '</span>' +
                          '<span class="anim-details-interval"></span>' +
                        '</span>';
          }

          if (onlyRadio) {
            lbl.innerHTML = name + '<b></b><a href="#">' + __('layers_none') + '</a>' + animHtml;
            this.addEvent(lbl.getElementsByTagName('a')[0], 'click', this._onAllClick.bind(this, node, false));
          } else {
            lbl.innerHTML = name + '<b></b><a href="#">' + __('layers_none') + '</a><a href="#">' + __('layers_all') + '</a>' + animHtml;
            this.addEvent(lbl.getElementsByTagName('a')[0], 'click', this._onAllClick.bind(this, node, false));
            this.addEvent(lbl.getElementsByTagName('a')[1], 'click', this._onAllClick.bind(this, node, true));
          }
          if (animChildren.length > 1) { // création d'une animation temporelle
            var anim = this._initAnimation(animChildren, lbl);
            this.addEvent(lbl.getElementsByClassName('btn-speeddown')[0], 'click', this._speedAnim.bind(this, anim, "down"));
            this.addEvent(lbl.getElementsByClassName('btn-speedup')[0], 'click', this._speedAnim.bind(this, anim, "up"));
            this.addEvent(lbl.getElementsByClassName('btn-playpause')[0], 'click', this._toggleAnim.bind(this, anim));
          }
          for (var s in sliders) {
            if (! sliders[s]._initialized) {
              sliders[s].tooltipDiv = L.DomUtil.create('div', 'slider-tooltip');
              sliders[s].tooltipDiv.innerHTML = "&nbsp;";
              sliders[s].parentNode.insertBefore(sliders[s].tooltipDiv, sliders[s]);
              sliders[s]._initialized = true;
              sliders[s]._selectedValue = null;
              sliders[s].parentNode.classList.add("empty-selection");
              sliders[s].parentNode.classList.add("text-center");
              sliders[s]._nodes.forEach(function(node, idx) { node.input.sliderValue = idx; node.input.slider = sliders[s]; });
              $(sliders[s])
                .slider({
                  min: 0,
                  max: sliders[s]._nodes.length - 1,
                  step: 1,
                  tooltip: "hide", // "always",
                  formater: function(val) { return sliders[s]._nodes[val].tooltip; }
                })
                .on('slide', this._onSlide.bind(this, sliders[s]))
                .on('slideStop', this._onSlide.bind(this, sliders[s]));
            }
          }

          this.addEvent(lbl, 'click', this._onLblClick.bind(this, lbl, node.contentDiv));
          this._counts.push({ domNode: lbl.getElementsByTagName('b')[0], children: node.children });
        } else {
          isRadio = node.layers && node.layers.length && node.layers[0].radioGroup;
          div = L.DomUtil.create('div', 'layer-container', container);
          if (isRadio && node.isSlider) {
            this._groups[node.layers[0].radioGroup] = this._groups[node.layers[0].radioGroup] || [];
            this._groups[node.layers[0].radioGroup].push(node);
            if (sliders[node.layers[0].radioGroup]) {
              lbl = sliders[node.layers[0].radioGroup];
            } else {
              lbl = L.DomUtil.create('input', '', div);
              lbl.type = "hidden";
              lbl.radioGroup = node.layers[0].radioGroup;
              sliders[node.layers[0].radioGroup] = lbl;
              lbl._nodes = [];
              lbl._initialized = false;
            }
            lbl._nodes.push(node);
            node.input = { tagName: "TICK", sliderInput: lbl };
            node.tooltip = name;
            node.label = lbl;
            node.legendDiv = L.DomUtil.create('div', 'legends');
            lbl.parentNode.insertBefore(node.legendDiv, lbl.nextSibling); // insère le conteneur de légende juste après le slider
            node.legendLayer = null;
          } else if (isRadio && node.isDropDown) {
            this._groups[node.layers[0].radioGroup] = this._groups[node.layers[0].radioGroup] || [];
            this._groups[node.layers[0].radioGroup].push(node);
            if (dropdowns[node.layers[0].radioGroup]) {
              lbl = dropdowns[node.layers[0].radioGroup];
            } else {
              lbl = L.DomUtil.create('select', '', div);
              lbl.radioGroup = node.layers[0].radioGroup;
              dropdowns[node.layers[0].radioGroup] = lbl;
              var emptyOption = L.DomUtil.create('option', '', lbl);
              emptyOption.value = "__empty__";
              emptyOption.innerText = "-";
              this.addEvent(lbl, 'change', this._onSelect.bind(this, lbl));
              lbl._nodes = { "__empty__": { input: emptyOption, label: lbl } };
            }
            lbl._nodes[node.completeName] = node;
            node.input = L.DomUtil.create('option', '', lbl);
            node.input.value = node.completeName;
            node.input.innerText = name;
            node.label = lbl;
            node.legendDiv = L.DomUtil.create('div', 'legends');
            lbl.parentNode.insertBefore(node.legendDiv, lbl.nextSibling); // insère le conteneur de légende juste après le select
            node.legendLayer = null;
          } else {
            lbl = L.DomUtil.create('label', 'layer', div);
            if (isRadio) {
              this._groups[node.layers[0].radioGroup] = this._groups[node.layers[0].radioGroup] || [];
              this._groups[node.layers[0].radioGroup].push(node);
              lbl.innerHTML = '<input type="radio" name="' + node.layers[0].radioGroup + '"/><i class="icon-radio"></i> ' + name;
              lbl.setAttribute('data-class', 'cd-radio');
            } else {
              lbl.innerHTML = '<input type="checkbox"/><i class="icon-checkbox"></i> ' + name;
              lbl.setAttribute('data-class', 'cd-checkbox');
            }
            node.input = lbl.firstChild;
            node.input.dataset.layerListPath = escape(node.completeName);
            node.label = lbl;
            node.legendDiv = L.DomUtil.create('div', 'legends', div);
            node.legendLayer = null;
            this.addEvent(node.input, 'click', this._onClick.bind(this, node));
          }
        }
      }

      this._layerTree.map(buildHtml.bind(this, this._container, {}, {}));
      this._refresh();

      if (this.options.parent && this.options.parent.atlas && this._layerTree.length) {
        this.options.parent.atlas.fire("layerlistready", { tree : this._layerTree });
      }
    },

    _clearAnimations: function() {
      this._animations.forEach(function(anim) {
        if (anim.timer) clearInterval(anim.timer);
      });
      this._animations = [];
    },

    _disableAnimations: function() {
      this._animations.forEach(function(anim) {
        if (anim.timer) {
          this._toggleAnim(anim);
        }
      }, this);
    },

    _toggleAnim: function(anim, evt) {
      var btn = anim.parentLbl.getElementsByClassName('btn-playpause')[0];
      anim.count = 0;
      if (anim.timer) {
        clearInterval(anim.timer);
        anim.timer = null;
        anim.parentLbl.classList.remove('animated');
        btn.innerHTML = '<span class="glyphicon glyphicon-play glyphicon-primary"></span>';
        btn.title = __("layer_anim_play");
      } else {
        anim.timer = setInterval(this._onAnimFrame.bind(this, anim), anim.interval * 1000);
        anim.parentLbl.classList.add("animated");
        btn.innerHTML = '<span class="glyphicon glyphicon-pause glyphicon-primary"></span>';
        btn.title = __("layer_anim_pause");
      }
      if (evt) {
        L.DomEvent.stop(evt);
      }
    },

    _speedAnim: function(anim, action, evt) {
      var idx = ANIM_INTERVALS.indexOf(anim.interval),
          oldClass = 'interval' + anim.interval.toString().replace('.', '_');
      if (action == 'up')
        anim.interval = ANIM_INTERVALS[Math.max(0, idx - 1)];
      else // 'down'
        anim.interval = ANIM_INTERVALS[Math.min(ANIM_INTERVALS.length - 1, idx + 1)];
      anim.parentLbl.getElementsByClassName('anim-details-interval')[0].innerHTML = __("layer_interval", anim.interval.toLocaleString());
      anim.parentLbl.classList.remove(oldClass);
      void window.scrollX; // force un DOM reflow afin de réinitialiser l'animation CSS (https://medium.com/better-programming/how-to-restart-a-css-animation-with-javascript-and-what-is-the-dom-reflow-a86e8b6df00f)
      anim.parentLbl.classList.add('interval' + anim.interval.toString().replace('.', '_'));
      if (anim.timer) {
        clearInterval(anim.timer);
        anim.timer = setInterval(this._onAnimFrame.bind(this, anim), anim.interval * 1000);
      }
      L.DomEvent.stop(evt);
    },

    _initAnimation: function(nodes, parentLbl) {
      var anim = { timer: null, interval: DEFAULT_ANIM_INTERVAL, nodes: nodes, count: 1, parentLbl: parentLbl };
      anim.parentLbl.getElementsByClassName('anim-details-interval')[0].innerHTML = __("layer_interval", anim.interval.toLocaleString());
      if (window.reactComponents) {
        anim.parentLbl.classList.add("interval" + anim.interval.toString().replace('.', '_'));
      } else if (this._animationLoop != "disabled") { // on lance l'animation automatiquement en mode atlas mais pas en mode organigramme
        if (this._animationLoop == "once" && anim.nodes[0] && anim.nodes[0].input) {
          var self = this;
          setTimeout(function() {
            if (! anim.nodes[0].input.checked) {
              anim.nodes[0].input.checked = true;
              self._onClick(anim.nodes[0]);
            }
          }, 0);
        }
        anim.timer = setInterval(this._onAnimFrame.bind(this, anim), anim.interval * 1000);
        anim.parentLbl.classList.add("animated");
        anim.parentLbl.classList.add("interval" + anim.interval.toString().replace('.', '_'));
        var btn = anim.parentLbl.getElementsByClassName('btn-playpause')[0];
        btn.innerHTML = '<span class="glyphicon glyphicon-pause glyphicon-primary"></span>';
        btn.title = __("layer_anim_pause");
      }
      this._animations.push(anim);
      return anim;
    },

    _onAnimFrame: function(anim) {
      var next = 0;
      for(var i = 0; i < anim.nodes.length; i++) {
        if (isChecked(anim.nodes[i].input)) next = i + 1;
      }
      if (next >= anim.nodes.length) {
        next = 0;
      }
      check(anim.nodes[next], true, this._groups);
      this._onClick(anim.nodes[next]);
      if (this._animationLoop == "once") {
        anim.count++;
        if (anim.count >= anim.nodes.length) {
          this._toggleAnim(anim);
        }
      }
    },

    _isVisible: function(layer) {
      return layer.module.isVisible({ user: true, zoom: false });
    },

    _isZoomVisible: function(layers) {
      var visible = false;
      layers.forEach(function(layer) {
        visible = visible || layer.module.isVisible({ user: false, zoom: true });
      })
      return visible;
    },

    _setVisible: function(layer, visible, parent) {
      if (visible && parent) {
        var checkedCount = 0;
        parent.children.forEach(function(node) { if (node.layers && node.layers.length && this._isVisible(node.layers[0])) checkedCount++; }, this)
        if (checkedCount >= parent.maxChildrenChecked) {
          return;
        }
      }
      layer.module.setVisible(visible);
    },

    _updateCookie: function(name, defaultValue, value) {
      var cookie;
      try {
        cookie = JSON.parse((document.cookie.match('(^|; )layerlist=([^;]*)') || 0)[2] || '{}'); // http://stackoverflow.com/questions/5639346/shortest-function-for-reading-a-cookie-in-javascript;
      } catch (e) {
        cookie = {};
      }
      if (value == defaultValue) {
        delete cookie[name];
      } else {
        cookie[name] = value;
      }
      document.cookie = 'layerlist=' + JSON.stringify(cookie);
    },

    /**
     * Renvoie un instantané de l'état des différentes cases à cocher
     * @return {Object} un Objet du même format que le cookie layerlist
     */
    saveStatus: function() {
      var status = {};
      this._layerTree.forEach(function(node) {
        node.children.forEach(function(child) {
          if (child.layers[0].radioGroup && this._groups[child.layers[0].radioGroup]) {
            // cas d'un bouton radio
            if (isChecked(child.input)) {
              status[child.layers[0].radioGroup] = cleanLabel(child.completeName);
            }
          } else {
            // cas d'une case à cocher
            status[child.completeName] = isChecked(child.input);
          }
        }, this);
      }, this);
      return status;
    },

    /**
     * Restore un état depuis un objet généré par saveStatus
     * @param  {Object} saved
     * @return void
     */
    restoreStatus: function(saved) {
      this._status = saved;
    },

    _refresh: function() {
      var defaultLegend = this.options.wvc.legend;
      function moveLegends(node) {
        var includedModules = [],
            excludedModules = [],
            legendMoved = [];
        node.layers.forEach(function(mod) {
          if (mod.includeLegends) {
            includedModules.push(mod.module);
          } else {
            excludedModules.push(mod.module);
          }
        });
        if (includedModules.length) {
          // d'abord créer le conteneur
          if (! node.legendLayer) {
            node.legendLayer = L.articque.legendLayer({ vertical: true });
            node.legendLayer.type = "layerlist";
            node.legendLayer.addTo(node.legendDiv);
          }
          includedModules.forEach(function(mod) {
            ["_legend", "_sizeLegend", "_shapeLegend", "_fillLegend", "_strokeLegend", "_orientLegend", "_widthLegend"].forEach(function(legend) {
              if (mod[legend] && mod[legend]._layer && mod[legend]._layer._leaflet_id != node.legendLayer._leaflet_id && legendMoved.indexOf(mod[legend]._leaflet_id) == -1) {
                mod[legend]._layer.removeModule(mod[legend]);
                node.legendLayer.addModule(mod[legend]);
                mod[legend].setPosition('left', 'vert-stacked-top#' + node.legendLayer._modules.length);
                // demande un recalcul des dimensions du conteneur de la légende après le premier affichage de celle-ci
                mod[legend].on('legendmoved', setTimeout.bind(null, node.legendLayer.invalidateSize.bind(node.legendLayer), 0));
                legendMoved.push(mod[legend]._leaflet_id);
              }
            })
          });
        }
        // on réattribue à wvc.sideLegend les légendes qui n'ont pas été intégrées dans le layerList
        if (defaultLegend && excludedModules.length) {
          excludedModules.forEach(function(mod) {
            ["_legend", "_sizeLegend", "_shapeLegend", "_fillLegend", "_strokeLegend", "_orientLegend", "_widthLegend"].forEach(function(legend) {
              if (mod[legend] && mod[legend]._layer && mod[legend]._layer.type == "layerlist" && legendMoved.indexOf(mod[legend]._leaflet_id) == -1) {
                mod[legend]._layer.removeModule(mod[legend]);
                defaultLegend.addModule(mod[legend]);
                if (defaultLegend.options.vertical) { // on s'assure d'être en mode vert-stacked-top
                  mod[legend].setPosition('left', 'vert-stacked-top#' + defaultLegend._modules.length);
                } else {
                  mod[legend].setPosition('right', 'stacked-top');
                }
                legendMoved.push(mod[legend]._leaflet_id);
              }
            })
          });
        }
      }
      function refreshNode(node) {
        if (node.children) {
          node.children.map(refreshNode.bind(this))
          // var states = node.children.map(refreshNode.bind(this)),
          //     alltrue = states.indexOf(false) == -1,
          //     allfalse = states.indexOf(true) == -1;
          // if (alltrue || allfalse) {
          //   node.input.indeterminate = false;
          //   node.input.checked = alltrue;
          // } else {
          //   node.input.indeterminate = true;
          // }
          return null;
        } else if (node.input.tagName == "INPUT") {
          check(node, this._isVisible(node.layers[0]));
          var hiddenByZoom = !this._isZoomVisible(node.layers);
          var $legendDiv = $(node.legendDiv);
          node.input.disabled = hiddenByZoom;
          node.label.title = hiddenByZoom ? __('ext_layerlist_disabled_zoom') : "";
          node.label.className = "layer " + node.label.getAttribute('data-class') + (hiddenByZoom ? " disabled" : "");
          moveLegends(node);
          if (node.legendLayer) {
            // recalcule la hauteur de la légende une fois qu'elle est visible
            var animCallback = isChecked(node.input) && ! $legendDiv.is(":visible") ? node.legendLayer.computePositions.bind(node.legendLayer) : undefined;
            $legendDiv[isChecked(node.input) ? (node.isAnimated ? 'show' : 'slideDown') : (node.isAnimated ? 'hide' : 'slideUp')](animCallback);
            node.legendLayer._modules.forEach(function(mod) { mod.refresh(); });
          }
          if (isChecked(node.input) && node.layers[0].radioGroup) {
            this._groupStates[node.layers[0].radioGroup] = node.completeName;
          }
          return node.layers[0].radioGroup ? null : isChecked(node.input);
        } else if (node.input.tagName == "OPTION") { // select
          var $legendDiv = $(node.legendDiv);
          node.input.selected = this._isVisible(node.layers[0]);
          node.input.disabled = !this._isZoomVisible(node.layers);
          moveLegends(node);
          if (node.legendLayer) {
            // recalcule la hauteur de la légende une fois qu'elle est visible
            var animCallback = node.input.selected && ! $legendDiv.is(":visible") ? node.legendLayer.computePositions.bind(node.legendLayer) : undefined;
            $legendDiv[node.input.selected ? (node.isAnimated ? 'show' : 'slideDown') : (node.isAnimated ? 'hide' : 'slideUp')](animCallback);
            node.legendLayer._modules.forEach(function(mod) { mod.refresh(); });
          }
          if (node.input.selected && node.layers[0].radioGroup) {
            this._groupStates[node.layers[0].radioGroup] = node.completeName;
          }
          return node.layers[0].radioGroup ? null : node.input.selected;
        } else if (node.input.tagName == "TICK") { // slider
          var $legendDiv = $(node.legendDiv);
          var selected = this._isVisible(node.layers[0]);
          if (selected) {
            node.input.slider._selectedValue = node.input.sliderValue;
            $(node.input.sliderInput).slider('setValue', node.input.sliderValue);
            node.input.slider.tooltipDiv.innerHTML = node.tooltip;
            if (!this._isZoomVisible(node.layers))
              node.input.slider.parentNode.parentNode.classList.add("empty-selection");
            else
              node.input.slider.parentNode.parentNode.classList.remove("empty-selection");
          }
          moveLegends(node);
          if (node.legendLayer) {
            // recalcule la hauteur de la légende une fois qu'elle est visible
            var animCallback = node.input.selected && ! $legendDiv.is(":visible") ? node.legendLayer.computePositions.bind(node.legendLayer) : undefined;
            $legendDiv[selected ? (node.isAnimated ? 'show' : 'slideDown') : (node.isAnimated ? 'hide' : 'slideUp')](animCallback);
            node.legendLayer._modules.forEach(function(mod) { mod.refresh(); });
          }
          if (selected && node.layers[0].radioGroup) {
            this._groupStates[node.layers[0].radioGroup] = node.completeName;
          }
          return node.layers[0].radioGroup ? null : selected;
        }
      }
      this._layerTree.map(refreshNode.bind(this));
      this._counts.forEach(function(count) {
        count.domNode.innerHTML =
          count.children.reduce(function(acc, cur) { return acc + (cur.input && isChecked(cur.input) ? 1 : 0); }, 0) +
          '/' +
          count.children.length;
      });
    }

  });

  A.ext.layerList = {

    parent: null,
    container: null,

    loadExtension: function(parent) {
      if (window.reactComponents) {
        window.reactComponents.visualisationTabs.addLegendTab('layer-list', __('Couches'));
      }
      var $containerParent = $('#offcanvas-layer-list');
      if (! $containerParent.length) {
        $containerParent = $('.offcanvas-content');
      }
      if ($containerParent.length) {
        if ($containerParent.hasClass('offcanvas-menu')) {
          $containerParent.addClass('visible');
        }
        this.parent = parent;

        this.container = document.createElement('div');
        this.container.className = 'layer-list expanded-content';
        $containerParent.get(0).appendChild(this.container);

        this.layerControl = A.layerControl({
          container: this.container,
          parent: this.parent,
          wvc: this.parent.wvc,
          layers: []
        });

        this.parent.on('willupdateview', this.onUpdateView, this);
        this.parent.wvc.map.on('zoomend', this.onZoomEnd, this)
      }
    },

    onZoomEnd: function() {
      var self = this;
      // setTimoueout pour laisser le temps à l'extension zoomVisibility de faire son travail
      setTimeout(function() { self.layerControl._refresh(); }, 0);
    },

    onUpdateView: function() {
      var wvc = this.parent.wvc,
          mods = [],
          cookie,
          existingLabels = {},
          module,
          params,
          cookieValue,
          disableAnimAutoStart = false;

      try {
        cookie = JSON.parse((document.cookie.match('(^|; )layerlist=([^;]*)') || 0)[2] || '{}');
      } catch (e) {
        cookie = {};
      }

      if (this.layerControl._status) {
        // applique un éventuel status (issu d'un bookmark)
        for(var s in this.layerControl._status) {
          disableAnimAutoStart = true;
          cookie[s] = this.layerControl._status[s];
        }
      }

      var addModule = function(type, mod) {
        mod = (type == 'RepresentationModule') ? mod.module : mod;
        if (mod.customParams && mod.customParams.layerList) {
          params = mod.customParams.layerList;
          if (params.label && params.label.indexOf("{{") > -1) {
            params.label = params.label.replace(/\{\{ModuleName\}\}/ig, mod.name);
            if (mod.dataColumns && mod.dataColumns.length > 1) {
              params.label = params.label.replace(/\{\{ColumnName\}\}/ig, mod.dataColumns[1]);
              for(var i = 0; i < mod.dataColumns.length; i++) {
                params.label = params.label.replace(new RegExp("\\{\\{ColumnName" + i + "\\}\\}", "ig"), mod.dataColumns[i]);
              }
            }
          }
          module = {
            name: params.label,
            module: mod,
            isAnimated: !!params.isAnimated,
            isDropDown: !!params.isDropDown,
            isSlider: !!params.isSlider,
            checkedByDefault: true,
            includeLegends: !! params.isLegendIncluded
          };

          if ((typeof params.default != "undefined") && (params.default == "hidden")) {
            module.checkedByDefault = false;
            mod.hide();
          }

          if (params.radioGroup) {
            module.radioGroup = params.radioGroup;
            if (typeof cookie[module.radioGroup] != 'undefined' && (cookie[module.radioGroup] == "__none__" || ! existingLabels[module.radioGroup] || existingLabels[module.radioGroup].indexOf(cookie[module.radioGroup]) > -1)) {
              mod.setVisible(cookie[module.radioGroup] == cleanLabel(params.label));
            }
          } else {
            cookieValue = (typeof cookie[params.label] == 'undefined') ? module.checkedByDefault : cookie[params.label];
            if (cookieValue != module.checkedByDefault) {
              mod.setVisible(cookieValue);
            }
          }

          mods.push(module);
        }
      };

      wvc._modules.forEach(function(mod) {
        if (mod.module.customParams && mod.module.customParams.layerList && mod.module.customParams.layerList.radioGroup) {
          var rg = mod.module.customParams.layerList.radioGroup;
          existingLabels[rg] = existingLabels[rg] || [];
          existingLabels[rg].push(cleanLabel(mod.module.customParams.layerList.label));
        }
      });
      wvc._modules.forEach(addModule.bind(this, "RepresentationModule"));
      wvc.tileLayers.forEach(addModule.bind(this, "TileLayer"));
      wvc.chartList.charts.forEach(addModule.bind(this, "Chart"));

      this.layerControl._layerlistBehavior = "";
      this.layerControl._animationLoop = "";
      if (wvc.customParams && wvc.customParams.layerList) {
        if (typeof wvc.customParams.layerList == "string") {
          this.layerControl._layerlistBehavior = wvc.customParams.layerList;
        } else if (typeof wvc.customParams.layerList != "undefined") {
          this.layerControl._layerlistBehavior = wvc.customParams.layerList.group || "";
          this.layerControl._animationLoop = wvc.customParams.layerList.loop || "";
        }
      }
      if (disableAnimAutoStart) {
        this.layerControl._animationLoop = "disabled";
      }

      this.layerControl.setLayers(mods);

      if (window.reactComponents && mods.length) {
        // si aucune légendes latérale, on affiche la liste de couche
        window.reactComponents.visualisationTabs.showLegend('layer-list', {override: false});
      }
    }

  };
})();