(function (window) {
  var baseUrl = null; // window._webappOptions.wsBaseUrl

  var wsSessionId,
      workSessionId,
      sessionInactiveTime,
      pingInterval,
      expirationTimer,
      expirationAlertTimer,
      pingTimer,
      ajaxAsync = true;

  var ABOUT_TO_EXPIRE_DURATION = 60, // alerte 60 secondes avant l'expiration
      alertServiceUnavailable = false,
      alertProxyError = false,
      alertUnknownError = false;

  var ajaxCors = function (url, data, options) {
    if (baseUrl === null) {
      console.warn(__('Une erreur est survenue') + ' : wsBaseUrl undefined'); // eslint-disable-line no-console
      return window.jQuery.Deferred().reject();
    }
    data.CD7SESSID = wsSessionId;
    if (workSessionId) { data.CD7DOCSESSID = workSessionId; }
    if (sessionInactiveTime) {
      // TODO remplacer par un appel à postponeSession si possible
      if (expirationTimer) { clearTimeout(expirationTimer); }
      expirationTimer = setTimeout(sessionExpired, sessionInactiveTime);
      if (expirationAlertTimer) { clearTimeout(expirationAlertTimer); }
      expirationAlertTimer = setTimeout(sessionAboutToExpire, sessionInactiveTime - ABOUT_TO_EXPIRE_DURATION * 1000); // 1 minute avant l'expiration
    }
    if (workSessionId && pingInterval && !wsc.sessionExpired) {
      if (pingTimer) { clearTimeout(pingTimer); }
      pingTimer = setTimeout(doPing, pingInterval);
    }

    // on tente un sendBeacon plutôt qu'un appel ajax synchrone
    if (! ajaxAsync && typeof navigator.sendBeacon == 'function') {
      if (navigator.sendBeacon(baseUrl + 'disconnect', JSON.stringify(data))) {
        return window.jQuery.Deferred().resolve();
      }
    }

    return window.jQuery.ajax({
      type: 'POST',
      url: baseUrl + url,
      // xhrFields: ajaxAsync ? { withCredentials: true} : {},
      dataType: "json",
      contentType: "multipart/form-data",
      crossDomain: true,
      async: ajaxAsync,
      data: JSON.stringify(data)
    })
    .fail(function (_jqXHR, _textStatus, errorThrown) {
      if (errorThrown == 'SESSION_UNKNOWN' || errorThrown == 'SESSION_EXPIRED' || errorThrown.indexOf && errorThrown.indexOf('UNKNOW_CD7SESSID') > -1) {
        sessionExpired();
      } else if (errorThrown == 'Service Unavailable') {
        if (ajaxAsync && ! alertServiceUnavailable) { // si pas en cours de déconnexion
          alertServiceUnavailable = true;
          wsc.alert(__('La session a été interrompue') + '. ' + __('Rechargement de la page nécessaire'), errorThrown, url);
        }
      } else if (errorThrown.indexOf && errorThrown.indexOf('Forbidden call to CDAPI while diagram execution') === 0) {
        console.log(errorThrown + '. Cet appel ne devrait ne devrait être effectué qu\'une fois l\'exécution de l\'organigramme terminée.'); // eslint-disable-line no-console
      } else if (errorThrown == 'Proxy Error') {
        if (ajaxAsync && ! alertProxyError && !(options && options.ignoreProxyError)) { // si pas en cours de déconnexion
          alertProxyError = true;
          wsc.disconnect(true);
          wsc.alert(__('Une opération trop longue n\'a pas pu aboutir') + '. ' + __('Rechargement de la page nécessaire'), errorThrown, url);
        }
      } else {
        if (ajaxAsync) { // si pas en cours de déconnexion
          try {
            errorThrown = decodeURI(errorThrown);
          } catch(e) {} // eslint-disable-line no-empty
          console.log(__('Une erreur est survenue') + ' : ' + errorThrown); // eslint-disable-line no-console
          if (! alertUnknownError) {
            alertUnknownError = true;
            wsc.alert(__('Une erreur est survenue') + '. ' + __('Rechargement de la page nécessaire') + "\n[" + errorThrown + "]", errorThrown, url);
          }
        }
      }
    });


    // var xhr = new XMLHttpRequest(),
    //     ajaxDeferred = window.jQuery.Deferred();
    // xhr.open('POST', encodeURI(baseUrl + url), ajaxAsync);
    // xhr.setRequestHeader('Content-Type', 'application/json');
    // if (ajaxAsync) {
    //   xhr.withCredentials = true;
    // }
    // xhr.onreadystatechange = function(){
    //   if ( xhr.readyState == 4 ) {
    //     if ( xhr.status == 200 ) {
    //       ajaxDeferred.resolve(JSON.parse(xhr.responseText));
    //     } else {
    //       if (xhr.statusText == 'SESSION_UNKNOWN' || xhr.statusText == 'SESSION_EXPIRED' || xhr.statusText.indexOf('UNKNOW_CD7SESSID') > -1) {
    //         sessionExpired();
    //       } else if (xhr.statusText == 'Service Unavailable') {
    //         if (ajaxAsync) { // si pas en cours de déconnexion
    //           alert(__('La session a été interrompue') + '. ' + __('Rechargement de la page nécessaire'));
    //         }
    //       } else if (xhr.statusText.indexOf('Forbidden call to CDAPI while diagram execution') === 0) {
    //         console.log(xhr.statusText + '. This WebService request should only be done once the organigram is fully executed.'); // eslint-disable-line no-console
    //       } else if (xhr.statusText == 'Proxy Error') {
    //         if (ajaxAsync) { // si pas en cours de déconnexion
    //           wsc.disconnect(true);
    //           alert(__('Une opération trop longue n\'a pas pu aboutir') + '. ' + __('Rechargement de la page nécessaire'));
    //         }
    //       }
    //       ajaxDeferred.reject([xhr.statusText]);
    //     }
    //   }
    // };
    // xhr.send(JSON.stringify(data));

    // return ajaxDeferred.promise();
  };

  var sessionExpired = function () {
    if (! wsc.sessionExpired) {
      wsc.disconnect(true, true);
      wsc.fire('session.expired');
    }
  };

  var postponeSession = function () {
    if (expirationTimer) { clearTimeout(expirationTimer); }
    expirationTimer = setTimeout(sessionExpired, sessionInactiveTime);
    if (expirationAlertTimer) { clearTimeout(expirationAlertTimer); }
    expirationAlertTimer = setTimeout(sessionAboutToExpire, sessionInactiveTime - ABOUT_TO_EXPIRE_DURATION * 1000); // 1 minute avant l'expiration
  };

  var sessionAboutToExpire = function () {
    if (! wsc.sessionExpired) {
      wsc.fire('session.aboutToExpire', { time: ABOUT_TO_EXPIRE_DURATION, postpone: postponeSession });
    }
  };

  // window.jQuery(document).ajaxError(function (event, jqXHR) {
  //   if (403 === jqXHR.status) {
  //     sessionExpired();
  //   }
  // });

  var checkMaintenance = function(data) {
    if (!! data.maintenance && ! wsc.maintenanceEnabled) {
      wsc.maintenanceEnabled = true;
      wsc.fire("maintenance.enabled");
    }
  };

  var doPing = function () {
    window.jQuery.ajax({
      type: 'POST',
      url: baseUrl + 'keepSessionAlive',
      // xhrFields: { withCredentials: true },
      dataType: "json",
      data: JSON.stringify({ "CD7SESSID": wsSessionId, "CD7DOCSESSID": workSessionId})
    })
    .done(checkMaintenance)
    .fail(sessionExpired);

    // var xhr = new XMLHttpRequest();
    // xhr.open('POST', encodeURI(baseUrl + 'keepSessionAlive'), ajaxAsync);
    // xhr.withCredentials = true;
    // xhr.onreadystatechange = function(){
    //   if (xhr.readyState == 4 && xhr.status != 200) {
    //     sessionExpired();
    //   }
    // };
    // xhr.send(JSON.stringify({ "CD7DOCSESSID": workSessionId }));

    pingTimer = setTimeout(doPing, pingInterval);
  };

  var wsc = {
    listeners: {},
    diagramModified: false,
    resourcesCache: {
      maps: [],
      data: []
    },
    undoPossible: false,
    redoPossible: false,
    sessionExpired: false,
    disabledExecution: false,

    /**
     * alerte à afficher à l'utilisateur
     * par défaut, une alert javascript est affichée
     * sauf si un listener à l'event "alert" change la proporiété `cancel` en `true`
     * @public
     * @param  {string} message  le message
     * @param  {string} error    le nom de l'erreur
     * @param  {string} url      l'url du WS qui a provoqué l'erreur
     * @return {void}
     */
    alert: function(message, error, url) {
      var event = { message: message, error: error, url: url, cancel: false };
      this.fire("alert", event);
      if (! event.cancel)
        window.alert(message); // comportement par défaut
    },

    /**
    * Marque le fichier comme ayant été modifié
    * @private
    * @param {boolean} value  la nouvelle valeur
    * @return {void}
    */
    _setDiagramModified: function (value) {
      var doFire = value != this.diagramModified;
      this.diagramModified = value;
      if (doFire) {
        this.fire('diagramModified.change', value);
      }
      if (value) {
        this._setUndoRedoPossible('undo', true);
      }
    },

    /**
    * Enregistre si une action undo ou redo est possible
    * @private
    * @param {string}  action 'undo' ou 'redo'
    * @param {boolean} value  la nouvelle valeur
    * @return {void}
    */
    _setUndoRedoPossible: function (action, value) {
      var doFire = value != this[action + 'Possible'];
      this[action + 'Possible'] = value;
      if (doFire) {
        this.fire(action + 'Possible.change', value);
      }
    },

    /**
     * Spécifie le chemin d'accès au webservice
     * @param  {string} wsBaseUrl     url d'accès
     * @param  {jQuery} jQuery        [optionnel] jQuery
     * @return {void}
     */
    init: function (wsBaseUrl, jQuery) {
      baseUrl = wsBaseUrl;
      if (jQuery) {
        window.jQuery = jQuery;
      }
      window.jQuery.support.cors = true; // pour éviter une erreur "no transport" sous IE9
    },

    /**
    * Connexion au web service : ouvre une session de travail
    * @public
    * @param  {Object} [auth] authentification {username: string, password: string} pour utiliser identifiants (fortement déconseillé en production) ou {key: string} (avec une clé développeur)
    * @return {Deferred}             un objet deferred
    */
    connect: function (auth) {
      auth = auth || {};
      auth.username = auth.username || null;
      auth.password = auth.password || null;
      auth.key = auth.key || null;
      return ajaxCors('connect', auth)
      .done(function (data) {
        wsSessionId = data.CD7SESSID;
        workSessionId = data.CD7DOCSESSID;
        sessionInactiveTime = data.SessionInactiveTime * 1000; // SessionInactiveTime en secondes => millisecondes
        pingInterval = data.PingInterval * 1000; // PingInterval secondes => millisecondes
        wsc.sessionExpired = false;
        pingTimer = setTimeout(doPing, pingInterval);
      });
    },

    /**
    * Déconnexion au service web. Libère le document ouvert avec `loadDiagram`.
    * @public
    * @param  {boolean} async          si true, l'appel est fait de manière asynchrone (appel ajax normal), sinon de manière synchrone (util pour un appel en cours de fermeture de la page web)
    * @param  {boolean} keepBackup     si true, demande au WS de ne pas supprimer le backup (utile pour l'expiration de session)
    * @return {Deferred}             un objet deferred
    */
    disconnect: function (async, keepBackup) {
      if (wsc.sessionExpired) {
        return window.jQuery.Deferred().resolve();
      }
      if (async !== true) {
        ajaxAsync = false;
      }
      var deferred = ajaxCors(
        'disconnect',
        { DeleteBackup: ! keepBackup }
      );
      if (pingTimer) clearTimeout(pingTimer);
      if (expirationTimer) clearTimeout(expirationTimer);
      if (expirationAlertTimer) clearTimeout(expirationAlertTimer);
      wsc.sessionExpired = true
      return deferred;
    },

    /**
    * Prolonge la session du document
    * @public
    * @return {void}
    */
    sessionExtends: function(){
      postponeSession();
    },

    /**
    * Charge un organigramme
    * @public
    * @param  {number} id              identifiant du fichier `.cdx`
    * @param  {bool} recoverBackup     si `true`, charge la sauvegarde automatique le cas échéant
    * @param  {bool} [autoBackup=true] si `true`, effectue des sauvegardes automatique à interval régulier
    * @param  {bool} [skipUnauthorizedResources=false] si `true`, ne pas charger les ressources dont l'accès est refusé
    * @return {Deferred}             un objet deferred
    */
    loadDiagram: function (id, recoverBackup, autoBackup, skipUnauthorizedResources) {
      this._setDiagramModified(false);
      return ajaxCors(
        'diagram/load',
        { DiagId: id, AutoBackup: (typeof autoBackup == 'undefined') ? true : autoBackup, RecoverBackup: recoverBackup, SkipUnauthorizedResources: !!skipUnauthorizedResources }
      );
    },

    /**
    * Charge un wizard en lecture
    * @public
    * @param  {number} wizardId              identifiant du wizard
    * @param  {number} diagId              identifiant du fichier `.cdx`
    * @return {Deferred}             un objet deferred
    */
    loadWizard: function (wizardId, diagId) {
      this._setDiagramModified(false);
      return ajaxCors(
        'diagram/loadWizard',
        { DiagId: diagId, WizardId: wizardId}
      );
    },

    /**
    * Charge un organigramme depuis un chemin
    * @public
    * @param  {string} path            chemin du fichier `.cdx`
    * @param  {bool} recoverBackup     si `true`, charge la sauvegarde automatique le cas échéant
    * @param  {bool} [autoBackup=true] si `true`, effectue des sauvegardes automatique à interval régulier
    * @return {Deferred}             un objet deferred
    */
    loadTemplate: function (path, recoverBackup, autoBackup) {
      this._setDiagramModified(false);
      return ajaxCors(
        'diagram/loadFile',
        { DiagPath: path, AutoBackup: (typeof autoBackup == 'undefined') ? true : autoBackup, RecoverBackup: recoverBackup }
      );
    },

    /**
    * Sauvegarde le fichier `.cdx` précédemment envoyé
    * @public
    * @param  {string} [filename='']  nom du fichier à sauvegarder. Si `''`, le fichier précédemment ouvert est remplacé
    * @param  {string} saveAsDiagId   Dans le cas d'un "enregistrer sous", identifiant du nouveau document déjà créé BDD
    * @return {Deferred}            un objet deferred
    */
    saveDiagram: function (filename, saveAsDiagId) {
      return ajaxCors(
        'diagram/save',
        { DiagFullPath: filename, SaveAsDiagId: saveAsDiagId }
      ).done(function (data) {
        if (data.status == 'diagramUnauthorized') {
          wsc.alert(__('Enregistrement impossible : l\'accès au document n\'est pas autorisé'));
        } else {
          wsc._setDiagramModified(false);
        }
      });
    },

    /**
    * Sauvegarde le fichier `.cdx` précédemment envoyé
    * @public
    * @param  {string} base64Image    image codée en base64 contenant une vignette d'aperçu de la visualisation. Si spécifiée, l'image est sauvée à côté du fichier `.cdx`
    * @return {Deferred}            un objet deferred
    */
    setThumbnail: function (base64Image) {
      return ajaxCors(
        'diagram/setThumbnail',
        { RenderPreview64: base64Image }
      ).done(function (data) {
        if (data.status == 'diagramUnauthorized') {
          wsc.alert(__('Enregistrement impossible : l\'accès au document n\'est pas autorisé'));
        }
      });
    },

    /**
    * Effectue une sauvegarde du fichier `.cdx` précédemment ouvert ainsi que de toutes les ressources associées dans un fichier `.zip`
    * @public
    * @param  {string} filename       nom du fichier à sauvegarder.
    * @return {Deferred}            un objet deferred
    */
    saveZip: function (filename) {
      return ajaxCors(
        'diagram/saveZip',
        { diagFullPath: filename }
      ).done(function () { wsc._setDiagramModified(false); });
    },

    /**
    * Création d'un nouvel organigramme
    * @public
    * @return {Deferred}            un objet deferred
    */
    newDiagram: function () {
      return ajaxCors(
        'diagram/newDiagram',
        {}
      );
    },

    /**
    * Sauvegarde en mémoire des paramètres des modules de l'organigramme
    * @public
    * @param  {int|int[]} moduleId  identifiant du ou des modules dont il faut sauvegarder le contexte
    * @return {Deferred}            un objet deferred
    */
    saveContext: function (/*moduleId1, moduleId2, ...*/) {
      return ajaxCors(
        'diagram/saveContext',
        { moduleIds: arguments.length ? Array.prototype.slice.call(arguments) : null }
      );
    },

    /**
    * Restauration des paramètres des modules de l'organigramme dans l'état du dernier saveContext
    * @public
    * @return {Deferred}            un objet deferred
    */
    restoreContext: function () {
      return ajaxCors(
        'diagram/restoreContext',
        {}
      );
    },

    /**
    * Récupération de la description d'un organigramme
    * @public
    * @return {Deferred}            un objet deferred
    */
    getDiagramDesc: function () {
      var self = this;
      this.fire('getDiagramDesc.start');
      return ajaxCors(
        'diagram/getDiagramDesc',
        {}
      ).done(function (diag) {
        wsc.disabledExecution = diag.DisableExecution;
        self.fire('getDiagramDesc.end');
      });
    },

    /**
    * Désactive ou réactive l'exécution automatique de l'organigramme
    * @param  {bool} [disable=false] si true, desactive l'exécution automatique. Si false, active l'exécution automatique
    * @return {Deferred}             un objet deferred
    */
    disableExecution: function(disable) {
      wsc.disabledExecution = disable;
      this._setDiagramModified(true);
      return ajaxCors(
        'diagram/disableExecution',
        { Disable: disable || false }
      );
    },


    /**
    * Exécute l'organigramme
    * @public
    * @param  {bool} [executeAll=false] si `true`, force l'exécution complète de l'organigramme (vide le cache)
    * @return {Deferred}            un objet deferred
    */
    executeDiagram: function (executeAll) {
      return ajaxCors(
        'diagram/execute',
        { executeAll: !!executeAll }
      );
    },

    /**
    * Vide le cache de l'organigramme
    * @public
    * @return {Deferred}            un objet deferred
    */
    clearCache: function () {
      this.resourcesCache.maps = [];
      this.resourcesCache.data = [];
      idb.clear();
      return ajaxCors(
        'diagram/clearCache',
        {}
      );
    },

    /**
     * Active ou désactive le mode mémorisation sur tous les modules de l'organigramme
     * @param  {[type]} enable Active le mode mémorisation si `true`, le désactive, si `false`
     * @return {Deferred}            un objet deferred
     */
    toggleLock: function(isLock) {
      return ajaxCors(
        'diagram/toggleLock',
        { Lock: isLock }
      );
    },

    /**
    * Récupère l'état d'avancement de l'exécution de l'organigramme
    * @public
    * @return {Deferred}            un objet deferred
    */
    getExecutionStatus: function () {
      return ajaxCors(
        'diagram/getExecutionStatus',
        {}
      );
    },

    /**
    * Annule l'exécution de l'organigramme précédemment lancée
    * @public
    * @return {Deferred}            un objet deferred
    */
    cancelExecution: function () {
      return ajaxCors(
        'diagram/cancelExecution',
        {}
      );
    },

    /**
    * Récupération du détail d'un dataspace
    * @public
    * @param  {int} [moduleId] identifiant du dataspace. Si non spécifié, alors le détail du premier dataspace est renvoyé
    * @return {Deferred}            un objet deferred
    */
    getDataSpaces: function (moduleId) {
      var self = this;
      this.fire('getDataSpaces.start');
      return ajaxCors(
        'diagram/getDataSpaces',
        { moduleId: moduleId }
      ).done(function () { self.fire('getDataSpaces.end'); });
    },

    /**
    * Modification de la valeur d'une cellule d'un dataspace (pour les données internes)
    * @public
    * @param  {int}    moduleId     identifiant du dataspace à modifier
    * @param  {int}    columnIndex  index de la colonne du dataspace à modifier
    * @param  {string} rowId        identifiant de la ligne du dataspace à modifier
    * @param  {string} value        nouvelle valeur de la cellule
    * @return {Deferred}            un objet deferred
    */
    setDataSpaceCell: function(moduleId, columnIndex, rowId, value) {
      this._setDiagramModified(true);
      return ajaxCors(
        'diagram/SetDataSpaceCell',
        { moduleId: moduleId, columnIndex: columnIndex, rowId: rowId, value: value }
      );
    },

    /**
    * Récupération des erreurs rencontrées lors de la dernière exécution de l'organigramme
    * @public
    * @return {Deferred}            un objet deferred
    */
    getDiagramErrors: function () {
      return ajaxCors(
        'diagram/getDiagramErrors',
        {}
      );
    },

    /**
    * Ajouter un conteneur de modules
    * @public
    * @param {int[]} moduleIds      la liste des ids des modules à ajouter dans le conteneur
    * @return {Deferred}            un objet deferred
    */
    addContainer: function(moduleIds) {
      this._setDiagramModified(true);
      return ajaxCors(
        'diagram/addContainer',
        { Modules: moduleIds.map(function(id) { return { moduleId: parseFloat(id) }; }) }
      );
    },

    /**
    * Modifie le paramétrage d'un conteneur de modules
    * @public
    * @param {Object} params        structure de l'object fournie par le WS
    * @return {Deferred}            un objet deferred
    */
    setContainerParameters: function(containerId, params) {
      this._setDiagramModified(true);
      return ajaxCors(
        'diagram/setContainerParameters',
        { ContainerId: containerId, ContainerParams: params }
      );
    },

    /**
    * Récupération des paramètres d'un module
    * @public
    * @param  {int} moduleId identifiant du module
    * @return {Deferred}            un objet deferred
    */
    getModuleParameters: function (moduleId) {
      return ajaxCors(
        'modules/getModuleParameters',
        { moduleId: moduleId }
      );
    },

    /**
    * Changement des paramètres d'un module
    * @public
    * @param {int} moduleId   identifiant du module
    * @param {object} data    nouveaux paramètres du module. Doit contenir tous les paramètres du module, même ceux n'étant pas modifiés. Il est de pratique courante de récupérer les paramètres via `getModuleParameters()`, de modifier certaines propriétés puis de les sauvegarder par un appel à `setModuleParameters()`.
    * @param {bool} [updateLockedParamsAfterCompute=false]   mettre à jour les paramètres verrouillés du module après son exécution
    * @return {Deferred}            un objet deferred
    */
    setModuleParameters: function (moduleId, data, updateLockedParamsAfterCompute) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/setModuleParameters',
        { moduleId: moduleId, moduleParams: data, UpdateLockedParamsAfterCompute: !!updateLockedParamsAfterCompute}
      );
    },

    /**
    * Récupération des paramètres d'un module ainsi que de la description de l'interface d'édition du module
    * @public
    * @param {int} moduleId   identifiant du module
    * @return {Deferred}            un objet deferred
    */
    getModuleGuiDescription: function (moduleId) {
      var self = this;
      return ajaxCors(
        'modules/getModuleGuiDescription',
        { moduleId: moduleId }
      ).done(function(data) {
        self.fire('getModuleGuiDescription', data);
      });
    },

    /**
    * Change les paramètres d'un module et en même temps, retourne la nouvelle description de l'interface d'édition du module
    * @param {int} moduleId   identifiant du module
    * @param {object} data    nouveaux paramètres du module. Doit contenir tous les paramètres du module, même ceux n'étant pas modifiés. Il est de pratique courante de récupérer les paramètres via `getModuleParameters()`, de modifier certaines propriétés puis de les sauvegarder par un appel à `setModuleParameters()`.
    * @return {Deferred}            un objet deferred
    */
    setGetModuleGuiDescription: function (moduleId, data) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/setGetModuleGuiDescription',
        { moduleId: moduleId, moduleParams: data }
      );
    },

    /**
    * Envoie les données de l'interface du module BDD
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
    dbConnect: function (data) {
      return ajaxCors(
        'modules/dbConnect', data
      );
    },

    /**
    * Envoie les données de prévisualisation d'une requête du module Schema
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
     schemaPreview: function (data) {
      return ajaxCors(
        'modules/schemaPreview', data
      );
    },

    /**
    * Envoie les données de prévisualisation d'une table du module ADW
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
    adwPreview: function (data) {
      return ajaxCors(
        'modules/adwPreview', data
      );
    },

    /**
    * Envoie le comptage des données d'une table du module ADW
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
   adwCount: function (data) {
    return ajaxCors(
      'modules/adwCount', data, {ignoreProxyError : true}
    );
  },

    /**
    * Envoie les données d'une colonne filtrable du module ADW
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
    adwFilter: function (data) {
      return ajaxCors(
        'modules/adwFilter', data
      );
    },

    /**
    * Envoie les données de prévisualisation d'une table d'agrégation du module ADW
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
    adwAggregatePreview: function (data) {
      return ajaxCors(
        'modules/adwAggregatePreview', data
      );
    },

    /**
    * Envoie les données de filtre d'agrégation du module ADW
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
    adwGetAggregFilterData: function (data) {
      return ajaxCors(
        'modules/adwGetAggregFilterData', data
      );
    },

    /**
    * Envoie les données de l'interface du module Cognos
    * @param {object} data       données de connexion
    * @return {Deferred}       un objet deferred
    */
    cognosConnect: function (data) {
      return ajaxCors(
        'modules/cognosConnect', data
      );
    },

    /**
    * Envoie les données de l'interface du module SF
    * @param {object} data     données de connexion
    * @return {Deferred}       un objet deferred
    */
    sfConnect: function (data) {
      return ajaxCors(
        'modules/sfConnect', data
      );
    },

    /**
     * Récupère un rapport de correspondance des identifiants d'un module données
     * @param  {int} moduleId identifiant du module
     * @param  {string} type  type de rapport :
     *                          * "dataIds"    => liste des identifiants du fichier de données qui ne sont pas dans la carte
     *                          * "geoIds"     => liste des identifiants de la carte qui ne sont pas dans le fichier de données
     *                          * "duplicates" => liste des identifiants qui apparaissent plusieurs fois dans le fichier de données ainsi que le nombre d'occurences pour chacun
     * @return {Deferred}     un objet deferred
     */
    getMatchingReport: function(moduleId, type) {
      return ajaxCors(
        'modules/getMatchingReport', { DataModuleId: moduleId, ReportType: type }
      );
    },

    /**
     * Récupère les données cartographiques manquantes dans le cartoJson
     * @private
     * @param  {object} cartoJson        le cartoJson en cours de complétion
     * @param  {Deferred} deferred       la promesse à resoudre
     * @param  {bool} useIndexedDBCache  [optionnel] true par défaut. Si true, stocke et récupère les fonds de carte depuis IndexedDB (dans le navigateur) lorsque c'est possible
     * @return {void}
     */
    _getDisplayingData: function(cartoJson, deferred, useIndexedDBCache) {
      var self = this,
          fetching = false,
          map;
      for(var mapIndex = 0; mapIndex < cartoJson.Resources.Maps.length; mapIndex++) {
        map = cartoJson.Resources.Maps[mapIndex];
        if (! map.GeomList) {
          map.GeomList = [];
        }
        if (map.Hash && (map.GeomList.length < map.Count - map.IgnoreCount)) {
          // on cherche dans le cache
          var found = false;
          this.resourcesCache.maps.forEach(function(cache) {
            if (cache.Hash == map.Hash) {
              map.GeomList = cache.GeomList;
              if (cache.features) { map.features = cache.features; }
              found = true;
            }
          });
          if (! found) {
            (useIndexedDBCache === false ? window.jQuery.Deferred().resolve(null) : idb.retrieve(map.Hash))
              .done(function(result) {
                //console.log("retrieve(map.Hash).done", result);
                if (result && result.map && result.map.GeomList && result.map.GeomList.length) {
                  // si le résultat a été trouvé dans indexedDb
                  result.map.GeomList.forEach(function(geom) { map.GeomList.push(geom); });
                  self._getDisplayingData(cartoJson, deferred, useIndexedDBCache);
                  deferred.notify(cartoJson);
                } else {
                  // sinon on demande au WS
                  ajaxCors(
                    'modules/getDisplayingData',
                    { mapHash: map.Hash, offset: map.GeomList ? map.GeomList.length : 0 }
                  ).done(function(mapData) {
                    map.GeomList.push.apply(map.GeomList, mapData.GeomList);
                    map.fromWS = true;
                    map.IgnoreCount = mapData.IgnoreCount;
                    if (! mapData.Id && ! mapData.Count) { // erreur coté serveur => on arrête les requêtes sur cette carte
                      map.fromWS = false;
                      map.Count = map.GeomList.length;
                    }
                    self._getDisplayingData(cartoJson, deferred, useIndexedDBCache);
                    deferred.notify(cartoJson);
                  }).fail(function() {
                    deferred.reject();
                  });
                }
              });
            fetching = true;
            break;
          }
        }
      }
      if (! fetching) {
        this.resourcesCache.maps = cartoJson.Resources.Maps;
        deferred.resolve(cartoJson);
        if (useIndexedDBCache !== false) {
          setTimeout(function() {
            cartoJson.Resources.Maps.forEach(function(map) {
              if (map.fromWS) { // on ne cherche à sauvegarder que les données provenant du WS et non celle provenant déjà du cache
                delete map.fromWS;
                if (map.GeomList.length == map.Count - map.IgnoreCount) { // on ne met en cache que si toutes les entités ont été chargées
                  idb.cache(map.Hash, map);
                }
              }
            });
          }, 2500);// on attend un peu pour ne pas ralentir le reste de l'appli
        }
        self.fire('getDisplayingInfos.end');
      }
    },

    /**
    * Retourne la description de la visualisation au format cartoJSON
    * @public
    * @param  {int}  moduleId            identifiant du module visualisation
    * @param  {bool} useIndexedDBCache   [optionnel] if true, store and retrieve maps from browser IndexedDB when possible. True par défaut
    * @return {Deferred}            un objet deferred
    */
    getDisplayingInfos: function (moduleId, useIndexedDBCache) {
      var self = this,
          deferred = window.jQuery.Deferred();
      this.fire('getDisplayingInfos.start');
      ajaxCors(
        'modules/getDisplayingInfos',
        { moduleId: moduleId, excludeResources: this.resourcesCache.maps.length > 0 }
      ).done(function (cartoJson) {
        self._getDisplayingData(cartoJson, deferred, useIndexedDBCache);
      }).fail(function() {
        deferred.reject();
      });
      return deferred.promise();
    },

    /**
    * Retourne une image (encodée en base64) représentant une visualisation rendue côté serveur
    * @public
    * @param  {int} moduleId identifiant du module visualisation
    * @return {Deferred}            un objet deferred
    */
    getDisplayingRender: function (moduleId) {
      return ajaxCors(
        'modules/getDisplayingRender',
        { moduleId: moduleId }
      );
    },

    /**
    * Ajoute plusieurs modules à l'organigramme
    * @public
    * @param  {object[]} modules      liste d'objets { moduleType: string, moduleName: string, posX: float, posY: float, parentModuleId: int[], childModuleId: int[]} spécifiant les modules à ajouter
    * @return {Deferred}            un objet deferred
    */
    // addModules: function ([{ moduleType: ..., moduleName: ..., posX: ..., posY: ..., parentModuleId: ..., childModuleId: ...}])

    /**
    * Ajoute un module à l'organigramme
    * @public
    * @param {string} moduleType   type de module à créer. Voir les valeurs possibles dans diagram-model.js => "Data", "Map", "BDD", "SF", "Union", "Length", "Surface", "Intersection", "InclusionExclusion", "MinimumDistance", "PoleSelection", "FlyDistance", "Maillage", "RoutingBN", "Belonging", "Filter", "Aggregation", "Polarisation", "Interpolation", "GroupSurface", "Geocod", "SectoV3", "TradeArea", "GeoLocalization", "Implantation", "PointsCreation", "Cartogram", "Lissage", "Elevation3D", "DTM", "Envelope", "IsochroneBN", "ManualSecto", "Quantification", "Calculation", "Regression", "Bertin", "MatrixOp", "ACP", "AFC", "CAH", "Winner", "BoxWhiskers", "TriangularDiagram", "Strokes", "Fill", "Symbols", "Values", "Pies", "Portions", "Histograms", "Raster", "Flows", "WMS", "Displaying", "DisplayingWeb", "SuperDisplaying"
    * @param {string} moduleName   nom du module
    * @param {int} posX            position dans la représentation de l'organigramme en px. Si 0, alors la position sera calculée automatiquement en fonction des modules parents et enfants spécifiés dans `parentId` et `childId`
    * @param {int} posY            "
    * @param {int[]} [parentId=[]] liste des identifiants des modules parents. Créera des liens des modules parents vers le module ajouté
    * @param {int[]} [childId=[]]  liste des modules enfants. Créera des liens du module ajouté vers les modules enfants
    * @param {string} [category=""]categorie du module (geo, mix, stat, rep). Si spécifié et valide, alors la liste des modules récemment utilisés sera mise à jour
    * @param {int} [customId=""]   id du module dans la bibliothèque. Si spécifié, alors le paramétrage du module sauvegardé dans la bibliothèque et désigné par cet ID sera utilisé pour initialiser le module
    * @return {Deferred}            un objet deferred
    */
    addModules: function (moduleType, moduleName, posX, posY, parentId, childId, category, customId) {
      this._setDiagramModified(true);
      if (category != "in" && category != "geo" && category != "mix" && category != "stat" && category != "rep" && category != "vis")
        category = "";
      return ajaxCors(
        'modules/addModules',
        { Modules: (arguments.length >= 4) ?
          [{ moduleType: moduleType, moduleName: moduleName, posX: posX, posY: posY, parentModuleId: parentId || [], childModuleId: childId || [], category: category, customId: customId}] :
          arguments[0].map(function (mod) { mod.parentModuleId = mod.parentModuleId || []; mod.childModuleId = mod.childModuleId || []; mod.category = mod.category || ""; return mod; })
        }
      );
    },

    /**
    * Ajoute plusieurs modules à l'organigramme avec les paramètres des modules et des liens
    * @public
    * @param  {object[]} modules
    un objet comprenant :
    une liste d'objets { moduleType: string, moduleName: string, moduleParams: {...}} spécifiant les modules à ajouter avec leurs paramètres
    une liste de liens
    * @return {Deferred}            un objet deferred
    */
    // addModulesSet: function ({Modules:[{ moduleType: ..., moduleName: ..., moduleParams: { ModuleId: -1, ModuleParams: nouveaux paramètres du module. Doit contenir tous les paramètres du module, même ceux n'étant pas modifiés} }},
    // Links:[{parentIndex: ..., childIndex:..., parentId:..., childId:...}]}) les liens définissent un lien entre un parent et un enfant.
    // Si parentIndex ou childIndex>= 0, le lien doit être établi entre le module nouvellement ajouté de rang parentIndex ou childIndex.
    // Si parentIndex ou childIndex < 0, le lien doit être établi avec le module ayant l'Id correspondant à parentId ou childId.
    addModulesSet: function (modules) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/addModulesWithParamsAndLinks',
        modules
      );
    },

    /**
    * Retourne les paramètres par défaut d'un module
    * @public
    * @param  string[] moduletypes  une liste de types de modules (cf addModules pour la iste des types de modules) { moduleType: string, moduleName: string, moduleParams: {...}} spécifiant les modules à ajouter avec leurs paramètres
    * @return {Deferred}            un objet deferred
    */
    getModulesDefaultParameters: function (moduletypes) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/getModulesDefaultParameters',
        { ModuleTypes: moduletypes }
      );
    },

    /**
    * Suppression d'un module et des liens au départ et à l'arrivée du module
    * Cette méthode déclenche automatiquement une exécution de l'organigramme. Il faut appeler executeDiagramAsync(false, true) pour suivre la progression de cette exécution.
    * @public
    * @param  {int} moduleId identifiant du module
    * @return {Deferred}            un objet deferred
    */
    removeModule: function (moduleId) {
      return this.removeModules(moduleId);
    },

    /**
    * Suppression d'un ou plusieurs modules
    * Cette méthode déclenche automatiquement une exécution de l'organigramme. Il faut appeler executeDiagramAsync(false, true) pour suivre la progression de cette exécution.
    * @public
    * @param  {int|int[]} moduleId identifiant du ou des modules à supprimer. Si plusieurs identifiants sont spécifiés, il est possible de passer soit un paramètre contenant un tableau d'entier ou `n` paramètres, chacun étant de type entier.
    * @return {Deferred}            un objet deferred
    */
    removeModules: function (/* moduleId1, moduleId2, ... */) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/removeModules',
        {
          Modules: (Array.isArray(arguments[0]) ? arguments[0] : Array.apply(null, arguments))
                   .map(function (id) { return { moduleId: id }; })
        }
      );
    },

    /**
    * Change le nom d'un module
    * @public
    * @param  {int} moduleId    identifiant du module
    * @param  {string} name     nouveau nom
    * @return {Deferred}            un objet deferred
    */
    renameModule: function (moduleId, name) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/renameModule',
        { moduleId: moduleId, moduleName: name }
      );
    },

    /**
    * Déplace un module
    * @public
    * @param  {int} moduleId    identifiant du module
    * @param  {int} posX        nouvelle position du module en px
    * @param  {int} posY        nouvelle position du module en px
    * @return {Deferred}            un objet deferred
    */
    moveModule: function (moduleId, posX, posY) {
      return this.moveModules([{ moduleId: moduleId, posX: posX, posY: posY}]);
    },

    /**
    * Déplace plusieurs modules
    * @public
    * @param  {object[]} list    une liste de { moduleId : int, posX: int, posY : int } spécifiant les nouvelles positions de chacun des modules déplacés
    * @return {Deferred}            un objet deferred
    */
    moveModules: function (list /* of {moduleId, posX, posY} */) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/moveModules',
        { Modules: list }
      );
    },

    /**
    * Crée un ensemble de liens entre des modules parents et des modules enfants
    * Cette méthode déclenche automatiquement une exécution de l'organigramme. Il faut appeler executeDiagramAsync(false, true) pour suivre la progression de cette exécution.
    * @public
    * @param  {object[]} modules      liste d'objets { parentModuleId: int, childModuleId: int } spécifiant les modules parent et enfant du lien à créer
    * @return {Deferred}            un objet deferred
    */
    //linkModules: function ([{parentModuleId: ..., childModuleId: ...}, ...]) {

    /**
    * Crée un lien entre un module parent et un module enfant
    * Cette méthode déclenche automatiquement une exécution de l'organigramme. Il faut appeler executeDiagramAsync(false, true) pour suivre la progression de cette exécution.
    * @public
    * @param  {int} parentModuleId   identifiant du module parent
    * @param  {int} childModuleId    identifiant du module enfant
    * @return {Deferred}            un objet deferred
    */
    linkModules: function (parentModuleId, childModuleId) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/linkModules',
        { Links: ((arguments.length == 2) ? [{ parentModuleId: parentModuleId, childModuleId: childModuleId}] : arguments[0]) }
      );
    },

    /**
    * Supprime un ensemble de liens entre des modules parents et des modules enfants
    * Cette méthode déclenche automatiquement une exécution de l'organigramme. Il faut appeler executeDiagramAsync(false, true) pour suivre la progression de cette exécution.
    * @public
    * @param  {object[]} modules      liste d'objets { parentModuleId: int, childModuleId: int } spécifiant les modules parent et enfant du lien à supprimer
    * @return {Deferred}            un objet deferred
    */
    //unlinkModules: function ([{parentModuleId: ..., childModuleId: ...}, ...]) {

    /**
    * Supprime un lien entre un module parent et un module enfant
    * Cette méthode déclenche automatiquement une exécution de l'organigramme. Il faut appeler executeDiagramAsync(false, true) pour suivre la progression de cette exécution.
    * @public
    * @param  {int} parentModuleId   identifiant du module parent
    * @param  {int} childModuleId    identifiant du module enfant
    * @return {Deferred}            un objet deferred
    */
    unlinkModules: function (parentModuleId, childModuleId) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/unlinkModules',
        { Links: ((arguments.length == 2) ? [{ parentModuleId: parentModuleId, childModuleId: childModuleId}] : arguments[0]) }
      );
    },


    /**
    * Enregistre le déplacement d'un ensemble de légendes dans une vue interactive
    * @public
    * @param {int}    webDisplayingModuleId  Identifiant de la vue web dans laquelle la légende est déplacée
    * @param {object[]} captionPositions     liste d'objet { ModuleId: int, CaptionType: string, Posx: string, Posy: string, Container: string } spécifiant un déplacement de légende
    * @return {Deferred}            un objet deferred
    */
    //setCaptionPositions: function (webDisplayingModuleId, [{ ModuleId: ..., CaptionType: ..., Posx: ..., Posy: ... , Container: ... }, ...]) {

    /**
    * Enregistre le déplacement d'une légende dans une vue interactive
    * @public
    * @param {int}    webDisplayingModuleId  Identifiant de la vue web dans laquelle la légende est déplacée
    * @param {int}    moduleId               Identifant du module auquel appartient la légende. `0` pour les légende n'appartenant à aucun module
    * @param {string} captionType            Type de légende. `Default` pour les modules n'ayant qu'un seul type de légende. Comprend un identifiant pour les légendes n'appartenant à aucun module
    * @param {string} x                      Position horizontale
    * @param {string} y                      Position vertical
    * @param {string} container              Conteneur "map", "side" ou "auto"
    * @return {Deferred}            un objet deferred
    */
    setCaptionPositions: function (webDisplayingModuleId, moduleId, captionType, x, y, container) {
      this._setDiagramModified(true);
      return ajaxCors(
        'modules/setCaptionPositions',
        {
          WebDisplayingModuleId: webDisplayingModuleId,
          CaptionPositions: ((arguments.length == 6) ?
            [{ ModuleId: moduleId, CaptionType: captionType, Posx: x, Posy: y, Container: container }] :
            arguments[1])
        }
      );
    },

    /**
    * Ajoute un texte sur la visu
    * @public
    * @param {int} moduleId Identifiant de la visualisation dans laquelle le texte doit être ajouté
    * @return {Deferred}            un objet deferred
    */
    addComment: function (moduleId) {
      this._setDiagramModified(true);
      return ajaxCors('modules/addComment', { ModuleId: moduleId });
    },

    /**
    * Obtient la liste des modules compatibles avant ou après un module donné
    * @public
    * @param  {int} moduleId     identifiant du module
    * @return {Deferred}            un objet deferred
    */
     getCompatibleModules: function (moduleId) {
      return ajaxCors(
        'modules/getCompatibleModules',
        { ModuleId: moduleId }
      );
    },

    /**
    * Obtient la liste des modules connectables
    * @public
    * @param  {int} moduleId     identifiant du module
    * @return {Deferred}            un objet deferred
    */
     getLinkableModules: function (moduleId) {
      return ajaxCors(
        'modules/getLinkableModules',
        { ModuleId: moduleId }
      );
    },

    /**
    * Vérifie si une formule est valide ou non
    * @public
    * @param  {int} moduleId         identifiant du module
    * @param  {string} formula       la formule à valider
    * @param  {int[]} inputsOrder    ordre des entrées
    * @return {Deferred}             un objet deferred
    */
    checkFormula: function (moduleId, formula, inputsOrder) {
      //      return window.jQuery.Deferred().resolve({ IsValid: Math.random() > 0.2 });
      return ajaxCors(
        'modules/checkFormula',
        { ModuleId: moduleId, Formula: formula, InputsOrder: inputsOrder }
      );
    },

    /**
    * Récupère le tooltip pou run module donné de l'organigramme
    * @public
    * @param  {int} moduleId     identifiant du module
    * @return {Deferred}       un objet deferred
    */
    getModuleTooltip: function (moduleId) {
      return ajaxCors(
        'modules/getModuleTooltip',
        { ModuleId: moduleId }
      );
    },

    /**
    * récupère la réponse à la requête GetCapabilities d'un serveur WMS
    * @public
    * @param  {string} url          l'url de base du serveur WMS
    * @param  {string} version      la version du standard WMS à utiliser. Une valeur parmi ["defaultVersion", "version1_0_0", "version1_1_0", "version1_1_1", "version1_3_0"]
    * @return {Deferred}            un objet deferred
    */
    rasterGetCapabilities: function (url, version) {
      return ajaxCors(
        'modules/rasterGetCapabilities',
        { Url: url, Version: version }
      );
    },

    /**
     * Ajoute une image dans la mise en page (visible uniquement dans les exports)
     * @public
     * @param  {int} moduleId        identifiant du module visualisation
     * @param  {int} documentId      identifiant du document image
     * @return {Deferred}            un objet deferred
     */
    addDisplayingImage: function(moduleId, documentId) {
      wsc._setDiagramModified(true);
      return ajaxCors(
        'modules/addDisplayingImage',
        { DisplayingModuleId: moduleId, ImageDocumentId: documentId }
      );
    },

    /**
     * [removeDisaplyingImage description]
     * @public
     * @param  {int} moduleId        identifiant du module visualisation
     * @param  {int} imageId         identifiant de l'image dans la mise en page (`UniqueId` dans le retour de la fonction `getExportLayout`)
     * @return {Deferred}            un objet deferred
     */
    removeDisplayingImage: function(moduleId, imageId) {
      wsc._setDiagramModified(true);
      return ajaxCors(
        'modules/removeDisplayingImage',
        { DisplayingModuleId: moduleId, ImageContainerId: imageId }
      );
    },

    /**
     * Récupère un ratio 
     * @public
     * @param  {int} moduleId        identifiant du module visualisation
     * @return {Deferred}            un objet deferred
     */
    getWorldToPixRatio: function(moduleId) {
      return ajaxCors(
        'modules/getWorldToPixRatio',
        { moduleId: moduleId }
      );
    },

    /**
    * Modifie la projection et/ou l'unité de la carte
    * @public
    * @param {string} projection une valeur parmi ["unknown", "latlong", "crast", "denoy", "eck1", "eck2", "eck3", "eck4", "eck5", "cea", "fouc_s", "gall", "gins8", "hatano", "kav7", "lagrng", "lamb93", "lambI", "lambII", "lambIIet", "lambIII", "lambIV", "lask", "merc", "merc_bing", "merchI", "merchII", "merchIII", "merchIV", "mill", "moll", "poly", "putp1", "putp2", "putp4p", "tpers", "vandg4", "wag2", "wag3", "wag4", "wag5", "wag6", "weren", "wink1", "wink2"]
    * @param {string} unit       une valeur parmi ["millimeterUnit", "centimeterUnit", "meterUnit", "kilometerUnit", "mileUnit", "yardUnit", "feetUnit", "inchUnit"]
    * @return {Deferred}       un objet deferred
    */
    setDiagramProjection: function (projection, unit) {
      this._setDiagramModified(true);
      return ajaxCors(
        'diagram/setDiagramProjection',
        { Projection: { ProjCode: projection, MapUnit: unit } }
      );
    },

    /**
    * Annule la dernière action utilisateur
    * @public
    * @return {Deferred}            un objet deferred
    */
    undo: function () {
      var self = this;
      this._setDiagramModified(true);
      return ajaxCors('diagram/undo', {})
        .done(function (data) {
          if (!data.IsUndoEnabled) {
            self._setDiagramModified(false);
          }
          self._setUndoRedoPossible('undo', !!data.IsUndoEnabled);
          self._setUndoRedoPossible('redo', true);
        });
    },

    /**
    * Refait la dernière action utilisateur annulée
    * @public
    * @return {Deferred}            un objet deferred
    */
    redo: function () {
      var self = this;
      this._setDiagramModified(true);
      return ajaxCors('diagram/redo', {})
        .done(function (data) {
          self._setUndoRedoPossible('redo', !!data.IsRedoEnabled);
          self._setUndoRedoPossible('undo', true);
        });
    },

    /**
    * Appel régulier pour suivre l'avancement de l'exécution de l'organigramme
    * @private
    * @param  {Deferred}      deferred  l'objet deferred à valider lorsque l'exécution de l'organigramme sera terminée
    * @param  {int}           moduleId  identifiant du module
    * @return {void}
    */
    _pollExecutionStatus: function (deferred, moduleId) {
      var self = this,
      interval = setInterval(function () {
        ajaxCors('diagram/getExecutionStatus', {})
        .done(function (data) {
          data.moduleId = moduleId;
          if (data.executionStatus == 'completedJob') {
            deferred.resolve();
            self.fire('executeDiagramAsync.end', data);
            if (data.diagramHasChanged) {
              self._setDiagramModified(true);
            }
            clearInterval(interval);
          } else if (data.executionStatus == 'errorJob') {
            deferred.reject(null, data.description);
            self.fire('executeDiagramAsync.end', data);
            clearInterval(interval);
          // } else if (data.executionStatus == 'errorJob') {
          //   deferred.reject(null, data.description);
          //   self.fire('executeDiagramAsync.end', data);
          //   clearInterval(interval);
          } else {
            deferred.notify(data);
          }
        })
        .fail(function () {
          deferred.reject(null, __('Rechargement de la page nécessaire'));
          sessionExpired();
          clearInterval(interval);
        });
      }, 1000);
    },

    /**
    * Lance l'éxécution de l'organigramme
    * @public
    * @param  {bool} [executeAll=false]      si `true`, force l'exécution complète de l'organigramme (vide le cache)
    * @param  {bool} [alreadyRunning=false]  si `true`, spécifie que l'organigramme est déjà en cours d'exécution. On se contente alors de suivre l'exécution en cours sans en relancer un nouvelle. Cette option est nécessaire pour les fonctions d'ajout et de suppression de lien qui exécute automatiquement l'organigramme.
    * @param  {int}  [moduleId=-1]           identifiant du module, le cas échéant (e.g. secto manuelle)
    * @return {Deferred}            un objet deferred
    */
    executeDiagramAsync: function (executeAll, alreadyRunning, moduleId) {
      var deferred = window.jQuery.Deferred();
      this.fire('executeDiagramAsync.start');
      if (alreadyRunning) {
        this._pollExecutionStatus(deferred, moduleId);
      } else {
        ajaxCors('diagram/executeThread', { executeAll: !!executeAll, moduleId: moduleId || -1 })
        .done(checkMaintenance)
        .then(this._pollExecutionStatus.bind(this, deferred, moduleId))
        .fail(function () {
          deferred.reject();
        });
      }
      return deferred.promise();
    },

    /**
    * Récupération des paramètres d'un export ainsi que de la description de l'interface d'édition de l'export
    * @public
    * @param {int} exportId   identifiant de l'export
    * @return {Deferred}      un objet deferred
    */
    getExportGuiDescription: function (exportId) {
      return ajaxCors(
        'exports/getExportGuiDescription',
        { ExportId: exportId }
      );
    },

    /**
    * Change les paramètres d'un export et en même temps, retourne la nouvelle description de l'interface d'édition du module
    * @param {int} exportId   identifiant de l'export
    * @param {object} data    nouveaux paramètres de l'export. Doit contenir tous les paramètres de l'export, même ceux n'étant pas modifiés. Il est de pratique courante de récupérer les paramètres via `getExportParameters()`, de modifier certaines propriétés puis de les sauvegarder par un appel à `setExportParameters()`.
    * @return {Deferred}            un objet deferred
    */
    setGetExportGuiDescription: function (exportId, data) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/setGetExportGuiDescription',
        { exportId: exportId, exportParams: data }
      );
    },

    /**
     * Récupération de la taille du document et de son orientation pour une visualisation donnée
     * @param  {int} moduleId  identifiant du module visualisation
     * @return {Deferred}      un objet deferred
     */
    getExportPageSizeAndOrientation: function(moduleId) {
      var self = this;
      return ajaxCors(
        'exports/getPageSizeAndOrientation',
        { ModuleId: moduleId }
      ).done(function(data) { self.fire('getExportPageSizeAndOrientation.end', data); });
    },

    /**
     * Affectation d'une nouvelle taille de document pour un visualisation donnée
     * @param {int} moduleId  identifiant du module visualisation
     * @param {object} data   la taille et l'orientation du document
     * @param {Deferred} data un objet deferred
     */
    setExportPageSize: function(moduleId, data) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/setPageSize',
        { displayingModuleId: moduleId, pageParam: data }
      );
    },

    /**
     * Affectation d'une nouvelle orientation de document pour un visualisation donnée
     * @param {int} moduleId  identifiant du module visualisation
     * @param {object} data   la taille et l'orientation du document
     * @param {Deferred} data un objet deferred
     */
    setExportPageOrientation: function(moduleId, data) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/setPageOrientation',
        { displayingModuleId: moduleId, pageParam: data }
      );
    },

    /**
     * Récupération du layout des éléments pour une visualisation donnée
     * @param  {int} moduleId  identifiant du module visualisation
     * @return {Deferred}      un objet deferred
     */
    getExportLayout: function(moduleId) {
      var self = this;
      this.fire('getExportLayout.start');
      return ajaxCors(
        'exports/getLayout',
        { ModuleId: moduleId }
      ).done(function() { self.fire('getExportLayout.end'); });
    },

    /**
     * Affectation d'un layout pour un visualisation donnée
     * @param {int} moduleId   identifiant du module visualisation
     * @param {object} data    le layout des éléments
     * @param {Deferred} data  un objet deferred
     */
    setExportLayout: function(moduleId, data) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/setLayout',
        { displayingModuleId: moduleId, layout: data }
      );
    },

    /**
    * Ajout d'un export
    * @public
    * @param {string} exportType   type d'export à créer. Voir les valeurs possibles dans exports-list.js => "Data_txt", "Data_csv", "Data_xls", "Data_xlsx", "Data_sql", "Bitmap_png"
    * @return {Deferred}            un objet deferred
    */
    addExport: function (exportType) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/addExport',
        {ExportType: exportType}
      );
    },

    /**
    * Suppression d'un export
    * @public
    * @param  {int[]} [exportIds]   identifiants des exports à supprimer
    * @return {Deferred}            un objet deferred
    */
    removeExports: function (exportIds) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/removeExports',
        {Exports: exportIds.map(function (id) { return { ExportId: id }})}
      );
    },

    /**
    * Modification des paramètres d'un export
    * @public
    * @param  {int} [exportId]      identifiant de l'export à exécuter
    * @param  {object} data    nouveaux paramètres de l'export. Doit contenir tous les paramètres de l'export, même ceux n'étant pas modifiés. Il est de pratique courante de récupérer les paramètres via `getExportParameters()`, de modifier certaines propriétés puis de les sauvegarder par un appel à `setExportParameters()`.
    * @return {Deferred}            un objet deferred
    */
    setExportParameters: function (exportId, data) {
      this._setDiagramModified(true);
      return ajaxCors(
        'exports/setExportParameters',
        { exportId: exportId, exportParams: data }
      );
    },

    /**
    * Execution synchrone d'un export
    * @public
    * @param  {int} [exportId]      identifiant de l'export à exécuter
    * @param {bool} [isStream]      si vrai, le fichier est sauvegardé dans le dossier tmp/ pour téléchargement direct et n'est pas sauvegardé en BDD
    * @return {Deferred}            un objet deferred
    */
    executeExport: function (exportId, isStream) {
      return ajaxCors(
        'exports/ExecuteSingleExport',
        { exportId: exportId, isStream: !!isStream }
      );
    },


    /**
    * Appel régulier pour suivre l'avancement de l'exécution d'un export
    * @private
    * @param  {Deferred}      deferred  l'objet deferred à valider lorsque l'exécution de l'export sera terminée
    * @return {void}
    */
    _pollExportStatus: function (deferred) {
      var self = this,
      interval = setInterval(function () {
        ajaxCors('exports/getExportExecutionStatus', {})
        .done(function (data) {
          if (data.executionStatus == 'completedJob') {
            self.fire('executeExportAsync.end', data);
            deferred.resolve(data);
            clearInterval(interval);
          } else if (data.executionStatus == 'errorJob') {
            self.fire('executeExportAsync.end', data);
            deferred.reject(null, data);
            clearInterval(interval);
          } else {
            deferred.notify(data);
          }
        });
      }, 1000);
    },

    /**
    * Lance l'éxécution de l'export
    * @public
    * @param  {int} [exportId]      identifiant de l'export à exécuter
    * @param {bool} [isStream]      si vrai, le fichier est sauvegardé dans le dossier tmp/ pour téléchargement direct et n'est pas sauvegardé en BDD
    * @return {Deferred}            un objet deferred
    */
    executeExportAsync: function (exportId, isStream) {
      var deferred = window.jQuery.Deferred();
      var self = this;
      this.fire('executeExportAsync.start');
      ajaxCors('exports/ExecuteSingleExportThread', { exportId: exportId, isStream: !!isStream })
        .then(function(data){
          if(data.executionStatus == 'errorJob'){
            self.fire('executeExportAsync.end', data);
            deferred.reject(null, data);
          }
          else{
            self._pollExportStatus(deferred);
          }
        })
        .fail(function () {
          deferred.reject();
        });
      return deferred.promise();
    },

    /**
     * calcul d'un itinéraire
     * @param  {Point} latlng1       le point de départ pour le calcul de l'itinéraire sous la forme {"x": <float>, "y": <float>}
     * @param  {Point} latlng2       le point d'arrivée pour le calcul de l'itinéraire sous la forme {"x": <float>, "y": <float>}
     * @param  {Object} options      les options
     *                                 - 'TransportMode' : string ("Pedestrian", "PassengerCar", "Taxi", "PublicBus", "DeliveryTruck", "Bicycle" ou "Emergency")
     *                                 - 'IsFastest'     : bool (true : chemin le plus rapide, false : chemin le plus court)
     *                                 - 'HasNoToll'     : bool
     *                                 - 'HasNoMotorWay' : bool
     *                                 - 'HasNoFerry'    : bool
     *                                 - 'TruckProfile'  : object
     *                                   - 'MaxSpeed'       : vitesse maximale [0; 115] (km/h)
     *                                   - 'VehicleProfile' : object
     *                                     - 'HazmatRestriction': { 'HazMat': string } ("NONE", "ALL", "EXPLOSIVE", "WATER", "US_EXPLOSIVES", "US_GAS", "US_FLAM", "US_FLAM_SOL", "US_ORGANIC", "US_POISON", "US_RADIO_ACTIVE", "US_CORROSIVE", "US_OTHER", "US_PIH")
     *                                     - 'Height'           : hauteur du véhicule (cm)
     *                                     - 'Length'           : longueur du véhicule (cm)
     *                                     - 'Weight'           : poids du véhicule (en dixième de tonnes métriques)
     *                                     - 'WeightAxle'       : poids maxi du véhicule par essieu (en dixième de tonnes métriques)
     *                                     - 'Width'            : largeur du véhicule (cm)
     * @return {Deferred}            un objet deferred
     */
    computeRoute: function(latlng1, latlng2, options) {
      options = options || {};
      return ajaxCors(
        'benomad/computeRoute',
        {
          StartPoint:     latlng1,
          StopPoint:      latlng2,
          TransportMode:  options.TransportMode || 'PassengerCar', // string ("Pedestrian", "PassengerCar", "Taxi", "PublicBus", "DeliveryTruck", "Bicycle" ou "Emergency")
          IsFastest:      options.IsFastest !== false,
          HasNoToll:      !! options.HasNoToll,
          HasNoMotorWay:  !! options.HasNoMotorWay,
          HasNoFerry:     !! options.HasNoFerry,
          TruckProfile:   options.TruckProfile || null
        }
      );
    },

    /**
    * Ajoute un module à la bibliothèque de modules de l'utilisateur
    * @public
    * @param  {int} moduleId     identifiant du module
    * @return {Deferred}            un objet deferred
    */
    addModuleToLibrary: function (moduleId) {
      return ajaxCors(
        'modules/addModuleToLibrary',
        { ModuleId: moduleId }
      );
    },

    /**
    * Mettre à jour la bibliothèque de modules de l'utilisateur
    * @public
    * @param  {object[]} modules    Tableau d'objets { id: int, type: string, name: string}. Ce tableau représente la liste des modules de la bibliothèque
    * @return {Deferred}            un objet deferred
    */
    updateLibrary: function (modules) {
      return ajaxCors(
        'modules/updateLibrary',
        { modules: modules }
      );
    },

    /**
    * Récupérer le code benomad du webservice
    * @public
    * @return {Deferred}            un objet deferred
    */
    getSHUID: function(){
      return ajaxCors(
        'administration/getSHUID',
        {}
      );
    },

    /**
    * Annule l'exécution de l'export précédemment lancé
    * [TODO] Pas dispo dans l'API web service
    * @public
    * @return {Deferred}            un objet deferred
    */
    // cancelExport: function () {
    //   return true;
    // },

    /**
    * Ajoute un listener
    * @public
    * @param  {string}   event nom de l'évènement
    * @param  {function} cb    callback à exécuter lorsque que l'évènement est déclenché
    * @return {void}
    */
    on: function (event, cb) {
      this.listeners[event] = this.listeners[event] || [];
      this.listeners[event].push(cb);
    },

    /**
    * Supprimer un listener
    * @public
    * @param  {string}   event nom de l'évènement
    * @param  {function} cb    callback à supprimer
    * @return {void}
    */
    off: function (event, cb) {
      if (this.listeners[event] && this.listeners[event].length) {
        for (var i = this.listeners[event].length - 1; i >= 0; i--) {
          if (this.listeners[event][i] == cb) {
            this.listeners[event].splice(i, 1);
            return;
          }
        }
      }
    },

    /**
    * Déclenche un évènement
    * @public
    * @param  {string} event  nom de l'évènement
    * @param  {object} params paramètre à transmettre aux callbacks enregistrées pour cet évènement
    * @return {void}
    */
    fire: function (event, params) {
      if (this.listeners[event] && this.listeners[event].length) {
        for (var i = this.listeners[event].length - 1; i >= 0; i--) {
          this.listeners[event][i](params);
        }
      }
    },

    isDisabledExecution: function() { return wsc.disabledExecution;}
  };

  /**
   * utilisation de IndexedDB pour stocker les fonds de cartes de manière pérenne dans le cache du navigateur
   * (ré-utilisation entre différents organigrammes ou entre différents onglets)
   *
   * 1 base `articqueCDonlineCache` est utilisée qui contient 2 stores `maps` et `sizes`
   * Les informations ont été séparée en 2 stores pour pouvoir récupérer les tailles (ce qui est fait systématiquement) sans extraire les fonds de carte.
   * En effet, l'extraction d'un enregistrement implique la création de l'objet JS correspondant et peut s'avérer lourd pour des gros fonds de cartes
   *
   * Des limites sont appliquées pour le stockage des éléments :
   *  - un fond de carte ne peut pas dépasser 45Mo. Si c'est le cas, il n'est pas sauvé dans la base IndexedDB
   *  - l'ensemble des fonds de carte ne peut pas dépasser 100Mo. Si c'est le cas, les fonds de carte les plus vieux
   *    sont supprimés de la base jusqu'à repasser sous la barre des 100 Mo
   *
   * Le store `maps` dispose d'un index `timestamp` afin de récupérer les fonds de cartes du plus vieux au plus récent.
   */

  var idb = {
    VERSION: 1,
    DB_NAME: 'articqueCDonlineCache',
    STORE_NAME: 'maps',
    SIZE_STORE_NAME: 'sizes',
    MAX_RECORD_SIZE: 45*1024*1024, // 45 Mo maximum par enregistrement
    MAX_DB_SIZE: 100*1024*1024, // 100 Mo maximum pour la base complète
    DO_NOTHING: function() {},

    initialized: false,

    _db: null,

    _hashes: [],

    _sizes: {},

    /**
     * Méthode d'intialisation : si besoin, création puis connexion à la base. Puis récupération des hash et des tailles correspondantes
     * @private
     * @return {this}
     */
    _init: function() {
      this.initialized = true;
      if (window.indexedDB && IDBIndex.prototype.openKeyCursor) {
        // on exclut IE qui ne supporte ni la méthode IDBObjectStore.getAllKeys, ni la méthode IDBIndex.openKeyCursor
        // (ce qui oblige à récupérer le contenu complet des enregistrements pour connaitre la liste des fonds de carte disponible
        // et qui ralentit trop le navigateur du fait du poids des objets JS à créer)
        try {
          var openReq = window.indexedDB.open(this.DB_NAME, this.VERSION);

          // après ouverture de la base
          openReq.onsuccess = function(evt) {
            try {
              idb._db = evt.target.result
              // callback universelle pour toutes les fonctions (sauf celles déclarant un `onerror` custom)
              idb._db.onerror = function(evt) {
                console.log("indexedDB error: ", evt); // eslint-disable-line no-console
              };

              // extraction de la liste des hash stockés en base
              idb._db
                  .transaction(idb.STORE_NAME, 'readonly')
                  .objectStore(idb.STORE_NAME)
                  .index('timestamp') // on passe par un index pour Edge qui ne dispose pas de la openKeyCursor() sur le store
                  .openKeyCursor() // on parcourt les clés (hash) des cartes
                  .onsuccess = function(evt) {
                    if (evt.target.result) {
                      try {
                        if (window._articqueDebug) { console.log("IndexedDB - retrieving hash " + evt.target.result.primaryKey); } // eslint-disable-line no-console
                        idb._hashes.push(evt.target.result.primaryKey); // sauvegarde dans une variable locale
                        evt.target.result.continue();
                      } catch (error) {
                        if (window._articqueDebug) { console.log("IndexedDB - error while getting hashes list", error); } // eslint-disable-line no-console
                      }
                    } else {
                      // parcours des clés du store `maps` terminé
                      try {
                        // extraction de la liste des tailles (en octets pour chaque hash)
                        // on en profite pour supprimer les tailles dont le hash correspondant n'est plus stocké
                        if (window._articqueDebug) { console.log("IndexedDB - retrieving sizes"); } // eslint-disable-line no-console
                        idb._db
                          .transaction(idb.SIZE_STORE_NAME, 'readwrite')
                          .objectStore(idb.SIZE_STORE_NAME)
                          .openCursor()
                          .onsuccess = function(evt) {
                            var cursor = evt.target.result;
                            if(cursor) {
                              if (idb._hashes.indexOf(cursor.primaryKey) < 0) {
                                if (window._articqueDebug) { console.log("IndexedDB - removing size for " + cursor.primaryKey); } // eslint-disable-line no-console
                                cursor.delete();
                              } else {
                                idb._sizes[cursor.value.hash] = cursor.value.size;
                              }
                              cursor.continue();
                            }
                          };
                      } catch(error) {
                        if (window._articqueDebug) { console.log("IndexedDB - error while getting sizes", error); } // eslint-disable-line no-console
                      }
                    }
                  };
            } catch(error) {
              if (window._articqueDebug) { console.log("IndexedDB - error while getting map keys", error); } // eslint-disable-line no-console
            }
          };

          // lorsque la base n'existe pas, il faut la créer
          openReq.onupgradeneeded = function(evt) {
            try {
              idb._db = evt.target.result
              idb._db.createObjectStore(idb.SIZE_STORE_NAME); // creation du store `sizes`
              idb._db.createObjectStore(idb.STORE_NAME).createIndex('timestamp', 'timestamp', { unique: false }); // creation du store `maps`
            } catch (error) { idb.DO_NOTHING(); }
          };
        } catch(error) {
          if (window._articqueDebug) { console.log("IndexedDB - error while opening IndexedDB", error); } // eslint-disable-line no-console
        }
      } else if (window._articqueDebug) { console.log("IndexedDB - no support"); } // eslint-disable-line no-console
      return this;
    },

    /**
     * Méthode de stockage d'un fond de carte dans IndexedDB
     * @public
     * @param  {string} hash
     * @param  {Object} data
     * @return {void}
     */
    cache: function(hash, data) {
      // si on n'est pas connecté à IndexedDB ou que ce hash est déjà stocké, on ne fait rien
      if (idb._db  && idb._hashes.indexOf(hash) == -1) {
        try {
          // on calcule la taille aproximative de l'enregistrement en base
          var size = 500;
          for(var i = data.GeomList.length - 1; i >= 0; i--) {
            size += data.GeomList[i].geom.length + data.GeomList[i].id.length + data.GeomList[i].name.length + 10;
          }
          // si la taille ne dépasse pas la limite fixée
          if (size < idb.MAX_RECORD_SIZE) {
            if (window._articqueDebug) { console.log("IndexedDB - adding map " + hash + " (size " + size + ")"); } // eslint-disable-line no-console
            var addRequest = idb._db
                .transaction(idb.STORE_NAME, 'readwrite') // 'readwrite' pour pouvoir ajouter un élément
                .objectStore(idb.STORE_NAME)
                .add(
                  {
                    timestamp: (new Date()).getTime(), // pour indexation
                    map: { // on recréé un nouvel objet pour ne pas stocker la propriété `features`
                      Count: data.Count,
                      GeomList: data.GeomList,
                      Hash: data.Hash,
                      Id: data.Id
                    }
                  },
                  hash // clé primaire
                );
            addRequest.onerror = idb.DO_NOTHING; // on ne gère pas l'erreur (si l'enregistrement existe déjà)
            addRequest.onsuccess = idb._saveSize.bind(idb, hash, size); // si l'ajout s'est bien déroulé, on ajoute aussi la taille dans le store adequat
          }
        } catch (error) {
          if (window._articqueDebug) { console.log("IndexedDB - error while adding map", error); } // eslint-disable-line no-console
        }
      }
    },

    /**
     * Sauvegarde la taille en octet d'un fond de carte pour un hash donné
     * @private
     * @param  {string} hash
     * @param  {number} size
     * @return {void}
     */
    _saveSize: function(hash, size) {
      idb._hashes.push(hash);
      // sauvegarde la taille de la carte
      try {
        idb._sizes[hash] = size;
        // on commence par calculer le total stocké dans IndexedDB
        var totalSize = 0;
        for (var h in idb._sizes) {
          if (idb._hashes.indexOf(h) > -1) {
            totalSize += idb._sizes[h];
          }
        }
        if (window._articqueDebug) { console.log("IndexedDB - total size " + Math.round(totalSize / 1024 / 1024) + 'Mb'); } // eslint-disable-line no-console
        // si on depasse la limite fixée => on supprime les cartes les plus vielles
        if (totalSize > idb.MAX_DB_SIZE) {
          if (window._articqueDebug) { console.log("IndexedDB - data size > 100 Mb => will remove older maps"); } // eslint-disable-line no-console
          idb._db
              .transaction(idb.STORE_NAME, 'readwrite')
              .objectStore(idb.STORE_NAME)
              .index('timestamp')
              .openCursor() // on parcourt les cartes par date de stockage croissant (de la plus vielle à la plus récente)
              .onsuccess = function(evt) {
                if (evt.target.result) {
                  // on supprime la carte la plus vielle
                  try {
                    if (window._articqueDebug) { console.log("IndexedDB - removing map " + evt.target.result.primaryKey); } // eslint-disable-line no-console
                    totalSize -= (idb._sizes[evt.target.result.primaryKey] || 0);
                    var idx = idb._hashes.indexOf(evt.target.result.primaryKey);
                    if (idx > -1) {
                      idb._hashes.splice(idx, 1);
                    }
                    evt.target.result.delete();
                    // si on dépasse toujours la limite fixée, on continue les suppressions
                    if (totalSize > idb.MAX_DB_SIZE) {
                      evt.target.result.continue();
                    }
                  } catch (error) {
                    if (window._articqueDebug) { console.log("IndexedDB - error while removing map", error); } // eslint-disable-line no-console
                  }
                }
              };
        }

        // on ajoute la taille de la carte qui vient d'être ajoutée
        idb._db
            .transaction(idb.SIZE_STORE_NAME, 'readwrite')
            .objectStore(idb.SIZE_STORE_NAME)
            .add({ hash: hash, size: size }, hash)
            .onerror = idb.DO_NOTHING;
      } catch (error) {
        if (window._articqueDebug) { console.log("IndexedDB - error while saving size", error); } // eslint-disable-line no-console
      }
    },

    /**
     * récupération d'un fond de carte à partir de son hash
     * @public
     * @param  {string} hash
     * @return {jQuery.Deferred} une promesse qui sera résolue lorsque le fond de carte sera disponible.
     *                           Si le fond de carte n'est pas disponible ou si une erreur survient, la promesse sera résolue avec un résultat `null`
     */
    retrieve: function(hash) {
      var deferred = window.jQuery.Deferred();
      // si on n'est pas connecté à IndexedDB ou que ce hash est déjà stocké, on ne fait rien
      if (idb._db && idb._hashes.indexOf(hash) > -1) {
        try {
          var getRequest = idb._db
                .transaction(idb.STORE_NAME, 'readwrite') // readwrite car on veut mettre à jour le timestamp
                .objectStore(idb.STORE_NAME)
                .get(hash);
          getRequest.onsuccess = function(evt) {
            if (window._articqueDebug) { console.log("IndexedDB - retrieving map " + hash); } // eslint-disable-line no-console
            evt.target.result.timestamp = (new Date()).getTime();
            try {
              getRequest.transaction
                .objectStore(idb.STORE_NAME)
                .put(evt.target.result, hash);
            } catch (error) { if (window._articqueDebug) { console.log("IndexedDB - error while updating timestamp for " + hash, error); } } // eslint-disable-line no-console
            deferred.resolve(evt.target.result);
          }
          getRequest.onerror = function(evt) {
            if (window._articqueDebug) { console.log("IndexedDB - error while retrieving map " + hash, evt); } // eslint-disable-line no-console
            deferred.resolve(null);
          }
        } catch (error) {
          if (window._articqueDebug) { console.log("IndexedDB - error while retrieving map " + hash, error); } // eslint-disable-line no-console
          deferred.resolve(null);
        }
      } else { deferred.resolve(null); }
      return deferred.promise();
    },

    /**
     * Suppression de toutes les données stockées dans IndexedDB
     * @public
     * @return {void}
     */
    clear: function() {
      // si on n'est pas connecté à IndexedDB, on ne fait rien
      if (idb._db) {
        if (window._articqueDebug) { console.log("IndexedDB - clearing all records"); } // eslint-disable-line no-console
        try {
          idb._hashes = [];
          idb._sizes = {};
          // store `maps`
          idb._db
              .transaction(idb.STORE_NAME, 'readwrite')
              .objectStore(idb.STORE_NAME)
              .clear();
          // store `sizes`
          idb._db
              .transaction(idb.SIZE_STORE_NAME, 'readwrite')
              .objectStore(idb.SIZE_STORE_NAME)
              .clear();
        } catch (error) {
          if (window._articqueDebug) { console.log("IndexedDB - error while clearing records", error); } // eslint-disable-line no-console
        }
      }
    },

  }._init();

  if (typeof module !== 'undefined') {
    module.exports = wsc; // intégration via require
  } else {
    window.webserviceClient = wsc; // intégration directe
  }
})(window);