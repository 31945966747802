// https://github.com/AppGeo/geo

function $merge(first, second) { // from jquery
  var len = +second.length,
    j = 0,
    i = first.length;

  for ( ; j < len; j++ ) {
    first[ i++ ] = second[ j ];
  }

  first.length = i;

  return first;
}

function _flatten(geom) { // from https://github.com/AppGeo/geo/blob/master/js/jquery.geo.core.js
  // return an array of only geometries
  // will extract geometries from Feature, FeatureCollection, & GeometryCollection
  // not in JTS
  var geometries = [],
      curGeom = 0;
  switch (geom.type) {
    case "Feature":
      $merge(geometries, _flatten(geom.geometry));
      break;

    case "FeatureCollection":
      for (; curGeom < geom.features.length; curGeom++) {
        $merge(geometries, _flatten(geom.features[curGeom].geometry));
      }
      break;

    case "GeometryCollection":
      for (; curGeom < geom.geometries.length; curGeom++) {
        $merge(geometries, _flatten(geom.geometries[curGeom]));
      }
      break;

    default:
      geometries[0] = geom;
      break;
  }
  return geometries;
}

function _allCoordinates(geom) { // from https://github.com/AppGeo/geo/blob/master/js/jquery.geo.core.js
  // return array of all positions in all geometries of geom
  // not in JTS
  var geometries = _flatten(geom),
      curGeom = 0,
      result = [];

  for (; curGeom < geometries.length; curGeom++) {
    var coordinates = geometries[curGeom].coordinates,
        isArray = coordinates && Array.isArray(coordinates[0]),
        isDblArray = isArray && Array.isArray(coordinates[0][0]),
        isTriArray = isDblArray && Array.isArray(coordinates[0][0][0]),
        i, j, k;

    if (!isTriArray) {
      if (!isDblArray) {
        if (!isArray) {
          coordinates = [coordinates];
        }
        coordinates = [coordinates];
      }
      coordinates = [coordinates];
    }

    for (i = 0; i < coordinates.length; i++) {
      for (j = 0; j < coordinates[i].length; j++) {
        for (k = 0; k < coordinates[i][j].length; k++) {
          result.push(coordinates[i][j][k]);
        }
      }
    }
  }
  return result;
}

function pointToString(value) {
  return "POINT " + pointToUntaggedString(value.coordinates);
}

function pointToUntaggedString(coordinates) {
  if (!(coordinates && coordinates.length)) {
    return "EMPTY";
  } else {
    return "(" + coordinates.join(" ") + ")";
  }
}

function lineStringToString(value) {
  return "LINESTRING " + lineStringToUntaggedString(value.coordinates);
}

function lineStringToUntaggedString(coordinates) {
  if (!(coordinates && coordinates.length)) {
    return "EMPTY";
  } else {
    var points = [];

    for (var i = 0; i < coordinates.length; i++) {
      if (coordinates[i] && coordinates[i].length)
        points.push(coordinates[i].join(" "));
    }

    return "(" + points + ")";
  }
}

function polygonToString(value) {
  return "POLYGON " + polygonToUntaggedString(value.coordinates);
}

function polygonToUntaggedString(coordinates) {
  if (!(coordinates && coordinates.length)) {
    return "EMTPY";
  } else {
    var lineStrings = [];

    for (var i = 0; i < coordinates.length; i++) {
      lineStrings.push(lineStringToUntaggedString(coordinates[i]));
    }

    return "(" + lineStrings + ")";
  }
}

function multiPointToString(value) {
  return "MULTIPOINT " + lineStringToUntaggedString(value.coordinates);
}

function multiLineStringToString(value) {
  return "MULTILINESTRING " + polygonToUntaggedString(value.coordinates);
}

function multiPolygonToString(value) {
  return "MULTIPOLYGON " + multiPolygonToUntaggedString(value.coordinates);
}

function multiPolygonToUntaggedString(coordinates) {
  if (!(coordinates && coordinates.length)) {
    return "EMPTY";
  } else {
    var polygons = [];
    for (var i = 0; i < coordinates.length; i++) {
      polygons.push(polygonToUntaggedString(coordinates[i]));
    }
    return "(" + polygons + ")";
  }
}

function geometryCollectionToString(value) {
  return "GEOMETRYCOLLECTION " + geometryCollectionToUntaggedString(value.geometries);
}

function geometryCollectionToUntaggedString(geometries) {
  if (!(geometries && geometries.length)) {
    return "EMPTY";
  } else {
    var geometryText = [];
    for (var i = 0; i < geometries.length; i++) {
      geometryText.push(stringify(geometries[i]));
    }
    return "(" + geometries + ")";
  }
}

function stringify(value) {
  if (!(value && value.type)) {
    return "";
  } else {
    switch (value.type) {
      case "Point":
        return pointToString(value);

      case "LineString":
        return lineStringToString(value);

      case "Polygon":
        return polygonToString(value);

      case "MultiPoint":
        return multiPointToString(value);

      case "MultiLineString":
        return multiLineStringToString(value);

      case "MultiPolygon":
        return multiPolygonToString(value);

      case "GeometryCollection":
        return geometryCollectionToString(value);

      default:
        return "";
    }
  }
}

function pointParseUntagged(wkt) {
  var pointString = wkt.match(/\(\s*([E\d\.\-]+)\s+([E\d\.\-]+)\s*\)/);
  return pointString && pointString.length > 2 ? {
    type: "Point",
    coordinates: [
      parseFloat(pointString[1]),
      parseFloat(pointString[2])
    ]
  } : null;
}

function lineStringParseUntagged(wkt) {
  var lineString = wkt.match(/\s*\((.*)\)/),
    coords = [],
    pointStrings,
    pointParts,
    i = 0;

  if (lineString && lineString.length > 1) {
    pointStrings = lineString[1].match(/[E\d\.\-]+\s+[E\d\.\-]+/g);

    for (; i < pointStrings.length; i++) {
      pointParts = pointStrings[i].match(/\s*([E\d\.\-]+)\s+([E\d\.\-]+)\s*/);
      coords[i] = [parseFloat(pointParts[1]), parseFloat(pointParts[2])];
    }

    return {
      type: "LineString",
      coordinates: coords
    };
  } else {
    return null;
  }
}

function polygonParseUntagged(wkt) {
  var parts = wkt.split(/\),\(/),
      polygon,
      coords = [],
      coordsPart,
      pointStrings,
      pointParts,
      i,
      j = 0;

  for (; j < parts.length; j++) {
    polygon = ("(" + parts[j] + ")").match( /\s*\((.*)\)/ );

    if (polygon && polygon.length > 1) {
      pointStrings = polygon[1].match(/[E\d\.\-]+\s+[E\d\.\-]+/g);
      coordsPart = [];
      for (i = 0; i < pointStrings.length; i++) {
        pointParts = pointStrings[i].match(/\s*([E\d\.\-]+)\s+([E\d\.\-]+)\s*/);
        coordsPart[i] = [parseFloat(pointParts[1]), parseFloat(pointParts[2])];
      }
      if (coordsPart.length) {
        coords.push(coordsPart);
      }
    }
  }

  return coords.length ? { type: "Polygon", coordinates: coords } : null;
}

function multiPointParseUntagged(wkt) {
  var multiSomething;

  if (wkt.indexOf("((") === -1) {
    multiSomething = lineStringParseUntagged(wkt);
  } else {
    multiSomething = multiLineStringParseUntagged(wkt);
    multiSomething.coordinates = _allCoordinates(multiSomething);
  }

  multiSomething.type = "MultiPoint";

  return multiSomething;
}

function multiLineStringParseUntagged(wkt) {
  var lineStringsWkt = wkt.substr(2, wkt.length - 4),
    lineString,
    lineStrings = lineStringsWkt.split(/\),\s*\(/),
    i = 0,
    multiLineString = {
      type: "MultiLineString",
      coordinates: []
    };

  for (; i < lineStrings.length; i++) {
    lineString = lineStringParseUntagged("(" + lineStrings[i] + ")");
    if (lineString) {
      multiLineString.coordinates.push(lineString.coordinates);
    }
  }

  return multiLineString;
}

function multiPolygonParseUntagged(wkt) {
  var polygonsWkt = wkt.substr(1, wkt.length - 2),
    polygon,
    polygons = polygonsWkt.split(/\)\),\(\(/),
    i = 0,
    multiPolygon = {
      type: "MultiPolygon",
      coordinates: []
    };

  for (; i < polygons.length; i++) {
    polygon = polygonParseUntagged("((" + polygons[i] + "))");
    if (polygon) {
      multiPolygon.coordinates.push(polygon.coordinates);
    }
  }

  return multiPolygon;
}

function geometryCollectionParseUntagged(wkt) {
  var geometriesWkt = wkt.substr(1, wkt.length - 2),
    geometries = geometriesWkt.match(/\),[a-zA-Z]/g),
    geometryCollection = {
      type: "GeometryCollection",
      geometries: []
    },
    curGeom,
    i = 0, curStart = 0, curLen;

  if (geometries && geometries.length > 0) {
    for (; i < geometries.length; i++) {
      curLen = geometriesWkt.indexOf(geometries[i], curStart) - curStart + 1;
      curGeom = parse(geometriesWkt.substr(curStart, curLen));
      if (curGeom) {
        geometryCollection.geometries.push(curGeom);
      }
      curStart += curLen + 1;
    }

    // one more
    curGeom = parse(geometriesWkt.substr(curStart));
    if (curGeom) {
      geometryCollection.geometries.push(curGeom);
    }

    return geometryCollection;
  } else {
    return null;
  }
}

function parse(wkt) {
  wkt = wkt.trim();

  var typeIndex = wkt.indexOf("("),
    untagged = wkt.substr(typeIndex);

  switch (wkt.substr(0, typeIndex).trim().toUpperCase()) {
    case "POINT":
      return pointParseUntagged(untagged);

    case "LINESTRING":
      return lineStringParseUntagged(untagged);

    case "POLYGON":
      return polygonParseUntagged(untagged);

    case "MULTIPOINT":
      return multiPointParseUntagged(untagged);

    case "MULTILINESTRING":
      return multiLineStringParseUntagged(untagged);

    case "MULTIPOLYGON":
      return multiPolygonParseUntagged(untagged);

    case "GEOMETRYCOLLECTION":
      return geometryCollectionParseUntagged(untagged);

    default:
      return null;
  }
}

function isPointInPoly(pt, poly){
  /* jshint ignore:start */
  for(var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
    ((poly[i][0] <= pt[0] && pt[0] < poly[j][0]) || (poly[j][0] <= pt[0] && pt[0] < poly[i][0]))
    && (pt[1] < (poly[j][1] - poly[i][1]) * (pt[0] - poly[i][0]) / (poly[j][0] - poly[i][0]) + poly[i][1])
    && (c = !c);
  return c;
  /* jshint ignore:end */
}

/**
 * On tente de corriger les polygones créer via Leaflet.Editable
 * En effet, avec l'UI CDOnline, lorsqu'on ajoute un trou à un multipolygon, le trou est systématiquement ajouté
 * au premier polygon du multipolygon. L'objet de cette fonction est de déplacer le trou vers le bon polygon
 * Le raisonnement est basé sur l'appartenance du premier point d'un trou au polygon parent (on ignore les
 * autres points du trou). On suppose que l'utilisateur ne fait pas n'importe quoi. En particulier, si l'utilisateur
 * définit un trou à l'extérieur du polygon parent, cette erreur n'est ni signalée, ni corrigée
 * @param  geometry geométrie GeoJSON
 * @return la géométrie corrigée
 */
function correctPolygon(geometry) {
  var polygons, polygon, polygon2, p, p2, i, j, main, holes, hole;
  if (geometry && geometry.type == "MultiPolygon" && geometry.coordinates && geometry.coordinates.length > 1) {
    polygons = geometry.coordinates;
    for (p in polygons) {
      polygon = polygons[p];
      if (polygon.length > 1) { // contient des trous
        main = polygon[0];
        holes = [];
        i = 1;
        while (i < polygon.length) {
          // on vérifie que le premier point de définition du trou est bien à l'intérieur du polygone principal
          if (polygon[i][0] && ! isPointInPoly(polygon[i][0], main)) {
            // si ce n'est pas le cas, on supprime le trou et on le met de côté
            holes.push(polygon.splice(i, 1)[0]);
          } else {
            // on est à l'intérieur du polygone principal
            for (j = 1; j < polygon.length; j++) {
              // mais on est aussi à l'intérieur d'un trou donc on est pas dans le polygone principal
              if (j != i && polygon[i][0] && isPointInPoly(polygon[i][0], polygon[j])) {
                holes.push(polygon.splice(i, 1)[0]);
              }
            }
            i++;
          }
        }
        // s'il y a des trous à replacer
        while (holes.length) {
          hole = holes[0];
          for (p2 in polygons) {
            polygon2 = polygons[p2];
            if (p != p2 && hole && hole[0] && polygon2[0] && isPointInPoly(hole[0], polygon2[0])) {
              hole = null;
              polygon2.push(holes.splice(0, 1)[0]);
              // console.log("moving hole " + i + " from poly " + p + " to poly " + p2);
            }
          }
          // on a pas trouver où le mettre donc on le réinsert là où on l'a enlevé
          if (hole) {
            polygon.push(holes.splice(0, 1)[0]);
            // console.log("failed to move hole " + i + " from poly " + p);
          }
        }
      }
    }
  }
  return geometry;
}

module.exports = {
  parse: parse,
  stringify: stringify,
  correctPolygon: correctPolygon
};