var filtersUI = require("./atlas-filters-ui.js"),
    stringFormatter = require("./string-formatter.js"),
    $ = window.$ || window.jQuery,

dataTables = {
  pageCapacity: 100,
  allRows: {}, //Liste des valeurs
  exportRows: {}, //Liste des valeurs
  filters: {}, //Liste des filtres
  selections: {}, //Liste des selections
  currentTab: 0,
  currentPage: {}, // page en cours pour chaque dataspace
  sort: {}, //Liste des tris
  activeFilters: {}, //Liste des filters actifs

  render: function(parent, domId) {
    this._$domNode = $('<div class="data-tables" id="data-tables"></div>');
    this._parent = parent;
    $('#' + domId).append(this._$domNode);
  },

  /**
   * Initialise le dom et les événements pour les tableaux de données
   * @param  {object[]} datatables  Liste des tableaux de données
   * @param  {object[]} dataspaces  Liste des espaces de données
   * @return void
   */
  init: function(datatables, dataspaces) {
    var self = this,
        $node = this._$domNode;

    if (!$node.hasClass('initialized')) {
      $node
        .html('<div class="data-tables-controls">' +
          (navigator.msSaveBlob || typeof document.createElement("a").download != 'undefined' ?
            '<button class="btn btn-link btn-csv" title="' + __("export_table") + '"><span class="glyphicon glyphicon-export"></span> CSV</button>' :
            ''
          ) +
          '<button class="btn btn-link btn-fullscreen" title="' + __("fullscreen") + '"><span class="glyphicon glyphicon-resize-full"></span></button>' +
          '<button class="btn btn-link btn-close" title="' + __("close") + '"><span class="glyphicon glyphicon-remove"></span></button>' +
          '</div><div class="data-tables-tabs"></div>')
        .addClass('initialized');

      $node
        .off('DOMMouseScroll mousewheel', '.nav-tabs')
        .on('DOMMouseScroll mousewheel', '.nav-tabs', function(e) {
          this.scrollLeft += (e.originalEvent.detail < 0 || e.originalEvent.wheelDelta > 0) ? -30 : 30;
          e.preventDefault();
        })
        .find('.data-tables-controls .btn-fullscreen').click(function() {
          var fs = $node.toggleClass('fullscreen').hasClass('fullscreen');
          $(this).find('span.glyphicon').removeClass(fs ? 'glyphicon-resize-full' : 'glyphicon-resize-small').addClass(fs ? 'glyphicon-resize-small' : 'glyphicon-resize-full');
        });
      $node.find('.data-tables-controls .btn-close').click(function() { self._parent.togglePanel('datatables'); });
      $node
        .find('.data-tables-controls .btn-csv').click(function() {
          var idx = $node.find(".nav-tabs .active:last").data("idx"),
              cache = self._parent._cache[self._parent._currentFilename];
          if (typeof idx == 'number' && cache && cache.dataspaces && cache.datatables && cache.datatables[idx] && cache.datatables[idx].Name && cache.dataspaces[cache.datatables[idx].Name]) {
            var filtered = self.filters[cache.datatables[idx].Id];
            var rows = cache.dataspaces[cache.datatables[idx].Name],
                csv = '\uFEFF',
                row,
                processRow = function (row, forceQuote) {
                  var finalVal = '';
                  for (var j = 0; j < row.length; j++) {
                    var innerValue = row[j] === null ? '' : row[j].toString();
                    if (row[j] instanceof Date) {
                      innerValue = row[j].toLocaleString();
                    }
                    var result = innerValue.replace(/"/g, '""');
                    if (forceQuote || result.search(/("|;|,|\n)/g) >= 0)
                      result = '"' + result + '"';
                    if (j > 0)
                      finalVal += ';';
                    finalVal += result;
                  }
                  return finalVal + '\n';
                };
            if (self._distanceTo) {
              self._prepareDistances(cache.datatables[idx].Id);
              latlng = self._distanceTo.getLatLng();
            }
            for (var i = 0; i < rows.length; i++) {
              if (i > 0 && filtered && filtered.indexOf(rows[i][0]) > -1 || !filtered || i < 1) {
                row = rows[i];
                if (cache.datatables[idx].VisibleColumns) {
                  row = row.filter(function(val, idxColumn) { return cache.datatables[idx].VisibleColumns.indexOf(idxColumn) > -1; });
                  if (i == 0) {
                    row = row.map(function(val) { return cache.datatables[idx].displayProperties.columns[val] ? cache.datatables[idx].displayProperties.columns[val].label : val });
                  }
                }
                if (self._distanceTo) {
                  if (i == 0) {
                    row = [$('<p>' + self._distanceTo.name + '</p>').text()].concat(row);
                  } else {
                    row = [self._formatDistance(self._computeDistance(cache.datatables[idx].Id, rows[i][0], latlng))].concat(row);
                  }
                }
                csv += processRow(row, i == 0);
                // pas de comptage dans l'export CSV car ça ferait une case non numérique qui manquerait d'explications
              }
            }
            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' }),
                tableName = cache.datatables[idx].displayProperties && cache.datatables[idx].displayProperties.title ? cache.datatables[idx].displayProperties.title : cache.datatables[idx].Name,
                // 1. on supprime les caractères interdits dans les noms de fichiers => '<', '>', ':', '"', '/', "\\", '|', '?', "\n", "\r", "\t"
                // 2. on supprime les codes de controles Unicode (inspiration : https://github.com/parshap/node-sanitize-filename/blob/master/index.js)
                // 3. on supprime les espaces multiples
                // 4. on limites le nombre de caractères
                cleanTableName = tableName.replace(/[&\<\>:"\/\\\|\?\n\r\t]/g, '').replace(/[\x00-\x1f\x80-\x9f]/g, '').replace(/\s+/, " "), // eslint-disable-line no-control-regex
                filename = (self._parent._currentFilename.replace(/\d+\_\d+\_/, '').replace('.cartojson', ' - ') + cleanTableName).substring(0, 251) + '.csv';
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                  alert(__('function_unavailable'));
                }
            }
          }
        });
    } else {
      //On force un resize de la carte
      if (this._parent.webView) {
        this._parent.webView.invalidateSize();
      }
    }

    $('.show-if-datatables').toggle(!!(datatables && datatables.length));
    if (! datatables || ! datatables.length) {
      if ($node.hasClass('visible')) {
        $node.removeClass('visible');
        if (this._parent.webView) {
          this._parent.webView.invalidateSize();
        }
      }
    } else {
      var tabs = ['<ul class="nav nav-tabs">'],
          content = ['<div class="tab-content-container"' + (navigator.userAgent.indexOf("MSIE") < 0 ? ' data-simplebar data-simplebar-auto-hide="false"' : '') + '><div class="tab-content">'];

      for(i = 0; i < datatables.length; i++) {
        tabs.push('<li class="datatable-tab' + (i ? '' : ' active') + '" data-idx="' + i + '"><a class="datatable" href="#' + datatables[i].Id + '">' + stringFormatter.sanitizeHTML(datatables[i].displayProperties ? datatables[i].displayProperties.title : datatables[i].Name) + ' ( <span class="filterCount"> ' + datatables[i].EntitiesCount + ' </span> )</a></li>');
        content.push('<div class="tab-pane' + (i ? '' : ' active') + '" id="' + datatables[i].Id + '">');
        content.push('</div>');
      }
      tabs.push('</ul>');
      content.push('</div>');
      if (! $node.hasClass('visible')) {
        $node.addClass('visible');
        if (this._parent.webView) {
          this._parent.webView.invalidateSize();
        }
      }
      $node
        .children('.data-tables-tabs').html(tabs.join('') + content.join(''))
        .find('.nav a').click(function (e) {
          e.preventDefault();
          $(this).tab('show');
        });
       this.update(datatables, dataspaces);

      // if(!this.minimized){
      //   if(!this.currentTab)
      //     $node.find('.nav a.datatable:first').trigger('click');
      //   else
      //     $node.find('.nav a.datatable:eq('+this.currentTab+')').trigger('click');
      // }

      $('.datatable-tab').click(function(){
        //On set le tab actif
        var id = self.currentTab = $(this).data('idx'),
            cache = self._parent._cache[self._parent._currentFilename];
        // si nécessaire, on calcule les distance pour les éléments de ce tableau
        if (self._distanceTo && ! self._distances[cache.datatables[id].Id]) {
          self._updateOneDatatable(cache.datatables[id], cache.dataspaces[cache.datatables[id].Name], id);
        } else {
          self._updateCounts(cache.datatables[id], cache.dataspaces[cache.datatables[id].Name]);
        }
      });

      //On ajoute un event sur chaque row du tableau
      $(".tab-pane").on("click", ".dataspace-row-data:not(.filtered-row-gray)", this._rowClick.bind(this));
      $(".tab-pane").on("mousedown", ".dataspace-row-data", this._mouseDown.bind(this));

      // on ajoute un event pour la gestion de la pagination
      $(".tab-pane").on("click", ".pagination-link", this._paginationLinkClick.bind(this));

      if(this._parent.options.webView.isSortable){
        $(".tab-pane").on("click", ".sort-column", function(){
          var cache = self._parent._cache[self._parent._currentFilename];
          var id = $(this).data('datatable');
          var col = $(this).data('column');
          var columnType = (col == 'dist') ? 'Continuous' : cache.datatables[id].ColumnTypes[col];
          //On set le filtre en fonction du type de colonne
          if(!self.sort[id]){
            self.sort[id] = {'column': col, 'sort':'asc', 'type': columnType};
          } else if(self.sort[id].column == col){ //On change juste le sens
            if(self.sort[id].sort == 'asc')
              self.sort[id].sort = 'desc';
            else
              self.sort[id].sort = 'asc';
          } else {
            self.sort[id] = {'column': col, 'sort':'asc', 'type': columnType}; //On set la nouvelle colonne
          }

          self._updateOneDatatable(cache.datatables[id], cache.dataspaces[cache.datatables[id].Name], id);
        });

        //Initialisation des interfaces de filtre
        filtersUI.init(this, '.tab-pane');
      }

      this._distances = {};
      this._parent.on('markeradded',   function(data)     { self._setDistanceTo(data.marker); });
      this._parent.on('markerremoved', function(/*data*/) { self._setDistanceTo(null); });
    }
  },

  /**
   * Mise à jour de tous les tableaux de données
   * @param  {object[]} datatables  Liste des tableaux de données
   * @param  {object[]} dataspaces  Liste des espaces de données
   * @return void
   */
  update: function(datatables, dataspaces) {
    //On remplit tous les dataspaces
    for(i = 0; i < datatables.length; i++) {
      this._updateOneDatatable(datatables[i], dataspaces[datatables[i].Name], i);
    }
  },

  /**
   * renvoit une version html imprimable des tableaux de données
   * @return {string} un fragment html
   */
  print: function() {
    var html = "";
    var self = this;
    this._$domNode.find(".nav-tabs a").each(function(tabIdx) {
      // if (! $(this).parent().hasClass('map-toggle')) {
       html += '<h2>' + $(this).text() + '</h2><div style="width: 100%; overflow: hidden">';

       //On ajoute chaque ligne du tableau
       var idTab = $(this).attr('href').substring(1);
       var rows = self.exportRows[idTab];
       var filtered = self.filters[idTab];
       var cache = self._parent._cache[self._parent._currentFilename];
       html += "<table>";

       if (self._distanceTo) {
         self._prepareDistances(idTab);
         latlng = self._distanceTo.getLatLng();
       }

       for(var i = 0; i < rows.length; i++){
          var cells = rows[i].cells, latlng;
          if(!filtered || rows[i].id == "__header__" || filtered.indexOf(rows[i].id) > -1){
            html += '<tr>';
            if (self._distanceTo) {
              if (i == 0) { // header
                html += '<td style="background-color:#ddd;"><img height="20" style="vertical-align: baseline" src="' + self._distanceTo._icon.src + '"/></td>'
              } else { // contenu du tableau
                html += '<td>' + self._formatDistance(self._computeDistance(idTab, rows[i].id, latlng)) + '</td>'
              }
            }
            for(var j = 0; j < cells.length; j++){
              if(i == 0){
                if(self.activeFilters && self.activeFilters[tabIdx] && self.activeFilters[tabIdx][j]){
                  var valuesToDisplay = self._getHeaderValueToDisplay(cache.datatables[tabIdx], rows, tabIdx, j);
                  html += '<td style="background-color:#ddd;"><svg width="16" viewBox="0 0 80 90" focusable=false><path d="m 0,0 30,45 0,30 10,15 0,-45 30,-45 Z"></path></svg><div>'+  stringFormatter.sanitizeHTML(cells[j]) + " " + valuesToDisplay + '</div></td>';
                } else {
                  html += '<td style="background-color:#ddd;">'+ stringFormatter.sanitizeHTML(cells[j]) + '</td>';
                }
              }
              else
                html += '<td>'+ stringFormatter.sanitizeHTML(cells[j]) + '</td>';
            }
            html += '</tr>';
          }
        }

       html += "</table>";

        html += '</div>';
      // }
    });
    return html;
  },

  /**
   * Sélectionne des lignes dans les datatables
   * @param  {Object} selections
   * @param  {string} source [provenance de la sélection]
   * @return void
   */
  setSelection: function(selections, source) {
    var self = this,
        scrollTo = null;
    for(var id in selections) {
      //On check si on trouve le tab associé
      $('#'+id +' > table > tbody > tr').each(function(index, element){
        var $row = $(element);
        if (selections[id].indexOf($row.attr('data-id')) > -1) {
          self._selectRow($row);
          if (!scrollTo) {
            scrollTo = $row;
          }
        } else {
          self._unSelectRow($row);
        }
      });
    }

    this.selections = selections;
    //On check la visibilité de l'onglet
    if (source == "datatable") {
      this._parent.setSelection(selections, source);
      this._parent.toggleSelection(true);
    } else {
      if (scrollTo && scrollTo.length > 0) {
        try { //On scroll vers le dernier element sélectionné
          var scrollableContainer = navigator.userAgent.indexOf("MSIE") < 0 ? '.simplebar-content-wrapper' : '.tab-content-container';
          scrollTo.parents(scrollableContainer).get(0).scrollTop = scrollTo.get(0).offsetTop - 50; // 50px de marge pour les headers fixes
        } catch (e) { /* nothing */ }
      }
    }

    // on met à jour les comptages de colonnes
    var $tab = this._$domNode.find(".nav-tabs .active:last"),
        idx = $tab.data("idx"),
        cache = this._parent._cache[this._parent._currentFilename];
    if (typeof idx == "number" && cache.datatables[idx] && cache.dataspaces[cache.datatables[idx].Name]) {
      this._updateCounts(cache.datatables[idx], cache.dataspaces[cache.datatables[idx].Name]);
    }

    //On affiche les boutons de sélection
    $('.panel-selection').show();
  },


  /**
   * Applique les sélections en filtre sur les datatables
   * @return void
   */
  applyFilter: function(){
    // On parcours toutes les sélections
    var cache = this._parent._cache[this._parent._currentFilename];
    var arrayCheck = [];

    // dans le cas où au moins un module est configuré en "restreindre la sélection",
    // on modifie la sélection pour respecter cette contrainte avant l'application du flitre
    var limitSelection = false, i, selections = {}, m;
    for(i = 0; i < this._parent.webView._modules.length; i++){
      m = this._parent.webView._modules[i];
      if (m.module.customParams && m.module.customParams.selectable) {
        limitSelection = true;
        if (m.dataId) { selections[m.dataId] = []; }
        if (m.mapId)  { selections[m.mapId] = []; }
      }
    }
    if (limitSelection) {
      count = 0;
      for (i in selections){
        if (this.selections[i]) {
          selections[i] = this.selections[i];
          count += this.selections[i].length;
        }
      }
      this.selections = selections;
    }

    for(var idModule in this.selections){
      for(var idx in cache.datatables){
        //cherche le dataspace associé
        if(cache.datatables[idx].Id == idModule && arrayCheck.indexOf(idx) < 0){
          arrayCheck.push(idx);
          this._addFilters(idx, 0, this.selections[idModule], "map"); //On ajoute un filtre sur la première colonne pour les ids
        }
      }
    }
    this._setIdsFromFilter();
    this.selections = {};
  },


  /**
   * Méthode appelée lorsqu'un filtre des légendes est actif
   * @param  {array} selection [liste des identifiants sélectionnés]
   * @param  {int} dataId [identifiant du dataspace associé]
   * @param  {int} mapId  [identifiant du dataspace associé]
   * @return void
   */
  applyLegendSelection: function(selection, dataId, mapId){

    //On recherche le dataspace associé au dataId / mapId
    var cache = this._parent._cache[this._parent._currentFilename];
    var idx;
    if (cache) {
      for(var i = 0; i < cache.datatables.length; i++){
        if(cache.datatables[i].Id == dataId || cache.datatables[i].Id == mapId){
          idx = i;
        }
      }

      if(idx != null){
        this._addFilters(idx, 0, selection, "map"); //On ajoute un filtre sur la première colonne pour les ids
        this._setIdsFromFilter();
        this._updateOneDatatable(cache.datatables[idx], cache.dataspaces[cache.datatables[idx].Name], idx);
        $('.panel-filter').show();
      }
    }
  },

  /**
   * Ré initialise les sélections sur les datatables
   * @return void
   */
  resetSelection: function(){
    $(this._$domNode).find('tr.highlighted').removeClass('highlighted');
  },

  /**
   * Effectue les calculs des totaux des colonnes
   * @param  {string[][]} rows   [description]
   * @param  {Object} datatable  [description]
   * @return {[type]}           [description]
   */
  _computeCounts: function(datatable, rows) {
    var selected = this.selections && this.selections[datatable.Id] && this.selections[datatable.Id].length,
        countRow = [], // contenu de la ligne de comptage
        tooltips = [], // contenu des infobulles
        countedCol = 0; // nombre de colonnes qui disposent d'un comptage
    rows[0].forEach(function(col, idx) {
      if (datatable.VisibleColumns.indexOf(idx) > -1) {
        var countMethod = datatable.displayProperties.columns[col] ? datatable.displayProperties.columns[col].countMethod : 'none',
            decimals = datatable.displayProperties.columns[col] ? datatable.displayProperties.columns[col].decimals : 'auto';
        if (countMethod == 'none') {
          countRow.push("");
          tooltips.push("");
        } else {
          countedCol++;
          var sum = 0, count = 0, val, valStr, countStr;
          for(r = 1, l = rows.length; r < l; r++) {
            if(! this.filters[datatable.Id] || this.filters[datatable.Id].indexOf(rows[r][0]) > -1) {
              if (! selected || this.selections[datatable.Id].indexOf(rows[r][0]) > -1) {
                val = L.articque.Util.numberUnformat(rows[r][idx]);
                if (! isNaN(val)) {
                  if (countMethod == 'avg' || countMethod == 'sum') {
                    sum += val;
                  }
                  count++;
                }
              }
            }
          }
          countStr = stringFormatter.round(count, 0, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
          switch(countMethod) {
            case 'count' :
              countRow.push('#&emsp;' + countStr);
              tooltips.push(__(count > 1 ? "data_tooltip_count_n" : "data_tooltip_count_1", countStr))
              break;
            case 'sum' :
              if (count > 0) {
                  valStr = stringFormatter.round(sum, (typeof decimals == "number") ? decimals : (sum > 1 ? 2 : 6), L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
                  countRow.push('&Sigma;&emsp;' + valStr);
                  tooltips.push(__(count > 1 ? "data_tooltip_sum_n" : "data_tooltip_sum_1", countStr, valStr))
                } else {
                  countRow.push('&Sigma;&emsp;&empty;');
                  tooltips.push("");
                }
              break;
            case 'avg' :
              if (count > 0) {
                var avg = sum / count;
                valStr = stringFormatter.round(avg, (typeof decimals == "number") ? decimals : (avg > 1 ? 2 : 6), L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
                countRow.push('x&#772;&emsp;' + valStr);
                tooltips.push(__(count > 1 ? "data_tooltip_avg_n" : "data_tooltip_avg_1", countStr, valStr))
              } else {
                countRow.push('x&#772;&emsp;&empty;');
                tooltips.push("");
              }
              break;
            default:
              countRow.push("");
              tooltips.push("");
          }
        }
      }
    }, this);
    return { row : countRow,  tooltips: tooltips,countedCol: countedCol, selected: !! selected };
  },

  /**
   * Met à jour les compteurs sur chaque colonne
   * @param  {[type]} datatable   [description]
   * @param  {[type]} rows        [description]
   * @param  {[type]} idDatatable [description]
   * @return {[type]}             [description]
   */
  _updateCounts: function(datatable, rows) {
    var count = this._computeCounts(datatable, rows);
    if (count.countedCol > 0) {
      count.row.forEach(function(c, idx) {
        $('#col-count-' + datatable.Id + '-' + idx)
          .attr("title", count.tooltips[idx])
          .html(c)
          .toggleClass('col-count-selected', count.selected);
      });
    }
  },

  /**
   * Mise à jour d'un seul tableau de données
   * @param  {object} datatable  Tableau de données
   * @param  {string[]} rows  Liste des données à afficher
   * @param  {int} idDatatable  Index du tableau de données
   * @return void
   */
  _updateOneDatatable: function(datatable, rows, idDatatable) {
    var self = this,
        $node = $('#'+datatable.Id),
        content = [], r, l,
        head, head2 = null, exportRows = [];

    //  retrocompatibilité
    if(typeof datatable.VisibleColumns == "string" && datatable.VisibleColumns.length > 2) {
      try {
        datatable.VisibleColumns = JSON.parse(datatable.VisibleColumns);
      } catch(e) {
        datatable.VisibleColumns = [0,1];
      }
    }

    //On tri les VisibleColumns avant de l'utiliser (pour les filtres de la carte)
    datatable.decimals = datatable.decimals || {};
    if (datatable.VisibleColumns && ! datatable.displayProperties) {
      datatable.VisibleColumns.sort(function(a,b) { return a - b; });
      // on convertit VisibleColumns vers le nouveau format displayProperties
      datatable.displayProperties = { title: datatable.Name, columns: {} };
      datatable.VisibleColumns.forEach(function(vc) {
        datatable.displayProperties.columns[rows[0][vc]] = { label: rows[0][vc], countMethod: 'none' };
      });
    } else if (datatable.displayProperties && ! datatable.VisibleColumns) {
      // on calcule VisibleColumns à partir du nouveau format displayProperties
      datatable.VisibleColumns = [];
      for(var c in datatable.displayProperties.columns) {
        var idx = rows[0].indexOf(c);
        if (idx > -1) {
          datatable.VisibleColumns.push(idx);
          datatable.decimals[idx] = typeof datatable.displayProperties.columns[c].decimals == "number" ? datatable.displayProperties.columns[c].decimals : "auto";
        }
      }
    } else if (! datatable.displayProperties && ! datatable.VisibleColumns) {
      // on convertit VisibleColumns vers le nouveau format displayProperties
      datatable.displayProperties = { title: datatable.Name, columns: {} };
      datatable.VisibleColumns = [];
      rows[0].forEach(function(vc, idx) {
        datatable.displayProperties.columns[vc] = { label: vc, countMethod: 'none' };
        datatable.VisibleColumns.push(idx);
      });
    }

    this.allRows[datatable.Id] = rows;
    datatable.VisibleColumns.sort(function(a, b) { return a - b; });

    if (rows && rows.length) {
      var pageCount = Math.ceil((rows.length - 1) / this.pageCapacity), // -1 car on ne compte pas la première ligne (en-têtes)
          currentPage = this.currentPage[idDatatable] = (typeof this.currentPage[idDatatable] == 'undefined') ? 1 : Math.max(1, Math.min(pageCount, this.currentPage[idDatatable]));

      if (pageCount > 3) {
        var pages = [],
            start = Math.max(currentPage - 2, 1),
            end = Math.min(currentPage + 2, pageCount);

        if (start > 1) pages.push({ lbl: '1', p: 1 });
        if (start == 3) pages.push({ lbl: '2', p: 2 });
        else if (start > 2) pages.push({ lbl: '...', p: 2, disabled: true });
        if (end == pageCount - 2) pages.push({ lbl: pageCount - 1, p: pageCount - 1 });
        else if (end < pageCount - 1) pages.push({ lbl: '...', p: pageCount - 1, disabled: true });
        if (end < pageCount) pages.push({ lbl: pageCount, p: pageCount });
        for(var i = start; i <= end; i++) pages.push({ lbl: i, p: i, active: i == currentPage });
        pages.sort(function(a, b) { return a.p - b.p; });
        pages.push({ lbl: '<span class="glyphicon glyphicon-chevron-left"></span>', p: currentPage - 1, disabled: currentPage == 1 });
        pages.push({ lbl: '<span class="glyphicon glyphicon-chevron-right"></span>', p: currentPage + 1, disabled: currentPage == pageCount });

        content.push('<nav class="nav-pagination"><ul class="pagination">');
        pages.forEach(function(page) {
          content.push('<li' + (page.disabled ? ' class="disabled"' : '') + (page.active ? ' class="active"' : '') + '><a class="pagination-link" href="javascript:void(0)" data-table="' + idDatatable + '" data-page="' + page.p + '">' + page.lbl + '</a></li>');
        });
        content.push('</ul></nav>');
      } else {
        pageCount = 1;
      }

      var indexCol, classSort;
      content.push('<table class="table table-striped table-bordered-inside table-hover table-condensed data-tabs"><thead><tr>');
      if(!datatable.VisibleColumns){ // retrocompatibilité
        content.push.apply(content, rows[0].map(function(th, index) { return '<th class="sort-column" data-datatable="'+idDatatable+'" data-column="'+index+'">' + stringFormatter.sanitizeHTML(th) + '</th>'; }));
        head = rows[0];
      }
      else{
        if (this._distanceTo) {
          // ajout d'une colonne distance en début de tableau
          indexCol = "dist";
          classSort = '';
          if (self._parent.options.webView.isSortable){
            if (self.sort[idDatatable] && self.sort[idDatatable].column == indexCol){
              classSort = 'sortable sort-'+ ((self.sort[idDatatable].sort == 'asc') ? 'za' : 'az')
            } else {
              classSort = ' sortable';
            }
          }
          content.push('<th class="sort-column '+classSort+'" data-module="'+datatable.Id+'" data-datatable="'+idDatatable+'" data-column="'+indexCol+'"><img height="20" style="vertical-align: baseline" src="' + this._distanceTo._icon.src + '"/></th>');
        }
        content.push.apply(content, rows[0].filter(function(val, idx){return datatable.VisibleColumns.indexOf(idx) > -1}).map(function(th,index) {
          var label = datatable.displayProperties.columns[th] ? datatable.displayProperties.columns[th].label : th; // récupération du nom de colonne choisi par l'utilisateur
          indexCol = datatable.VisibleColumns[index]; //On prend l'id des colonnes visibles uniquement
          classSort = '';
          var addDom = '';
          if(self._parent.options.webView.isSortable){
            if(self.sort[idDatatable] && self.sort[idDatatable].column == indexCol){
              classSort = 'sortable sort-'+ ((self.sort[idDatatable].sort == 'asc') ? 'za' : 'az')
            } else {
              classSort = ' sortable';
            }

            //On vérifie s'il n'y a pas un filtre actif sur l'en-tête
            var activeColumn = '', valuesToDisplay = '', buttonDelete = '';
            if(self.activeFilters && self.activeFilters[idDatatable] && self.activeFilters[idDatatable][indexCol]){
              activeColumn = ' column-filter-active';
              valuesToDisplay = self._getHeaderValueToDisplay(datatable, rows, idDatatable, indexCol);
              buttonDelete = '<span class="glyphicon glyphicon-remove btn-filter-delete"></span>';
            }

            //On ajoute les boutons des filtres sur chaque entête
            addDom = ' <span class="filter-label-value">'+valuesToDisplay+'</span> <span class="glyphicon glyphicon glyphicon-filter atlas-filter '+activeColumn+'"></span> '+buttonDelete;
          }
          var html = '<th class="sort-column '+classSort+'" data-module="'+datatable.Id+'" data-datatable="'+idDatatable+'" data-column="'+indexCol+'">'+ stringFormatter.sanitizeHTML(label) + addDom;

          html += ' </th>';
          return html;
        }));

        head = rows[0].filter(function(val, idx){return datatable.VisibleColumns.indexOf(idx) > -1}).map(function(th) { return datatable.displayProperties.columns[th] ? datatable.displayProperties.columns[th].label : th; });
      }

      //On check si on doit trier les données
      if(this.sort[idDatatable]){
        this._sortDataspace(datatable.Id, idDatatable);
      }

      // on prepare le cache pour le calcul des distances
      var latlng;
      if (this._distanceTo) {
        this._prepareDistances(datatable.Id);
        latlng = this._distanceTo.getLatLng();
      }

      //On check si on a des filtres actifs
      if (datatable.Id in this.filters){
        this._sortValuesFilter(datatable.Id, idDatatable);
      }

      if(this.filters[datatable.Id]){
        $(".datatable-tab[data-idx="+idDatatable+"] .filterCount").html(this.filters[datatable.Id].length  + " / " + (rows.length-1));
      } else {
        $(".datatable-tab[data-idx="+idDatatable+"] .filterCount").html(rows.length-1);
      }
      this._parent.fire('toggleFilterIcon', { visible: Object.keys(this.filters).length > 0 });

      // le cas échéant, on ajoute une ligne de comptage
      var count = this._computeCounts(datatable, rows);
      if (count.countedCol > 0) {
        head2 = [];
        content.push('</tr><tr>' + (this._distanceTo ? '<th class="col-count' + (count.selected ? ' col-count-selected' : '') + '"></th>' : ''));
        count.row.forEach(function(c, idx) {
          content.push('<th id="col-count-' + datatable.Id + '-' + idx + '" class="col-count' + (count.selected ? ' col-count-selected' : '') + '" title="' + count.tooltips[idx] + '">' + c + '</th>');
          head2.push(c);
        });
      }
      content.push('</tr></thead><tbody>');
      // on parcourt tout le tableau mais on n'affiche que la page courante
      // le parcours complet est nécessaire pour la création de exportRows utilisé par la méthode print()
      var minVisibleRow = (pageCount > 1) ? 1 + (currentPage - 1) * this.pageCapacity : 1,
          maxVisibleRow = (pageCount > 1) ? Math.min(minVisibleRow + this.pageCapacity, rows.length) : rows.length;
      for(r = 1, l = rows.length; r < l; r++) {
        var id = rows[r][0],
            visible = r >= minVisibleRow && r < maxVisibleRow,
            filteredClass = '';

        //Gestion des éléments filtrés
        if(this.filters[datatable.Id]){
          if(this.filters[datatable.Id].indexOf(id) < 0)
            filteredClass = ' filtered-row-gray';
        }

        if (this.selections && this.selections[datatable.Id] && this.selections[datatable.Id].indexOf(id) > -1) { filteredClass += ' highlighted'; }

        if (visible) {
          content.push('<tr data-id="'+id+'" class="dataspace-row-data'+filteredClass+'">');
        }
        if(!datatable.VisibleColumns){ // retrocompatibilité
          if (visible) {
            content.push.apply(content, rows[r].map(function(th) { return '<td>' + stringFormatter.sanitizeHTML(th) + '</td>'; }));
          }
          exportRows.push({ id: id, cells: rows[r] });
        }
        else{
          if (visible) {
            if (this._distanceTo) {
              // ajout d'une colonne distance en début de tableau
              content.push('<td>' + this._formatDistance(this._computeDistance(datatable.Id, id, latlng)) + '</td>');
            }
            content.push.apply(content, rows[r].filter(function(val, idx){return datatable.VisibleColumns.indexOf(idx) > -1}).map(function(th, index) {
              var indexCol = datatable.VisibleColumns[index];
              var type = self._getColumnDataType(datatable.ColumnTypes[indexCol]);
              if(type == 'date')
                return '<td>' + stringFormatter.dateIsoToStr(th) + '</td>';
              else if (typeof datatable.decimals[indexCol] == "number")
                return '<td>' + stringFormatter.round(th, datatable.decimals[indexCol], L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP, th) + '</td>';
              else
                return '<td>' + stringFormatter.sanitizeHTML(th) + '</td>';
            }));
            content.push('</tr>');
          }
          exportRows.push({
            id: id,
            cells: rows[r].filter(function(val, idx){ return datatable.VisibleColumns.indexOf(idx) > -1 })
                          .map(function(th, index) {
                            if (self._getColumnDataType(datatable.ColumnTypes[datatable.VisibleColumns[index]]) == 'date')
                              return stringFormatter.dateIsoToStr(th);
                            if (typeof datatable.decimals[datatable.VisibleColumns[index]] == "number")
                              return stringFormatter.round(th, datatable.decimals[datatable.VisibleColumns[index]], L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP, th);
                            return th;
                          })
          });
        }
      }
      content.push('</tbody></table>');
    } else {
      content.push('<p>' + __('no_data') + '</p>');
    }

    //On ajoute la liste des rows du tableau
    var arrayFinal = [{ id: "__header__", cells: head }];
    if (head2 && head2.length) {
      arrayFinal.push({ id: "__header__", cells: head2 });
    }
    this.exportRows[datatable.Id] = arrayFinal.concat(exportRows);

    //On set tout le dom dans le datatable
    $node.html(content.join(''))
  },

  /**
  * Retourne la valeur à afficher dans l'entête de la colonne du dataspace passés en paramètres
  * @param {int} idDatatable   Identifiant du tableau de données
  * @param {int} indexCol index de la colonne de données
  */
  _getHeaderValueToDisplay: function(datatable, rows, idDatatable, indexCol){
    var valuesToDisplay = "";
    var type = this._getColumnDataType(datatable.ColumnTypes[indexCol]);
    if(type == 'quali'){
      //On récupère le nombre d'éléments filtrés vs le nombre possible
      var tmp1 = this.activeFilters[idDatatable][indexCol].length;
      var tmp2 = rows.map(function(row){return row.cells ? row.cells[indexCol] : row[indexCol]}).filter(function (valueF, indexF, selfF) {return selfF.indexOf(valueF) === indexF;}).length - 1;
      valuesToDisplay = '<span title="'+this.activeFilters[idDatatable][indexCol].join(', ')+'" >(' + tmp1 + ' / ' + tmp2 + ')</span>';
    } else if(type == 'quanti'){
      var decimals = datatable.decimals ? datatable.decimals[indexCol] : "auto";
      if (decimals == "auto")
        decimals = Math.abs(this.activeFilters[idDatatable][indexCol][1] - this.activeFilters[idDatatable][indexCol][0]) > 1 ? 2 : 6;
      var min = stringFormatter.round(this.activeFilters[idDatatable][indexCol][0], decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
      var max = stringFormatter.round(this.activeFilters[idDatatable][indexCol][1], decimals, L.articque.Util.DECIMAL_SEP, L.articque.Util.THOUSANDS_SEP);
      valuesToDisplay = '<span>(' + min + ' '+ __('data_filter_to')+' ' + max + ')</span>';
    } else { //Date
      valuesToDisplay = '<span>(' + stringFormatter.dateIsoToStr(this.activeFilters[idDatatable][indexCol][0]) + ' ' + __('data_filter_to') + ' ' + stringFormatter.dateIsoToStr(this.activeFilters[idDatatable][indexCol][this.activeFilters[idDatatable][indexCol].length -1]) + ')</span>';
    }
    return valuesToDisplay;
  },

  _selectRow: function(row){
    row.addClass('highlighted');
  },
  _unSelectRow: function(row){
    row.removeClass('highlighted');
  },

  /**
   * Méthode pour appliquer les filtres actifs et mettre à jour le/les tableaux ainsi que la carte
   * @param  {int} [optionnel] idDataspace Identifiant du tableau de données à mettre à jour
   * @return void
   */
  _updateTableAndMap: function(idDataspace){
    this._setIdsFromFilter();

    //On propage la sélection sur le tableau et la carte
    var cache = this._parent._cache[this._parent._currentFilename];

    if(idDataspace)
      this._updateOneDatatable(cache.datatables[idDataspace], cache.dataspaces[cache.datatables[idDataspace].Name], idDataspace);
    else
      this.update(cache.datatables, cache.dataspaces);

    this._parent.webView.moduleGroup.beginUpdate();

    //On check pour chaque module de la carte
    for(var i = 0; i < this._parent.webView._modules.length; i++){
      //On check s'il y a au moins un filtre dessus
      if(!this.filters[this._parent.webView._modules[i].mapId]){
        this._parent.webView.moduleGroup._modules[i].clearFilter();
      }
    }

    if(Object.keys(this.filters).length === 0)
     this._parent.clearFilter();
    else {
      this._parent.webView.setSelection(this.filters);
      this._parent.webView.moduleGroup.applyFilter('doNothing');
      this._parent.webView.adjustZoom();
    }

    this._parent.webView.moduleGroup.endUpdate();
  },

  /**
   * Ajoute un filtre actif à l'atlas
   * @param  {int} idDataspace  Identifiant du tableau de données
   * @param  {int} col          Index de la colonne de données à filter
   * @param  {int} ids          Liste des identifiants à filtrer
   * @param  {str} source       "datatable" | "map"
   * @return void
   */
  _addFilters: function(idDataspace, col, ids, source){
    // on ne valide pas l'ajout du filtre si au moins un module est en "restreindre la sélection"
    // et que ce dataspace n'est lié à aucun module sur lequel la sélection est possible
    var cache = this._parent._cache[this._parent._currentFilename];
    if (source == "datatable") { // si il y a une sélection sur la carte et qu'on créé un filtre depuis un tableau de données => on annule la sélection pour éviter les incohérences
      this.selections = {};
    }
    if (this._isSelectable(cache.datatables[idDataspace].Id)) {
      if(!this.activeFilters[idDataspace])
        this.activeFilters[idDataspace] = {};

      this.activeFilters[idDataspace][col] = ids;
    }

    // on vérifie le résultat du filtre => si tous les éléments sont filtrés alors on annule le filtre
    // car ça provoque des problèmes lors de la synchro avec la carte : on n'est pas capable de savoir si c'est un
    // filtre volontaire de tous éléments ou s'il c'est un filtre dans une autre table
    this._setIdsFromFilter();
    if (source == "datatable" && this.filters[cache.datatables[idDataspace].Id] && this.filters[cache.datatables[idDataspace].Id].length == 0) {
      delete this.activeFilters[idDataspace][col];
      if(Object.keys(this.activeFilters[idDataspace]).length < 1)
        delete this.activeFilters[idDataspace];
    }
  },


  /**
   * Supprime un filtre actif de l'atlas
   * @param  {int} idDataspace  Identifiant du tableau de données
   * @param  {int} col          Index de la colonne de données à supprimer
   * @return void
   */
  _deleteFilters: function(id, col){
    if(this.activeFilters[id] && this.activeFilters[id][col])
      delete this.activeFilters[id][col];

    if(Object.keys(this.activeFilters[id]).length < 1)
      delete this.activeFilters[id];

    if(col == 0){ //Cas du filtre de la colonne des identifiants on reset la légende du module également
      //On filtre le module associé
      var idModule = this._parent._cache[this._parent._currentFilename].datatables[id].Id;

      //On recherche le module associé dans le webView
      var modules = this._parent.webView._modules;
       for(var i = 0; i < modules.length; i++){
        //On check s'il y a au moins un filtre dessus
        if(modules[i].mapId == idModule || modules[i].dataId == idModule){
          this._parent.webView.moduleGroup._modules[i].clearFilter();
        }
      }
    }
  },

  /**
   * Applique les filtres actifs dans l'atlas
   * @return void
   */
  _setIdsFromFilter: function(){
    //Pour chaque dataspaces
    var cache = this._parent._cache[this._parent._currentFilename];
    var datatables = cache.datatables;
    this.filters = {};

    for(var i = 0; i < datatables.length; i++){
      if(this.activeFilters[i]){
        var filters = this.activeFilters[i];
        var rows = this.allRows[datatables[i].Id];

        //Pour chaque filtre on récupère les ids
        var arrayToIntersect = {};
        for(var idx in filters){
          arrayToIntersect[idx] = [];
          var values = filters[idx];
          var col = idx;
          var columnType = this._getColumnDataType(datatables[i].ColumnTypes[col]);

          for(var j = 1; j < rows.length; j++ ){ //On ignore le header
            var val;
            if(columnType != 'quanti') {
              val = rows[j][col];
              if(values.indexOf(val) > -1)
                arrayToIntersect[idx].push(rows[j][0]);
            } else {
              val = L.articque.Util.numberUnformat(rows[j][col]);
              // dans le cas d'un filtre quanti, on reçoit les 2 valeurs min et max
              if (val >= values[0] && val <= values[1])
                arrayToIntersect[idx].push(rows[j][0]);
            }
          }
        }

        //On récupère l'intersection de chaque array
        var arrayFinal = null;
        for(var idx2 in arrayToIntersect){
          var array = arrayToIntersect[idx2];
          if(!arrayFinal){
            arrayFinal = array;
          } else {
            arrayFinal = arrayFinal.filter(function(n) {
              return array.indexOf(n) !== -1;
            });
          }
        }

        //On set la selection pour ce dataspace
        this.filters[datatables[i].Id] = arrayFinal;
      }
    }
  },

  /**
   * Méthode utilisée lors du clique une ligne
   * @return void
   */
  _rowClick: function (evt){
    //On sélectionne l'élément
    var element = $(evt.currentTarget),
        id = element.attr('data-id'),
        //On récupère l'id du tableau parent
        idTab = element.closest('div').attr('id'),
        obj;
    if (evt.ctrlKey || ! this.selections) {
      obj = this.selections;
      if (Array.isArray(obj[idTab])) {
        var idx = obj[idTab].indexOf(id);
        if (idx > -1) {
          obj[idTab].splice(idx, 1);
        } else {
          obj[idTab].push(id);
        }
      } else {
        obj[idTab] = [id];
      }
    } else {
      obj = {};
      obj[idTab] = [id];
      this._parent.fire('datarowclick', { dataId: idTab, rowId: id });
    }

    //On met à jour la sélection sur la carte
    this.setSelection(obj, "datatable");
  },

  _mouseDown: function(){
    this._startSelection();
  },

  _startSelection: function(){
    this._onMouseUp = this._updateSelection.bind(this);
    document.addEventListener("mouseup", this._onMouseUp);
  },

  /**
   * Appelé lorsque une sélection multiple est effectuée sur le tableau de données
   * @return void
   */
  _updateSelection: function() {
    var sel = window.getSelection();
    var ranges = [];
    for(var i = 0; i < sel.rangeCount; i++) {
     ranges[i] = sel.getRangeAt(i);
    }

    // window.ranges = ranges;
    if(ranges.length > 0) {
      var $start = $(ranges[0].startContainer);
      var $startTr = $start.parents('tr:first');
      while ($startTr.hasClass("filtered-row-gray")) { // on remonte jusqu'au dernier élément non filtré
        $startTr = $startTr.prev();
      }
      var startId = $startTr.attr('data-id');
      var $end = $(ranges[0].endContainer);
      var $endTr = $end.parents('tr:first');
      while ($endTr.hasClass("filtered-row-gray")) { // on remonte jusqu'au dernier élément non filtré
        $endTr = $endTr.prev();
      }
      var endId = $endTr.attr('data-id');

      //On récupère l'onglet actif
      var idx = this._$domNode.find(".tab-pane.active:last").attr('id');

      //On récupère la liste des éléments
      //4 cases possibles : start & end / Start / End / null
      //On parcours toutes les valeurs
      var values = this.allRows[idx];
      var selection = [];
      var flagStart = false;
      if(startId && endId && (startId != endId)){
        values.forEach(function(value){
          var id = value[0];
          if(startId == String(id) || !startId || flagStart){
            selection.push(id);
            flagStart = true;
          }

          if(endId == String(id))
            flagStart = false;
        });
      }

      if (selection.length > 1 && this._isSelectable(idx)){ //Pour ne pas géner le onClick
        var selectionObj = {};
        selectionObj[idx] = selection;
        this.setSelection(selectionObj, "datatable");
      }
    }

    sel.removeAllRanges();
    //On supprime l'event
    document.removeEventListener("mouseup", this._onMouseUp);
  },

  /**
   * permet de savoir s'il est possible d'effectuer des sélections dans un dataspace donné
   * @param  {[type]}  dataspaceId [description]
   * @return {Boolean}             [description]
   */
  _isSelectable: function(dataspaceId) {
    var limitSelection = false, i, ids = {};
    for(i = 0; i < this._parent.webView._modules.length; i++){
      m = this._parent.webView._modules[i];
      if (m.module.customParams && m.module.customParams.selectable) {
        limitSelection = true;
        if (m.dataId) { ids[m.dataId] = true; }
        if (m.mapId)  { ids[m.mapId] = true; }
      }
    }
    return ! limitSelection || !!ids[dataspaceId];
  },

  /**
   * Tri les données d'un tableau de données en prenant en compte la non réponse
   * @param  {int} idx  Identifiant du tableau de données
   * @param  {int} num  Numéro du tableau de données
   * @return void
   */
  _sortValuesFilter: function(idx, num){
    var self = this,
        latlng = self._distanceTo ? self._distanceTo.getLatLng() : null,
        column, sort, type;
    if(this.sort && this.sort[num]){
      column = this.sort[num].column;
      sort = this.sort[num].sort;
      type = this._getColumnDataType(this.sort[num].type);
    } else {
      column = 0;
      sort = 'asc';
      type = 'quali';
    }

    var compareFunction =  function(a, b) {
      //On check si c'est un filter par rapport
      var idA = isFiltered[a[0]];
      var idB = isFiltered[b[0]];

      if(idA === idB){
        if (column == 'dist') { // cas particulier si le tri est sur la colonne distance
          if (! self._distanceTo || ! latlng) return 0; // si le marqueur a disparu de la carte, on annule le tri
          return self._compareSort(
              self._computeDistance(idx, a[0], latlng), // a[0] car id dans la première colonne
              self._computeDistance(idx, b[0], latlng),
              sort,
              'quanti'
            );
        } else { // cas général
          return self._compareSort(a[column], b[column], sort, type);
        }
      } else {
        if(idA === true && idB === false)
          return -1;
        else if(idA === false && idB === true)
          return 1;
        else
          return 0;
      }
    };
    var header = this.allRows[idx].shift();
    var isFiltered = {};
    this.allRows[idx].forEach(function(row) {
      isFiltered[row[0]] = self.filters[idx].indexOf(row[0]) > -1;
    });
    this.allRows[idx].sort(compareFunction);
    this.allRows[idx].unshift(header);
  },

  /**
   * Tri les données d'un tableau de données
   * @param  {int} idx  Identifiant du tableau de données
   * @param  {int} num  Numéro du tableau de données
   * @return void
   */
  _sortDataspace: function(idx, num){
    var self = this, column, sort, type;
    if (this.sort && this.sort[num]){
      column = this.sort[num].column;
      sort = this.sort[num].sort;
      type = this._getColumnDataType(this.sort[num].type);
    } else {
      column = 0;
      sort = 'asc';
      type = 'quali';
    }

    var sortFunction;
    if (column == 'dist') { // cas particulier si le tri est sur la colonne distance
      if (! self._distanceTo) return; // si le marqueur a disparu de la carte, on annule le tri
      var latlng = self._distanceTo.getLatLng();
      sortFunction = function(a, b) {
        return self._compareSort(
          self._computeDistance(idx, a[0], latlng), // a[0] car id dans la première colonne
          self._computeDistance(idx, b[0], latlng),
          sort,
          'quanti'
        );
      }
    } else { // cas général
      sortFunction = function(a, b) {
        return self._compareSort(a[column], b[column], sort, type);
      }
    }

    var header = this.allRows[idx].shift();
    this.allRows[idx].sort(sortFunction);
    this.allRows[idx].unshift(header);
  },

  /**
   * Méthode de tri pour le tableau de données
   * @param  {int/float} a  valeur a
   * @param  {int/float} b  valeur b
   * @param  {string} sort  sens du tri
   * @param  {string} type  type des données à trier
   * @return {int}
   */
  _compareSort: function(a, b, sort, type){
    var inverse = (sort != 'asc');

    switch(type) {
      case 'quali':
        if(typeof String.prototype.localeCompare == 'function'){
          return (inverse ? -1 : 1) * a.toString().localeCompare(b)
        } else {
          if (a < b) return inverse ? 1 : -1;
          if (a > b) return inverse ? -1 : 1;
          return 0;
        }
      case 'quanti':
        var av = L.articque.Util.numberUnformat(a),
            bv = L.articque.Util.numberUnformat(b);
        if (av < bv) return inverse ? 1 : -1;
        if (av > bv) return inverse ? -1 : 1;
        if (isNaN(av) && ! isNaN(bv)) return inverse ? 1 : -1;
        if (! isNaN(av) && isNaN(bv)) return inverse ? -1 : 1;
        return 0;
      case 'date':
        if (a < b) return inverse ? 1 : -1;
        if (a > b) return inverse ? -1 : 1;
        return 0;
    }
  },

  /**
   * Retourne un type simplifié
   * @param  {string} columnType
   * @return void
   */
  _getColumnDataType: function(columnType){
    switch(columnType) {
      case 'IdSrc':
      case 'IdDest':
      case 'GeoId':
      case 'GeoName':
      case 'Qualitative':
        return 'quali';
      case 'Continuous':
      case 'ContinuousMatrix':
      case 'Discrete':
      case 'DiscreteMatrix':
        return 'quanti';
      case 'Date':
      case 'DateMatrix':
        return 'date';
    }
  },

  /**
   * click sur un lien de navigation
   * @param  {[type]} event [description]
   * @return {[type]}       [description]
   */
  _paginationLinkClick: function(event) {
    var cache = this._parent._cache[this._parent._currentFilename],
        $a = $(event.currentTarget),
        idDataspace = $a.data('table');
    this.currentPage[idDataspace] = parseInt($a.data('page'));
    $a.parents(navigator.userAgent.indexOf("MSIE") < 0 ? '.simplebar-content-wrapper' : '.tab-content-container').get(0).scrollTop = 0;
    this._updateOneDatatable(cache.datatables[idDataspace], cache.dataspaces[cache.datatables[idDataspace].Name], idDataspace);
  },

  /**
   * Spécifie un point à partir duquel calculer les distances avec les objets de la carte correspondant à chacune des lignes du tableau
   * @param {L.marker} marker  les coordonnées du point, `null` pour désactiver le calcul de distance
   */
  _setDistanceTo: function(marker) {
    if (this._distanceTo == marker) return;
    if (this._distanceTo && marker && this._distanceTo.equals(marker.getLatLng())) return;
    this._distanceTo = marker;
    var $tab = this._$domNode.find(".nav-tabs .active:last"),
        idx = $tab.data("idx"),
        cache = this._parent._cache[this._parent._currentFilename],
        existing = Object.keys(this._distances);
    this._distances = {};
    if (marker)
      this._updateOneDatatable(cache.datatables[idx], cache.dataspaces[cache.datatables[idx].Name], idx);
    else if (cache) { // suppression du marqueur => on rafraichit toutes les tables pour lesquels des distances ont été calculées
      for(var i in existing) {
        for(var j = 0; j < cache.datatables.length; j++) {
          if (cache.datatables[j].Id == existing[i]) {
            this._updateOneDatatable(cache.datatables[j], cache.dataspaces[cache.datatables[j].Name], j);
          }
        }
      }
    }
  },

  /**
   * prépare l'objet en cache pour stocker les distances calculées
   * @param  {int} tableId   id du tableau de données
   * @return {void}
   */
  _prepareDistances: function(tableId) {
    this._distances[tableId] = this._distances[tableId] || { distances: {} };

    var found = false;
    this._parent.webView._modules.forEach(function(mod) {
      if (mod.dataId == tableId || mod.mapId == tableId) {
        found = true;
        this._distances[tableId].features = mod.module._features || mod.module._flows;
      }
    }, this);
    if (! found) {
      this._parent.webView._modules.forEach(function(mod) {
        if (mod.dataParentIds.indexOf(tableId) > -1) {
          this._distances[tableId].features = mod.module._features || mod.module._flows;
        }
      }, this);
    }
  },

  /**
   * Calcule une distance (utilise Leaflet) et les features récupérées depuis leaflet-articque
   * @param  {int}      tableId   id du tableau de données
   * @param  {string}   featureId id de la ligne dans la tableau de données
   * @param  {L.Latlng} latlng    position du point à partir duquel calculer la distance
   * @return {Number}             une distance en mètre ou `""` si le calcul n'a pas pu être effectué
   */
  _computeDistance: function(tableId, featureId, latlng) {
    if (tableId && featureId && this._distances[tableId]) {
      if (this._distances[tableId].distances && this._distances[tableId].distances[featureId]) {
        return this._distances[tableId].distances[featureId];
      }
      if (this._distances[tableId].features) {
        for(var i = this._distances[tableId].features.length - 1; i >= 0; i--) {
          if (this._distances[tableId].features[i].id == featureId) {
            return this._distances[tableId].distances[featureId] = L.latLng(this._distances[tableId].features[i].center.y, this._distances[tableId].features[i].center.x).distanceTo(latlng);
          }
        }
      }
      return this._distances[tableId].distances[featureId] = "";
    }
    return "";
  },

  /**
   * Formate une distance en mètres (ex : 123456 => "123 km", 1234 => "1.2km", 123 => "123 m")
   * @param  {number} val valeur à formatter
   * @return {string}     une chaine formattée
   */
  _formatDistance: function(val) {
    if (val == "") {
      return "";
    } else if (val > 10000) { // plus de 10 km => arrondi au km
      return stringFormatter.round(val / 1000, 0) + ' km';
    } else if (val > 1000) { // plus de 1 km => arrondi à l'hectometre
      return stringFormatter.round(val / 1000, 1) + ' km';
    } else { // au mètre près
      return stringFormatter.round(val, 0) + ' m';
    }
  }

};

module.exports = dataTables;
