(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  A.ext.hover = {

    loadExtension: function(parent, dependancies) {
      this.parent = parent;
      this.parent.on('updateview', this.onUpdateView, this);
    },

    onUpdateView: function(displayingInfos) {
      var self = this;
      this.parent.wvc._modules.forEach(function(mod) {
        if (mod.module.customParams && mod.module.customParams.hover) {
          if (! (mod.module instanceof L.articque.RMFill)) {
            console.warn("CustomParam hover is restricted to RMFill modules");
          }
          mod.module.options.hoverAutoSelect  = ! L.Browser.mobile; // pose pb sur mobile pour la sélection multiple => desactivation sur mobile
          mod.module.options.selectionLayer   = mod.module.customParams.hover.layer || 'top';
          mod.module.options.selectedStyle    = {
            fillColor   : mod.module.customParams.hover.fillColor   || '',
            strokeColor : mod.module.customParams.hover.strokeColor || '',
            strokeWidth : mod.module.customParams.hover.strokeWidth || '',
            dashColor   : mod.module.customParams.hover.dashColor   || ''
          };
        }
      }, this);
    }

  };
})();