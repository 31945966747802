(function() {
  var A = window.articque = window.articque || {};
  A.ext = A.ext || {};

  // bug sur le double clic sous chrome > 55 (pointerEvents) qui est déclenché 2 fois (https://github.com/Leaflet/Leaflet/issues/5180)
  // => bidouille avec un timeout pour éviter ce double déclenchement
  var drilling = false;

  A.ext.atlasDrillDown = {

    parent: null,
    event: null,
    eventCb: null,
    mouseMoveCb: null,
    modules: [],

    loadExtension: function(parent) {
      this.parent = parent;
      this.parent.on('updateview', this.onUpdateView, this);
    },

    onUpdateView: function() {
      var wvc = this.parent.wvc;
      this.modules = [];

      if (this.event && this.eventCb) {
        // on supprime la callback précédente
        wvc.map.off(this.event, this.eventCb);
        if (this.mouseMoveCb) {
          wvc.moduleGroup.off('mousemove', this.mouseMoveCb);
          wvc.map._container.style.cursor = '';
        }
      }
      wvc._modules.forEach(function(mod) {
        if (mod.module.customParams && mod.module.customParams.atlasDrillDown) {
          this.modules.unshift(mod.module);
          //this.event = L.Browser.touch ? 'dblclick' : (mod.module.customParams.atlasDrillDown.event || 'dblclick');
          this.event = mod.module.customParams.atlasDrillDown.event || 'dblclick';
          if (this.event == 'dblclick') {
            wvc.map.doubleClickZoom.disable();
          }
          // on modifie le style de sélection de l'entité en cours d'affichage
          mod.module._features.forEach(function(f) {
            if (mod.module.customParams.atlasDrillDown.url.replace('{{id}}', f.id) == mod.module.customParams.atlasDrillDown.current) {
              f.selectedStyle = {};
            }
          });
          this.eventCb = this.onAtlasDrillDown.bind(this);
          wvc.map.on(this.event, this.eventCb);
          if (mod.module.customParams.atlasDrillDown.cursor) {
            this.mouseMoveCb = this.onMouseMove.bind(this);
            wvc.moduleGroup.on('mousemove', this.mouseMoveCb)
          }
        }
      }, this);
    },

    onMouseMove: function(evt) {
      //Si la sélection est active dans les atlas alors on ne fait pas de drilldown
      if(this.parent.atlas.options.selectionActive || this.parent.atlas.options.fixedCursor)
        return;
      
      var cursor = '';
      for(var i = 0; i < this.modules.length; i++) {
        var f = this.modules[i].getFeatureAt(evt);
        if (f && this.modules[i].customParams.atlasDrillDown.url.replace('{{id}}', f.id) != this.modules[i].customParams.atlasDrillDown.current) {
          cursor = this.modules[i].customParams.atlasDrillDown.cursor;
        }
      }
      this.parent.wvc.map._container.style.cursor = cursor;
    },

    onAtlasDrillDown: function(evt) {
      //Si la sélection est active dans les atlas alors on ne fait pas de drilldown
      if(this.parent.atlas.options.selectionActive)
        return;
      
      if (drilling) return; drilling = true; setTimeout(function() { drilling = false}, 100); // voir explications ci-dessus à la déclaration de `drilling`
      var feature = null;
      for(var i = 0; i < this.modules.length; i++) {
        if (this.modules[i].isVisible()) {
          feature = this.modules[i].getFeatureAt(evt);
          if (feature && this.parent.atlas) {
            this.parent.atlas.navigateTo(this.modules[i].customParams.atlasDrillDown.url.replace('{{id}}', feature.id), feature.bbox, "map");
            return;
          }
        }
      }
    }

  };
})();