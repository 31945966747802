/**
 * @license
 * Copyright (C) Articque Informatique
 * All rights reserved. Duplication and distribution prohibited.
 * Les Roches, 37230 Fondettes France
 * Telephone +33 02.47.49.90.49
 * Fax +33 02.47.49.91.49
 * E-Mail: info@articque.com
 */

'use strict';

// programmation orientée objet en JS (tiré de leaflet)
require("./lib/cd.js");

window.articque = window.articque || {};

window.articque.translations ={
  "fr": require('../translations/api.fr.json'),
  "en": require('../translations/api.en.json')
};
window.articque.lang = 'fr';

window.__ = window.__ || function (str) { // fonction d'internationnalisation
  var translations = window.articque.translations[window.articque.lang],
      values = Array.prototype.slice.call(arguments, 1);
  if (translations && translations[str]) {
    str = translations[str];
  }
  values.forEach(function(val, i) {
    str = str.replace("{" + i + "}", val);
  })
  return str;
};

var cdo = window.articque.cdonline = {
  webServiceClient: require('./lib/webservice-client.js'),
  DiagramModel: require('./lib/diagram-model.js'),
  ExportsModel: require('./lib/exports-model.js'),
  stringFormatter: require('./lib/string-formatter.js')
};

if (window.L) {
  cdo.WebView    = require('./lib/web-view-creator.js');
  cdo.extensions = require('./lib/extensions.js');
  cdo.Atlas      = require('./lib/atlas-v2.js');
  cdo.atlasUI    = {
    breadcrumb:      require('./lib/atlas-breadcrumb-v2.js'),
    appBar:          require('./lib/atlas-app-bar-v2.js'),
    panels:          require('./lib/atlas-panels-v2.js'),
    dataTables:      require('./lib/atlas-data-tables-v2.js'),
    loader:          require('./lib/atlas-loader-v2.js'),
    searchEngine:    require('./lib/atlas-search-engine-v2.js'),
    isochroneEngine: require('./lib/atlas-isochrone-engine.js')
  }
} else {
  window.L = { articque: { Util: { DECIMAL_SEP: ',', THOUSANDS_SEP: ' ', numberUnformat: function(str) { return (typeof str == 'number') ? str : parseFloat(str.replace(this.THOUSANDS_SEP, '').replace(this.DECIMAL_SEP, '.').replace(' x 10^', 'E').replace(/\s/g, '')); } } } };
}
