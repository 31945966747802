(function() {
  var A = window.articque = window.articque || {};
  var $ = window.$ || window.jQuery;
  A.ext = A.ext || {};

  A.ext.tooltip = {

    _neededDataSpaces: [],
    parent: null,

    loadExtension: function(parent, dependancies) {
      this.parent = parent;
      this.stringFormatter = dependancies.stringFormatter;
      this.simpleChart = dependancies.simpleChart;
      this.jailedEval = dependancies.jailedEval;
      this.parent.on('updateview', this.onUpdateView, this);
    },

    onUpdateView: function(displayingInfos) {
      var self = this;
      this._neededDataSpaces = [];
      if (this.parent.uiState) { // seulement en mode édition
        //this.parent.on('share', this.onShare, this);
        this.parent.wvc._modules.forEach(function(mod) {
          if (mod.module.customParams &&
            mod.module.customParams.tooltip)
          {
            if (mod.module.customParams.tooltip.dataspaceName) {
              if (this._neededDataSpaces.indexOf(mod.module.customParams.tooltip.dataspaceName) < 0)
              {
                this._neededDataSpaces.push(mod.module.customParams.tooltip.dataspaceName);
              }
              this.parent.uiState.prefetchDataSpaceByName(mod.module.customParams.tooltip.dataspaceName)
                .done(function(dataspace) {
                  self.checkDataspace(dataspace);
                  self.initTooltips(mod.module, dataspace.Values);
                }).fail(function() {
                  window.reactComponents.notifBar.update({
                    clearState: true,
                    notification: __("Erreur"),
                    secondary: __('Le dataspace "{0}" utilisé pour les infobulles du module "{1}" n\'existe pas', mod.module.customParams.tooltip.dataspaceName, mod.module.name) + "\n" +
                               __('Ouvrir les paramètres du module "{0}" pour corriger le problème', mod.module.name),
                    duration: -1,
                    animation: false,
                    action: __("Fermer"),
                    type: 'danger'
                  });
                });
            } else {
              self.initTooltips(mod.module, null);
            }
          }
        }, this);
      } else if (this.parent.dataspacesCache) { // en mode lecture seule (partage)
        this.parent.wvc._modules.forEach(function(mod) {
          if (mod.module.customParams &&
            mod.module.customParams.tooltip)
          {
            if (mod.module.customParams.tooltip.dataspaceName &&
              this.parent.dataspacesCache[mod.module.customParams.tooltip.dataspaceName])
            {
              this.initTooltips(mod.module, this.parent.dataspacesCache[mod.module.customParams.tooltip.dataspaceName]);
            } else {
              this.initTooltips(mod.module, null);
            }
          }
        }, this);
      }
    },

    // onShare: function(share) {
    //   share.data.dataspaces = {};
    //   for (var ds in this.parent.uiState.dataspacesCache) {
    //     for (var i = this.parent.uiState.dataspacesCache[ds].length - 1; i >= 0; i--) {
    //       if (this.parent.uiState.dataspacesCache[ds][i].Values !== null && this._neededDataSpaces.indexOf(this.parent.uiState.dataspacesCache[ds][i].Name) > -1) {
    //         share.data.dataspaces[this.parent.uiState.dataspacesCache[ds][i].Name] = this.parent.uiState.dataspacesCache[ds][i].Values;
    //       }
    //     }
    //   }
    // },

    initTooltips: function(module, dataspace) {
      var self = this,
          charts = [],
          stringFormatter = this.stringFormatter,
          simpleChart = this.simpleChart;
      if (module.customParams && module.customParams.tooltip) {
        if (dataspace) {
          module._dstt = dataspace;
        }
        var tooltipFunction = function(f, promise) {
          if (window._articqueDebug) { console.log('extension tooltip pour feature id = ', f.id) }
          if (! this._dstt) {
            if (window._articqueDebug) { console.log('pas de dataspace => contenu fixe : ', module.customParams.tooltip.content || 'vide'); }
            return module.customParams.tooltip.content;
          }
          var line0 = this._dstt[0].map(function(colName) { return colName.replace(/\r/g, "").replace(/\n/g, " "); }), // même traitement que dans tooltip-gui-script.jsx (support des retours à la ligne dans les en-tête de colonnes)
              line = null;
          // cherche la bonne ligne
          for (var i = 0; i < this._dstt.length; i++) {
            if (this._dstt[i][0] == f.id || (module instanceof L.articque.RMFlows && this._dstt[i][0] + ' > ' + this._dstt[i][1] == f.id)) {
              line = this._dstt[i];
            }
          }
          if (line) {
            // remplace les {{col}} par les valeurs correspondantes
            if (window._articqueDebug) { console.log('ligne du dataspace trouvée ', line); }
            var domDeferred = $.Deferred(), // quand le conteneur de la tooltip est prêt - idem que `promise.domReady` mais conversion en Deferred pour chainer plusieurs évènements
                evalDeferreds = []; // sera resolu quand toutes les expressions ont été évaluées
            var resultStr = '<!--' + f.id + '-->' + module.customParams.tooltip.content.replace(/\{\{(.*?)\}\}/g, function(match, varName) {
              varName = stringFormatter.unescapeHtml(varName);
              if (varName.indexOf('|') > 0) {
                var parts = varName.split('|'),
                    col = line0.indexOf(parts[0]),
                    func = parts[1];
                if (col == -1 && varName.toLowerCase() == 'identifiant') { col = 0; }
                if (col > -1) {
                  func = func.substr(0, func.indexOf('('));
                  if (parts[1].indexOf("$var") > -1) {
                      expr = parts[1].replace(/\$var/g, line[col]); // cas général (retrocompatibilité) => eval de l'expression JS
                  } else {
                    var values, feature;
                    // cherche la bonne feature
                    for (var i = 0; i < module._features.length; i++) {
                      if (module._features[i].id == f.id) {
                        feature = module._features[i];
                        break;
                      }
                    }
                    if (feature && feature.properties && module.options.valuesName && feature.properties[module.options.valuesName]){
                      values = feature.properties[module.options.valuesName];
                    } else {
                      values = [line[col]];
                    }
                    expr = "var data = { string: " + JSON.stringify(line[col]) + ", number: " + JSON.stringify(L.articque.Util.numberUnformat(line[col])) + ", values: " + JSON.stringify(values) + "};";
                    if (stringFormatter[func]) { // cas où on connait la fonction dans stringFormatter
                      expr += parts[1].replace(func + '(', "stringFormatter." + func + '(data, ') + ';';
                    } else {
                      expr += parts[1];
                    }
                  }
                  var placeholder = '<!--__articque.placeholder' + evalDeferreds.length + '-->',
                      newDeferred = self.jailedEval(expr);
                  evalDeferreds.push(newDeferred);
                  newDeferred.done(function(result) {
                    resultStr = resultStr.replace(placeholder, result);
                  });
                  return placeholder;
                } else {
                  if (window._articqueDebug) { console.log('!!! colonne non trouvée ', match); console.log('colonnes disponibles ', line0); }
                  return match;
                }
              } else {
                var col = line0.indexOf(varName);
                if (col == -1 && varName.toLowerCase() == 'identifiant') { col = 0; }
                return (col > -1) ? line[col] : match;
              }
            });
            // si il y a au moins une expression JS évaluée de manière asynchrone
            if (evalDeferreds.length > 0) {
              $.when.apply($, evalDeferreds).then(function() {
                // peut être null si la tooltip disparait avant que toutes les expressions JS ne soient évaluées
                if (domDeferred) {
                  domDeferred.then(function(domNode) {
                    // on remet la même chaine mais chaque placeholder d'expression a été remplacé car tous les deferreds sont résolus
                    domNode.innerHTML = resultStr;
                  });
                }
              });
            }
            // leaflet-articque prévient lorsque le conteneur est créé dans le dom
            promise.domReady(function(domNode) {
              domDeferred.resolve(domNode);
              // on attend que toutes les expressions JS soient évaluées avant de créer les graphiques
              $.when.apply($, evalDeferreds).then(function() {
                var imageCharts = $(domNode).find('img.articque-chart');
                if (imageCharts.length && domDeferred) {
                  domDeferred.then(function(domNode) {
                    imageCharts = $(domNode).find('img.articque-chart');
                    imageCharts.each(function() {
                      simpleChart.create(this, {columns: line0, values: line}, false)
                                 .done(function(chart) { charts.push(chart); });
                    });
                  });
                }
              });
            });
            // leaflet-articque prévient lorsque le conteneur va être détruit dans le dom
            promise.domWillChange(function(domNode) {
              domDeferred = null;
              charts.forEach(simpleChart.detach);
              charts = [];
            });
            return resultStr;
          }
          // si on a pas trouvé la bonne ligne dans le dataspace
          if (window._articqueDebug) { console.log('ligne du dataspace non trouvée parmi ', this._dstt); }
          return '';
        };

        module.options.tooltip = tooltipFunction.bind(module);
        if (module instanceof L.articque.RMFill) { // pour le cas où le module est déjà initialisé
          module._getTooltip = module.options.tooltip;
        }
      }

    },

    checkDataspace: function(dataspace) {
      if (dataspace.Values && dataspace.EntitiesCount > dataspace.Values.length - 1) {
        window.reactComponents.notifBar.update({
          clearState: true,
          notification: 'ATTENTION : Le tableau de données utilisé pour les infobulles est trop grand',
          secondary: 'Seules les ' + (dataspace.Values.length - 1) + ' premières lignes sont disponibles',
          duration: -1,
          type: 'danger'
        });
      }
    }

  };
})();
