(function() {
  var A = window.articque = window.articque || {};
  var $ = window.$ || window.jQuery;
  A.ext = A.ext || {};

  A.ext.popupCard = {

    _neededDataSpaces: [],
    parent: null,
    container: null,
    popupTabAdded: false,
    popupCountTracked: false,

    loadExtension: function(parent, dependancies) {
      var $containerParent = $('#offcanvas-popup-card');

      this.container = document.createElement('div');
      this.parent = parent;

      if (! $containerParent.length) {
        $containerParent = $('.offcanvas-content');
      }
      if ($containerParent.length) {
        if ($containerParent.hasClass('offcanvas-menu')) {
          $containerParent.addClass('visible');
        }
        $containerParent.get(0).appendChild(this.container);
      }
      this.stringFormatter = dependancies.stringFormatter;
      this.simpleChart = dependancies.simpleChart;
      this.jailedEval = dependancies.jailedEval;
      this.parent.on('updateview', this.onUpdateView, this);
      if (this.parent.atlas) {
        this.parent.atlas.on('willnavigateto', this.closeAllPopups, this);
        this.parent.atlas.on('datarowclick', this.onRowClick, this);
      }
    },

    closeAllPopups: function() {
      this.parent.wvc.map.closePopup(); // on ferme toutes les popups ouvertes
      setTimeout(function() { // cas des fiches flottantes multiples
        $(".leaflet-popup-close-button:visible").each(function() { this.click(); });
      }, 300);
      // si le panneau latéral "fiche au clic" est ouvert en mode "doStack", alors on le referme
      if (this.parent.atlas && this.parent.atlas.options.panelsState && this.parent.atlas.options.panelsState['details'] &&
        this.parent.atlas.options.panelsState['details'].open && this.parent.atlas.options.panelsState['details'].previous) {
        this.parent.atlas.togglePanel(this.parent.atlas.options.panelsState['details'].position);
      }
    },

    onRowClick: function(evt) {
      var f, i;
      this.parent.wvc._modules.forEach(function(mod) {
        if ((mod.dataId == evt.dataId || mod.mapId == evt.dataId) && mod.module._features && mod.module._features.length) {
          for (i = mod.module._features.length - 1; i >= 0; i--) {
            f = mod.module._features[i]
            if (f.id == evt.rowId) {
              mod.module.fire("featureclick", { feature: f, latlng: L.latLng(f.center.y, f.center.x) });
              break;
            }
          }
        }
      });
    },

    onUpdateView: function(displayingInfos) {
      var self = this,
          popupOpen = 0, // surveillance du nb de popup ouvertes pour désactiver les tooltips pdt qu'un popup est ouverte
          onPopupOpen = function(e) {
            popupOpen++;
            self.parent.wvc.moduleGroup.disableTooltips();
          },
          onPopupClose = function(e) {
            popupOpen--;
            if (popupOpen < 1) {
              self.parent.wvc.moduleGroup.enableTooltips();
            }
          };
      this._neededDataSpaces = [];
      self.container.innerHTML = "";
      if (this.parent.uiState) { // seulement en mode édition
        this.closeAllPopups();
        //this.parent.on('share', this.onShare, this);
        this.parent.wvc._modules.forEach(function(mod) {
          if (mod.module.customParams &&
            mod.module.customParams.popupCard)
          {
            if (! self.popupTabAdded && mod.module.customParams.popupCard.type == "lateral" && window.reactComponents) {
              window.reactComponents.visualisationTabs.addLegendTab('popup-card', __('Information'));
              $('#offcanvas-popup-card').get(0).appendChild(self.container);
              self.popupTabAdded = true;
            }
            if (mod.module.customParams.popupCard.dataspaceName) {
              if (this._neededDataSpaces.indexOf(mod.module.customParams.popupCard.dataspaceName) < 0)
              {
                this._neededDataSpaces.push(mod.module.customParams.popupCard.dataspaceName);
              }
              this.parent.uiState.prefetchDataSpaceByName(mod.module.customParams.popupCard.dataspaceName)
                .done(function(dataspace) {
                  self.checkDataspace(dataspace);
                  self.initPopups(mod.module, dataspace.Values);
                }).fail(function() {
                  window.reactComponents.notifBar.update({
                    clearState: true,
                    notification: __("Erreur"),
                    secondary: __('Le dataspace "{0}" utilisé pour les fiches au clic du module "{1}" n\'existe pas', mod.module.customParams.popupCard.dataspaceName, mod.module.name) + "\n" +
                               __('Ouvrir les paramètres du module "{0}" pour corriger le problème', mod.module.name),
                    duration: -1,
                    animation: false,
                    action: __("Fermer"),
                    type: 'danger'
                  });
                });
            } else {
              self.initPopups(mod.module, null);
            }
          }
        }, this);
      } else /*if (this.parent.dataspacesCache)*/ { // en mode lecture seule (partage)
        this.parent.wvc._modules.forEach(function(mod) {
          if (mod.module.customParams &&
            mod.module.customParams.popupCard)
          {
            if (! self.popupTabAdded && mod.module.customParams.popupCard.type == "lateral") {
              $('.show-if-popup-card-lateral').show();
              self.popupTabAdded = true;
            }
            if (mod.module.customParams.popupCard.dataspaceName &&
              this.parent.dataspacesCache[mod.module.customParams.popupCard.dataspaceName])
            {
              this.initPopups(mod.module, this.parent.dataspacesCache[mod.module.customParams.popupCard.dataspaceName]);
            } else {
              this.initPopups(mod.module, null);
            }
          }
        }, this);
      }

      if (! self.popupCountTracked) {
        self.parent.wvc.map.on('popupopen', onPopupOpen, self);
        self.parent.wvc.map.on('popupclose', onPopupClose, self);
        self.popupCountTracked = true;
      }
    },

    initPopups: function(module, dataspace) {
      var self = this,
          stringFormatter = this.stringFormatter,
          simpleChart = this.simpleChart;
      if (module.customParams && module.customParams.popupCard) {
        if (dataspace) {
          module._dspc = dataspace;
        }
        var popupFunction = function(f, promise) {
          if (window._articqueDebug) { console.log('extension popup pour feature id = ', f.id) }
          if (! this._dspc) {
            if (window._articqueDebug) { console.log('pas de dataspace => contenu fixe : ', module.customParams.popupCard.content || 'vide'); }
            return module.customParams.popupCard.content;
          }
          var line0 = this._dspc[0].map(function(colName) { return colName.replace(/\r/g, "").replace(/\n/g, " "); }), // même traitement que dans popup-gui-script.jsx (support des retours à la ligne dans les en-tête de colonnes)
              charts = [],
              line = null;
          // cherche la bonne ligne
          for (var i = 0; i < this._dspc.length; i++) {
            if (this._dspc[i][0] == f.id || (module instanceof L.articque.RMFlows && this._dspc[i][0] + ' > ' + this._dspc[i][1] == f.id)) {
              line = this._dspc[i];
            }
          }
          if (line) {
            // remplace les {{col}} par les valeurs correspondantes
            if (window._articqueDebug) { console.log('ligne du dataspace trouvée ', line); }
            var domDeferred = $.Deferred(), // quand le conteneur de la fiche au clic est prêt - idem que `promise.domReady` mais conversion en Deferred pour chainer plusieurs évènements
                evalDeferreds = []; // sera resolu quand toutes les expressions ont été évaluées
            var resultStr = '<!--' + f.id + '-->' + module.customParams.popupCard.content
              .replace(/\{\{(.*?)\}\}/g, function(match, varName) {
                varName = stringFormatter.unescapeHtml(varName);
                if (varName.indexOf('|') > 0) {
                  var parts = varName.split('|'),
                      col = line0.indexOf(parts[0]),
                      func = parts[1];
                  if (col == -1 && varName.toLowerCase() == 'identifiant') { col = 0; }
                  if (col > -1) {
                    func = func.substr(0, func.indexOf('('));
                    if (parts[1].indexOf("$var") > -1) {
                        expr = parts[1].replace(/\$var/g, line[col]); // cas général (retrocompatibilité) => eval de l'expression JS
                    } else {
                      var values, feature;
                      // cherche la bonne feature
                      for (var i = 0; i < module._features.length; i++) {
                        if (module._features[i].id == f.id) {
                          feature = module._features[i];
                          break;
                        }
                      }
                      if (feature && feature.properties && module.options.valuesName && feature.properties[module.options.valuesName]){
                        values = feature.properties[module.options.valuesName];
                      } else {
                        values = [line[col]];
                      }
                      expr = "var data = { string: " + JSON.stringify(line[col]) + ", number: " + JSON.stringify(L.articque.Util.numberUnformat(line[col])) + ", values: " + JSON.stringify(values) + "};";
                      if (stringFormatter[func]) { // cas où on connait la fonction dans stringFormatter
                        expr += parts[1].replace(func + '(', "stringFormatter." + func + '(data, ') + ';';
                      } else {
                        expr += parts[1];
                      }
                    }
                    var placeholder = '<!--__articque.placeholder' + evalDeferreds.length + '-->',
                        newDeferred = self.jailedEval(expr);
                    evalDeferreds.push(newDeferred);
                    newDeferred.done(function(result) {
                      resultStr = resultStr.replace(placeholder, result);
                    });
                    return placeholder;
                  } else {
                    if (window._articqueDebug) { console.log('!!! colonne non trouvée ', match); console.log('colonnes disponibles ', line0); }
                    return match;
                  }
                } else {
                  var col = line0.indexOf(varName);
                  if (col == -1 && varName.toLowerCase() == 'identifiant') { col = 0; }
                  return (col > -1) ? line[col] : match;
                }
              });
              // si il y a au moins une expression JS évaluée de manière asynchrone
              if (evalDeferreds.length > 0) {
                $.when.apply($, evalDeferreds).then(function() {
                  // domDeferred peut être null si la tooltip disparait avant que toutes les expressions JS ne soient évaluées
                  if (domDeferred) {
                    domDeferred.then(function(domNode) {
                      // on remet la même chaine mais chaque placeholder d'expression a été remplacé car tous les deferreds sont résolus
                      if (typeof domNode._updateContent == "function")
                        domNode._updateContent(resultStr);
                    });
                  }
                });
              }
              // leaflet-articque prévient lorsque le conteneur est créé dans le dom
              promise.domReady(function(domNode) {
                domDeferred.resolve(domNode);
                // on attend que toutes les expressions JS soient évaluées avant de créer les graphiques
                $.when.apply($, evalDeferreds).then(function() {
                  var imageCharts = $(domNode).find('img.articque-chart');
                  if (imageCharts.length && domDeferred) {
                    domDeferred.then(function(domNode) {
                      imageCharts = $(domNode).find('img.articque-chart');
                      imageCharts.each(function() {
                        simpleChart.create(this, {columns: line0, values: line}, false)
                                   .done(function(chart) { charts.push(chart); });
                      });
                    });
                  }
                });
              });
              // leaflet-articque prévient lorsque le conteneur va être détruit dans le dom
              promise.domWillChange(function(domNode) {
                domDeferred = null;
                charts.forEach(simpleChart.detach);
                charts = [];
              });
              return resultStr;
          }
          // si on a pas trouvé la bonne ligne dans le dataspace
          if (window._articqueDebug) { console.log('ligne du dataspace non trouvée parmi ', this._dspc); }
          return '';
        };

        var self = this,
            onFeatureClick = function(data) {
              var promise = {
                    cbReady: null,
                    cbWillChange: null,
                    domReady: function(cb) { this.cbReady = cb; },
                    domWillChange: function(cb) { this.cbWillChange = cb; },
                    resolve: function(domNode) {
                      if (typeof this.cbReady == 'function') this.cbReady(domNode);
                      if (typeof this.cbWillChange == 'function') domNode.__leafletArticqueOnWillChange = this.cbWillChange;
                    }
                  },
                  content = popupFunction.call(module, data.feature, promise),
                  fetchDeferred;
              if (module.customParams.popupCard.content.substr(0,4) == 'http') { // cas d'une URL
                fetchDeferred = $.get(content, function(data) { content = data; });
              } else {
                fetchDeferred = $.Deferred().resolve();
              }
              fetchDeferred.done(function() {
                if (content && module.customParams.popupCard.type == "lateral" && self.container) {
                  if (typeof self.container.__leafletArticqueOnWillChange == 'function') {
                    self.container.__leafletArticqueOnWillChange(self.container);
                    self.container.__leafletArticqueOnWillChange = null;
                  }
                  self.container.style.padding = "8px";
                  self.container._updateContent = function(newContent) {
                    self.container.innerHTML = newContent;
                  }
                  self.container._updateContent(content);
                  if (window.reactComponents) {
                    if (self.parent.uiState) {
                      setTimeout(function() { self.parent.wvc.invalidateSize(); }, 100);
                    }
                    window.reactComponents.visualisationTabs.showLegend('popup-card');
                  } else {
                    if (self.parent.atlas && typeof self.parent.atlas.togglePanel == 'function') { // template v2
                      if (! self.parent.atlas.options.panelsState['details'].open)
                        self.parent.atlas.togglePanel('details', true);
                    } else { // template v1
                      if (! $('.show-if-popup-card-lateral').hasClass('active'))
                        $('.show-if-popup-card-lateral a').trigger('click');
                      if (window.matchMedia && window.matchMedia("(max-width: 767px)").matches) {
                        $(".offcanvas-menu").addClass("expanded");
                      }
                    }
                  }
                  promise.resolve(self.container);
                } else if (content && module.customParams.popupCard.type.indexOf("floating") === 0) {
                  var popup = L.popup()
                      .setLatLng(data.latlng)
                      .setContent(content);
                  if (module.customParams.popupCard.type.indexOf('multiple') > -1) {
                    self.parent.wvc.map.addLayer(popup);
                  } else {
                    popup.openOn(self.parent.wvc.map);
                  }
                  var onPopupclose = function(data) {
                    if (data.popup._leaflet_id == popup._leaflet_id) {
                      var elt = popup.getElement();
                      if (typeof elt.__leafletArticqueOnWillChange == 'function') {
                        elt.__leafletArticqueOnWillChange(elt);
                        elt.__leafletArticqueOnWillChange = null;
                      }
                      self.parent.wvc.map.off('popupclose', onPopupclose);
                    }
                  };
                  self.parent.wvc.map.on('popupclose', onPopupclose);
                  popup.getElement()._updateContent = function(newContent) {
                    popup.setContent(newContent);
                  }
                  promise.resolve(popup.getElement());
                }
              });
            },
            onLayerChanged = function(data) {
              if (! data.newLayer) {
                module.off('featureclick', onFeatureClick, self);
                module.off('layerchanged', onLayerChanged, self);
              }
            };
        module.on('featureclick', onFeatureClick, self);
        module.on('layerchanged', onLayerChanged, self);
      }

    },

    checkDataspace: function(dataspace) {
      if (dataspace.Values && dataspace.EntitiesCount > dataspace.Values.length - 1) {
        window.reactComponents.notifBar.update({
          clearState: true,
          notification: 'ATTENTION : Le tableau de données utilisé pour les fiches au clic est trop grand',
          secondary: 'Seules les ' + (dataspace.Values.length - 1) + ' premières lignes sont disponibles',
          duration: -1,
          type: 'danger'
        });
      }
    }

  };
})();
